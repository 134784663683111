import React from "react";
import PropTypes from "prop-types";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { folderReducerKeyForType } from "../../helpers/folder";
import { bindActionCreators } from "redux";
import { connect as reduxConnect } from "react-redux";
import * as audienceFolderActions from "../../actions/audienceFolderActions";
import * as flowActions from "../../actions/flowActions";
import type { Flow } from "../../types/flowTypes";

import {
    allowedToDeleteFolder,
    allowedToRenameFolder,
    allowedToCreateFolder,
    searchTree,
} from "../../reducers/_folderGeneral";

// TODO Extract
const folderTypeForMenuId = {
    "flow-tree-folder": "W",
    "layout-tree-folder": "L",
};

class FolderContextMenu extends React.Component {
    static propTypes = {
        // Passed in from our application of the connectMenu enhancer/helper
        connectMenuId: PropTypes.string.isRequired,
        // Injected from react-contextmenu
        item: PropTypes.object,
        // Injected from Redux
        layouts: PropTypes.object,
        flows: PropTypes.object,
        folders: PropTypes.array,
        roles: PropTypes.array,
        userId: PropTypes.number,
        companyId: PropTypes.number,
        isInternal: PropTypes.bool,
        folderType: PropTypes.string.isRequired,
        selectedFlowIds: PropTypes.array,
        // Injected from Redux (ActionCreators)
        startRenamingFolder: PropTypes.func.isRequired,
        requestDeleteFolder: PropTypes.func.isRequired,
        requestNewFolder: PropTypes.func.isRequired,
        requestMoveFlowsToFolder: PropTypes.func,
        isInNewFlows: PropTypes.bool,
    };

    canDelete = () => {
        const folderId = this.props.item && this.props.item.folderId ? this.props.item.folderId : null;
        const { layouts, flows, folders, userId, companyId, isInternal, roles } = this.props;
        const state = { flows, folders, layouts, roles };
        return allowedToDeleteFolder(state, folderId, userId, companyId, isInternal);
    };

    canCreate = () => {
        const folderId = this.props.item && this.props.item.folderId ? this.props.item.folderId : null;
        const { folders, userId, companyId, isInternal } = this.props;
        return allowedToCreateFolder(folders, folderId, userId, companyId, isInternal);
    };

    moveSelectedFlows = () => {
        const folderId = this.props.item && this.props.item.folderId ? this.props.item.folderId : null;
        this.props.requestMoveFlowsToFolder(this.props.selectedFlowIds, folderId);
    };

    renderMoveFlowsOption = () => {
        const { selectedFlowIds, flows, userId, folders } = this.props;
        const folderId = this.props.item && this.props.item.folderId ? this.props.item.folderId : null;
        const folder = searchTree(folders, folderId || 0);

        if (selectedFlowIds.length <= 0 || !folder || (folder.userId != userId && !folder.isHistorical)) {
            return null;
        }

        let disableOption;

        const flowsArray: Array<Flow> = Object.values(flows);
        const selectedFlows = flowsArray.filter(x => selectedFlowIds.includes(x.FlowId));
        //if folder is historical, allow only flows locked permanently to folders with user id == 0
        if (folder.isHistorical) {
            disableOption = folder.userId != 0 || selectedFlows.filter(x => !x.IsLockedPermanently).length > 0;
        } else {
            //if is not historical then only allow to move flows belonging to the user and that are not permanently locked
            disableOption = selectedFlows.filter(x => x.IsLockedPermanently || x.FlowCreatedBy != userId).length > 0;
        }

        return (
            <MenuItem onClick={this.moveSelectedFlows} disabled={disableOption}>
                Move Selected Flows Here
            </MenuItem>
        );
    };

    render() {
        const { folders, userId, companyId, isInternal, folderType, isInNewFlows } = this.props;
        const identifier = this.props.connectMenuId;
        const canDelete = this.canDelete();
        const canCreate = this.canCreate();
        const folderId = this.props.item && this.props.item.folderId ? this.props.item.folderId : null;
        const canRename = allowedToRenameFolder(folders, folderId, userId, companyId, isInternal);
        return (
            <ContextMenu
                id={identifier}
                currentItem={this.currentItem}
                className={isInNewFlows ? `${identifier} exp` : ""}
            >
                <MenuItem onClick={this.clickNew} disabled={!canCreate}>
                    New Folder
                </MenuItem>
                <MenuItem onClick={this.clickRename} disabled={!canRename}>
                    Rename Folder
                </MenuItem>
                <MenuItem data={{ some_data: 2 }} onClick={this.clickDelete} disabled={!canDelete}>
                    Delete Folder
                </MenuItem>
                {folderType == "W" && this.renderMoveFlowsOption()}
            </ContextMenu>
        );
    }

    clickRename = (e, data) => {
        const { folderType } = this.props;
        this.props.startRenamingFolder(folderType, data.folderId);
    };

    clickDelete = (e, data) => {
        const { folderType } = this.props;
        this.props.requestDeleteFolder(folderType, data.folderId);
    };

    clickNew = (e, data) => {
        const { folderType } = this.props;
        this.props.requestNewFolder(folderType, data.folderId);
    };
}

// redux
const mapStateToProps = (state, ownProps) => {
    const folderType = folderTypeForMenuId[ownProps.connectMenuId] || "A";
    const folderReducerKey = folderReducerKeyForType(folderType);
    const folders = state.folders[folderReducerKey];
    return {
        flows: state.flows.byId,
        layouts: state.layouts,
        folders,
        userId: state.session.userId,
        companyId: state.session.companyId,
        isInternal: state.session.isInternal,
        roles: state.session.roles,
        folderType,
        selectedFlowIds: state.flowMultiSelection.selectedFlowIds,
        isInNewFlows:
            state.router &&
            state.router.location &&
            state.router.location.pathname &&
            state.router.location.pathname.includes("/flows"),
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(Object.assign({}, audienceFolderActions, flowActions), dispatch);
const ConnectedFolderContextMenu = reduxConnect(mapStateToProps, mapDispatchToProps)(FolderContextMenu);

export default ConnectedFolderContextMenu;
