import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../actions/exportLayoutActions2";

import TreeItem from "../tree/TreeItem";
import TextWithHighlightedSearchTerms from "../tree/TextWithHighlightedSearchTerms";

import { DragSource } from "react-dnd";
import { DragDropTypes } from "../../helpers/constants";

import { ContextMenuTrigger } from "react-contextmenu";

type Props = {
    scope: boolean,
};

class SVFieldListEntry extends React.Component<Props> {
    handleDoubleClick = () => {
        const { field, selectedLayout, scope } = this.props;
        this.props.AddSVFieldToLayoutObjects(field, selectedLayout, scope);
    };

    componentDidMount() {
        $(this.divNode).bstooltip();
    }

    componentWillUnmount() {
        $(this.divNode).bstooltip("destroy");
    }

    render() {
        const { field, connectDragSource } = this.props;
        if (!field) {
            return null;
        }

        const displayText = field;
        return connectDragSource(
            <div
                style={{}}
                data-container="body"
                data-toggle="tooltip"
                data-placement="right"
                ref={node => (this.divNode = node)}
            >
                <ContextMenuTrigger id="variable-tree" collect={props => props} variableId={field} holdToDisplay={-1}>
                    <TreeItem handleDoubleClick={this.handleDoubleClick} active={false}>
                        <TextWithHighlightedSearchTerms text={displayText} />
                    </TreeItem>
                </ContextMenuTrigger>
            </div>
        );
    }
}

// Prop Types /
const propTypes = {
    // Provided from outside
    field: PropTypes.any,
    setSelectedLayout: PropTypes.func,
    setSelectedLayout2: PropTypes.func,
    requestSelectedLayoutObjects: PropTypes.func,
    AddSVFieldToLayoutObjects: PropTypes.func,
    updateLayoutAndPositions: PropTypes.func,
    selectedLayout: PropTypes.any,
    connectDragSource: PropTypes.func.isRequired,
    enabledFeatures: PropTypes.array,
};
SVFieldListEntry.propTypes = propTypes;

// Drag and Drop here
function collect(connect, monitor) {
    // React-dnd will inject these props into our component
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    };
}

const fieldSource = {
    canDrag(/* props */) {
        return true;
    },
    beginDrag(props) {
        return { field: props.field };
    },
};

const SVFieldListEntryDND = DragSource(DragDropTypes.SVFIELD, fieldSource, collect)(SVFieldListEntry);

// Redux //
const mapStateToProps = (state, ownProps) => ({
    field: ownProps.field,
    userId: state.session.userId,
    selectedLayout: state.selected.layout,
    enabledFeatures: state.session.enabledFeatures || [],
});
const mapDispatchToProps = dispatch => bindActionCreators(actionCreators, dispatch);

const VariableLayoutListEntryDNDC = connect(mapStateToProps, mapDispatchToProps)(SVFieldListEntryDND);

export default VariableLayoutListEntryDNDC;
