import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import h from "../../../../helpers";
import { toJson } from "../../../../helpers/typedHelpers";
import { getIsInFlowsItem } from "../../../../reducers/session";
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import { IAppState } from "../../../../types/stores/index";

type Props = {
    // Passed In
    value?: string;
    onChange: (value?: string) => void;
    disabled?: boolean;
    companyId?: number;
    fullWidth?: boolean;
};

const SingleViewFieldPicker: React.FC<Props> = ({ value, onChange, disabled, fullWidth, companyId = 0 }: Props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [fields, setFields] = useState<Array<string>>([]);

    const isInNewFlows = useSelector((state: IAppState) => getIsInFlowsItem(state));

    useEffect(() => {
        if (loading) {
            fetch("/SingleView2/GetFields/" + companyId, { credentials: "same-origin" })
                .then(h.checkStatus)
                .then(toJson)
                .then(data => {
                    const response = data as unknown as { success: boolean; columns: Array<any> };
                    if (response.success) {
                        const fields: Array<string> = response.columns;
                        fields.sort();
                        setLoading(false);
                        setError(false);
                        setFields(fields);
                    } else {
                        setLoading(false);
                        setError(true);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    setError(true);
                    h.error("Error fetching SingleView fields.", error);
                });
        }
    }, [loading, companyId]);

    const myOnChange = (e: any) => onChange(e.target.value);

    if (error) {
        return <div>Error loading SingleView fields.</div>;
    }

    if (disabled && !isInNewFlows) {
        // Fix flow type complaining about 'undefined' returns.
        return value != null ? <div>value</div> : null;
    }

    return (
        <FormControl style={{ width: "100%" }}>
            <InputLabel style={fullWidth ? {} : { padding: "10px 0px 0px 10px" }}>Dedupe Field</InputLabel>
            <Select
                id="dedupe-key"
                style={
                    fullWidth
                        ? { width: "100%" }
                        : { fontSize: 16, marginLeft: "10px", paddingTop: "10px", width: "150px" }
                }
                onChange={myOnChange}
                value={value || ""}
                disabled={loading || disabled}
            >
                {fields.map((field, i) => (
                    <MenuItem key={i} value={field}>
                        {field}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SingleViewFieldPicker;
