import React, { useState, useEffect } from "react";
import TreeItem from "./TreeItem";
import { useSelector } from "react-redux";
import { IAppState } from "../../types/stores/index";

type Props = {
    collapsed?: boolean;
    defaultCollapsed: boolean;
    nodeLabel: React.ReactNode;
    itemClassName?: string;
    children: React.ReactElement;
    onUpdate?: () => void;
    headerMap?: ((headerTreeItem: any) => JSX.Element) | null;
};

const TreeView: React.FC<Props> = ({
    defaultCollapsed,
    collapsed,
    nodeLabel,
    itemClassName,
    children,
    onUpdate,
    headerMap,
}: Props) => {
    const [collapseState, setCollapseState] = useState(typeof collapsed == "boolean" ? collapsed : defaultCollapsed);
    const pathname = useSelector(
        (state: IAppState) => state.router && state.router.location && state.router.location.pathname
    );

    const handleClick = () => setCollapseState(!collapseState);

    useEffect(() => {
        if (onUpdate) {
            onUpdate();
        }

        if (collapseState) {
            setCollapseState(typeof collapsed == "boolean" ? collapsed : defaultCollapsed);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onUpdate, defaultCollapsed, collapsed]);

    let containerClassName = "tree-view_children";
    if (collapseState) {
        containerClassName += " tree-view_children-collapsed";
    }

    let folderIcon = <i className="proton-icon proton-icon-folder-open" />;
    if (collapseState) {
        folderIcon = <i className="proton-icon proton-icon-folder-closed" />;
    }

    if (
        pathname &&
        (pathname.includes("flows") ||
            pathname.includes("schedules") ||
            pathname.includes("layouts") ||
            pathname.includes("/admin/fields/edit") ||
            pathname.includes("/admin/restricted-fields"))
    ) {
        folderIcon = (
            <i className="material-icons" style={{ position: "relative", fontSize: "2em" }}>
                folder_open
            </i>
        );
        if (collapseState) {
            folderIcon = (
                <i className="material-icons" style={{ position: "relative", fontSize: "2em" }}>
                    folder
                </i>
            );
        }
    }

    let headerTreeItem = (
        <TreeItem staticClasses={"tree-view_header " + itemClassName || ""} handleClick={handleClick}>
            {folderIcon}
            <span className="tree-header-label">{nodeLabel}</span>
        </TreeItem>
    );

    if (headerMap) {
        headerTreeItem = headerMap(headerTreeItem);
    }

    return (
        <div className="tree-view">
            {headerTreeItem}
            <div className={containerClassName}>{collapseState ? null : children}</div>
        </div>
    );
};

export default TreeView;
