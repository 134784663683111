export function fetchT<T>(
    url: string,
    method: string = "GET",
    body: FormData | string | null = null,
    headers?: Headers
): Promise<T> {
    return fetch(url, {
        credentials: "same-origin",
        method,
        body,
        headers,
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    });
}

const getJsonHeaders = () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json; charset=utf-8");
    return headers;
};

export const jsonHeaders: Headers = getJsonHeaders();

// this should be the basic result from fetch, then it can be extended like:
// type IGetFieldLabelApprovalData = IRequestResult & {
//    fieldApprovalData: IFieldApprovalData;
//};
export type IRequestResult = {
    success: boolean;
    message: string;
};
