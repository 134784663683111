import update from "immutability-helper";
import { ILogMessageStore, LogMessageActions, ILogMessage } from "../types/stores/logMessage";

export const GET_LOG_MESSAGES = "GET_LOG_MESSAGES";

const initialState: ILogMessageStore = {
    logMessages: Array<ILogMessage>(),
    isLoading: false,
};

function logMessage(state = initialState, action: LogMessageActions): ILogMessageStore {
    switch (action.type) {
        case GET_LOG_MESSAGES: {
            return update(state, {
                logMessages: { $set: action.logMessages },
                isLoading: { $set: action.isLoading },
            });
        }

        default:
            return state;
    }
}

export default logMessage;
