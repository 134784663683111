import * as React from "react";
import withScrolling from "react-dnd-scrolling";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

const ScrollingComponent = withScrolling("div");

const DragDropWithScrollContext = PassedComponent =>
    class DragDropWithScrollContext extends React.Component {
        render() {
            return (
                <DndProvider backend={HTML5Backend} context={window}>
                    <ScrollingComponent>
                        <PassedComponent {...this.props} />
                    </ScrollingComponent>
                </DndProvider>
            );
        }
    };
export default DragDropWithScrollContext;
