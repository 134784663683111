import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../actions/actionCreators";
import * as fieldTreeActions from "../../actions/fieldTreeActions";
import * as newExportLayoutActions from "../../actions/exportLayoutActions2";
import { getTreeSearch } from "../../reducers/search";
import TreeItem from "../tree/TreeItem";
import FieldListEntryText from "./FieldListEntryText";
import { DragDropTypes, FieldVisibilityTypes } from "../../helpers/constants";
import { DragSource, ConnectDragSource, DragSourceMonitor, DragSourceConnector } from "react-dnd";
import { ContextMenuTrigger } from "react-contextmenu";
import HtmlTooltip from "../material-components/Misc/HtmlTooltip";
import makeTooltipForField from "./makeTooltipForField";
import { IField } from "../../types/stores/fieldTypes";
import { IAppState } from "../../types/stores";

type Props = {
    // passed in
    fieldKey: number;
    TreeType?: number;
    ctrlShift: boolean;
    onCtrlShift?: (lastSelected: number, fieldKey: number) => void;
    // redux
    field?: IField;
    lastSelected?: number;
    selectedField?: number;
    selectedFields?: Array<number>;
    enabledFeatures?: Array<string>;
    page?: string;
    selectedLayout?: number;
    pathname?: string;
    selectedCompanyId?: number;
    treeSearch?: string | Array<string>;
    showHiddenFields?: boolean;
    setVar?: (property: string, value: any) => void;
    setEditFolder?: (isEditing: boolean) => void;
    setSelectedField?: (fieldKey: number) => void;
    clearSelectedField?: () => void;
    setSelectedFields?: (fieldKey: number) => void;
    clearSelectedFields?: () => void;
    AddFieldToLayoutObjects?: (fieldKey: number, selectedLayout: number) => void;
    genericQbAddField?: (fieldKey: number) => void;
    addRestrictedField?: (fieldKey: number, selectedCompanyId: number) => void;
    connectDragSource?: ConnectDragSource;
    isDraggingItemMe?: boolean;
    isGrayout?: boolean;
};

// Drag Sources must be React Component Classes - maybe we can change when we upgrade react dnd?
class FieldListEntry extends React.Component<Props> {
    handleClick = e => {
        const { fieldKey, lastSelected, selectedField, ctrlShift } = this.props;
        this.props.setEditFolder!(false);
        if (e.ctrlKey && ctrlShift) {
            if (e.shiftKey) {
                this.props.onCtrlShift!(lastSelected!, fieldKey);
                this.props.clearSelectedField!();
            } else {
                if (selectedField && selectedField != fieldKey) {
                    this.props.setSelectedFields!(selectedField);
                }
                this.props.setSelectedFields!(fieldKey);
                this.props.clearSelectedField!();
            }
        } else {
            this.props.setSelectedField!(fieldKey);
            this.props.clearSelectedFields!();
        }
    };

    handleDoubleClick = () => {
        const {
            fieldKey,
            selectedCompanyId,
            selectedLayout,
            enabledFeatures,
            AddFieldToLayoutObjects,
            genericQbAddField,
            page,
            pathname,
            addRestrictedField,
        } = this.props;

        // This should be reworked, it's too 'magical' and hard to track down. Not sure how yet..
        const pageString = (page && page.toLowerCase()) || "";
        const hasManageLayouts = enabledFeatures!.includes("manage-layouts");

        if (pageString.includes("layout")) {
            if (hasManageLayouts) {
                AddFieldToLayoutObjects!(fieldKey, selectedLayout!);
            }
        } else if (pageString == "ae/index") {
            // SPA - Requires lots of maintenance here :(
            if (pathname && pathname.includes("restricted-fields")) {
                addRestrictedField!(fieldKey, selectedCompanyId!);
            } else {
                // SPA - OTHER (Flow)
                genericQbAddField!(fieldKey);
            }
        }
    };

    render() {
        const {
            field,
            TreeType,
            selectedField,
            selectedFields,
            fieldKey,
            connectDragSource,
            treeSearch,
            pathname,
            isDraggingItemMe,
            showHiddenFields,
            enabledFeatures,
            isGrayout,
        } = this.props;

        if (!field) {
            return null;
        }

        if (!showHiddenFields && field.FieldSelectBy == "N") {
            return null;
        }

        const active = selectedField == fieldKey || (selectedFields && selectedFields.includes(fieldKey));

        let identifier = "field-context";
        if (pathname && pathname.includes("fields/edit")) {
            identifier = "edit-context";
        }

        let mainContent;

        let allowMultiple3p = enabledFeatures!.includes("allow-multiple-3P-data-providers");
        
        if (isGrayout && !allowMultiple3p) {
            const backgroundColor = "darkGray";
            mainContent = (
                <div style={{ width: "91%", float: "left", backgroundColor }}>
                    <TreeItem handleClick={this.handleClick} handleDoubleClick={this.handleDoubleClick} active={active}>
                        <FieldListEntryText fieldKey={fieldKey} TreeType={TreeType} />
                    </TreeItem>
                </div>
            );
        } else {
            const backgroundColor = field.FieldSelectBy == "Y" ? "inherit" : "lightGray";
            mainContent = (
                <div style={{ width: "91%", float: "left", backgroundColor }}>
                    <TreeItem handleClick={this.handleClick} handleDoubleClick={this.handleDoubleClick} active={active}>
                        <FieldListEntryText fieldKey={fieldKey} TreeType={TreeType} />
                    </TreeItem>
                </div>
            );
        }

        if (!isDraggingItemMe) {
            const mainTooltip = makeTooltipForField(field, treeSearch);
            mainContent = (
                <HtmlTooltip placement="right" title={mainTooltip} enterDelay={250} maxwidth={500}>
                    {mainContent}
                </HtmlTooltip>
            );
        }

        return connectDragSource!(
            <div style={{ clear: "both" }}>
                <div>
                    <ContextMenuTrigger
                        id={identifier}
                        collect={props => props}
                        /* @ts-ignore */
                        fieldKey={fieldKey}
                        /* @ts-ignore */
                        item={field}
                        holdToDisplay={-1}
                    >
                        {mainContent}
                        <div style={{ clear: "both" }} />
                    </ContextMenuTrigger>
                </div>
            </div>
        );
    }
}

// Drag and Drop here
function collect(connect: DragSourceConnector, monitor: DragSourceMonitor) {
    // React-dnd will inject these props into our component
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    };
}
const fieldSource = {
    canDrag() {
        return true;
    },
    beginDrag(props: Props) {
        props.setVar!("isItemDragging", true);
        props.setVar!("itemDraggingType", DragDropTypes.FIELD);
        props.setVar!("itemDraggingDetail", props.fieldKey);
        return { fieldKey: props.fieldKey, workid: props.field!.workid };
    },
    endDrag(props) {
        props.setVar!("isItemDragging", false);
        props.setVar!("itemDraggingType", "");
        props.setVar!("itemDraggingDetail", null);
    },
};

const FieldListEntryWithDND = DragSource(DragDropTypes.FIELD, fieldSource, collect)(FieldListEntry);

// END DND

const mapStateToProps = (state: IAppState, ownProps: Props) => {
    const fieldState =
        ownProps.TreeType == FieldVisibilityTypes.EXPORT
            ? state.layoutfields
            : ownProps.TreeType == FieldVisibilityTypes.REPORT
            ? state.reportFields
            : state.fields;

    const field = fieldState.byId[ownProps.fieldKey];

    // Render optimization - only the one being dragged removes its tooltip
    const isDraggingItemMe =
        state.vars.isItemDragging &&
        state.vars.itemDraggingType == DragDropTypes.FIELD &&
        state.vars.itemDraggingDetail == ownProps.fieldKey;

    return {
        field,
        treeSearch: getTreeSearch(state),
        selectedField: state.selected.field,
        selectedFields: state.selected.selectedFields,
        lastSelected: state.selected.lastSelected,
        page: state.vars.page,
        selectedLayout: state.selected.layout,
        enabledFeatures: state.session.enabledFeatures || [],
        isDraggingItemMe,
        pathname: state.router && state.router.location && state.router.location.pathname,
        selectedCompanyId: state.adminCompanies.selectedCompanyId,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(Object.assign({}, actionCreators, fieldTreeActions, newExportLayoutActions), dispatch);
const FieldListEntryConnected = connect(mapStateToProps, mapDispatchToProps)(FieldListEntryWithDND);

export default FieldListEntryConnected;
