import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";

type Props = {
    id: string;
    value: string;
    disabled?: boolean;
    onChange: (newValue: string) => void;
};

const MaterialTableTextField: React.FC<Props> = ({ id, value, disabled, onChange }) => {
    const [localValue, setLocalValue] = useState("");

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    return (
        <TextField
            key={id}
            id={id}
            value={localValue || ""}
            onBlur={() => onChange(localValue)}
            onChange={e => setLocalValue(e.target.value)}
            fullWidth
            disabled={disabled}
        />
    );
};

export default MaterialTableTextField;
