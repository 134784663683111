import {
    DestinationGroupingParentsActions,
    IDestinationGroupingParentsStore,
} from "../types/stores/destinationGroupingParents";

export const SET_DESTINATIONGROUPINGPARENTS = "SET_DESTINATIONGROUPINGPARENTS";
export const SET_LOADING_DESTINATIONGROUPING_PARENTS = "SET_LOADING_DESTINATIONGROUPING_PARENTS";
export const SET_DESTINATIONGROUPINGPARENT_DETAILS = "SET_DESTINATIONGROUPINGPARENT_DETAILS";
export const SET_IS_SAVING_DESTINATIONGROUPING_PARENTS = "SET_IS_SAVING_DESTINATIONGROUPING_PARENTS";
export const SET_SAVE_COMPLETED_DESTINATIONGROUPING_PARENTS = "SET_SAVE_COMPLETED_DESTINATIONGROUPING_PARENTS";

const initialState: IDestinationGroupingParentsStore = {
    destinationGroupingParents: [],
    isLoading: false,
    isSaving: false,
    completed: false,
    //groupingsByParentId: {},
    destinationGroupingParent: null,
};

function destinationGroupingParents(
    state = initialState,
    action: DestinationGroupingParentsActions
): IDestinationGroupingParentsStore {
    switch (action.type) {
        case SET_DESTINATIONGROUPINGPARENTS:
            return Object.assign({}, state, {
                destinationGroupingParents: action.destinationGroupingParents,
            });
        case SET_LOADING_DESTINATIONGROUPING_PARENTS:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case SET_IS_SAVING_DESTINATIONGROUPING_PARENTS:
            return Object.assign({}, state, {
                isSaving: action.isSaving,
            });
        case SET_SAVE_COMPLETED_DESTINATIONGROUPING_PARENTS:
            return Object.assign({}, state, {
                completed: action.completed,
            });
        case SET_DESTINATIONGROUPINGPARENT_DETAILS:
            return Object.assign({}, state, {
                destinationGroupingParent: action.destinationGroupingParent,
            });
        default:
            return state;
    }
}

export default destinationGroupingParents;
