import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Hidden from "@material-ui/core/Hidden";

// core components
import styles from "../../../styles/dashComponentStyles/Table/TableHeaderStyle";

//auto left first col and right rest unless specified alignment
function alignCell(row, entry) {
    if (entry[3] == "left" || entry[3] == "center" || entry[3] == "right") {
        return entry[3];
    } else if (row[0] == entry) {
        return "left";
    } else return "right";
}

export function IconComponent(order, orderBy, header, onClick) {
    return () => [
        <i
            key={header + "-" + order}
            className={"material-icons"}
            style={orderBy === header ? { opacity: 1 } : { opacity: 0 }}
            onClick={onClick}
        >
            {order === "asc" ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        </i>,
    ];
}

function TableHeader(props) {
    const { classes, headers, ignoreHeaders, orderBy, order, onClick, widget, customLeadingColumnWidth, ...rest } =
        props;

    const createSortHandler = property => event => {
        onClick(event, property);
    };

    return (
        <TableHead {...rest}>
            <TableRow className={widget ? classes.widgetHeaderRow : classes.pageHeaderRow}>
                {headers.map(
                    (header, index) =>
                        header && (
                            <Hidden
                                key={index}
                                xlDown={header[2] == "xlDown"}
                                lgDown={header[2] == "lgDown"}
                                mdDown={header[2] == "mdDown"}
                                smDown={header[2] == "smDown"}
                                xsDown={header[2] == "xsDown"}
                            >
                                <TableCell
                                    className={widget ? classes.widgetHeaderCell : classes.pageHeaderCell}
                                    style={
                                        customLeadingColumnWidth
                                            ? { maxWidth: index == 0 ? customLeadingColumnWidth : "125px" }
                                            : null
                                    }
                                    key={header[0] + index}
                                    align={alignCell(headers, header)}
                                    sortDirection={orderBy === header[0] ? order : false}
                                    onClick={
                                        !(ignoreHeaders && ignoreHeaders.includes(header[0]))
                                            ? createSortHandler(header[0])
                                            : null
                                    }
                                >
                                    {order != null &&
                                        orderBy != null &&
                                        !(ignoreHeaders && ignoreHeaders.includes(header[0])) && (
                                            <TableSortLabel
                                                key={header[0] + index}
                                                classes={{
                                                    icon:
                                                        orderBy === header[0]
                                                            ? classes.activeSortIcon
                                                            : classes.inactiveSortIcon,
                                                }}
                                                active={orderBy === header[0]}
                                                direction={order}
                                                IconComponent={IconComponent(
                                                    order,
                                                    orderBy,
                                                    header[0],
                                                    createSortHandler(header[0])
                                                )}
                                            />
                                        )}
                                    {header[1]}
                                </TableCell>
                            </Hidden>
                        )
                )}
            </TableRow>
        </TableHead>
    );
}

TableHeader.propTypes = {
    customLeadingColumnWidth: PropTypes.string,
    classes: PropTypes.object,
    headers: PropTypes.array.isRequired,
    ignoreHeaders: PropTypes.array,
    order: PropTypes.oneOf(["asc", "desc"]),
    orderBy: PropTypes.string,
    onClick: PropTypes.func,
    widget: PropTypes.bool,
};

export default withStyles(styles)(TableHeader);
