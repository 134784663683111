import {
    IXandrRateCategoryStore,
    SET_XANDR_RATE_CATEGORIES,
    SET_XANDR_RATE_CATEGORIES_LOADING,
    XandrRateCategoryActions,
} from "../types/stores/xandrRateCategory";

const defaultState: IXandrRateCategoryStore = {
    categories: [],
    isLoading: false,
};

function xandrRateCategories(state = defaultState, action: XandrRateCategoryActions): IXandrRateCategoryStore {
    switch (action.type) {
        case SET_XANDR_RATE_CATEGORIES_LOADING:
            return { ...state, isLoading: action.isLoading };

        case SET_XANDR_RATE_CATEGORIES:
            return { ...state, categories: action.categories };

        default:
            return state;
    }
}
export default xandrRateCategories;
