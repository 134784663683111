import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import type { FlowScriptDBUI, FlowItem, FlowErrorsByItemId } from "../types/flowTypes";
import { FlowScriptResultsByItemId, getFlowScriptResultsByFlowItemId } from "./flowScriptResults";

const myGenericReducer = makeReducerFor("FLOW_SCRIPT_DBUI", "FlowScriptDBUIId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowScriptsDBUIArray = createSelector(
    state => state.flowScriptsDBUI.byId,
    (flowScriptsDBUIById: {| [number]: FlowScriptDBUI |}): Array<FlowScriptDBUI> => {
        const r: Array<FlowScriptDBUI> = Object.values(flowScriptsDBUIById);
        return r;
    }
);

export type FlowScriptsDBUIByItemId = {
    [number]: FlowScriptDBUI,
};
// eslint-disable-line
export const getFlowScriptsDBUIByFlowItemId = createSelector(
    state => getFlowScriptsDBUIArray(state),
    (flowScriptsDBUI: Array<FlowScriptDBUI>): FlowScriptsDBUIByItemId =>
        flowScriptsDBUI.reduce((acc, row) => {
            acc[row.FlowItemId] = row;
            return acc;
        }, {})
);

export const getFlowScriptsDBUIForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowScriptsDBUIByFlowItemId(state),
    (
        selectedFlow: number,
        flowItems: Array<FlowItem>,
        flowScriptsDBUIByItemId: FlowScriptsDBUIByItemId
    ): Array<FlowScriptDBUI> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        const result = [];
        for (const itemId of itemIds) {
            const script = flowScriptsDBUIByItemId[itemId];
            if (script != null) {
                result.push(script);
            }
        }
        return result;
    }
);

export const makeGetScriptDBUIErrorsForId = () =>
    createSelector(
        (_, props) => props.id,
        state => getScriptDBUIErrorsForSelectedFlow(state),
        (id: number, itemErrors: FlowErrorsByItemId): Array<string> => itemErrors[id] || []
    );

const scriptsDBUIToErrorsById = (
    flowScriptsDBUI: Array<FlowScriptDBUI>,
    flowScriptResultsById: Array<FlowScriptResultsByItemId>
): FlowErrorsByItemId => {
    const errors = {};
    for (const item of flowScriptsDBUI) {
        errors[item.FlowItemId] = validateFlowScript(item, flowScriptResultsById[item.FlowItemId]);
    }
    return errors;
};

export const getScriptDBUIErrorsForSelectedFlow = createSelector(
    state => getFlowScriptsDBUIForSelectedFlow(state),
    state => getFlowScriptResultsByFlowItemId(state),
    scriptsDBUIToErrorsById
);

export const getScriptDBUIErrorsForAllFlows = createSelector(
    state => getFlowScriptsDBUIArray(state),
    state => getFlowScriptResultsByFlowItemId(state),
    scriptsDBUIToErrorsById
);

//////////////////// HELPERS /////////////////////////////

const validateFlowScript = (
    flowScriptDBUI: FlowScriptDBUI,
    flowScriptResults: FlowScriptResultsByItemId
): Array<string> => {
    const errors = [];

    if (!flowScriptDBUI.Script || flowScriptDBUI.Script.trim().length === 0) {
        errors.push("No Script has been entered.");
    }

    for (let x in flowScriptResults) {
        const item = flowScriptResults[x];
        const message = item.IsValid ? "" : `Statement ${item.ScriptId} errored. ${item.InvalidReason}`;
        if (message) {
            errors.push(message);
        }
    }

    return errors;
};
