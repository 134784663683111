import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { updateAttribute } from "../../../../actions/flowActions";
import { requestCompanies, setLoadingCompany } from "../../../../actions/userActions";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHeader from "../../../material-components/Table/TableHeader";
import TableBody from "../../../material-components/Table/TableBody";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
// used to remove arrow icons from number text fields
// cant have those because we fail to update on click from 1 -> 0
// due to using both selects and text fields
const styles = {
    input: {
        "&[type=number]": {
            "-moz-appearance": "textfield",
        },
        "&::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "&::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
    },
};

const ExportTikTokTemplateFields = ({ destinationId, flowExportTikTokTemplateFields, canEdit }) => {
    const dispatch = useDispatch();

    // state
    const [templateRows, setTemplateRows] = useState([]);

    // props
    const companies = useSelector(state => state.companies.byId);
    const loadingCompanies = useSelector(state => state.vars.loadingCompanies);
    const flow = useSelector(state => state.flows.byId[state.selected.flow]);

    const renderTemplateRows = useCallback(() => {
        let rows = [];

        if (flowExportTikTokTemplateFields != null) {
            for (const template of flowExportTikTokTemplateFields) {
                rows.push({
                    TemplateId: template.FlowExportTemplateId,
                    ParentFlowItemId: template.ParentFlowItemId,
                    TargetingAudienceName: template.TargetingAudienceName || "",
                    AdvertiserID: template.AdvertiserID || "",
                });
            }
        }

        setTemplateRows(rows);
    }, [flowExportTikTokTemplateFields]);

    // effects
    useEffect(() => {
        renderTemplateRows();
    }, [destinationId, flowExportTikTokTemplateFields, renderTemplateRows]);

    useEffect(() => {
        if (!companies || (Object.keys(companies).length <= 0 && !loadingCompanies)) {
            dispatch(setLoadingCompany(true));
            dispatch(requestCompanies());
        }
    }, [dispatch, companies, loadingCompanies]);

    const renderTemplateTable = () => {
        if (!companies) {
            return false;
        }

        const tableHeaders = [
            ["TargetingAudienceName", "Audience Name", "smDown", "left"],
            ["AdvertiserID", "Advertiser ID", "smDown", "left"],
        ];

        const emptyMessage = ["EmptyMessage", "No Driver Fields AMCH"];
        let data = [];
        templateRows.forEach((item, index) => {
            const row = [
                ["TargetingAudienceName", renderTargetingAudienceNameColumn(item, index), "smDown", "left"],
                ["AdvertiserID", renderAdvertiserIDColumn(item, index), "smDown", "left"],
            ];

            data.push(row);
        });

        return (
            <div id="templateFieldsTable" style={{ width: "100%", overflowX: "auto" }}>
                <Table size={"medium"}>
                    <TableHeader style={{ fontSize: "20px" }} headers={tableHeaders} ignoreHeaders={tableHeaders} />
                    <TableBody
                        style={{ fontSize: "18px" }}
                        data={data}
                        page={0}
                        rowsPerPage={data.length}
                        emptyMessage={emptyMessage}
                    />
                </Table>
            </div>
        );
    };

    const renderTargetingAudienceNameColumn = (item, index) => {
        const rows = [...templateRows];
        const column = (
            <TextField
                key={index + "-audience-name-" + item.TemplateId}
                type="text"
                value={item.TargetingAudienceName}
                disabled={!canEdit || (flow && flow.IsLocked)}
                inputProps={{
                    style: { fontSize: "16px" },
                    maxLength: 65,
                }}
                fullWidth
                onChange={e => {
                    rows[index].TargetingAudienceName = removeBlockCharacters(e.target.value);
                    return setTemplateRows(rows);
                }}
                onBlur={e => handleChange(item.TemplateId, "TargetingAudienceName", e)}
            />
        );

        return column;
    };

    const renderAdvertiserIDColumn = (item, index) => {
        const rows = [...templateRows];
        const regex = RegExp(/^[0-9]*$/);
        const column = (
            <TextField
                key={index + "-audience-name-" + item.TemplateId}
                value={item.AdvertiserID}
                disabled={!canEdit || (flow && flow.IsLocked)}
                inputProps={{
                    style: { fontSize: "16px" },
                    maxLength: 50,
                }}
                fullWidth
                onChange={e => {
                    if (e.target.value == "" || regex.test(e.target.value)) {
                        rows[index].AdvertiserID = e.target.value;
                        return setTemplateRows(rows);
                    }
                }}
                onBlur={e => handleChange(item.TemplateId, "AdvertiserID", e)}
            />
        );

        return column;
    };

    const handleChange = (TemplateId, attributeName, e) => {
        dispatch(
            updateAttribute(
                "flowExportTikTokTemplateFields",
                TemplateId,
                attributeName,
                removeBlockCharacters(e.target.value)
            )
        );
    };

    const removeBlockCharacters = value => value.replace(/,/g, "");

    return (
        <>
            <div className="flow-edit-header" style={{ flexWrap: "wrap" }}>
                <div style={{ display: "flex", flexGrow: 1 }}>
                    <p>TikTok Driver File Fields</p>
                </div>
                <div className="flow-edit-header-actions" style={{ display: "flex", marginLeft: "20px" }}>
                    <div style={{ display: "flex", marginLeft: "40px" }}></div>
                </div>
            </div>
            <Divider style={{ margin: "20px auto" }} />
            <Paper style={{ marginTop: "50px", width: "100%, padding: 5px" }}>{renderTemplateTable()}</Paper>
            <Divider style={{ margin: "40px auto 20px auto" }} />
        </>
    );
};

ExportTikTokTemplateFields.propTypes = {
    destinationId: PropTypes.number,
    flowExportTikTokTemplateFields: PropTypes.array,
    canEdit: PropTypes.bool,
};

export default withStyles(styles)(ExportTikTokTemplateFields);
