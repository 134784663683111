import h from "../helpers";
import { SET_USER_AUTOMATION_ITEMS } from "../reducers/automation";
import { IAutomationItemWithName, ISetUserAutomationItems } from "../types/stores/manageAutomation";
import { Dispatch } from "../types/types";

export const SetUserAutomationItems = (
    userAutomationItems: Array<IAutomationItemWithName>
): ISetUserAutomationItems => ({
    type: SET_USER_AUTOMATION_ITEMS,
    userAutomationItems,
});

export const getUserAutomationItems =
    () =>
    (dispatch: Dispatch): Promise<void> =>
        fetch("/ManageAutomation/GetUserAutomationItems", { credentials: "same-origin", method: "POST" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const response = data as unknown as { items: Array<IAutomationItemWithName> };

                dispatch(
                    SetUserAutomationItems(
                        response.items.sort((a, b) => {
                            if (a.JobName < b.JobName) return -1;
                            if (a.JobName > b.JobName) return 1;
                            return 0;
                        })
                    )
                );
            })
            .catch(error => {
                h.error("Error getting automation items.", error);
            });
