import React from "react";
import { FieldDropTargetGeneric } from "../tree-field/FieldDropTarget";
import CrosstabDimensionField from "../crosstab/CrosstabDimensionField";
import DirectionPicker from "./DirectionPicker";
import clone from "../../helpers/clone";
import { SortByFieldNoNull, SortDirection } from "../../types/stores/fieldTypes";

type Props = {
    value: Array<SortByFieldNoNull>;
    onChange: (value: Array<SortByFieldNoNull>) => void;
    disabled?: boolean;
    sortSplitOrderBy?: SortDirection;
};

const SortByFieldsPicker: React.FC<Props> = ({ value, onChange, disabled, sortSplitOrderBy }: Props) => {
    const directionChanged = (newVal: SortDirection, index: number) => {
        const newValue: Array<SortByFieldNoNull> = clone(value);
        if (newValue[index] == null) {
            return;
        }
        newValue[index].SortDirection = newVal;
        onChange(newValue);
    };

    const fieldRemoved = (index: number) => {
        const newValue = clone(value);
        newValue.splice(index, 1);
        onChange(newValue);
    };

    const onFieldDropped = (fieldId: string) => {
        const parsedFieldId = parseInt(fieldId, 10);
        const newEntry: SortByFieldNoNull = {
            SortFieldId: parsedFieldId,
            SortDirection: sortSplitOrderBy ? sortSplitOrderBy : "asc",
        };
        if (value == null) {
            onChange([newEntry]);
        } else {
            onChange(value.concat(newEntry));
        }
    };

    const renderFields = () => {
        if (value == null || value.length == 0) {
            return (
                <div className="m-t-xs no-fields">
                    <hr className="m-a-0" />
                    <strong>
                        <em>(No Fields Defined)</em>
                    </strong>
                </div>
            );
        }

        return value.map((field, index) => (
            <div key={index} className="m-t">
                <hr className="m-a-0" />
                <div
                    className="m-t field-count"
                    style={{
                        fontSize: "14px",
                        fontWeight: 500,
                    }}
                >
                    Field {index + 1}
                </div>
                <div>
                    <CrosstabDimensionField
                        fieldKey={field.SortFieldId}
                        onRemoveField={() => fieldRemoved(index)}
                        disabled={disabled || false}
                    />
                </div>
                <div>
                    <DirectionPicker
                        value={field.SortDirection || ""}
                        onChange={newDir => directionChanged(newDir, index)}
                        className="m-t-xs"
                        disabled={disabled || false}
                    />
                </div>
            </div>
        ));
    };

    return (
        <div className="sort-by-fields">
            <div>
                <h6 className="m-t">Sort By Fields</h6>
                <div>
                    <FieldDropTargetGeneric
                        onFieldDropped={onFieldDropped}
                        wording="field"
                        isDisabled={disabled || false}
                    />
                </div>
            </div>
            <div className="render-fields">{renderFields()}</div>
        </div>
    );
};

export default SortByFieldsPicker;
