import React from "react";
import * as fieldTreeActions from "../../actions/fieldTreeActions";
import * as actionCreators from "../../actions/actionCreators";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
    DropTarget,
    DropTargetMonitor,
    DropTargetConnector,
    DragSourceConnector,
    DragSourceMonitor,
    ConnectDragSource,
    ConnectDropTarget,
} from "react-dnd";
import { DragSource } from "react-dnd";
import { DragDropTypes } from "../../helpers/constants";
import { ContextMenuTrigger } from "react-contextmenu";
import HtmlTooltip from "../material-components/Misc/HtmlTooltip";
import { IField } from "../../types/stores/fieldTypes";
import { IAppState } from "../../types/stores/index";

type Props = {
    field: IField;
    toolTip: JSX.Element | string;
    children: JSX.Element | null;
    // Injected by ReactDnD
    connectDragSource?: ConnectDragSource;
    connectDropTarget?: ConnectDropTarget;
    isOver?: boolean;
    canDrop?: boolean;
    // Redux
    selectedFields?: Array<number>;
    // Redux AC
    requestMoveFieldToFolder?: (fieldId: number, newFolderId: number) => void;
    requestMoveFieldsToFolder?: (selectedFields: Array<number>, newFolderId: number) => void;
};

// Drag Sources must be React Component Classes  - maybe we can change when we upgrade react dnd?
class FieldFolderDragDrop extends React.Component<Props> {
    render() {
        let { field, children, toolTip, connectDropTarget, connectDragSource, isOver, canDrop } = this.props;

        // Pass isOver, canDrop props to child element
        if (children != null && typeof children == "object") {
            children = React.cloneElement(children, { isOver, canDrop });
        }
        const hasChildren = children && children.props.children.length > 0;

        const rendered = (
            <div>
                <ContextMenuTrigger
                    id="edit-context"
                    collect={props => props}
                    key={field.id}
                    /* @ts-ignore */
                    item={field}
                    hasChildren={hasChildren}
                    holdToDisplay={-1}
                >
                    <HtmlTooltip placement="right" title={toolTip} enterDelay={250} maxwidth={500}>
                        <div>{children}</div>
                    </HtmlTooltip>
                </ContextMenuTrigger>
            </div>
        );

        // Stupid workaround for flow
        const a = connectDropTarget!(rendered);
        if (a != null) {
            return connectDragSource!(a);
        }
        return a;
    }
}

const folderTarget = {
    canDrop() {
        return true;
    },
    drop(props: Props, monitor: DropTargetMonitor) {
        // Dropped onto folder
        const dropped = monitor.getItem(); // workid is a field key
        if (props.selectedFields!.length > 0) {
            props.requestMoveFieldsToFolder!(props.selectedFields!, props.field.workid!);
        } else {
            props.requestMoveFieldToFolder!(dropped.workid, props.field.workid!);
        }
    },
};
function collectTarget(connect: DropTargetConnector, monitor: DropTargetMonitor) {
    // React-dnd will inject these props into our component
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
    };
}

let FieldFolderDropC = DropTarget([DragDropTypes.FIELD], folderTarget, collectTarget)(FieldFolderDragDrop);

const folderSource = {
    canDrag() {
        return false;
    },
    beginDrag(props: Props) {
        // Return data describing the dragged item
        return props.field;
    },
};

function collectSource(connect: DragSourceConnector, monitor: DragSourceMonitor) {
    // React-dnd will inject these props into our component
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    };
}

let FieldFolderDragC = DragSource(DragDropTypes.FOLDER, folderSource, collectSource)(FieldFolderDropC);

const mapStateToProps = (state: IAppState) => ({
    selectedFields: state.selected.selectedFields,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(Object.assign({}, fieldTreeActions, actionCreators), dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FieldFolderDragC);
