import { createSelector } from "reselect";
import { getAncestorFieldIdsForFlowItemId } from "../helpers/flowItems";
import { IAppState } from "../types/stores";
import { getFlowItemsArray } from "../reducers/flowItems";
import { getFlowRelationsArray } from "../reducers/flowRelations";
import { getFlowFiltersArray } from "./flowFilters";
import { getFlowCasesArray } from "../reducers/flowCases";

import {
    LOAD_ENABLED_FIELD_LABELS,
    LOAD_ENABLED_FIELD_LABELS3,
    LOAD_ENABLED_FIELD_VISIBILITY_TYPES,
    LOAD_VISIBILITY_COMPANY_USERS,
    IFieldsByCompanyActions,
    IFieldsByCompanyStore,
    LOAD_ENABLED_FIELD_COMPANIES,
    LOAD_FIELD_RATES,
    LOAD_TAXMAN_FIELD_RATES,
    LOAD_DISTRIBUTION_COUNTS,
} from "../types/stores/fieldsByCompany";

const initialState: IFieldsByCompanyStore = {
    enabledFieldLabels: {},
    enabledFieldLabels3: {},
    enabledFieldVisibilityTypes: {},
    visibilityByCompanyUsers: {},
    enabledFieldCompanies: {},
    fieldRates: {},
    taxmanFieldRates: {},
    fieldDistributionCounts: {},
};

function fieldsByCompany(state = initialState, action: IFieldsByCompanyActions): IFieldsByCompanyStore {
    switch (action.type) {
        case LOAD_ENABLED_FIELD_LABELS: {
            if (!action.enabledFieldLabels) {
                return state;
            }

            return { ...state, enabledFieldLabels: action.enabledFieldLabels };
        }

        case LOAD_ENABLED_FIELD_LABELS3: {
            if (!action.enabledFieldLabels3) {
                return state;
            }

            return { ...state, enabledFieldLabels3: action.enabledFieldLabels3 };
        }

        case LOAD_ENABLED_FIELD_VISIBILITY_TYPES: {
            if (!action.enabledFieldVisibilityTypes) {
                return state;
            }

            return { ...state, enabledFieldVisibilityTypes: action.enabledFieldVisibilityTypes };
        }

        case LOAD_VISIBILITY_COMPANY_USERS: {
            return { ...state, visibilityByCompanyUsers: action.visibilityByCompanyUsers };
        }

        case LOAD_ENABLED_FIELD_COMPANIES: {
            return { ...state, enabledFieldCompanies: action.enabledFieldCompanies };
        }

        case LOAD_FIELD_RATES: {
            return { ...state, fieldRates: action.fieldRates };
        }

        case LOAD_TAXMAN_FIELD_RATES: {
            return { ...state, taxmanFieldRates: action.taxmanFieldRates };
        }

        case LOAD_DISTRIBUTION_COUNTS: {
            return { ...state, fieldDistributionCounts: action.fieldDistributionCounts };
        }

        default:
            return state;
    }
}

export default fieldsByCompany;

export const getFieldRatesByParentFlowItemId = createSelector(
    (state: IAppState) => getFlowItemsArray(state),
    (state: IAppState) => getFlowRelationsArray(state),
    (state: IAppState) => getFlowFiltersArray(state),
    (state: IAppState) => getFlowCasesArray(state),
    (state: IAppState) => state.fieldsByCompany.fieldRates,
    (state: IAppState) => state.fieldsByCompany.taxmanFieldRates,
    (state: IAppState) => state.fields.byId || {},
    (flowItems, flowRelations, flowFilters, flowCases, fieldRates, taxmanFieldRates, fieldsById) => {
        let results: any = {};
        let maxCPM: any = {};
        let maxTaxmanCPM: any = {};
        for (const flowItem of flowItems) {
            const fieldIds = getAncestorFieldIdsForFlowItemId(
                flowItem.FlowItemId,
                flowRelations,
                flowFilters,
                flowCases,
                true
            );
            const umbrellaIds: Array<number> = [];

            for (const fieldId of fieldIds) {
                const field = fieldsById[fieldId];
                if (field && field.UmbrellaFieldId) {
                    umbrellaIds.push(field.UmbrellaFieldId);
                }
            }

            if (fieldRates) {
                if (umbrellaIds) {
                    const itemFieldRates = Object.values(fieldRates).filter((x: any) =>
                        umbrellaIds.includes(x.UmbrellaId)
                    );
                    maxCPM = Math.max(...itemFieldRates.map((x: any) => x.CPM));
                }
            }

            if (taxmanFieldRates) {
                if (fieldIds) {
                    let itemTaxmanFieldRates = fieldIds.map(id => taxmanFieldRates[id]).filter(Boolean);
                    maxTaxmanCPM = Math.max(...itemTaxmanFieldRates.map((x: any) => x.CPM));
                }
            }

            const maxTotalCPM = Math.max(maxCPM, maxTaxmanCPM);
            const defaultVaue = maxTotalCPM != -Infinity ? maxTotalCPM : null;
            results[flowItem.FlowItemId] = defaultVaue;
        }

        return results;
    }
);
