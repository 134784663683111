import React, { useEffect } from "react";
import update from "immutability-helper";
import { useDispatch, useSelector } from "react-redux";
import { getUserAutomationItems } from "../../../../actions/automation";
import { MenuItem, TextField } from "@material-ui/core";
import { IAppState } from "../../../../types/stores/index";
import { ScheduleState } from "../ScheduleAddEdit";
import { ScheduleType } from "../../../../enums/schedules";

type Props = {
    schedule: ScheduleState;
    setSchedule: React.Dispatch<React.SetStateAction<ScheduleState>>;
    updateUnsavedChanges: () => void;
    hasErrors: boolean;
    disabled?: boolean;
};

const EndDate: React.FC<Props> = ({ schedule, setSchedule, updateUnsavedChanges, hasErrors, disabled }: Props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserAutomationItems());
    }, [dispatch]);

    const automationItems = useSelector((state: IAppState) => state.automation.userAutomationItems);

    const handleTypeChange = val => {
        const type = val.target.value;
        const nOccurrences = type == 2 ? 1 : null;
        if (type != schedule.scheduleType) {
            setSchedule(
                update(schedule, {
                    scheduleType: { $set: type },
                    nOccurrences: { $set: nOccurrences },
                })
            );
            const sourceIds = automationItems.map(item => item.Id);

            if (type == 4 && sourceIds.length > 0 && !sourceIds.includes(schedule.sourceId!)) {
                const idToSet = sourceIds[0];
                setSchedule(
                    update(schedule, {
                        scheduleType: { $set: type },
                        sourceId: { $set: idToSet },
                    })
                );
            }
            updateUnsavedChanges();
        }
    };

    return (
        <TextField
            id="timing"
            error={hasErrors}
            select
            label="Timing"
            value={typeof schedule.scheduleType == "undefined" ? "" : schedule.scheduleType}
            onChange={handleTypeChange}
            className="material-form-control"
            helperText={(hasErrors && schedule.errors.name) || ""}
            disabled={disabled}
        >
            <MenuItem value={ScheduleType.Recurring.toString()}>Recurring</MenuItem>
            <MenuItem value={ScheduleType.OneTime.toString()}>One time</MenuItem>
            <MenuItem value={ScheduleType.NumberOfOccurrences.toString()}>Number of occurrences</MenuItem>
            <MenuItem value={ScheduleType.EndDate.toString()}>End date</MenuItem>
            {automationItems.length > 0 && (
                <MenuItem value={ScheduleType.TableRefresh.toString()}>When there is a table refresh</MenuItem>
            )}
            <MenuItem value={ScheduleType.ExperianAssetRefresh.toString()}>When Experian Assets are updated</MenuItem>
            <MenuItem value={ScheduleType.TimeFrequency.toString()}>Time Frequency</MenuItem>
        </TextField>
    );
};

export default EndDate;
