//import mergeDeep from '../helpers/mergeDeep';
//import clone from '../helpers/clone';
//import update from 'immutability-helper';

/*
    reportfields contains the same object, but it is a seperate set of data from fields controlled by dbo.ReportFieldGroupToCompanies
    as opposed to dbo.VisibleFieldGroupCompanies
    The shape of state.fields is one top level object with two keys "byId" and "byParent".
    "byId" is the usual associative array with fieldKeys as keys, and objects as values.
    "byParent" is an associative array.  Its keys are fieldKeys, its values are an array of all child nodes with that parent.
    For example, if I want to find all nodes directly under fieldKey 6000, I look for store.getState().fields.byParent[6000], which might list [6100, 6200].
    Then I know that 6100 and 6200 are direct descendents of 6000.

    If I want to find the specific attributes and data for a field, then just look under byId.  To find 6000's data, look in
    store.getState().fields.byId[6000].

    Example:

Here is the shape of state.fields = {
    byId: {
        "1":  {
            "id": 1,
            "sort": 1,
            "parent": "#",
            "text": "Experian",
            "type": null,
            "data": null,
            "created_dt": "2016-12-22T10:33:26.863",
            "companyid": 0,
            "sourcekey": 23,
            "viewid": 4,
            "fieldkey": 1,
            "leaf_description": "Experian’s ConsumerView database gives you the ability to leverage consumer demographic characteristics along with psychographic, lifestyle, behavioral, past purchase information for more than 300 million consumers in over 120 million households.",
            "detail_description": null,
            "isLeaf": false
        },
        "2":  {
            ... text: Auto ...
        },
        "3":  {
            ... text: Demographics ...
        },
        "74":  {
            ... text: CAPE ...
            ... leaf_description: "CAPE(Census Area Projected Estimate) is based upon analysis of ... ",
        },
    },
    byParent: {
        "#": ["1", "42"]
        "1": ["2", "3", "74"]
    }
} */

function reportfields(state = {}, action) {
    switch (action.type) {
        case "LOAD_REPORT_FIELDS": {
            if (!action.reportFields) {
                return state;
            }

            return createFieldsState(action.reportFields);
        }
        default:
            return state;
    }
}

function createFieldsState(fields) {
    const newState = {
        byId: {},
        byParent: {},
        byFolder: {},
    };

    for (const workid of Object.keys(fields)) {
        const fieldParent = fields[workid];
        if (!fieldParent) {
            continue;
        }

        if (fieldParent.isLeaf && !(fieldParent.fieldkey in newState.byId)) {
            newState.byId[fieldParent.fieldkey] = fieldParent;
        }

        if (!(workid in newState.byFolder)) {
            newState.byFolder[workid] = fieldParent;
        }

        //Will turn these into imports from fields
        /* Do one sweep of the data and create a parent lookup table on load.
        This should be the most efficient way of traversing the tree. */
        const parent = fieldParent.parent;
        if (!(parent in newState.byParent)) {
            newState.byParent[parent] = [];
        }
        newState.byParent[parent].push(workid);
    }
    return newState;
}

export default reportfields;
