import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import type { FlowItem, FlowRelation, FlowErrorsByItemId } from "../types/flowTypes";
import { FlowSVDedupeD } from "../types/stores/flowTypes";
import { FlowSVDedupesByItemId } from "../types/stores/flowSVDedupes";

const myGenericReducer = makeReducerFor("FLOW_SVDEDUPE", "FlowSVDedupeId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowSVDedupesArray = createSelector(
    state => state.flowSVDedupes.byId,
    (flowSVDedupesById: {| [number]: FlowSVDedupeD |}): Array<FlowSVDedupeD> => {
        const r: Array<FlowSVDedupeD> = Object.values(flowSVDedupesById);
        return r;
    }
);

export const getFlowSVDedupesByFlowItemId = createSelector(
    state => getFlowSVDedupesArray(state),
    (flowSVDedupes: Array<FlowSVDedupeD>): FlowSVDedupesByItemId =>
        flowSVDedupes.reduce((acc, row) => {
            acc[row.FlowItemId] = row;
            return acc;
        }, {})
);

export const getFlowSVDedupesForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowSVDedupesByFlowItemId(state),
    (state, isExcludeExport) => isExcludeExport,
    (
        selectedFlow: number,
        flowItems: Array<FlowItem>,
        flowSVDedupesByItemId: FlowSVDedupesByItemId,
        isExcludeExport: boolean
    ): Array<FlowSVDedupeD> => {
        /**********************************************
         * Not sure of reasoning for excluding exports
         * Is it still needed????  10/7/2019
         *********************************************/
        const itemIds = isExcludeExport
            ? flowItems.filter(x => x.FlowItemType != "export").map(fi => fi.FlowItemId)
            : flowItems.map(fi => fi.FlowItemId);
        const result = [];
        for (const itemId of itemIds) {
            const thisDedupe = flowSVDedupesByItemId[itemId];
            if (thisDedupe != null) {
                result.push(thisDedupe);
            }
        }
        return result;
    }
);
import { getFlowRelationsForSelectedFlow, getFlowRelationsForAllFlows } from "./flowRelations";

const svDedupesToErrorsById = (
    flowSVDedupes: Array<FlowSVDedupeD>,
    flowRelations: Array<FlowRelation>
): FlowErrorsByItemId => {
    const errorsById = {};
    for (const flowSVDedupe of flowSVDedupes) {
        errorsById[flowSVDedupe.FlowItemId] = validateFlowSVDedupe(flowSVDedupe, flowRelations);
    }
    return errorsById;
};

export const getSVDedupeErrorsForSelectedFlow = createSelector(
    state => getFlowSVDedupesForSelectedFlow(state, false),
    state => getFlowRelationsForSelectedFlow(state),
    svDedupesToErrorsById
);

export const getSVDedupeErrorsForAllFlows = createSelector(
    state => getFlowSVDedupesArray(state),
    state => getFlowRelationsForAllFlows(state),
    svDedupesToErrorsById
);

//////////////////// HELPERS //////////////////////////////

export const validateFlowSVDedupe = (
    flowSVDedupe: FlowSVDedupeD,
    flowRelations: Array<FlowRelation>
): Array<string> => {
    const errors = [];
    if (flowSVDedupe && (flowSVDedupe.SVField == "" || flowSVDedupe.SVField == null)) {
        errors.push("A dedupe key must be selected before saving.");
    }

    let needsParent = false;

    const topRelations = flowRelations.filter(
        z => z.ParentFlowItemId == 0 && z.ChildFlowItemId == flowSVDedupe.FlowItemId
    );
    const allRelations = flowRelations.filter(z => z.ChildFlowItemId == flowSVDedupe.FlowItemId);
    if ((topRelations.length > 0 || allRelations.length == 0) && !needsParent) {
        errors.push("Dedupe items must have a parent item assigned.");
        needsParent = true;
    }

    return errors;
};
