/*
    Shares a lot in common with FlowTree.
    Can the duplication be removed?
    What's changed?
        - CampaignListEntry changed to FlowListEntry
        - CampaignListFolder changed to FlowListFolder
        - renderFlow changed to renderCampaign
        - myCampaignIDs and related renamed -> myFlowIDs
        - removed Shared Folder feature filtering
        - Changed implementation of 'get my flow ids' selector
*/
import React from "react";
import TreeItem from "../tree/TreeItem";
import FlowListEntry from "./FlowListEntry";
import FlowListFolder from "./FlowListFolder";

import naturalCompare from "natural-compare";
import { connect } from "react-redux";
import { createDeepEqualSelector } from "../../helpers/typedHelpers";
import { getTreeSearch } from "../../reducers/search";
import { flowMatchesSearchString, flowIsInFolderAndSubFolders } from "../../reducers/flows";
import type { Folder } from "../../types/types";

import CircularLoading from "../loading/CircularLoading";

type Props = {
    // Passed In
    folderType: string,
    folder: Folder, // The current folder to draw
    treeViewOnUpdate: () => void, // Used for odd/even row coloring
    showAllDuringSearch?: boolean, // Used for folder search, searched folders are told to show all contents, even if they don't match
    schedulesMode: boolean,
    // Redux
    myFlowIDs: Array<number>,
    selectedFlowExists: boolean,
    selectedFlowFolderId: ?number,
    loadedFlowsIsZero: boolean,
    selectedFlowIsAboveZero: boolean,
    isFlowTreeLoading: boolean,
    hasPreviouslyClosed: any,
    selectedFlowId: any,
    previousFolderId: any,
    treeSearch: any,
};

class FlowList extends React.Component<Props> {
    componentDidMount() {
        this.props.treeViewOnUpdate();
    }

    componentDidUpdate() {
        this.props.treeViewOnUpdate();
    }

    renderFolderElements() {
        const {
            folder,
            folderType,
            treeViewOnUpdate,
            selectedFlowExists,
            selectedFlowFolderId,
            selectedFlowId,
            hasPreviouslyClosed,
            previousFolderId,
            treeSearch,
            schedulesMode,
        } = this.props;
        const showAllDuringSearch = this.props.showAllDuringSearch || false;

        let flowListFolders = [];

        for (const node of folder.children) {
            let defaultCollapsed =
                selectedFlowExists && selectedFlowFolderId != null
                    ? !flowIsInFolderAndSubFolders(selectedFlowFolderId, node)
                    : selectedFlowId == undefined &&
                      hasPreviouslyClosed &&
                      (node.id == previousFolderId || flowIsInFolderAndSubFolders(previousFolderId, node))
                    ? false
                    : true;
            defaultCollapsed = treeSearch != "" && treeSearch != null ? false : defaultCollapsed;
            flowListFolders.push(
                <FlowListFolder
                    key={node.id}
                    folder={node}
                    folderType={folderType}
                    treeViewOnUpdate={treeViewOnUpdate}
                    showAllDuringSearch={showAllDuringSearch}
                    defaultCollapsed={defaultCollapsed}
                    schedulesMode={schedulesMode}
                />
            );
        }

        return flowListFolders;
    }

    renderFlow = (flowId: number) => (
        <FlowListEntry
            flowId={flowId}
            key={flowId}
            treeViewOnUpdate={this.props.treeViewOnUpdate}
            showLockedFlows={!this.props.schedulesMode}
        />
    );

    render() {
        const { myFlowIDs, selectedFlowIsAboveZero, loadedFlowsIsZero, isFlowTreeLoading } = this.props;
        // If a saved flow is selected, wait until it's loaded from server before rendering to
        // avoid the folders seemingly opening/closing on their own
        if (selectedFlowIsAboveZero && loadedFlowsIsZero) {
            return null;
        }

        const flowElements = myFlowIDs.map(this.renderFlow);
        const folderElements = this.renderFolderElements();

        let emptyElement = null;
        if (!folderElements.length && !flowElements.length) {
            emptyElement = <TreeItem>empty</TreeItem>;
        }

        return (
            <div className="flow-list">
                {isFlowTreeLoading ? (
                    <div
                        style={{
                            height: "100px",
                            textAlign: "center",
                            display: "flex",
                            margin: "50px auto",
                            flexDirection: "column",
                        }}
                    >
                        <CircularLoading color="#575755" size="medium" />
                        <p>Fetching Flows...</p>
                    </div>
                ) : (
                    <>
                        {folderElements}
                        {flowElements}
                        {emptyElement}
                    </>
                )}
            </div>
        );
    }
}

import { getFlowIds } from "../../reducers/flows";
const makeGetMyFlowIDs = () =>
    createDeepEqualSelector(
        state => state.flows.byId,
        state => getFlowIds(state),
        state => getTreeSearch(state),
        (state, props) => props.showAllDuringSearch,
        (state, props) => props.folder.id,
        (flows, flowIds, treeSearch, showAllDuringSearch, folderId) => {
            let myFlowIDs = flowIds.filter(
                flowId => flows[flowId].FlowFolderId == folderId && flows[flowId].FlowIsActive
            );
            if (treeSearch && !showAllDuringSearch) {
                myFlowIDs = myFlowIDs.filter(flowId => flowMatchesSearchString(flows[flowId], treeSearch));
            }

            myFlowIDs.sort((a, b) => {
                let nameA = "";
                let nameB = "";
                nameA = flows[a] && flows[a].FlowName && flows[a].FlowName.toUpperCase();
                nameB = flows[b] && flows[b].FlowName && flows[b].FlowName.toUpperCase();
                return naturalCompare(nameA, nameB);
            });

            return myFlowIDs;
        }
    );

import type { MapStateToProps } from "react-redux";
const mapStateToProps: MapStateToProps<*, *, *> = () => {
    const getMyFlowIDs = makeGetMyFlowIDs();
    return (state, ownProps) => {
        const selectedFlowExists = state.flows.byId[state.selected.flow] != null;
        const selectedFlowFolderId = selectedFlowExists ? state.flows.byId[state.selected.flow].FlowFolderId : null;
        return {
            myFlowIDs: getMyFlowIDs(state, ownProps),
            loadedFlowsIsZero: Object.values(state.flows.byId).length == 0,
            selectedFlowIsAboveZero: state.selected.flow > 0,
            selectedFlowExists,
            selectedFlowId: state.selected.flow,
            selectedFlowFolderId,
            isFlowTreeLoading: state.treeBehaviors.isFlowTreeLoading,
            hasPreviouslyClosed: state.flowDesigner.hasPreviouslyClosed,
            previousFolderId: state.flowDesigner.previousFolderId,
            treeSearch: getTreeSearch(state),
        };
    };
};
const mapDispatchToProps = null;
const FlowListC = connect(mapStateToProps, mapDispatchToProps)(FlowList);

export default FlowListC;
