const tableFooterStyle = {
    tableFooter: {
        display: "flex",
        justifyContent: "space-between",
    },
    tablePagination: {
        backIconButtonProps: {
            ariaLabel: "previous page",
        },
        nextIconButtonProps: {
            ariaLabel: "next page",
        },
    },
    tablePaginationRight: {
        marginLeft: "auto",
        marginRight: 0,
    },
};

export default tableFooterStyle;
