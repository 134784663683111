import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { stopRenamingFieldFolder, requestRenameFieldFolder } from "../../actions/fieldTreeActions";
import { stopRenamingFolder, requestRenameFolder } from "../../actions/audienceFolderActions";
import { FilledInput, FormControl, IconButton, InputAdornment } from "@material-ui/core";
import { IAppState } from "../../types/stores/index";

type Props = {
    folderId: number;
    folderName: string;
    folderType?: string;
    isFieldFolder?: boolean;
};

type TextElement = HTMLInputElement | HTMLTextAreaElement;

const FolderRename: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch();

    const [folderName, setFolderName] = useState(props.folderName);

    const renamingAudienceFolderType = useSelector((state: IAppState) => state.vars.renamingAudienceFolderType);

    const cancel = () => {
        if (props.isFieldFolder) {
            dispatch(stopRenamingFieldFolder(props.folderId));
        } else {
            dispatch(stopRenamingFolder(renamingAudienceFolderType));
        }
    };

    const cancelButton = (
        <IconButton style={{ padding: "5px" }} aria-label="Cancel" onClick={cancel}>
            <i className="material-icons" style={{ height: "15px", width: "15px", fontSize: "15px" }}>
                cancel
            </i>
        </IconButton>
    );

    const save = () => {
        if (props.isFieldFolder) {
            dispatch(requestRenameFieldFolder(props.folderId, folderName));
        } else {
            let loadFlowFolders = false;
            if (renamingAudienceFolderType == "W") {
                loadFlowFolders = true;
            }
            dispatch(requestRenameFolder(renamingAudienceFolderType, props.folderId, folderName, loadFlowFolders));
        }
    };

    const saveButton = (
        <IconButton style={{ padding: "5px" }} color="primary" aria-label="Save" onClick={save}>
            <i className="material-icons" style={{ height: "15px", width: "15px", fontSize: "15px" }}>
                save
            </i>
        </IconButton>
    );

    const onKeyDown = (e: React.KeyboardEvent<TextElement>) => {
        if (e.nativeEvent.code == "Escape") {
            cancel();
        }
    };

    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        save();
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormControl style={{ width: "100%" }} variant="filled">
                <FilledInput
                    id={"folder-rename-" + props.folderId}
                    value={folderName}
                    onChange={e => setFolderName(e.target.value)}
                    onKeyDown={e => onKeyDown(e)}
                    onFocus={event => event.target.select()}
                    autoFocus
                    endAdornment={
                        <InputAdornment position="end">
                            {cancelButton}
                            {saveButton}
                        </InputAdornment>
                    }
                />
            </FormControl>
        </form>
    );
};

export default FolderRename;
