import { DropTarget } from "react-dnd";
import { DragDropTypes } from "../helpers/constants";

const withFieldDropTarget = (PassedComponent, options = {}) => {
    let listenTypes = [DragDropTypes.FIELD];
    const fieldTarget = makeFieldTarget(options);
    return DropTarget(listenTypes, fieldTarget, collectTarget)(PassedComponent);
};

export default withFieldDropTarget;

const makeFieldTarget = (options = {}) => ({
    canDrop(_, monitor) {
        const itemType = monitor.getItemType();
        if (itemType == DragDropTypes.FIELD) {
            return true;
        }
        return false;
    },
    drop(props, monitor) {
        const itemType = monitor.getItemType();
        // Field dropped onto "Drag and drop includes here"
        // Behavior changes if "isGenericQbAdd" option is present, uses genericQbAddField
        if (itemType == DragDropTypes.FIELD) {
            const item = monitor.getItem();
            if (props.selectedFields.length > 0) {
                if (props.type == "value") {
                    Array.prototype.forEach.call(props.selectedFields, field => {
                        props.fieldIdChanged(parseInt(field));
                    });
                } else if (options.isGenericQbAdd || props.isGenericQbAdd) {
                    props.genericQbAddFieldMulti(props.selectedFields, props.index, null, props.group);
                }
                props.clearSelectedFields();
            } else if (props.type == "value") {
                props.fieldIdChanged(item.fieldKey);
            } else if (options.isGenericQbAdd || props.isGenericQbAdd) {
                props.genericQbAddField(item.fieldKey, props.index, null, props.group);
            }
        }
    },
});

function collectTarget(connect, monitor) {
    // React-dnd will inject these props into our component
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
    };
}
