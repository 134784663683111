import {
    SET_TABLES_TO_SHARE,
    SET_ACTIVE_COMPANIES,
    FILES_WAITING_UPLOAD,
    GET_IMPORTS,
} from "../actions/fileImportActions";

function fileImport(
    state = {
        files: {},
        folders: [],
        modes: [],
        processColumns: [], // Columns of existing warehouse tables, cant be changed
        processTables: [],
        joinColumns: [], //Columns of the selected joinTable
        tabletables: [],
        experianTablesAssets: [],
        joinToTables: [],
        forbiddenColumns: [],
        deletingFile: false,
        tablesToShare: [],
        activeCompanies: [],
        filesWaitingUpload: [], // Files that we need to wait for uploading to request FileImport/GetImports again
    },
    action
) {
    switch (action.type) {
        case "GET_MODES":
            return Object.assign({}, state, {
                modes: action.modes,
            });
        case "GET_COLUMNS_FOR_TABLE":
            return Object.assign({}, state, {
                joinColumns: action.joinColumns,
            });
        case "GET_COLUMNS_FOR_TABLE_KEY":
            return Object.assign({}, state, {
                joinColumns: action.joinColumns,
            });
        case "GET_AVAILABLE_TABLE":
            return Object.assign({}, state, {
                processTables: action.processTables,
            });
        case "GET_JOINS":
            return Object.assign({}, state, {
                joinToTables: action.joinToTables,
            });
        case "GET_AVAILABLE_FIELDS":
            return Object.assign({}, state, {
                processColumns: action.processColumns,
            });
        case "GET_AVAILABLE_FOLDERS":
            return Object.assign({}, state, {
                folders: action.folders,
            });
        case GET_IMPORTS:
            return Object.assign({}, state, {
                files: action.files,
            });
        case "GET_TABLE_TABLES_FOR_DELETION":
            return Object.assign({}, state, {
                tabletables: action.tabletables,
            });
        case "GET_EXPERIAN_TABLES_ASSETS":
            return Object.assign({}, state, {
                experianTablesAssets: action.experianTablesAssets,
            });
        case "DELETE_IMPORT":
            return Object.assign({}, state, {
                deletingFile: action.deletingFile,
            });
        case "PROCESS_MODEL":
            return Object.assign({}, state, {
                processingModel: action.processingModel,
            });
        case "PROCESS_TABLE":
            return Object.assign({}, state, {
                processColumns: action.processColumns ? action.processColumns : state.processColumns,
                processingTable: action.processingTable,
            });
        case "CHECK_COL_FOR_PII":
            return Object.assign({}, state, {
                forbiddenColumns: action.forbiddenColumns,
            });
        case SET_TABLES_TO_SHARE: {
            return { ...state, tablesToShare: action.tables };
        }
        case SET_ACTIVE_COMPANIES: {
            return { ...state, activeCompanies: action.companies };
        }
        case FILES_WAITING_UPLOAD:
            return Object.assign({}, state, {
                filesWaitingUpload: action.filesWaitingUpload,
            });
        default:
            return state;
    }
}

export default fileImport;
