import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import type { FlowFromCloud, FlowItem, FlowErrorsByItemId } from "../types/flowTypes";

const myGenericReducer = makeReducerFor("FLOW_FROM_CLOUD", "FlowFromCloudId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowFromCloudsArray = createSelector(
    state => state.flowFromClouds.byId,
    (flowFromCloudsById: {| [number]: FlowFromCloud |}): Array<FlowFromCloud> => {
        const r: Array<FlowFromCloud> = Object.values(flowFromCloudsById);
        return r;
    }
);

export type FlowFromCloudsByItemId = {
    [number]: FlowFromCloud,
};

export const getFlowFromCloudsByFlowItemId = createSelector(
    state => getFlowFromCloudsArray(state),
    (flowFromClouds: Array<FlowFromCloud>): FlowFromCloudsByItemId =>
        flowFromClouds.reduce((acc, row) => {
            acc[row.FlowItemId] = row;
            return acc;
        }, {})
);

export const getFlowFromCloudsForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowFromCloudsByFlowItemId(state),
    (
        selectedFlow: number,
        flowItems: Array<FlowItem>,
        flowFromCloudsByItemId: FlowFromCloudsByItemId
    ): Array<FlowFromCloud> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        const result = [];
        for (const itemId of itemIds) {
            const filter = flowFromCloudsByItemId[itemId];
            if (filter != null) {
                result.push(filter);
            }
        }
        return result;
    }
);

const fromCloudsToErrorsById = (
    flowFromClouds: Array<FlowFromCloud>,
    cloudFlowItems: Array<FlowItem>
): FlowErrorsByItemId => {
    const errorsById: FlowErrorsByItemId = {};
    for (const flowFromCloud of flowFromClouds) {
        errorsById[flowFromCloud.FlowItemId] = validateFlowFromCloud(flowFromCloud, cloudFlowItems);
    }
    return errorsById;
};

export const getFromCloudErrorsForSelectedFlow = createSelector(
    state => getFlowFromCloudsForSelectedFlow(state),
    state => state.flowCloud.cloudFlowItems,
    (flowFromClouds: Array<FlowFromCloud>, cloudFlowItems: Array<FlowItem>): FlowErrorsByItemId =>
        fromCloudsToErrorsById(flowFromClouds, cloudFlowItems)
);

export const getFromCloudErrorsForAllFlows = createSelector(
    state => getFlowFromCloudsArray(state),
    state => state.flowCloud.cloudFlowItems,
    (flowFromClouds: Array<FlowFromCloud>, cloudFlowItems: Array<FlowItem>): FlowErrorsByItemId =>
        fromCloudsToErrorsById(flowFromClouds, cloudFlowItems)
);
//////////////////// HELPERS //////////////////////////////

export const validateFlowFromCloud = (flowFromCloud: FlowFromCloud, cloudFlowItems: Array<FlowItem>): Array<string> => {
    const errors = [];
    if (flowFromCloud.FromCompanyId == 0 || flowFromCloud.FromCompanyId == null) {
        errors.push("No from cloud company selected.");
    } else if (flowFromCloud.FromUserId == 0 || flowFromCloud.FromUserId == null) {
        errors.push("No from cloud user selected.");
    } else if (flowFromCloud.FromFlowId == 0 || flowFromCloud.FromFlowId == null) {
        errors.push("No from cloud flow selected.");
    } else if (flowFromCloud.FromFlowItemId == 0 || flowFromCloud.FromFlowItemId == null) {
        errors.push("No from cloud flow item selected.");
    } else if (flowFromCloud.FromFlowItemId == -1) {
        errors.push(
            "The previous selected cloud flow item doesn't exist anymore. Please select a new cloud flow item."
        );
    } else if (cloudFlowItems != null) {
        const foundParentToCloudFlowItem = cloudFlowItems.filter(cfi => cfi.FlowItemId == flowFromCloud.FromFlowItemId);
        if (foundParentToCloudFlowItem.length > 0) {
            const parentToCloudFlowItem = foundParentToCloudFlowItem[0];
            if (parentToCloudFlowItem.HasResultTable == false) {
                errors.push("The selected item " + parentToCloudFlowItem.FlowItemName + " is not calculated.");
            }
        } else {
            errors.push(
                "The previous selected cloud flow item doesn't exist anymore. Please select a new cloud flow item."
            );
        }
    }

    return errors;
};
