import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import type { FlowScript, FlowItem, FlowErrorsByItemId } from "../types/flowTypes";
import { FlowScriptResultsByItemId, getFlowScriptResultsByFlowItemId } from "./flowScriptResults";

const myGenericReducer = makeReducerFor("FLOW_SCRIPT", "FlowScriptId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowScriptsArray = createSelector(
    state => state.flowScripts.byId,
    (flowScriptsById: {| [number]: FlowScript |}): Array<FlowScript> => {
        const r: Array<FlowScript> = Object.values(flowScriptsById);
        return r;
    }
);

export type FlowScriptsByItemId = {
    [number]: FlowScript,
};
// eslint-disable-line
export const getFlowScriptsByFlowItemId = createSelector(
    state => getFlowScriptsArray(state),
    (flowScripts: Array<FlowScript>): FlowScriptsByItemId =>
        flowScripts.reduce((acc, row) => {
            acc[row.FlowItemId] = row;
            return acc;
        }, {})
);

export const getFlowScriptsForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowScriptsByFlowItemId(state),
    (selectedFlow: number, flowItems: Array<FlowItem>, flowScriptsByItemId: FlowScriptsByItemId): Array<FlowScript> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        const result = [];
        for (const itemId of itemIds) {
            const script = flowScriptsByItemId[itemId];
            if (script != null) {
                result.push(script);
            }
        }
        return result;
    }
);

export const makeGetScriptErrorsForId = () =>
    createSelector(
        (_, props) => props.id,
        state => getScriptErrorsForSelectedFlow(state),
        (id: number, itemErrors: FlowErrorsByItemId): Array<string> => itemErrors[id] || []
    );

const scriptsToErrorsById = (
    flowScripts: Array<FlowScript>,
    flowScriptResultsById: Array<FlowScriptResultsByItemId>
): FlowErrorsByItemId => {
    const errors = {};
    for (const item of flowScripts) {
        errors[item.FlowItemId] = validateFlowScript(item, flowScriptResultsById[item.FlowItemId]);
    }
    return errors;
};

export const getScriptErrorsForSelectedFlow = createSelector(
    state => getFlowScriptsForSelectedFlow(state),
    state => getFlowScriptResultsByFlowItemId(state),
    scriptsToErrorsById
);

export const getScriptErrorsForAllFlows = createSelector(
    state => getFlowScriptsArray(state),
    state => getFlowScriptResultsByFlowItemId(state),
    scriptsToErrorsById
);

//////////////////// HELPERS /////////////////////////////

const validateFlowScript = (flowScript: FlowScript, flowScriptResults: FlowScriptResultsByItemId): Array<string> => {
    const errors = [];

    if (!flowScript.Script || flowScript.Script.trim().length === 0) {
        errors.push("No Script has been entered.");
    }

    for (let x in flowScriptResults) {
        const item = flowScriptResults[x];
        const message = item.IsValid ? "" : `Statement ${item.ScriptId} errored. ${item.InvalidReason}`;
        if (message) {
            errors.push(message);
        }
    }

    return errors;
};
