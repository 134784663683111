import { combineReducers } from "redux";
import { makeFolderReducerForType } from "./_folderGeneral";
const flowFolders = makeFolderReducerForType("W");
const layoutFolders = makeFolderReducerForType("L");

// Below needs to line up with helper/folders.js - please update both
const folderReducer = combineReducers({
    flowFolders,
    layoutFolders,
});

export default folderReducer;
