import { ILoadingState, LoadingActions } from "../types/stores/loading";
export const START_REQUEST_PROGRESS = "START_REQUEST_PROGRESS";
export const UPDATE_REQUEST_PROGRESS = "UPDATE_REQUEST_PROGRESS";
export const END_REQUEST_PROGRESS = "END_REQUEST_PROGRESS";
export const SHOW_FLOW_LOADING = "SHOW_FLOW_LOADING";
export const HIDE_FLOW_LOADING = "HIDE_FLOW_LOADING";
export const START_TREE_REQUEST_PROGRESS = "START_TREE_REQUEST_PROGRESS";
export const END_TREE_REQUEST_PROGRESS = "END_TREE_REQUEST_PROGRESS";

function loadingStates(
    state: ILoadingState = {
        isFlowLoading: true,
        isTreeLoading: false,
        showGlobalLoading: false,
        globalLoadingProgress: 0,
    },
    action: LoadingActions
): ILoadingState {
    switch (action.type) {
        case "START_REQUEST_PROGRESS":
            return Object.assign({}, state, {
                showGlobalLoading: true,
                globalLoadingProgress: 0,
            });
        case "UPDATE_REQUEST_PROGRESS":
            return Object.assign({}, state, {
                globalLoadingProgress: action.progress,
            });
        case "END_REQUEST_PROGRESS":
            return Object.assign({}, state, {
                showGlobalLoading: false,
                globalLoadingProgress: 100,
            });
        case "SHOW_FLOW_LOADING":
            return Object.assign({}, state, {
                isFlowLoading: true,
            });
        case "HIDE_FLOW_LOADING":
            return Object.assign({}, state, {
                isFlowLoading: false,
            });
        case "START_TREE_REQUEST_PROGRESS":
            return Object.assign({}, state, {
                isTreeLoading: true,
            });
        case "END_TREE_REQUEST_PROGRESS":
            return Object.assign({}, state, {
                isTreeLoading: false,
            });
        default:
            return state;
    }
}
export default loadingStates;
