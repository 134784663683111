import { IFlowDesignerSettingsStore, FlowDesignerSettingsActions } from "../types/stores/flowDesigner";

// States for flow designer tools
export const TOGGLE_FLOW_LISTING = "TOGGLE_FLOW_LISTING";
export const TOGGLE_FLOW_LISTING_PINNABLE = "TOGGLE_FLOW_LISTING_PINNABLE";
export const SET_FLOW_PROCESS_TYPE = "SET_FLOW_PROCESS_TYPE";
export const SET_FLOW_CLOSED = "SET_FLOW_CLOSED";
export const SET_PREVIOUS_FLOW_FOLDER = "SET_PREVIOUS_FLOW_FOLDER";

const initialState: IFlowDesignerSettingsStore = {
    isFlowListingOpen: false,
    isFlowListingPinned: true,
    processType: 1, // default 'execute'
    hasPreviouslyClosed: false,
    previousFolderId: null,
};

function flowDesignerSettings(state = initialState, action: FlowDesignerSettingsActions): IFlowDesignerSettingsStore {
    switch (action.type) {
        case TOGGLE_FLOW_LISTING:
            return Object.assign({}, state, {
                isFlowListingOpen: action.isOpen,
            });
        case TOGGLE_FLOW_LISTING_PINNABLE:
            return Object.assign({}, state, {
                isFlowListingPinned: action.isPinned,
            });
        case SET_FLOW_PROCESS_TYPE: {
            return Object.assign({}, state, {
                processType: action.processType,
            });
        }
        case SET_FLOW_CLOSED: {
            return Object.assign({}, state, {
                hasPreviouslyClosed: action.hasClosed,
            });
        }
        case SET_PREVIOUS_FLOW_FOLDER: {
            return Object.assign({}, state, {
                previousFolderId: action.folderId,
            });
        }
        default:
            return state;
    }
}
export default flowDesignerSettings;
