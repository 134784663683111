import clone from "../helpers/clone";
import update from "immutability-helper";

function flowMultiSelection(
    state = {
        selectedFlowIds: [],
        isMultiSelectOn: false,
    },
    action
) {
    switch (action.type) {
        case "FLOW_MULTI_SELECTION_TOGGLE": {
            return Object.assign({}, state, {
                isMultiSelectOn: action.isMultiSelectOn,
            });
        }
        case "FLOW_MULTI_SELECTION_ADD": {
            if (!action.flowId) {
                return;
            }
            const newArray = clone(state.selectedFlowIds);
            newArray.push(action.flowId);
            return update(state, {
                selectedFlowIds: { $set: newArray },
            });
        }
        case "FLOW_MULTI_SELECTION_REMOVE": {
            if (!action.flowId) {
                return state;
            }
            const newArray = clone(state.selectedFlowIds);
            const elementToDelete = newArray.indexOf(action.flowId);
            if (elementToDelete > -1) {
                newArray.splice(elementToDelete, 1);
            }
            return update(state, {
                selectedFlowIds: { $set: newArray },
                isMultiSelectOn: { $set: newArray.length > 0 },
            });
        }
        case "FLOW_MULTI_SELECTION_CLEAR": {
            return update(state, {
                selectedFlowIds: { $set: [] },
            });
        }
        default:
            return state;
    }
}
export default flowMultiSelection;
