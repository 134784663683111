import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import { getFlowExportsForSelectedFlow, getFlowExportsArray } from "./flowExports";
import type { FlowExportPinterestTemplateFields, FlowItem, FlowErrorsByItemId, FlowExport } from "../types/flowTypes";

const myGenericReducer = makeReducerFor("FLOW_EXPORT_PINTEREST_TEMPLATE_FIELDS", "FlowExportTemplateId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowExportPinterestTemplateFieldsArray = createSelector(
    state => state.flowExportPinterestTemplateFields.byId,
    (flowExportPinterestTemplateFieldsById: {|
        [number]: FlowExportPinterestTemplateFields,
    |}): Array<FlowExportPinterestTemplateFields> => {
        const r: Array<FlowExportPinterestTemplateFields> = Object.values(flowExportPinterestTemplateFieldsById);
        return r;
    }
);

export type FlowExportPinterestTemplateFieldsByItemId = {
    [number]: Array<FlowExportPinterestTemplateFields>,
};

export const getFlowExportPinterestTemplateFieldsByFlowItemId = createSelector(
    state => getFlowExportPinterestTemplateFieldsArray(state),
    (
        flowExportPinterestTemplateFields: Array<FlowExportPinterestTemplateFields>
    ): FlowExportPinterestTemplateFieldsByItemId =>
        flowExportPinterestTemplateFields.reduce((acc, row) => {
            if (acc[row.FlowItemId] == null) {
                acc[row.FlowItemId] = [];
            }
            acc[row.FlowItemId].push(row);
            return acc;
        }, {})
);

export const getFlowExportPinterestTemplateFieldsForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowExportPinterestTemplateFieldsByFlowItemId(state),
    (
        selectedFlow: number,
        flowItems: Array<FlowItem>,
        flowExportPinterestTemplateFieldsByItemId: FlowExportPinterestTemplateFieldsByItemId
    ): Array<FlowExportPinterestTemplateFields> => {
        let result = [];
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        for (const itemId of itemIds) {
            const templateFields = flowExportPinterestTemplateFieldsByItemId[itemId];
            if (templateFields != null) {
                result = result.concat(templateFields);
            }
        }
        return result;
    }
);

const templateFieldsToErrorsById = (
    flowExports: Array<FlowExport>,
    flowExportPinterestTemplateFields: Array<FlowExportPinterestTemplateFields>,
    destinations: Array<any>
): FlowErrorsByItemId => {
    const errorsById = {};
    for (const flowExport of flowExports) {
        errorsById[flowExport.FlowItemId] = validateExportPinterestTemplateFields(
            flowExport,
            flowExportPinterestTemplateFields,
            destinations
        );
    }
    return errorsById;
};

export const getExportPinterestTemplateErrorsForSelectedFlow = createSelector(
    state => getFlowExportsForSelectedFlow(state),
    state => getFlowExportPinterestTemplateFieldsForSelectedFlow(state),
    state => state.vars.destinations,
    templateFieldsToErrorsById
);

export const getExportPinterestTemplateErrorsForAllFlows = createSelector(
    state => getFlowExportsArray(state),
    state => getFlowExportPinterestTemplateFieldsArray(state),
    state => state.vars.destinations,
    templateFieldsToErrorsById
);

export const validateExportPinterestTemplateFields = (
    flowExport: FlowExport,
    flowExportPinterestTemplateFields: Array<FlowExportPinterestTemplateFields>,
    destinations: Array<any>
) => {
    const errors = [];

    if (!destinations || (destinations && destinations.length == 0)) {
        return errors;
    }

    const templateFields = flowExportPinterestTemplateFields.filter(x => x.FlowItemId == flowExport.FlowItemId);

    // No destination selected
    const destination = destinations.find(x => x.PartnerAccessId == flowExport.DestinationId);

    if (!destination || !destination.DeployPinterestFile) {
        return errors;
    }

    // Missing data
    if (templateFields.length == 0 && destination.DeployPinterestFile) {
        errors.push("Driver File Fields missing.");
        return errors;
    }

    // Validations
    const noTargetingAudienceName = templateFields.filter(
        x => x.TargetingAudienceName == null || x.TargetingAudienceName == ""
    );
    const noPinterestAccountID = templateFields.filter(x => x.PinterestAccountID == null || x.PinterestAccountID == "");
    const pinterestAccountIDLessThan5 = templateFields.filter(x =>
        x.PinterestAccountID != null ? x.PinterestAccountID.length < 5 : ""
    );
    const noOldAudienceID = templateFields.filter(x => x.OldAudienceID == null || x.OldAudienceID == "");
    const oldAudienceIDExists = templateFields.filter(x => x.OldAudienceID != null && x.OldAudienceID != "");
    const noNotificationEmails = templateFields.filter(x => x.NotificationEmails == null || x.NotificationEmails == "");
    const invalidNotificationEmails = templateFields.filter(x => !validateEmails(x.NotificationEmails));

    if (noTargetingAudienceName.length > 0) {
        errors.push("All Driver File Targeting Audience Name must be populated.");
    }

    if (noPinterestAccountID.length > 0 || pinterestAccountIDLessThan5.length > 0) {
        errors.push("All Driver File Pinterest Account ID must be populated and have a minimum of 5 numbers.");
    }

    if (noOldAudienceID.length > 0 && oldAudienceIDExists.length > 0) {
        errors.push("All Driver File Old Audience ID must be populated or all must be blanked.");
    }

    if (noNotificationEmails.length > 0) {
        errors.push("All Driver File Notification Emails must be populated.");
    }

    if (invalidNotificationEmails.length > 0) {
        errors.push("Some Driver File Notification Emails are invalid.");
    }

    return errors;
};

function validateEmails(email) {
    if (email == undefined || !email.trim()) {
        return true;
    }

    const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    const result = email.replace(/\s/g, "").split(/,|;/);
    for (let i = 0; i < result.length; i++) {
        if (!regex.test(result[i].trim())) {
            return false;
        }
    }

    return true;
}
