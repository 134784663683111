import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateAttribute } from "../../../../actions/flowActions";
import { GetTapadDeliveryTemplates } from "../../../../actions/companiesActions";
import { labelCompare } from "../../../../helpers/selectHelper";
import { FormControl, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { IAppState } from "../../../../types/stores";

type Props = {
    flowExport: any;
    canEdit: boolean;
    deploySetting: number;
};

const TradedeskDeliveryTypes: React.FC<Props> = ({ flowExport, canEdit, deploySetting }: Props) => {
    const dispatch = useDispatch();

    const flow = useSelector((state: IAppState) => state.flows.byId[state.selected.flow]);
    const tapadDeliveryTypes = useSelector((state: IAppState) => state.vars.tapadDeliveryTypes);
    const companyId = useSelector((state: IAppState) => state.session.companyId);
    const isCompanyVerticalsLoading = useSelector((state: IAppState) => state.companyEdit.isLoadingVerticals);

    const destinationDeliveryTypes = useMemo(
        () => tapadDeliveryTypes.filter(x => x.DeploySettingId == deploySetting),
        [tapadDeliveryTypes, deploySetting]
    );

    useEffect(() => {
        if (
            companyId != null &&
            !isCompanyVerticalsLoading &&
            (!destinationDeliveryTypes || destinationDeliveryTypes.length == 0)
        ) {
            dispatch(GetTapadDeliveryTemplates(companyId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tapadDeliveryTypesDDL = destinationDeliveryTypes
        .map(item => ({
            label: item.Name,
            value: item.TapadDeliveryTypeId,
            item,
        }))
        .sort(labelCompare);

    const thisTapadDeliveryType = tapadDeliveryTypesDDL.filter(x => x.value == flowExport.DeliveryTypeId);

    const tapadDeliveryTypeChanged = (_, e) => {
        const newValue = e && e.value ? e.value : 0;
        dispatch(updateAttribute("flowExports", flowExport.FlowExportId, "DeliveryTypeId", newValue));
    };

    return (
        <FormControl fullWidth required>
            <Autocomplete
                id="deliveryTypeSelectionLabel"
                options={tapadDeliveryTypesDDL}
                getOptionLabel={option => (option && option.label) || ""}
                getOptionSelected={(option, value) => value.value === option.value}
                onChange={tapadDeliveryTypeChanged}
                autoComplete
                value={thisTapadDeliveryType.length > 0 ? thisTapadDeliveryType[0] : null}
                disabled={!canEdit || (flow && flow.IsLocked)}
                renderInput={params => <TextField {...params} label="Activation Template" fullWidth />}
            />
        </FormControl>
    );
};

export default TradedeskDeliveryTypes;
