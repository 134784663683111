import clone from "../helpers/clone";

function flowDeploymentQueue(
    state = {
        loading: {
            active: true,
            historical: true,
            tapad: true,
        },
        activeList: [],
        historicalList: [],
        audienceStatuses: [],
    },
    action
) {
    switch (action.type) {
        case "SET_FLOW_DEPLOYMENT_QUEUE_ACTIVE_LIST":
            return {
                ...state,
                activeList: action.activeList,
                loading: {
                    active: action.loading,
                    historical: state.loading.historical,
                    tapad: state.loading.tapad,
                },
            };
        case "SET_FLOW_DEPLOYMENT_QUEUE_HISTORICAL_LIST":
            return {
                ...state,
                historicalList: action.historicalList,
                loading: {
                    active: state.loading.active,
                    historical: action.loading,
                    tapad: state.loading.tapad,
                },
            };
        case "SET_FLOW_DEPLOY_STATUSES":
            return {
                ...state,
                audienceStatuses: action.audienceStatuses,
                loading: {
                    active: state.loading.active,
                    historical: state.loading.historical,
                    tapad: action.loading,
                },
            };
        case "UPDATE_FLOWDEPLOYMENTQUEUE_STATUS": {
            const newState = clone(state);
            //Immediately update status to prevent double runs
            if (newState.historicalList.filter(x => x.WorkflowId == action.workFlowId).length > 1) {
                newState.historicalList.filter(x => x.WorkflowId == action.workFlowId)[0].WorkflowStatusId = 7;
                newState.historicalList.filter(x => x.WorkflowId == action.workFlowId)[0].WorkflowStatusName =
                    "Deploying";
            }

            return newState;
        }
        default:
            return state;
    }
}
export default flowDeploymentQueue;
