import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAttribute } from "../../../../actions/flowActions";
import { Divider, Paper, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { IAppState } from "../../../../types/stores";
import { GetSnowflakeSettings } from "../../../../actions/companiesActions";
import { ISnowflakeSettings } from "../../../../types/stores/snowflakeSettings";
import { formatName } from "../../../../helpers";

type Props = {
    entityPluralName: string;
    flowItemId: number;
    destinationId: number;
    snowflakeSettingsId: number;
    snowflakeSettingsTable: string;
    snowflakeSettingsAppend: boolean;
};

const FlowItemSnowflakeTables: React.FC<Props> = ({
    entityPluralName,
    flowItemId,
    destinationId,
    snowflakeSettingsId,
    snowflakeSettingsTable,
    snowflakeSettingsAppend,
}: Props) => {
    const dispatch = useDispatch();

    // State
    const [selectedSnowflakeSetting, setSelectedSnowflakeSetting] = useState<ISnowflakeSettings | null>(null);
    const [tableName, setTableName] = useState<string | null>(null);
    // Props
    const isLoadingSnowflakeSettings = useSelector((state: IAppState) => state.companyEdit.isLoadingSnowflakeSettings);
    const snowflakeSettings = useSelector((state: IAppState) =>
        state.companyEdit.snowflakeSettings.filter(x => x.SnowflakeInstance != null)
    );

    // Events
    useEffect(() => {
        if (snowflakeSettings?.length > 0 && snowflakeSettingsId > 0) {
            setSelectedSnowflakeSetting(snowflakeSettings.find(x => x.Id == snowflakeSettingsId) as ISnowflakeSettings);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, snowflakeSettings]);

    useEffect(() => {
        setTableName(snowflakeSettingsTable || "");
    }, [snowflakeSettingsTable]);

    useEffect(() => {
        if (destinationId != null) {
            dispatch(GetSnowflakeSettings(destinationId));
        }
    }, [dispatch, destinationId]);

    const handleSnowflakeSettingChange = (newSnowflakeSetting: ISnowflakeSettings | null) => {
        setSelectedSnowflakeSetting(newSnowflakeSetting);
        dispatch(updateAttribute(entityPluralName, flowItemId, "SnowflakeSettingsId", newSnowflakeSetting?.Id ?? 0));
    };

    const handleTableChange = event => {
        const newValue = event.target.value == null ? "AE_DEPLOY" : formatName(event.target.value);
        setTableName(formatName(event.target.value));
        dispatch(updateAttribute(entityPluralName, flowItemId, "SnowflakeTable", newValue));
    };

    const handleChange = () => {
        dispatch(updateAttribute(entityPluralName, flowItemId, "AppendToExistingTable", !snowflakeSettingsAppend));
    };

    const renderSnowflakeTables = () => (
        <>
            <div style={{ paddingTop: "20px" }}>
                <Autocomplete
                    id="snowflake-settings"
                    style={{ margin: "5px 0px" }}
                    autoComplete
                    disabled={isLoadingSnowflakeSettings || !(snowflakeSettings && snowflakeSettings.length > 0)}
                    options={snowflakeSettings}
                    value={selectedSnowflakeSetting}
                    onChange={(_e, value) => handleSnowflakeSettingChange(value)}
                    getOptionLabel={option =>
                        (option && `${option.SnowflakeInstance?.Name} - ${option.Database}.${option.Schema}`) || ""
                    }
                    renderInput={params => <TextField {...params} label="Snowflake Settings" fullWidth />}
                />
            </div>

            <div style={{ paddingTop: "20px" }}>
                <TextField
                    id="snowflake-settings-table"
                    label="Table"
                    value={tableName}
                    onChange={handleTableChange}
                    fullWidth
                    style={{ margin: "5px 0px" }}
                ></TextField>
            </div>

            <div style={{ paddingTop: "20px" }}>
                <label>
                    <input
                        type="checkbox"
                        id={"snowflake-settings-append"}
                        name="appendToExistingTable"
                        checked={snowflakeSettingsAppend}
                        onChange={handleChange}
                        style={{ width: "19px", height: "19px" }}
                    />
                    <span>
                        {" "}
                        <b>Append to existing table? If not, default is &apos;Create or Replace Table&apos;</b>
                    </span>
                </label>
            </div>
        </>
    );

    return (
        <>
            <div className="flow-edit-header">
                <div style={{ flexGrow: 1, position: "relative" }}>
                    <p>Snowflake Table</p>
                </div>
            </div>
            <Divider style={{ margin: "20px auto" }} />
            <Paper style={{ marginTop: "30px", padding: "0px 20px 20px 20px", width: "100%" }}>
                <div className="m-t-md">{renderSnowflakeTables()}</div>
            </Paper>
        </>
    );
};

export default FlowItemSnowflakeTables;
