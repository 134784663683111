import ofilter from "./ofilter";

export default function breadcrumbs(fields, fieldKey, includeFieldName) {
    if (fields == null) {
        console.log("breadcrumbs: Error: Parameter fields is null."); // eslint-disable-line no-console
        return null;
    }

    if (fieldKey == null) {
        console.log("breadcrumbs: Error: Parameter fieldKey is null."); // eslint-disable-line no-console
        return null;
    }

    if (includeFieldName == null) {
        console.log("breadcrumbs: Error: Parameter includeFieldName is null."); // eslint-disable-line no-console
        return null;
    }

    let lookup = fieldKey.toString();
    let crumbs = [lookup];
    while (lookup) {
        lookup = Object.keys(ofilter(v => v.includes(lookup), fields.byParent)).shift();
        if (lookup && lookup != "#") {
            crumbs.push(lookup);
        }
    }

    crumbs = crumbs.map(fieldKey => {
        if (fields.byFolder[fieldKey] != null) {
            return fields.byFolder[fieldKey].text;
        }
        return "";
    });
    if (crumbs != null) {
        if (includeFieldName == false && crumbs.length > 0) {
            crumbs.splice(0, 1);
        }
    }
    return crumbs.reverse().join(" > ");
}
