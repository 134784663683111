import React from "react";

type Props = {
    id: string | null;
    hasUnsavedChanges: boolean;
};

const ScheduleHeader: React.FC<Props> = ({ id, hasUnsavedChanges }: Props) => {
    if (id) {
        return (
            <h3 className="m-t-0">
                Editing schedule{" "}
                {hasUnsavedChanges ? <span style={{ color: "#AAA", fontStyle: "italic" }}>(Unsaved changes)</span> : ""}
            </h3>
        );
    } else {
        return <h3 className="m-t-0">Adding new schedule</h3>;
    }
};

export default ScheduleHeader;
