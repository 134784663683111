import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { flowRequests } from "../../actions/actionCreators";
import { FieldVisibilityTypes } from "../../helpers/constants";
import FieldTree from "../tree-field/FieldTree";
import FlowTree from "../tree-flow/FlowTree";
import LayoutObjectTree from "../tree-exportobject/FlowLayoutObjectTree";
import TreeSearch from "./TreeSearch";
import ClientFieldItem from "../export-layout/ClientFieldItem";
import TreeView from "./TreeView";
import VariableLayoutTree from "../export-layout/VariableLayoutTree";
import SVFieldTree from "../export-layout/SVFieldTree";
import LayoutTreeFolder from "../export-layout/LayoutTreeFolder";
import { IAppState } from "../../types/stores/index";

type Props = {
    ctrlShift?: boolean;
    showExportTree?: boolean;
    showFieldTree?: boolean;
    showLayoutObjectTree?: boolean;
    showLayoutTree?: boolean;
    showFlowTree?: boolean;
    tempHideFlowTree?: boolean;
    outerHeight: string;
    onTableReportPage?: boolean;
    updateStripes?: () => void;
    schedulesMode?: boolean;
    isModal?: boolean;
};

const TreesWithSearch: React.FC<Props> = ({
    ctrlShift,
    showFieldTree,
    showExportTree,
    showLayoutObjectTree,
    showLayoutTree,
    showFlowTree,
    tempHideFlowTree,
    outerHeight,
    updateStripes,
    schedulesMode,
    isModal,
}: Props) => {
    const dispatch = useDispatch();

    const treeSearch = useSelector((state: IAppState) =>
        isModal ? state.search.modalTreeSearch : state.search.treeSearch
    );
    const selectedLayout = useSelector((state: IAppState) => state.selected.layout);
    const layouts = useSelector((state: IAppState) => state.layouts);
    const enabledFeatures = useSelector((state: IAppState) => state.session.enabledFeatures || []);
    const isInFlows = useSelector(
        (state: IAppState) =>
            state.router &&
            state.router.location &&
            state.router.location.pathname &&
            state.router.location.pathname.includes("/flows")
    );
    const isInSchedules = useSelector(
        (state: IAppState) =>
            state.router &&
            state.router.location &&
            state.router.location.pathname &&
            state.router.location.pathname.includes("schedules")
    );

    useEffect(() => {
        dispatch(flowRequests());
    }, [dispatch]);

    const heightToSubtract = treeSearch && treeSearch != "" ? "140" : "90";
    let outerHeightValue = "95%";
    if (outerHeight) {
        outerHeightValue = outerHeight;
    }

    const hrClass = "m-y-xs";
    let showDestinationItems = false;
    let showDestinationSVFields = false;
    if (layouts != null) {
        const thisLayout = layouts[selectedLayout];
        if (thisLayout != null) {
            showDestinationItems = thisLayout.DestinationLayout;
            //Make showDestinationSVFields be showDestinationItems when feature is removed
            showDestinationSVFields = thisLayout.DestinationLayout;
        }
    }

    return (
        <div style={{ height: outerHeightValue }} className={isInFlows || isInSchedules ? "trees-with-search" : ""}>
            <div className="tree-view-root" style={{ marginBottom: "10px", height: "40px" }}>
                <TreeSearch isModal={Boolean(isModal)} />
            </div>
            <div className="tree-view-root y-overflow" style={{ height: "calc(100% - " + heightToSubtract + "px)" }}>
                <div style={{ overflow: "visible" }}>
                    {showFlowTree && (
                        <div className={tempHideFlowTree ? "hide" : ""}>
                            <FlowTree schedulesMode={schedulesMode} />
                        </div>
                    )}
                    {showFieldTree && (
                        <React.Fragment>
                            <hr className={hrClass} />
                            <FieldTree
                                TreeType={showExportTree ? FieldVisibilityTypes.EXPORT : FieldVisibilityTypes.NONE}
                                ctrlShift={Boolean(ctrlShift)}
                                isModal={Boolean(isModal)}
                            />
                        </React.Fragment>
                    )}
                    {showLayoutObjectTree && (
                        <React.Fragment>
                            <hr className={hrClass} />
                            <LayoutObjectTree />
                        </React.Fragment>
                    )}
                    {showLayoutTree && (
                        <div>
                            <div style={{ overflow: "visible" }}>
                                <LayoutTreeFolder />
                            </div>
                            <div>
                                <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                                <ClientFieldItem />
                            </div>

                            {showDestinationSVFields && (
                                <div>
                                    <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                                    <TreeView
                                        nodeLabel={"Destination Fields"}
                                        defaultCollapsed={false}
                                        onUpdate={updateStripes}
                                        key="destination_fields"
                                    >
                                        <SVFieldTree />
                                    </TreeView>
                                </div>
                            )}
                            {showDestinationItems && (
                                <div>
                                    <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                                    <TreeView
                                        nodeLabel={"Destination Offers"}
                                        defaultCollapsed={false}
                                        onUpdate={updateStripes}
                                        key="destination_offers"
                                    >
                                        <VariableLayoutTree scope="DestinationOffer" />
                                    </TreeView>
                                </div>
                            )}
                            {!showDestinationItems && (
                                <div>
                                    <div>
                                        <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                                        <TreeView
                                            nodeLabel={"Fields"}
                                            defaultCollapsed={false}
                                            onUpdate={updateStripes}
                                            key="layout_count"
                                        >
                                            <FieldTree
                                                TreeType={FieldVisibilityTypes.EXPORT}
                                                ctrlShift={Boolean(ctrlShift)}
                                                isModal={Boolean(isModal)}
                                            />
                                        </TreeView>
                                    </div>
                                    <div>
                                        <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                                        <TreeView
                                            nodeLabel={"Variables"}
                                            defaultCollapsed={false}
                                            onUpdate={updateStripes}
                                            key="campaign_vars"
                                        >
                                            <VariableLayoutTree scope="CampaignFlow" />
                                        </TreeView>
                                    </div>
                                    {enabledFeatures.includes("offer-codes") && (
                                        <div>
                                            <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                                            <TreeView
                                                nodeLabel={"Offer Codes"}
                                                defaultCollapsed={false}
                                                onUpdate={updateStripes}
                                                key="offer_codes"
                                            >
                                                <VariableLayoutTree scope="OfferCode" />
                                            </TreeView>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TreesWithSearch;
