import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowExportsForSelectedFlow, getFlowExportsArray } from "./flowExports";

import { DeploySettings } from "../types/stores/companyTable";
const myGenericReducer = makeReducerFor("FLOW_EXPORT_XANDR_DRIVER_FIELDS", "XandrDriverFieldId");
import subItemReducer from "./_genericFlowSubItemReducer";
import { IAppState } from "../types/stores";
import {
    FlowExportXandrDriverFields,
    FlowExportXandrDriverFieldsById,
    FlowExportXandrDriverFieldsByItemId,
    IFlowExportXandrDriverFieldsStore,
} from "../types/stores/flowExportXandrDriverFields";
import { FlowErrorsByItemId, FlowExport } from "../types/stores/flowTypes";
import { IAdvertisersByCompany } from "../types/stores/vars";
import { DeliveryType } from "../enums/company";
import { ICompanyTapadDeliveryType } from "../types/stores/companyTable";

const defaultState: IFlowExportXandrDriverFieldsStore = { byId: {} };

const myReducer = (state = defaultState, action: string | null): IFlowExportXandrDriverFieldsStore =>
    subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowExportXandrDriverFieldsArray = createSelector(
    (state: IAppState) => state.flowExportXandrDriverFields.byId,
    (flowExportXandrDriverFieldsById: FlowExportXandrDriverFieldsById): Array<FlowExportXandrDriverFields> => {
        const r: Array<FlowExportXandrDriverFields> = Object.values(flowExportXandrDriverFieldsById);
        return r;
    }
);

export const getFlowExportXandrDriverFieldsByFlowItemId = createSelector(
    (state: IAppState) => getFlowExportXandrDriverFieldsArray(state),
    (flowExportXandrDriverFields: Array<FlowExportXandrDriverFields>): FlowExportXandrDriverFieldsByItemId =>
        flowExportXandrDriverFields.reduce((acc, row) => {
            if (acc[row.FlowItemId] == null) {
                acc[row.FlowItemId] = [];
            }
            acc[row.FlowItemId].push(row);
            return acc;
        }, {})
);

export const getFlowExportXandrDriverFieldsForSelectedFlow = createSelector(
    (state: IAppState) => getFlowExportsForSelectedFlow(state),
    (state: IAppState) => getFlowExportXandrDriverFieldsByFlowItemId(state),
    (state: IAppState) => state.vars.destinations,
    (state: IAppState) => state.vars.tapadDeliveryTypes,
    (
        flowExports: Array<FlowExport>,
        flowExportXandrDriverFieldsByItemId: FlowExportXandrDriverFieldsByItemId,
        destinations: Array<IAdvertisersByCompany>
    ): Array<FlowExportXandrDriverFields> => {
        let result: Array<FlowExportXandrDriverFields> = [];

        flowExports = flowExports.filter(x => {
            const thisDestination = destinations.find(y => y.PartnerAccessId == x.DestinationId);
            if (thisDestination && thisDestination.DeploySetting == DeploySettings.DeployXandrTemplate) {
                return x;
            }
        });
        const itemIds = flowExports.map(fi => fi.FlowItemId);
        for (const itemId of itemIds) {
            const templateFields = flowExportXandrDriverFieldsByItemId[itemId];
            if (templateFields != null && templateFields.length > 0) {
                result = result.concat(templateFields);
            }
        }
        return result;
    }
);

const xandrFieldsToErrorsById = (
    flowExports: Array<FlowExport>,
    flowExportXandrDriverFields: Array<FlowExportXandrDriverFields>,
    destinations: Array<any>,
    tapadDeliveryTypes: Array<ICompanyTapadDeliveryType>
): FlowErrorsByItemId => {
    const errorsById = {};
    for (const flowExport of flowExports) {
        errorsById[flowExport.FlowItemId] = validateExportXandrDriverFields(
            flowExport,
            flowExportXandrDriverFields,
            destinations,
            tapadDeliveryTypes
        );
    }
    return errorsById;
};

export const getExportXandrDriverFieldsErrorsForSelectedFlow = createSelector(
    (state: IAppState) => getFlowExportsForSelectedFlow(state),
    (state: IAppState) => getFlowExportXandrDriverFieldsForSelectedFlow(state),
    (state: IAppState) => state.vars.destinations, // state => state.vars.tapadDeliveryTypes
    (state: IAppState) => state.vars.tapadDeliveryTypes, // state => state.vars.tapadDeliveryTypes
    xandrFieldsToErrorsById
);

export const getExportXandrDriverFieldsErrorsForAllFlows = createSelector(
    (state: IAppState) => getFlowExportsArray(state),
    (state: IAppState) => getFlowExportXandrDriverFieldsArray(state),
    (state: IAppState) => state.vars.destinations,
    (state: IAppState) => state.vars.tapadDeliveryTypes, // state => state.vars.tapadDeliveryTypes
    xandrFieldsToErrorsById
);

export const validateExportXandrDriverFields = (
    flowExport: FlowExport,
    flowExportXandrDriverFields: Array<FlowExportXandrDriverFields>,
    destinations: Array<IAdvertisersByCompany>,
    tapadDeliveryTypes: Array<ICompanyTapadDeliveryType>
): Array<string> => {
    // DeliveryType.ThirdPartyCustom == deliveryType
    const thisDeliveryType = tapadDeliveryTypes
        ? tapadDeliveryTypes.find(x => x.TapadDeliveryTypeId == flowExport.DeliveryTypeId)
        : null;
    const errors: Array<string> = [];
    // destinations are not loaded yet
    if (!destinations || (destinations && destinations.length == 0)) {
        return errors;
    }

    // no destination has been selected
    const destination = destinations.find(x => x.PartnerAccessId == flowExport.DestinationId);
    if (!destination || destination.DeploySetting != DeploySettings.DeployXandrTemplate) {
        return errors;
    }

    if (
        flowExportXandrDriverFields.filter(x => !x.AudienceName).length > 0 ||
        (flowExportXandrDriverFields.filter(x => !x.BillingCategoryId).length > 0 &&
            DeliveryType.ThirdPartyCustom == thisDeliveryType?.DeliveryType) ||
        flowExportXandrDriverFields.filter(x => !x.DataSegementTypeId).length > 0 ||
        flowExportXandrDriverFields.filter(x => !x.ExpansionType).length > 0 ||
        flowExportXandrDriverFields.filter(x => !x.ExpirationDate).length > 0 ||
        flowExportXandrDriverFields.filter(x => !x.TTL).length > 0
    ) {
        errors.push("Xander driver file fields are missing required information.");
    }

    if (!flowExport.DeliveryTypeId) {
        errors.push("Activation destination template is required.");
    }

    return errors;
};
