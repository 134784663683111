import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";

import type { FlowItem, FlowSegmentSplitOffer } from "../types/flowTypes";

const myGenericReducer = makeReducerFor("FLOW_SEGMENT_SPLIT_OFFER", "SegmentSplitOfferId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

export type FlowSegmentSplitOffersByItemId = {
    [number]: Array<FlowSegmentSplitOffer>,
};

export const getFlowSegmentSplitOfferArray = createSelector(
    state => state.flowSegmentSplitOffers.byId,
    (flowSegmentSplitOffersById: {| [number]: FlowSegmentSplitOffer |}): Array<FlowSegmentSplitOffer> => {
        const r: Array<FlowSegmentSplitOffer> = Object.values(flowSegmentSplitOffersById);
        return r;
    }
);

// eslint-disable-line
export const getFlowSegmentSplitOffersByFlowItemId = createSelector(
    state => getFlowSegmentSplitOfferArray(state),
    (flowSegmentSplitOffers: Array<FlowSegmentSplitOffer>): FlowSegmentSplitOffersByItemId =>
        flowSegmentSplitOffers.reduce((acc, row) => {
            if (!acc[row.FlowItemId]) {
                acc[row.FlowItemId] = [];
            }
            acc[row.FlowItemId].push(row);
            return acc;
        }, {})
);

export const getFlowSegmentSplitOffersForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowSegmentSplitOffersByFlowItemId(state),
    (
        flowItems: Array<FlowItem>,
        flowSegmentSplitOffersByItemId: FlowSegmentSplitOffersByItemId
    ): Array<FlowSegmentSplitOffer> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        let result = [];
        for (const itemId of itemIds) {
            const script = flowSegmentSplitOffersByItemId[itemId];
            if (script != null) {
                result = result.concat(script);
            }
        }
        return result;
    }
);

export const getFlowSegmentSplitOffersForSelectedFlowItem = createSelector(
    state => state.selected.flowItem,
    state => getFlowSegmentSplitOffersByFlowItemId(state),
    (
        flowItemId: number,
        flowSegmentSplitOffersByItemId: FlowSegmentSplitOffersByItemId
    ): Array<FlowSegmentSplitOffer> => flowSegmentSplitOffersByItemId[flowItemId] || []
);
