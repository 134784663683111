import { IAdminCompaniesStore, AdminCompaniesActions } from "../types/stores/adminCompanies";

export const GET_USER_COMPANIES = "GET_USER_COMPANIES";
export const EDIT_MAXIMUM_SEATS = "EDIT_MAXIMUM_SEATS";
export const SET_SELECTED_COMPANY = "SET_SELECTED_COMPANY";
export const LOADING_COMPANIES = "LOADING_COMPANIES";
export const SET_COMPANY_DESTINATIONS = "SET_COMPANY_DESTINATIONS";

const initialState: IAdminCompaniesStore = {
    userCompanies: [],
    selectedCompanyId: 0,
    loading: false,
    companyDestinations: {},
};

function adminCompanies(state = initialState, action: AdminCompaniesActions): IAdminCompaniesStore {
    switch (action.type) {
        case GET_USER_COMPANIES:
            return Object.assign({}, state, {
                userCompanies: action.userCompanies,
            });
        case LOADING_COMPANIES:
            return Object.assign({}, state, {
                loading: action.loading,
            });
        case SET_SELECTED_COMPANY:
            return { ...state, selectedCompanyId: action.companyId };
        case EDIT_MAXIMUM_SEATS:
            return state;
        case SET_COMPANY_DESTINATIONS:
            return {
                ...state,
                companyDestinations: { ...state.companyDestinations, [action.companyId]: action.destinations },
            };
        default:
            return state;
    }
}

export default adminCompanies;
