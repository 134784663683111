import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import type { FlowGuideSetting } from "../types/flowTypes";

const myGenericReducer = makeReducerFor("FLOW_GUIDE_SETTINGS", "FlowGuideId");
export default myGenericReducer;

///////// SELECTORS /////////////

export const getFlowGuideSettingsArray = createSelector(
    state => state.flowGuideSettings.byId,
    (FlowGuideSettingsById: {| [number]: FlowGuideSetting |}): Array<FlowGuideSetting> => {
        const r: Array<FlowGuideSetting> = Object.values(FlowGuideSettingsById);
        return r;
    }
);

export const getFlowGuideSettingsForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowGuideSettingsArray(state),
    (selectedFlow: number, flowGuideSettings: Array<FlowGuideSetting>): FlowGuideSetting => {
        let result = flowGuideSettings.find(x => x.FlowId == selectedFlow);
        return result;
    }
);
