import isEqual from "lodash/isEqual";
import clone from "../helpers/clone";
import mergeDeep from "../helpers/mergeDeep";
import update from "immutability-helper";
import { createSelector } from "reselect";
import ofilter from "../helpers/ofilter";
import { folderMatchesSearchString } from "../helpers/folder";
import { searchTree, searchTreeReturnParent } from "./_folderGeneral";

function layouts(state = {}, action) {
    switch (action.type) {
        case "NEW_LAYOUT": {
            const newState = clone(state);
            newState[0] = {
                LayoutID: 0,
                LayoutName: "",
                LayoutActive: "Y",
                LayoutDefault: "Y",
                LayoutDateCreated: null,
                LayoutCreatedBy: null,
                LayoutUpdated: null,
                LayoutUpdatedBy: null,
                LayoutSetToDefault: null,
                LayoutDefaultBy: null,
                LayoutIncludeDefaultFields: "Y",
                LayoutIncludeCellID: "Y",
                LayoutCompanyID: null,
                LayoutDelimiter: ",",
                LayoutType: "S",
                LayoutCompression: "D",
                IsDefaultLayout: false,
                LayoutIsFixed: false,
                IsPivot: false,
                IsCreateDoneFile: false,
                FixedWidthFile: false,
            };
            return newState;
        }
        case "NEW_LAYOUT_CREATE": {
            const newState = clone(state);
            newState[0] = {
                LayoutID: 0,
                LayoutName: action.name || "",
                LayoutActive: "Y",
                LayoutDefault: "Y",
                LayoutDateCreated: null,
                LayoutCreatedBy: null,
                LayoutUpdated: null,
                LayoutUpdatedBy: null,
                LayoutSetToDefault: null,
                LayoutDefaultBy: null,
                LayoutIncludeDefaultFields: "Y",
                LayoutIncludeCellID: "Y",
                LayoutCompanyID: null,
                LayoutDelimiter: ",",
                LayoutType: "S",
                LayoutCompression: "D",
                IsDefaultLayout: false,
                LayoutIsFixed: false,
                IsPivot: false,
                IsCreateDoneFile: false,
                DestinationLayout: false,
                FixedWidthFile: false,
                DeploySeparateFiles: false,
            };
            return newState;
        }
        case "ASSIGN_NEW_LAYOUT_ID": {
            const newState = clone(state);
            newState[action.newLayoutId] = clone(newState[0]);
            delete newState[0];
            return newState;
        }
        case "UPDATE_LAYOUT": {
            let newState = clone(state);
            newState = update(newState, {
                [action.Id]: {
                    hasUnsavedChanges: { $set: action.Unsaved },
                },
            });

            return newState;
        }
        case "UPDATE_LAYOUT_LIST": {
            let newState;

            const newAction = clone(action);

            if (action.mergeBehavior && action.mergeBehavior == "replace") {
                newState = action.layouts;
                if (typeof state[0] === "object") {
                    newState[0] = clone(state[0]);
                }
            } else if (action.mergeBehavior && action.mergeBehavior == "merge-overwrite") {
                newState = clone(state);
                for (const layoutToDelete of Object.keys(action.layouts)) {
                    delete newState[layoutToDelete];
                }
                newState = mergeDeep(newState, newAction.layouts);
            } else {
                // Don't merge in data from server over layouts that have unsaved local changes
                const stateWithUnsavedChanges = ofilter(v => v["hasUnsavedChanges"], state);
                for (const layoutIdWithUnsavedChanges of Object.keys(stateWithUnsavedChanges)) {
                    delete newAction.layouts[layoutIdWithUnsavedChanges];
                }
                newState = mergeDeep(clone(state), newAction.layouts);
            }

            if (isEqual(state, newState)) {
                return state;
            }

            return newState;
        }
        case "SET_LAYOUT_ISFIXED": {
            return update(state, {
                [action.layouts.layoutID]: {
                    LayoutIsFixed: { $set: action.layouts.IsFixed },
                },
            });
        }
        case "SET_LAYOUT_ISDEPLOYSEPARATEFILES": {
            return update(state, {
                [action.layouts.layoutID]: {
                    DeploySeparateFiles: { $set: action.layouts.DeploySeparateFiles },
                },
            });
        }
        case "SET_LAYOUT_ISACTIVEDESTINATION": {
            let newState = clone(state);
            for (const layoutId of Object.keys(newState)) {
                newState = update(newState, {
                    [layoutId]: {
                        IsActiveDestinationLayout: { $set: false },
                    },
                });
            }

            newState = update(newState, {
                [action.layouts.layoutID]: {
                    IsActiveDestinationLayout: { $set: action.layouts.IsActiveDestinationLayout },
                },
            });
            return newState;
        }
        case "SET_LAYOUT_ISDESTINATION": {
            return update(state, {
                [action.layouts.layoutID]: {
                    DestinationLayout: { $set: action.layouts.DestinationLayout },
                },
            });
        }
        case "ARCHIVE_LAYOUT": {
            /* This update helper really is great, I should clean up the other actions and reducers to use it */
            return update(state, {
                [action.layoutID]: {
                    LayoutActive: { $set: "N" },
                    LayoutFolderId: { $set: action.folderId },
                },
            });
        }
        case "DELETE_LAYOUT": {
            return update(state, {
                [action.layoutID]: {
                    LayoutID: { $set: null },
                },
            });
        }
        case "RECOVER_LAYOUT": {
            return update(state, {
                [action.layoutID]: {
                    LayoutActive: { $set: "Y" },
                    LayoutFolderId: { $set: action.folderId },
                },
            });
        }
        case "MOVE_LAYOUT_TO_FOLDER": {
            const newState = clone(state);
            const newLayout = mergeDeep({}, newState[action.layoutId]);
            newLayout.LayoutFolderId = action.folderId;
            newState[action.layoutId] = newLayout;
            return newState;
        }
        default:
            return state;
    }
}

export default layouts;

import { haystackMatchesSearchObject } from "./search";
// layoutMatchesSearchString
// Wants an "layout object" as first argument
export const layoutMatchesSearchString = (layout, searchString) => {
    if (!layout || !layout.LayoutName) {
        return false;
    }
    return haystackMatchesSearchObject(layout.LayoutName, searchString);
};

export const layoutObjectMatchesSearchString = (layoutObject, searchString) => {
    if (!layoutObject || (!layoutObject.DisplayName && !layoutObject.ExportName)) {
        return false;
    }
    return (
        (!!layoutObject.DisplayName && haystackMatchesSearchObject(layoutObject.DisplayName, searchString)) ||
        (!!layoutObject.ExportName && haystackMatchesSearchObject(layoutObject.ExportName, searchString))
    );
};

export const variableMatchesSearchString = (variable, searchString) => {
    if (!variable || !variable.VariableName) {
        return false;
    }
    return haystackMatchesSearchObject(variable.VariableName, searchString);
};

export const getMyArchivedLayouts = createSelector(
    state => state.layouts,
    state => state.session.userId,
    (layouts, userId) =>
        ofilter(v => v["LayoutActive"] && v["LayoutActive"] == "N" && v["LayoutCreatedBy"] == userId, layouts)
);

export const numberOfLayoutsInFolder = (state: any, folderId: number) => {
    const layouts = state.layouts;
    if (!layouts) {
        return -1;
    }
    const layoutsValues = Object.values(layouts);
    return layoutsValues.filter(x => x.LayoutFolderId == folderId).length;
};

const layoutIdIsInFolder = (stateById, layoutId: number, folderId: number) => {
    // This function is a candidate for removal now that we don't have the "E" workaround
    const layouts = stateById;
    return layouts[layoutId].LayoutFolderId == folderId;
};

// layoutFolderIdContainsSearch -
// Used in tree search - does any of this folder's children have the search string (via LayoutName)'
export const layoutFolderIdContainsSearch = (
    layoutState: any,
    folders,
    folderId: number,
    searchString: string | Array<string>
) => {
    const layoutIds: Array<number> = Object.keys(layoutState);

    // Look for layouts that match
    for (const layoutId of layoutIds) {
        if (!layoutMatchesSearchString(layoutState[layoutId], searchString)) {
            continue;
        }
        if (layoutIdIsInFolder(layoutState, layoutId, folderId)) {
            return true;
        }
    }

    // Recursively call children
    const folder = searchTree(folders, folderId);
    if (folder.children) {
        for (const child of folder.children) {
            if (folderMatchesSearchString(child, searchString)) {
                return true;
            }
            if (layoutFolderIdContainsSearch(layoutState, folders, child.id, searchString)) {
                return true;
            }
        }
    }

    // Check parents, too.
    return layoutFolderIdParentsContainSearch(folders, folderId, searchString);
}; // this function is a possible candidate for memoization, it ends up recomputing some data because of its recursive nature

const layoutFolderIdParentsContainSearch = (folders, folderId: number, searchString: string | Array<string>) => {
    const parent = searchTreeReturnParent(folders, folderId);
    if (parent == null) {
        return false;
    }

    if (Array.isArray(parent)) {
        // Special case for top level is array (I prob should have made a root node :( )
        for (const element of parent) {
            if (folderMatchesSearchString(element, searchString)) {
                return true;
            }
        }
        return false;
    }
    if (folderMatchesSearchString(parent, searchString)) {
        return true;
    }
    return layoutFolderIdParentsContainSearch(folders, parent.id, searchString);
};
