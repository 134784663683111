import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import type { FlowItem, FlowExternalServiceInput } from "../types/stores/flowTypes";

const myGenericReducer = makeReducerFor("FLOW_EXTERNAL_SERVICE_INPUT", "InputId");
import subItemReducer from "./_genericFlowSubItemReducer";
import { IAppState } from "../types/stores";
import { IFlowExternalServiceInputStore } from "../types/stores/flowExternalServiceInput";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const myReducer = (state = {}, action: any): IFlowExternalServiceInputStore =>
    subItemReducer(myGenericReducer(state, action), action);

export default myReducer;

export type FlowExternalServiceInputById = {
    [key: number]: FlowExternalServiceInput;
};
export type FlowExternalServiceInputByItemId = {
    [key: number]: Array<FlowExternalServiceInput>;
};

export const getFlowExternalServiceInputsArray = createSelector(
    (state: IAppState) => state.flowExternalServiceInputs.byId,
    (flowExternalServiceInputById: FlowExternalServiceInputById): Array<FlowExternalServiceInput> => {
        const r: Array<FlowExternalServiceInput> = Object.values(flowExternalServiceInputById);
        return r;
    }
);

// eslint-disable-line
export const getFlowExternalServiceInputsByFlowItemId = createSelector(
    (state: IAppState) => getFlowExternalServiceInputsArray(state),
    (flowExternalServiceInput: Array<FlowExternalServiceInput>): FlowExternalServiceInputByItemId =>
        flowExternalServiceInput.reduce((acc, row) => {
            if (!acc[row.FlowItemId]) {
                acc[row.FlowItemId] = [];
            }
            acc[row.FlowItemId].push(row);
            return acc;
        }, {})
);

export const getFlowExternalServiceInputsForSelectedFlow = createSelector(
    (state: IAppState) => getFlowItemsForSelectedFlow(state),
    (state: IAppState) => getFlowExternalServiceInputsByFlowItemId(state),
    (
        flowItems: Array<FlowItem>,
        flowExternalServiceInputsByItemId: FlowExternalServiceInputByItemId
    ): Array<FlowExternalServiceInput> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        let result: Array<FlowExternalServiceInput> = [];
        for (const itemId of itemIds) {
            const params = flowExternalServiceInputsByItemId[itemId];
            if (params != null) {
                result = result.concat(params);
            }
        }
        return result;
    }
);

//@ts-ignore
export const getFlowExternalServiceInputsForSelectedFlowItem = createSelector(
    (state: IAppState) => state.selected.flowItem,
    (state: IAppState) => getFlowExternalServiceInputsByFlowItemId(state),
    (flowItemId, flowExternalServiceInputsByItemId): Array<FlowExternalServiceInput> =>
        flowExternalServiceInputsByItemId[flowItemId] || []
);
