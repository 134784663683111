function countLog(
    state = {
        loading: {
            active: true,
            historical: true,
        },
    },
    action
) {
    switch (action.type) {
        case "SET_COUNT_LOG_ACTIVE_LIST":
            return {
                ...state,
                activeList: action.activeList,
                loading: {
                    active: action.loading,
                    historical: state.loading.historical,
                },
            };
        case "SET_COUNT_LOG_HISTORICAL_LIST":
            return {
                ...state,
                historicalList: action.historicalList,
                loading: {
                    active: state.loading.active,
                    historical: action.loading,
                },
            };
        default:
            return state;
    }
}
export default countLog;
