import update from "immutability-helper";

const defaultState = {
    flowUserConfigsCyclone: {},
    flowItemRenamingNames: {},
    cycloneViewportScrollTop: 0,
    cycloneViewportScrollLeft: 0,
    cycloneViewportWidth: 0,
    cycloneViewportHeight: 0,
    flowGuideModelName: "",
};

function flowVars(state = defaultState, action) {
    switch (action.type) {
        case "SET_FLOW_ITEM_RENAMING_NAME": {
            const oldState = state.flowItemRenamingNames != null ? state.flowItemRenamingNames : {};
            const flowItemRenamingNames = update(oldState, {
                $merge: { [action.flowItemId]: action.renamingName },
            });
            return {
                ...state,
                flowItemRenamingNames,
            };
        }
        case "CYCLONE_SET_CANVAS_INFO": {
            return {
                ...state,
                cycloneViewportScrollTop: action.scrollTop,
                cycloneViewportScrollLeft: action.scrollLeft,
                cycloneViewportWidth: action.width,
                cycloneViewportHeight: action.height,
            };
        }
        case "SET_FLOW_USER_CONFIGS":
            return { ...state, flowUserConfigsCyclone: action.newVal };
        case "SET_FLOW_GUIDE_MODEL_NAME":
            return { ...state, flowGuideModelName: action.modelName };
        default:
            return state;
    }
}
export default flowVars;
