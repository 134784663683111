import update from "immutability-helper";
import clone from "../helpers/clone";

const defaultState = {
    field: null,
    report: null,
    layout: null,
    cannedReport: null,
    tableReportTable: null,
    flow: 0,
    flowItem: 0,
    flowItems: [],
    flowRelations: [],
    user: 0,
    selectedFields: [],
    lastSelected: null,
    flowControlTabValue: 0,
    simpleFlowGuideActiveStep: 0,
};

function selected(state = defaultState, action) {
    switch (action.type) {
        // FLOW
        case "SET_SELECTED_FLOW":
            return update(state, {
                flow: { $set: action.flowId },
            });
        case "SET_SELECTED_FLOW_ITEM":
            return update(state, {
                flowItem: { $set: action.flowItemId },
            });
        case "SET_SIMPLE_FLOW_GUIDE_ACTIVE_STEP":
            return update(state, {
                simpleFlowGuideActiveStep: { $set: action.newStep },
            });
        case "SET_SELECTED_FLOW_ITEMS":
            return update(state, {
                flowItems: { $set: action.flowItems },
            });
        case "SET_SELECTED_FLOW_RELATIONS":
            return update(state, {
                flowRelations: { $set: action.flowRelations },
            });
        case "CLEAR_SELECTED_FLOW":
            return update(state, {
                flow: { $set: null },
            });
        case "SET_FLOW_CONTROL_TAB_VALUE":
            return update(state, {
                flowControlTabValue: { $set: action.newTabValue },
            });
        // FIELD
        case "SET_SELECTED_FIELD":
            return Object.assign({}, state, {
                field: action.fieldKey,
                lastSelected: action.fieldKey,
            });
        case "CLEAR_SELECTED_FIELD":
            return Object.assign({}, state, {
                field: null,
            });
        case "SET_SELECTED_FIELDS": {
            let newSelectedFields = clone(state.selectedFields);
            let lastSelected = clone(state.lastSelected);
            if (newSelectedFields.includes(action.fieldKey)) {
                const index = newSelectedFields.indexOf(action.fieldKey);
                newSelectedFields.splice(index, 1);
            } else {
                newSelectedFields.push(action.fieldKey);
                lastSelected = action.fieldKey;
            }
            return Object.assign({}, state, {
                selectedFields: newSelectedFields,
                lastSelected,
            });
        }
        case "CLEAR_SELECTED_FIELDS":
            return Object.assign({}, state, {
                selectedFields: [],
            });

        // REPORT
        /*
            null = show splash screen
            0 = Working on unsaved
            > 0 = Working on saved report
        */
        case "SET_SELECTED_REPORT":
            return update(state, {
                report: { $set: action.Id },
            });
        case "ASSIGN_NEW_REPORT_ID":
            if (state.report !== 0 && state.report !== null) {
                return state;
            }
            return update(state, {
                report: { $set: action.newReportId },
            });
        case "NEW_SINGLE_VIEW_REPORT":
        case "NEW_COUNT_REPORT":
        case "NEW_FLOW_EXPORTREPORT_LOG_REPORT":
        case "NEW_FLOW_LOG_REPORT":
            return update(state, {
                report: { $set: 0 },
            });
        case "CLEAR_SELECTED_REPORT":
            return update(state, {
                report: { $set: null },
            });
        case "SET_SELECTED_DATASOURCE": // Is this used?
            return update(state, {
                report: { $set: action.dataSource.reportType },
            });
        case "CLEAR_SELECTED_DATASOURCE": // Is this used?
            return update(state, {
                report: { $set: null },
            });

        // LAYOUT
        case "SET_SELECTED_LAYOUT":
            return update(state, {
                layout: { $set: action.Id },
            });
        case "SET_SELECTED_GLOBAL_VARIABLE":
            return update(state, {
                globalVariable: { $set: action.Id },
            });
        case "ASSIGN_NEW_LAYOUT_ID":
            if (state.layout !== 0 && state.layout !== null) {
                return state;
            }
            return update(state, {
                layout: { $set: action.layoutId },
            });
        case "NEW_LAYOUT":
            return update(state, {
                layout: { $set: 0 },
            });
        case "CLEAR_SELECTED_LAYOUT":
            return update(state, {
                layout: { $set: null },
            });

        // CANNEDREPORT
        case "SET_SELECTED_CANNEDREPORT":
            return update(state, {
                cannedReport: { $set: action.Id },
            });

        case "CLEAR_SELECTED_CANNEDREPORT":
            return update(state, {
                cannedReport: { $set: null },
            });

        case "SET_TABLEREPORT_SELECTED_TABLE": {
            return update(state, {
                tableReportTable: {
                    $set: action.selectedTable,
                },
            });
        }

        case "UPDATE_QUERYBUILDER_GROUP_LIST": {
            return update(state, {
                queryBuilderGroups: { $set: action.queryBuilderGroups },
            });
        }

        // USER
        case "SET_SELECTED_USER":
            return update(state, {
                user: { $set: action.userId },
            });
        case "SET_SELECTED_REASSIGN_TO":
            return update(state, {
                reassiendUserId: { $set: action.reassiendUserId },
            });

        default:
            return state;
    }
}
export default selected;
