import React from "react";
import FlowItemToggle from "../Shared/FlowItemToggle";

type Props = {
    // Passed In
    flowItemId: number;
    disabled?: boolean;
};

const FlowItemDeployToggle: React.FC<Props> = ({ flowItemId, disabled = false }: Props) => {
    if (flowItemId == null) {
        return null;
    }

    return (
        <FlowItemToggle
            flowItemId={flowItemId}
            parameterName="ShouldDeploy"
            toggleLabel="Deploy"
            onTooltip="Will send this item to the deployment queue when requesting export approval"
            offTooltip=""
            flowItemType="Export"
            onText="YES"
            offText="NO"
            isInvalidateItemAndChildren={false}
            isDisabled={disabled || false}
        />
    );
};

export default FlowItemDeployToggle;
