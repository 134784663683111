import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { IAppState } from "../../../../types/stores";
import { Tooltip, Icon } from "@material-ui/core";
import IconSwitch from "../../../material-components/Misc/IconSwitch";

import { FlowExport, FlowSegmentSplit, FlowSegmentSplitOffer } from "../../../../types/stores/flowTypes";
import {
    updateAttribute,
    deleteFlowSegmentSplit,
    deleteFlowSegmentSplitOffer,
    newFlowSegmentSplitAndOffersOverWrite,
    invalidateItemAndChildren,
} from "../../../../actions/flowActions";
import { getFlowSegmentSplitsForSelectedFlowItem } from "../../../../reducers/flowSegmentSplits";
import { getFlowSegmentSplitOffersForSelectedFlowItem } from "../../../../reducers/flowSegmentSplitOffers";
import { IClientVariable } from "../../../../types/stores/vars";

interface IProps {
    flowExport: FlowExport;
    disabled: boolean;
    splitOverwrite: boolean;
    destinationOffers: IClientVariable;
}

export const FlowExportSplitToggle: React.FC<IProps> = ({
    flowExport,
    disabled,
    splitOverwrite,
    destinationOffers,
}: IProps) => {
    const dispatch = useDispatch();
    const segmentSplits = useSelector<IAppState, Array<FlowSegmentSplit>>(state =>
        getFlowSegmentSplitsForSelectedFlowItem(state)
    );
    const segmentSplitOffers = useSelector<IAppState, Array<FlowSegmentSplitOffer>>(state =>
        getFlowSegmentSplitOffersForSelectedFlowItem(state)
    );
    const enabledFeatures = useSelector((state: IAppState) => state.session.enabledFeatures);

    const hasSplitChanged = (e: any) => {
        const isChecked = e.target.checked;
        dispatch(updateAttribute("flowExports", flowExport.FlowExportId, "IsSplitOn", isChecked));
        if (!isChecked) {
            for (const split of segmentSplits) {
                dispatch(deleteFlowSegmentSplit(split.SegmentSplitId));
            }
            for (const split of segmentSplitOffers) {
                dispatch(deleteFlowSegmentSplitOffer(split.SegmentSplitOfferId));
            }
        } else if (splitOverwrite) {
            if (enabledFeatures.includes("flow-destination-level-splits-all-offers")) {
                dispatch(
                    newFlowSegmentSplitAndOffersOverWrite(
                        flowExport.FlowItemId,
                        segmentSplits,
                        destinationOffers,
                        segmentSplitOffers
                    )
                );
            }
            dispatch(invalidateItemAndChildren(flowExport.FlowItemId));
        }
    };

    const renderSwitch = () => {
        let content = (
            <IconSwitch
                id="split-on"
                name="splitOn"
                checked={flowExport.IsSplitOn}
                onChange={hasSplitChanged}
                disabled={disabled}
            />
        );

        return content;
    };

    return (
        <div style={{ display: "flex", marginLeft: "40px" }}>
            <div style={{ display: "flex", placeSelf: "flex-end" }}>
                <div
                    style={{
                        display: "flex",
                        fontSize: "17px",
                        position: "relative",
                        top: "5px",
                        marginRight: "30px",
                    }}
                >
                    <strong>Split&nbsp;</strong>
                    <Tooltip
                        title={`ON will allow to split the inputs in this export node`}
                        style={{ fontSize: "14px" }}
                    >
                        <Icon>info</Icon>
                    </Tooltip>
                </div>
                <div style={{ display: "flex" }}>{renderSwitch()}</div>
            </div>
        </div>
    );
};
export default FlowExportSplitToggle;
