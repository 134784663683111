import React from "react";
import IconSwitch from "../../../material-components/Misc/IconSwitch";
import { FormControlLabel, Icon, Tooltip } from "@material-ui/core";

type Props = {
    customDedupe: boolean;
    disabled: boolean;
    updateCustomDedupe: (e) => void;
};

const CustomDedupeToggle: React.FC<Props> = ({ customDedupe, disabled, updateCustomDedupe }: Props) => {
    const label = (
        <Tooltip
            title={
                <>
                    Turn ON to customize your dedupe options
                    <br />
                    Turn OFF to use defaults
                </>
            }
            id="dedupe_tip"
        >
            <div style={{ fontSize: "16px" }}>
                <strong>Custom Dedupe&nbsp;</strong>
                <Icon style={{ fontSize: "16px" }}>info</Icon>
            </div>
        </Tooltip>
    );

    return (
        <FormControlLabel
            labelPlacement="start"
            style={{
                minWidth: "175px",
                margin: "0 auto",
            }}
            control={
                <IconSwitch
                    id="custom-dedupe"
                    name="customDedupe"
                    checked={customDedupe}
                    onChange={updateCustomDedupe}
                    disabled={disabled}
                />
            }
            label={label}
        />
    );
};

export default CustomDedupeToggle;
