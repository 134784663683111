import React, { useState } from "react";
import { ReactNodeLike } from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

interface IPDialogProps {
    id: string;
    isOpen: boolean;
    onClose: (event?: {}, reason?: any) => void;
    afterClose?: (...args: any) => void;
    onEnter?: (event: {}, reason?: any) => void;
    afterOpen?: (...args: any) => void;
    fullWidth?: boolean;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
    disableEscape?: boolean;
    title?: ReactNodeLike;
    innerDescription?: string;
    children?: ReactNodeLike;
    footer?: ReactNodeLike; // Optional override of entire footer element, for more complicated setups
    onPrimaryButtonRequest?: (event?: {}, reason?: any) => void; // What happens if you click the primary button
    onSecondaryButtonRequest?: (event?: {}, reason?: any) => void;
    primaryButtonText?: string; // Text on primary button, defaults to "OK"
    secondaryButtonText?: string;
    isPrimaryButtonDisabled?: boolean; // Should the primary button be disabled?
    isSecondaryButtonDisabled?: boolean;
    isNoPaddingModal?: boolean;
    showPrimaryButton?: boolean; // Should the primary button be shown?
    showSecondaryButton?: boolean; // for another button
    cancelButtonText?: string;
    showConfirmModal?: boolean; // Should the primary button be shown?
    confirmTitle?: string;
    confirmButtonText?: string;
    confirmBody?: ReactNodeLike;
}

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps & { children?: React.ReactElement<any, any> }>(
    (props, ref) => <Slide direction="up" ref={ref} {...props} />
);

const MaterialModal: React.FC<IPDialogProps> = ({
    // container
    id,
    isOpen,
    afterOpen,
    onClose,
    disableEscape,
    // body
    title,
    innerDescription,
    children,
    fullWidth,
    maxWidth = undefined,
    // action
    primaryButtonText = "Save",
    secondaryButtonText,
    onPrimaryButtonRequest,
    onSecondaryButtonRequest,
    isPrimaryButtonDisabled,
    isSecondaryButtonDisabled,
    isNoPaddingModal,
    showPrimaryButton = true,
    showSecondaryButton = false,
    cancelButtonText = "Cancel",
    // confirm
    showConfirmModal,
    confirmTitle,
    confirmButtonText,
    confirmBody,
    footer,
    afterClose,
}: IPDialogProps) => {
    const [isConfirmOpen, setConfirmOpen] = useState(false);

    const handleClickOpen = () => setConfirmOpen(true);

    const handleClose = () => setConfirmOpen(false);

    const handleConfirmClick = () => {
        onPrimaryButtonRequest && onPrimaryButtonRequest();
        handleClose();
    };

    const handleSecondaryConfirmClick = () => {
        onSecondaryButtonRequest && onSecondaryButtonRequest();
        handleClose();
    };

    const modalCSS = isNoPaddingModal ? "material-modal material-min" : "material-modal";

    let primaryButton = (
        <Button
            variant="contained"
            color="primary"
            className="material-modal-primary-btn"
            onClick={_ =>
                showConfirmModal ? handleClickOpen() : onPrimaryButtonRequest ? onPrimaryButtonRequest() : null
            }
            disabled={Boolean(isPrimaryButtonDisabled)}
        >
            {primaryButtonText}
        </Button>
    );

    let secondaryButton = (
        <Button
            variant="contained"
            color="primary"
            className="material-modal-primary-btn"
            onClick={_ =>
                showConfirmModal ? handleClickOpen() : onSecondaryButtonRequest ? onSecondaryButtonRequest() : null
            }
            disabled={Boolean(isSecondaryButtonDisabled)}
        >
            {secondaryButtonText}
        </Button>
    );

    // Confirmation modal
    if (showConfirmModal) {
        primaryButton = (
            <div>
                {primaryButton}
                {showSecondaryButton && secondaryButton}
                <MaterialModal
                    id="confirm-modal"
                    maxWidth={maxWidth}
                    isOpen={Boolean(isConfirmOpen)}
                    title={confirmTitle || ""}
                    onClose={handleClose}
                    primaryButtonText={confirmButtonText || ""}
                    onPrimaryButtonRequest={handleConfirmClick}
                    onSecondaryButtonRequest={handleSecondaryConfirmClick}
                    showPrimaryButton={true}
                    showSecondaryButton={showSecondaryButton ? true : false}
                >
                    {confirmBody}
                </MaterialModal>
            </div>
        );
    }

    const defaultFooter = (
        <>
            {!disableEscape && (
                <Button
                    id="material-modal-cancel-button"
                    onClick={() => onClose()}
                    color="primary"
                    className="material-modal-cancel-btn"
                >
                    {cancelButtonText}
                </Button>
            )}
            {showSecondaryButton && secondaryButton}
            {showPrimaryButton && primaryButton}
        </>
    );

    return (
        <Dialog
            id={id}
            open={isOpen}
            onClose={(_, reason) => (reason == "backdropClick" ? (disableEscape ? null : onClose()) : onClose())}
            onExited={afterClose!}
            TransitionComponent={Transition}
            TransitionProps={{ onEntered: _ => (afterOpen ? afterOpen() : null) }}
            aria-labelledby={`form-dialog-${id}-title`}
            className={modalCSS || ""}
            maxWidth={maxWidth}
            fullWidth={Boolean(fullWidth)}
            disableEscapeKeyDown={Boolean(disableEscape)}
            // transitionDuration={{ enter: 200, exit: 0 }}
        >
            <DialogTitle id={`form-dialog-${id}-title`} className="title">
                {title}
            </DialogTitle>
            <DialogContent>
                {innerDescription && <DialogContentText>{innerDescription}</DialogContentText>}
                {children}
            </DialogContent>
            <DialogActions>{footer || defaultFooter}</DialogActions>
        </Dialog>
    );
};

export default MaterialModal;
