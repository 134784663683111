import React from "react";
import PropTypes from "prop-types";

import FolderRename from "./FolderRename";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../actions/actionCreators";
import * as audienceFolderActions from "../../actions/audienceFolderActions";

import { allowedToDeleteFolder, allowedToCreateFolder } from "../../reducers/_folderGeneral";

import highlightForSearch from "../../helpers/highlightForSearch";
import { getTreeSearch } from "../../reducers/search";
import { folderReducerKeyForType } from "../../helpers/folder";
class FolderLabel extends React.Component {
    render() {
        const links = [];
        const {
            node,
            userId,
            companyId,
            isInternal,
            allFolders,
            requestNewFolder,
            requestDeleteFolder,
            folderType,
            roles,
            flows,
            layouts,
            pathname,
        } = this.props;

        const showDelete = allowedToDeleteFolder(
            { flows, layouts, roles, audienceFolders: allFolders, isLabel: true },
            node.id,
            userId,
            companyId,
            isInternal
        );
        if (showDelete) {
            links.push(
                <i
                    title="Delete Folder"
                    className="glyphicon glyphicon-remove red m-r-5"
                    key="delete"
                    onClick={e => {
                        e.stopPropagation();
                        requestDeleteFolder(folderType, node.id);
                    }}
                />
            );
        }

        const showCreate = allowedToCreateFolder(allFolders, node.id, userId, companyId, isInternal);
        if (showCreate) {
            links.push(
                <i
                    title="Create Folder"
                    className="glyphicon glyphicon-plus darkgrey3 m-r-5"
                    key="new"
                    onClick={e => {
                        e.stopPropagation();
                        requestNewFolder(folderType, node.id);
                    }}
                />
            );
        }

        let folderName = node.name;
        if (node.level == 0 && node.userId !== 0) {
            folderName = this.props.userNameForId[node.userId] || "UserId " + node.userId;
        }
        folderName = highlightForSearch(folderName, this.props.treeSearch);
        if (
            this.props.renamingAudienceFolderId == node.id &&
            this.props.renamingAudienceFolderType == node.folderType
        ) {
            folderName = (
                <FolderRename folderName={node.name} folderId={node.id} key={node.id} folderType={node.folderType} />
            );
        }

        let inlineBlock = { display: "inline-block", width: "calc(100% - 23px)", paddingRight: "0px" };

        if (pathname) {
            if (pathname.includes("flows")) {
                inlineBlock = { margin: "-35px 40px 10px" };
            } else if (pathname.includes("schedules")) {
                inlineBlock = { margin: "-22px 30px 10px" };
            } else if (pathname.includes("/layouts")) {
                inlineBlock = { margin: "-25px 30px 10px" };
            }
        }

        // ^ This still isn't perfect.
        // If changing it, be careful of IE11 - sometimes it will display "..." instead of the foldername always,
        // for some reason text-overflow ellipses will kick in inappropriately.
        return (
            <div style={inlineBlock}>
                {folderName}
                <div className="pull-right tree-links">{links}</div>
            </div>
        );
    }
}
FolderLabel.propTypes = {
    // Passed In
    folderType: PropTypes.string.isRequired,
    node: PropTypes.object.isRequired,
    // Redux
    flows: PropTypes.object,
    layouts: PropTypes.object,
    allFolders: PropTypes.array,
    renamingAudienceFolderId: PropTypes.number,
    renamingAudienceFolderType: PropTypes.string,
    userId: PropTypes.number,
    companyId: PropTypes.number,
    isInternal: PropTypes.bool,
    treeSearch: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    userNameForId: PropTypes.object,
    roles: PropTypes.array,
    // Injected by Redux (action creator)
    requestDeleteFolder: PropTypes.func.isRequired,
    requestNewFolder: PropTypes.func.isRequired,
    pathname: PropTypes.string,
};

const emptyObject = {};
const mapStateToProps = (state, ownProps) => {
    const folderReducerKey = folderReducerKeyForType(ownProps.folderType);
    return {
        flows: state.flows,
        layouts: state.layouts,
        allFolders: state.folders[folderReducerKey],
        renamingAudienceFolderId: state.vars.renamingAudienceFolderId,
        renamingAudienceFolderType: state.vars.renamingAudienceFolderType,
        userId: state.session.userId,
        companyId: state.session.companyId,
        isInternal: state.session.isInternal,
        roles: state.session.roles,
        treeSearch: getTreeSearch(state),
        userNameForId: state.varsPersist.userNameForId || emptyObject,
        pathname: state.router && state.router.location && state.router.location.pathname,
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(Object.assign({}, actionCreators, audienceFolderActions), dispatch);
const FolderLabelC = connect(mapStateToProps, mapDispatchToProps)(FolderLabel);

export default FolderLabelC;
