import { ISnowflakeInstanceStore } from "../types/stores/snowflakeInstance";
import {
    IActionTypes,
    SET_SELECTED_SNOWFLAKE_INSTANCE,
    SET_SNOWFLAKE_INSTANCES,
    SET_SNOWFLAKE_INSTANCES_LOADING,
} from "../types/actions/snowflakeInstance";

const initialState: ISnowflakeInstanceStore = {
    instances: [],
    selectedInstance: 0,
    isLoading: false,
    isSaving: false,
    isTested: false,
    isTestSuccess: false,
    hasUnsavedChanges: false,
};

function snowflakeInstanceData(state = initialState, action: IActionTypes): ISnowflakeInstanceStore {
    switch (action.type) {
        case SET_SNOWFLAKE_INSTANCES: {
            return { ...state, instances: action.instances };
        }
        case SET_SELECTED_SNOWFLAKE_INSTANCE: {
            return { ...state, selectedInstance: action.selectedInstance };
        }
        case SET_SNOWFLAKE_INSTANCES_LOADING: {
            return { ...state, isLoading: action.isLoading };
        }
        default:
            return state;
    }
}
export default snowflakeInstanceData;
