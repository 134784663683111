import React from "react";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { bindActionCreators } from "redux";
import { connect as reduxConnect } from "react-redux";
import type { MapStateToProps } from "react-redux";
import * as actionCreators from "../../actions/actionCreators";
import * as flowActions from "../../actions/flowActions";
import connectMenu from "../../helpers/connectMenu";
import { requestFlowSaveObject } from "../../helpers/flowSaveObject";
import { getFlowItemsArray, nullFlowPermissions, getFlowsWithErrors } from "../../reducers/flowItems";
import { getFlowExportsHaveTapadTradeDesk } from "../../reducers/flowExports";

import { getFlowPermissions } from "../../reducers/flowItems";
import type { Flow, FlowItem, RequestFlowSave } from "../../types/flowTypes";
import { canMultiSelect } from "../../helpers/flowMultiSelect";
import Divider from "@material-ui/core/Divider";

type Props = {
    // Injected from react-contextmenu
    item: Object,
    // Redux
    enabledFeatures: Array<string>,
    allFlowItems: Array<FlowItem>,
    flows: { [number]: Flow },
    flowItems: { [number]: FlowItem },
    flow: ?Flow,
    userId: number,
    roles: Array<string>,
    flowsWithErrors: Array<number>,
    selectedFlowIds: Array<number>,
    hasTapadTradeDesk: boolean,
    // Injected by Redux (action creator)
    showModal: (modalName: string, modalOptions?: Object) => void,
    requestFlowProcess: (flowId: number) => void,
    requestFlow: (flowId: number) => void,
    requestCancelEntireFlow: (flowId: number) => void,
    requestFlowSave: RequestFlowSave => void,
    flowMultiSelectionAdd: (flowId: number) => void,
    flowMultiSelectionRemove: (flowId: number) => void,
    flowMultiSelectionClear: () => void,
    requestProcessSelectedFlows: () => void,
    simpleFlowOverrideToDesign: (willOverride: boolean) => void,
    UrlIdForSimpleFlow: (flowId: number) => void,
    goToUrl: (flowId: number) => void,
    setSelectedFlow: (flowId: number) => void,
    flowMultiSelectToggle: (isMultiSelectOn: number) => void,
};

/*****************************************************************
 *
 *  TODO:   Archive needs to be created, ticket #957
 *
 *****************************************************************/

class FlowTreeContextMenu extends React.Component<Props> {
    getPermissions = flowId => {
        if (!flowId) {
            return nullFlowPermissions;
        }

        const { flows, allFlowItems, flowsWithErrors, roles, userId, hasTapadTradeDesk } = this.props;
        const flow = flows[flowId];
        const allFlowItemsForFlow = allFlowItems.filter(x => x.FlowId == flowId);
        const isFlowAnyErrors = flowsWithErrors.includes(flowId);
        return getFlowPermissions(flow, allFlowItemsForFlow, isFlowAnyErrors, roles, userId, hasTapadTradeDesk);
    };
    clickArchive = () => {
        /* PlaceHolder */
    };
    saveAndCalculateAll = () => {
        const { requestFlowSave } = this.props;
        const params = requestFlowSaveObject();
        params.processEntireFlow = true;
        requestFlowSave(params);
    };
    calculateAll = (e, data) => {
        this.props.requestFlowProcess(data.flowId);
    };
    cancelFlow = (e, data) => {
        this.props.requestCancelEntireFlow(data.flowId);
    };

    deleteFlow = (e, data) => {
        const { showModal } = this.props;
        showModal("FLOW_DELETE", { flowId: data.flowId });
    };
    showBackupList = (e, data) => {
        const { showModal } = this.props;
        showModal("FLOW_BACKUP_MODAL", { flowId: data.flowId });
    };

    deleteSelectedFlows = () => {
        const { showModal, flows, selectedFlowIds } = this.props;
        showModal("FLOW_BULK_DELETE", { flows, selectedFlowIds });
    };

    designMode = flowId => {
        const { UrlIdForSimpleFlow, goToUrl, setSelectedFlow, simpleFlowOverrideToDesign } = this.props;
        UrlIdForSimpleFlow(flowId);
        setSelectedFlow(flowId);
        simpleFlowOverrideToDesign(true);
        goToUrl(`/flows/${flowId}/design`);
    };
    //validate that all the selected flows can be executed!
    canExecuteSelectedFlows = () => {
        const { selectedFlowIds } = this.props;
        for (const flowId of selectedFlowIds) {
            const flowPermissions = this.getPermissions(flowId);
            if (!flowPermissions || !flowPermissions.canCalculate) {
                return false;
            }
        }
        return true;
    };

    canDeleteSelectedFlows = () => {
        const { selectedFlowIds } = this.props;
        for (const flowId of selectedFlowIds) {
            const flowPermissions = this.getPermissions(flowId);
            if (!flowPermissions || !flowPermissions.canDelete) {
                return false;
            }
        }
        return true;
    };

    renderFlowBulkOptions = () => {
        const { item, flows, selectedFlowIds, userId, enabledFeatures } = this.props;
        const flowId: ?number = item && item.flowId ? item.flowId : null;
        if (!flowId || !flows[flowId]) {
            return null;
        }
        const flowPermissions = this.getPermissions(flowId);
        const flow = flows[flowId];
        if (!canMultiSelect(flowPermissions, flow, userId, enabledFeatures)) {
            return null;
        }
        const options = [];
        const executeFeature = enabledFeatures.includes("flow-execute");
        const isMultiselectEnabled = selectedFlowIds && selectedFlowIds.length > 0;
        const thisFlowIsSelected = selectedFlowIds.includes(flowId);
        if (isMultiselectEnabled) {
            if (thisFlowIsSelected) {
                options.push(
                    <MenuItem
                        key={"multi_options_deselect" + flowId}
                        onClick={() => this.props.flowMultiSelectionRemove(flowId)}
                    >
                        Remove From Selected Flows
                    </MenuItem>
                );
                options.push(
                    <MenuItem key={"multi_options_clear" + flowId} onClick={() => this.props.flowMultiSelectionClear()}>
                        Clear Selected Flows
                    </MenuItem>
                );
                options.push(<Divider key={"muli_options_divider" + flowId} />);
                if (executeFeature) {
                    options.push(
                        <MenuItem
                            key={"multi_options_execute" + flowId}
                            disabled={!this.canExecuteSelectedFlows()}
                            onClick={() => this.props.requestProcessSelectedFlows()}
                        >
                            Execute Selected Flows
                        </MenuItem>
                    );
                }
                if (flowPermissions.canDelete) {
                    options.push(
                        <MenuItem
                            key={"multi_options_delete" + flowId}
                            disabled={!this.canDeleteSelectedFlows()}
                            onClick={() => this.deleteSelectedFlows()}
                        >
                            Delete Selected Flows
                        </MenuItem>
                    );
                }
            } else {
                options.push(
                    <MenuItem
                        key={"multi_options_select" + flowId}
                        onClick={() => {
                            this.props.flowMultiSelectionAdd(flowId);
                            this.props.flowMultiSelectToggle(true);
                        }}
                    >
                        Add To Selected Flows
                    </MenuItem>
                );
                options.push(
                    <MenuItem
                        key={"multi_options_clear" + flowId}
                        onClick={() => {
                            this.props.flowMultiSelectionClear();
                            this.props.flowMultiSelectToggle(true);
                        }}
                    >
                        Clear Selected Flows
                    </MenuItem>
                );
            }
        } else {
            options.push(
                <MenuItem
                    key={"multi_options_select2" + flowId}
                    onClick={() => this.props.flowMultiSelectionAdd(flowId)}
                >
                    Select Multiple Flows
                </MenuItem>
            );
        }
        return options;
    };

    render() {
        const { item, flows, allFlowItems, enabledFeatures, selectedFlowIds, userId } = this.props;
        const flowId: ?number = item && item.flowId ? item.flowId : null;
        const identifier = "flow-tree-flow";
        const flowPermissions = this.getPermissions(flowId);

        let flow = null;

        const flowsArray: Array<Flow> = Object.values(flows);
        const foundFlows = flowsArray.filter(x => x.FlowId == flowId);
        if (foundFlows.length > 0) {
            flow = foundFlows[0];
        }
        const allFlowItemsForFlow = allFlowItems.filter(x => x.FlowId == flowId);
        if (enabledFeatures.includes("flow-context-menu-loading") && allFlowItemsForFlow.length == 0 && flow != null) {
            this.props.requestFlow(flow.FlowId);
            return (
                <ContextMenu id={identifier}>
                    <div>
                        <MenuItem disabled={true}>Loading ...</MenuItem>
                    </div>
                </ContextMenu>
            );
        }

        let needsSave = false;
        if (flow != null && flow.hasUnsavedChanges != null) {
            needsSave = flow.hasUnsavedChanges;
        }
        const bulkOptions = this.renderFlowBulkOptions();
        const showRegularOptions =
            !bulkOptions || selectedFlowIds.length <= 0 || !canMultiSelect(flowPermissions, flow, userId);
        return (
            <ContextMenu id={identifier} className={`${identifier} exp`}>
                <div>
                    {/* Archive function doesn't exist yet
                    <MenuItem onClick={this.clickArchive} disabled={true}>
                        Archive
                    </MenuItem>*/}
                    {enabledFeatures.includes("flow-execute") && showRegularOptions && (
                        <>
                            {!flowPermissions.canCancel && (
                                <MenuItem
                                    key={"execute"}
                                    onClick={needsSave ? this.saveAndCalculateAll : this.calculateAll}
                                    disabled={!flowPermissions.canCalculate}
                                >
                                    {flowPermissions.isSubmitted ? "Submitted" : "Execute"}
                                </MenuItem>
                            )}
                            {flowPermissions.canCancel && (
                                <MenuItem
                                    key={"cancel"}
                                    onClick={this.cancelFlow}
                                    disabled={flowPermissions.isInCancelProcess}
                                >
                                    {flowPermissions.isInCancelProcess ? "Cancelling" : "Cancel Execute"}
                                </MenuItem>
                            )}
                        </>
                    )}
                    {flow && (
                        <>
                            {flow.FlowBaseType != 0 && <Divider style={{ margin: "5px auto" }} />}
                            {flow.FlowBaseType != 0 && (
                                <MenuItem onClick={() => this.designMode(flow.FlowId)}>Designer View</MenuItem>
                            )}
                        </>
                    )}
                    {<Divider style={{ margin: "5px auto" }} />}
                    {showRegularOptions && (
                        <MenuItem onClick={this.deleteFlow} disabled={!flowPermissions.canDelete}>
                            Delete Flow
                        </MenuItem>
                    )}
                    {flow &&
                        flow.HasBackup &&
                        enabledFeatures.includes("access-run-other-user-flow") &&
                        selectedFlowIds &&
                        selectedFlowIds.length <= 0 && (
                            <>
                                <Divider style={{ margin: "5px auto" }} />
                                <MenuItem onClick={this.showBackupList}>Restore Backup Data</MenuItem>
                            </>
                        )}
                    {showRegularOptions && <Divider style={{ margin: "5px auto" }} />}
                    {bulkOptions}
                </div>
            </ContextMenu>
        );
    }
}

export const ConnectedFlowTreeContextMenu1 = connectMenu("flow-tree-flow")(FlowTreeContextMenu);

const mapStateToProps: MapStateToProps<*, *, *> = state => ({
    enabledFeatures: state.session.enabledFeatures || [],
    userId: state.session.userId,
    roles: state.session.roles, // roles added for delete historical flows
    flows: state.flows.byId,
    flowItems: state.flowItems.byId,
    selectedFlowIds: state.flowMultiSelection.selectedFlowIds,
    allFlowItems: getFlowItemsArray(state),
    flowsWithErrors: getFlowsWithErrors(state),
    hasTapadTradeDesk: getFlowExportsHaveTapadTradeDesk(state),
});
const mapDispatchToProps = dispatch => bindActionCreators(Object.assign({}, actionCreators, flowActions), dispatch);
const ConnectedFlowTreeContextMenu2 = reduxConnect(mapStateToProps, mapDispatchToProps)(ConnectedFlowTreeContextMenu1);

export default ConnectedFlowTreeContextMenu2;
