import debounceUpdateBatcher from "./debounceUpdateBatcher";
import State from "./state";

/*
const alwaysBatch = () => true;
const optInBatch = action => action.meta && action.meta.batch;
const neverBatch = () => false;
*/
const didNotOptOut = action => !action.meta || !action.meta.doNotBatch;
const shouldBatch = action => didNotOptOut(action) && !isRouterAction(action);
const isRouterAction = action => action.type != null && action.type.includes("@@router");

export default () => next => action => {
    const resolved = next(action);

    //console.log(action);
    if (State.notify !== null && !shouldBatch(action)) {
        //console.log('notify - no batch');
        State.notify();
    } else {
        //console.log('wait - batched');
        debounceUpdateBatcher();
    }

    return resolved;
};
