const TOGGLE_FLOW_TREE_LOADING = "TOGGLE_FLOW_TREE_LOADING";
const TOGGLE_LAYOUT_TREE_LOADING = "TOGGLE_LAYOUT_TREE_LOADING";
const SET_FLOW_LOADING_EXCLUDE = "SET_FLOW_LOADING_EXCLUDE";

export const ToggleIsFlowTreeLoading = newValue => ({
    type: TOGGLE_FLOW_TREE_LOADING,
    isLoading: newValue,
});

export const SetIsFlowLoadingExcludes = newValue => ({
    type: SET_FLOW_LOADING_EXCLUDE,
    isFlowTreeLoadingExclude: newValue,
});

export const ToggleIsLayoutTreeLoading = newValue => ({
    type: TOGGLE_LAYOUT_TREE_LOADING,
    isLoading: newValue,
});
