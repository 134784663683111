/*
    Shares a lot in common with FlowTree.
    Can the duplication be removed?
    What's changed?
        - default folderType
        - element CampaignList changed to FlowList
        - Redux hooked up to flowActions
        - requestFlows called instead of requestCampaigns2
        - Changed className from react_campaigns to react_flows (Don't think this is used anywhere)
*/
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import TreeView from "../tree/TreeView";
import ObjectList from "./ObjectList";
import { getFlowExportReportsByFlowItemId } from "../../reducers/flowExportReports";
import withUpdateStripes from "../misc/withUpdateStripes";

const FlowLayoutObjectTree = ({ updateStripes }) => {
    const flowExportReportList = useSelector(state => getFlowExportReportsByFlowItemId(state));
    const flowItemId = useSelector(state => state.selected.flowItem);
    const flowExportReport = flowExportReportList[flowItemId];
    const layouts = useSelector(state => state.layoutObjects.objects);
    useEffect(() => {
        updateStripes();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const collapsed = undefined;
    const defaultCollapsed = false;
    let objects = [];

    let objectTree = null;
    if (layouts != null && flowExportReport != null) {
        let thisLayout = layouts.filter(x => x.Layout.LayoutID == flowExportReport.LayoutId);
        if (thisLayout.length > 0) {
            objects = thisLayout[0].LayoutObjects;
            objectTree = (
                <ObjectList
                    objects={objects}
                    selectedExportReport={flowExportReport.FlowExportReportId}
                    key="object_count_list"
                    treeViewOnUpdate={updateStripes}
                />
            );
        }
    }

    return (
        <div className="tree-view-root y-overflow">
            <TreeView
                nodeLabel={"Current Objects"}
                collapsed={collapsed}
                defaultCollapsed={defaultCollapsed}
                onUpdate={updateStripes}
                key="obj_count"
            >
                {objectTree}
            </TreeView>
            {/* <LayoutContextMenu /> */}
        </div>
    );
};

FlowLayoutObjectTree.propTypes = {
    // FROM HOC
    updateStripes: PropTypes.func.isRequired,
};
export default withUpdateStripes(FlowLayoutObjectTree);
