import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TablePagination from "@material-ui/core/TablePagination";

// core components
import styles from "../../../styles/dashComponentStyles/Table/TableFooterStyle";

function TableFooter(props) {
    const {
        classes,
        displayDense,
        dense,
        onDenseChange,
        displayPaging,
        rowsPerPageOptions,
        count,
        rowsPerPage,
        page,
        onPageChange,
        onRowsPerPageChange,
        ...rest
    } = props;
    return (
        <div className={classes.tableFooter}>
            {displayDense && (
                <FormControlLabel
                    {...rest}
                    control={<Switch checked={dense} onChange={onDenseChange} color={"primary"} />}
                    label="Dense Padding"
                />
            )}
            {displayPaging && (
                <div className={classes.tablePaginationRight}>
                    <TablePagination
                        className={classes.tablePagination}
                        component={"div"}
                        rowsPerPageOptions={rowsPerPageOptions}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                </div>
            )}
        </div>
    );
}

TableFooter.propTypes = {
    classes: PropTypes.object,
    displayDense: PropTypes.bool.isRequired,
    dense: PropTypes.bool,
    onDenseChange: PropTypes.func,
    displayPaging: PropTypes.bool.isRequired,
    rowsPerPage: PropTypes.number,
    rowsPerPageOptions: PropTypes.array,
    count: PropTypes.number,
    page: PropTypes.number,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
};

export default withStyles(styles)(TableFooter);
