import React from "react";

const BouncingDots: React.FC = () => (
    <div id="wave">
        <span className="dot" />
        <span className="dot" />
        <span className="dot" />
    </div>
);

export default BouncingDots;
