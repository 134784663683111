import { ICompanyEditStore, ICompanyEditAction } from "../types/actions/companies";
import { ISnowflakeSettings } from "../types/stores/snowflakeSettings";
import {
    SAVING_COMPANY_DETAILS,
    LOADING_COMPANY_DETAILS,
    LOADING_COMPANY_VERTICALS,
    LOADING_COMPANY_FOLDERS,
    LOADING_COMPANY_PARENT_FOLDERS,
    LOADING_COMPANY_DESTINATIONS,
    LOADING_COMPANY_USERS,
    LOADING_COMPANY_BY_ID,
    LOADING_COMPANY_FEATURES,
    LOADING_COMPANY_SNOWFLAKE_SETTINGS,
    LOADING_COMPANY_ACTIVATION_SCHEDULES,
    SET_COMPANY_DETAILS,
    SET_COMPANY_CURRENT_VERTICAL,
    SET_COMPANY_CREATED_DATE,
    SET_COMPANY_UPDATED_DATE,
    SET_COMPANY_SV_CLIENT_KEYS,
    SET_COMPANY_SV_TABLES,
    SET_COMPANY_SV_CONTROL,
    SET_COMPANY_FOLDER_EXIST,
    SET_COMPANY_TAPAD_DELIVERY_TYPES,
    SET_TAPAD_PRICING,
    SET_COMPANY_DESTINATION_DEDUPE_SETTINGS,
    SET_COMPANY_VERTICALS,
    SET_COMPANY_DESTINATIONS,
    SET_SELECTED_COMPANY,
    SET_COMPANY_PDF_CONTENT,
    SET_COMPANY_PDF_FILENAME,
    SET_COMPANY_USERS,
    SET_COMPANY_BY_ID,
    SET_DEPLOY_SETTINGS,
    SET_FLOW_CONTROL_DEPLOY_SETTINGS,
    SET_COMPANY_FEATURES,
    SET_COMPANY_RESTRICTED_USERNAME,
    SET_COMPANY_RESTRICTED_PASSWORD,
    SET_COMPANY_SNOWFLAKE_SETTINGS,
    SET_NOT_ALLOWED_DEPLOY_SNOWFLAKE_TABLES,
} from "./companies";

function companyEdit(
    state = {
        selectedCompany: null,
        isSaving: false,
        isLoading: false,
        isLoadingFolders: false,
        isLoadingParentFolders: false,
        isLoadingVerticals: false,
        isLoadingDestinations: false,
        isLoadingUsers: false,
        isLoadingCompanyById: false,
        company: null,
        companyCreateDate: "",
        companyUpdateDate: "",
        DatabaseEnvironmentId: 0,
        currentVertical: null,
        verticals: [],
        destinations: [],
        deploySettings: [],
        flowControlDeploySettings: [],
        // details
        restrictedUsername: "",
        restrictedPassword: "",
        tapadDeliveryTypes: [],
        exportTapadPricing: [],
        destinationDedupeSettings: [],
        // others
        svClientKeys: [],
        svTables: [],
        svControl: null,
        doesFolderExist: false,
        // pdf
        pdfContent: null,
        pdfFileName: "",
        companyUsers: [],
        companyById: [],
        //features current company
        isLoadingFeatures: false,
        features: [],
        // snowflake
        snowflakeSettings: Array<ISnowflakeSettings>(),
        notAllowedDeploySnowflakeTables: Array<string>(),
        isLoadingSnowflakeSettings: false,
        // activation destination
        isLoadingActivationSchedules: false,
    },
    action: ICompanyEditAction
): ICompanyEditStore {
    switch (action.type) {
        case SET_SELECTED_COMPANY:
            return Object.assign({}, state, {
                selectedCompany: action.selectedCompany,
            });
        case SAVING_COMPANY_DETAILS:
            return Object.assign({}, state, {
                isSaving: action.isSaving,
            });
        case LOADING_COMPANY_DETAILS:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case LOADING_COMPANY_FOLDERS:
            return Object.assign({}, state, {
                isLoadingFolders: action.isLoadingFolders,
            });
        case LOADING_COMPANY_PARENT_FOLDERS:
            return Object.assign({}, state, {
                isLoadingParentFolders: action.isLoadingParentFolders,
            });
        case LOADING_COMPANY_VERTICALS:
            return Object.assign({}, state, {
                isLoadingVerticals: action.isLoadingVerticals,
            });
        case LOADING_COMPANY_DESTINATIONS:
            return Object.assign({}, state, {
                isLoadingDestinations: action.isLoadingDestinations,
            });
        case LOADING_COMPANY_USERS:
            return Object.assign({}, state, {
                isLoadingUsers: action.isLoadingUsers,
            });
        case LOADING_COMPANY_BY_ID:
            return Object.assign({}, state, {
                isLoadingCompanyById: action.isLoadingCompanyById,
            });
        case LOADING_COMPANY_FEATURES:
            return Object.assign({}, state, {
                isLoadingFeatures: action.isLoadingFeatures,
            });
        case LOADING_COMPANY_SNOWFLAKE_SETTINGS:
            return Object.assign({}, state, {
                isLoadingSnowflakeSettings: action.isLoadingSnowflakeSettings,
            });
        case LOADING_COMPANY_ACTIVATION_SCHEDULES:
            return Object.assign({}, state, {
                isLoadingActivationSchedules: action.isLoadingActivationSchedules,
            });
        case SET_COMPANY_DETAILS:
            return Object.assign({}, state, {
                company: action.company,
            });
        case SET_COMPANY_TAPAD_DELIVERY_TYPES:
            return Object.assign({}, state, {
                tapadDeliveryTypes: action.tapadDeliveryTypes,
            });
        case SET_TAPAD_PRICING:
            return Object.assign({}, state, {
                tapadPricing: action.exportTapadPricing,
            });
        case SET_COMPANY_DESTINATION_DEDUPE_SETTINGS:
            return Object.assign({}, state, {
                destinationDedupeSettings: action.destinationDedupeSettings,
            });
        case SET_COMPANY_CREATED_DATE:
            return Object.assign({}, state, {
                companyCreateDate: action.companyCreateDate,
            });
        case SET_COMPANY_UPDATED_DATE:
            return Object.assign({}, state, {
                companyUpdateDate: action.companyUpdateDate,
            });
        case SET_COMPANY_SV_CLIENT_KEYS:
            return Object.assign({}, state, {
                svClientKeys: action.svClientKeys,
            });
        case SET_COMPANY_SV_TABLES:
            return Object.assign({}, state, {
                svTables: action.svTables,
            });
        case SET_COMPANY_SV_CONTROL:
            return Object.assign({}, state, {
                svControl: action.svControl,
            });
        case SET_COMPANY_FOLDER_EXIST:
            return Object.assign({}, state, {
                doesFolderExist: action.doesFolderExist,
            });
        case SET_COMPANY_VERTICALS:
            return Object.assign({}, state, {
                verticals: action.verticals,
            });
        case SET_COMPANY_DESTINATIONS:
            return Object.assign({}, state, {
                destinations: action.destinations,
            });
        case SET_COMPANY_PDF_CONTENT:
            return Object.assign({}, state, {
                pdfContent: action.pdfContent,
            });
        case SET_COMPANY_PDF_FILENAME:
            return Object.assign({}, state, {
                pdfFileName: action.pdfFileName,
            });
        case SET_COMPANY_USERS:
            return Object.assign({}, state, {
                companyUsers: action.companyUsers,
            });
        case SET_COMPANY_BY_ID:
            return Object.assign({}, state, {
                companyById: action.companyById,
            });
        case SET_DEPLOY_SETTINGS:
            return Object.assign({}, state, {
                deploySettings: action.deploySettings,
            });
        case SET_FLOW_CONTROL_DEPLOY_SETTINGS:
            return Object.assign({}, state, {
                flowControlDeploySettings: action.flowControlDeploySettings,
            });
        case SET_COMPANY_CURRENT_VERTICAL:
            return Object.assign({}, state, {
                currentVertical: action.currentVertical,
            });
        case SET_COMPANY_FEATURES:
            return Object.assign({}, state, {
                features: action.features,
            });
        case SET_COMPANY_RESTRICTED_USERNAME:
            return Object.assign({}, state, {
                restrictedUsername: action.restrictedUsername,
            });
        case SET_COMPANY_RESTRICTED_PASSWORD:
            return Object.assign({}, state, {
                restrictedPassword: action.restrictedPassword,
            });
        case SET_COMPANY_SNOWFLAKE_SETTINGS:
            return Object.assign({}, state, {
                snowflakeSettings: action.snowflakeSettings,
            });
        case SET_NOT_ALLOWED_DEPLOY_SNOWFLAKE_TABLES:
            return Object.assign({}, state, {
                notAllowedDeploySnowflakeTables: action.notAllowedDeploySnowflakeTables,
            });
        default:
            return state;
    }
}

export default companyEdit;
