import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAttribute } from "../../../../actions/flowActions";
import { Divider, FormControl, Paper, TextField } from "@material-ui/core";
import { IAppState } from "../../../../types/stores";
import MaterialTable, { Column } from "@material-table/core";
import { getFlowExportsByFlowItemId } from "../../../../reducers/flowExports";
import type { FlowExportsByItemId } from "../../../../reducers/flowExports";
import { createSelector } from "reselect";
import { FlowExport } from "../../../../types/stores/flowTypes";
import moment from "moment";
import NumericalTextField from "../../../material-components/Misc/NumericalTextField";
import { IAdvertisersByCompany } from "../../../../types/stores/vars";
import { getFlowItemsForSelectedFlow } from "../../../../reducers/flowItems";

type Props = {
    flowItemId: number;
};

const FlowTaxonomyFieldEditForm: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch();
    const getFlowExport = makeGetFlowExport();
    const getExportDestinations = makeGetExportDestinations();
    const layouts = useSelector((state: IAppState) => state.layoutObjects.objects);
    const flowExport = useSelector((state: IAppState) => getFlowExport(state, props));
    const environment = useSelector((state: IAppState) => state.session.environment);
    const flowExportTaxonomyFileFields = useSelector((state: IAppState) => state.flowExportTaxonomyFileFields);
    const layout = layouts && layouts.filter(x => x.Layout.LayoutID == flowExport?.ExportId)[0];
    const currentCompanyId = useSelector((state: IAppState) => state.session.companyId);
    const companies = useSelector((state: IAppState) => state.session.companies);
    const PartnerList = useSelector((state: IAppState) => getExportDestinations(state));
    const enabledFeatures = useSelector((state: IAppState) => state.session.enabledFeatures);
    const flowItems = useSelector(state => getFlowItemsForSelectedFlow(state));
    let filteredDestinations = PartnerList || [];
    //Only auprod and ukprod can see the grouped partners, others only the parent partners
    if (environment != "auprod" && environment != "ukprod" && PartnerList && PartnerList.length > 0) {
        filteredDestinations = PartnerList.filter(x => !x.isGrouped || x.PartnerAccessId == x.GroupCompanyID);
    }

    const destinations = filteredDestinations.map(item => ({
        label: item.VerticalName + " - " + item.CompanyName,
        value: item.PartnerAccessId,
        item,
    }));

    const thisDestination = destinations.find(x => x.value == flowExport?.DestinationId);

    // NANI-85558 remove this method when feature is removed
    useEffect(() => {
        // Should execute new method
        if (enabledFeatures.includes("export-taxonomy-values-update-fix")) return;

        let taxObjects = Object.keys(flowExportTaxonomyFileFields.byId).map(x => flowExportTaxonomyFileFields.byId[x]);

        let taxonomyObjects = taxObjects.filter(x => x.FlowItemId == props.flowItemId);

        //updates taxonomy json with default values...
        taxonomyObjects.forEach(row => {
            let taxonomyObj = JSON.parse(row.ValueJSON);
            layout.LayoutTaxonomyObjects.forEach(x => {
                if (x.DefaultValue.includes("{date:")) {
                    const dateFormat = x.DefaultValue.substring(
                        x.DefaultValue.indexOf(":") + 1,
                        x.DefaultValue.indexOf("}")
                    );
                    let newDate = moment();
                    let thisDate = moment(newDate, dateFormat).format(dateFormat).toString();
                    taxonomyObj[x.ExportName] = thisDate;
                } else {
                    const company = companies.filter(x => x.CompanyID == currentCompanyId)[0];
                    switch (x.DefaultValue) {
                        case "{ProviderName}": //?
                            taxonomyObj[x.ExportName] = thisDestination?.item.CompanyName;
                            break;
                        case "{CompanyName}":
                            taxonomyObj[x.ExportName] = company.CompanyName;
                            break;
                        case "":
                            break;
                        default:
                            if (!x.DefaultValue.includes("{") && taxonomyObj[x.ExportName] == "") {
                                taxonomyObj[x.ExportName] = x.DefaultValue;
                            }
                            break;
                    }
                }
            });

            handleFieldChange(row.FlowExportTaxonomyFileFieldId, "ValueJSON", JSON.stringify(taxonomyObj));
        });
    });

    useEffect(() => {
        // Should execute original method
        if (!enabledFeatures.includes("export-taxonomy-values-update-fix")) return;

        let taxObjects = Object.keys(flowExportTaxonomyFileFields.byId).map(x => flowExportTaxonomyFileFields.byId[x]);

        let taxonomyObjects = taxObjects.filter(x => x.FlowItemId == props.flowItemId);

        //updates taxonomy json with default values...
        taxonomyObjects.forEach(row => {
            let shouldUpdate = false;
            let taxonomyObj = JSON.parse(row.ValueJSON);
            layout.LayoutTaxonomyObjects.forEach(x => {
                if (x.DefaultValue.includes("{date:")) {
                    const dateFormat = x.DefaultValue.substring(
                        x.DefaultValue.indexOf(":") + 1,
                        x.DefaultValue.indexOf("}")
                    );
                    let newDate = moment();
                    let thisDate = moment(newDate, dateFormat).format(dateFormat).toString();
                    if (taxonomyObj[x.ExportName] != thisDate) {
                        taxonomyObj[x.ExportName] = thisDate;
                    }
                } else {
                    const company = companies.filter(x => x.CompanyID == currentCompanyId)[0];
                    switch (x.DefaultValue) {
                        case "{ProviderName}": //?
                            if (
                                thisDestination &&
                                thisDestination.item &&
                                thisDestination.item.CompanyName != "" &&
                                taxonomyObj[x.ExportName] != thisDestination.item.CompanyName
                            ) {
                                taxonomyObj[x.ExportName] = thisDestination.item.CompanyName;
                                shouldUpdate = true;
                            }
                            break;
                        case "{CompanyName}":
                            if (
                                company &&
                                company.CompanyName != "" &&
                                taxonomyObj[x.ExportName] != company.CompanyName
                            ) {
                                taxonomyObj[x.ExportName] = company.CompanyName;
                                shouldUpdate = true;
                            }
                            break;
                        case "":
                            break;
                        default:
                            if (!x.DefaultValue.includes("{") && taxonomyObj[x.ExportName] == "") {
                                taxonomyObj[x.ExportName] = x.DefaultValue;
                                shouldUpdate = true;
                            }
                            break;
                    }
                }
            });

            if (shouldUpdate) {
                handleFieldChange(row.FlowExportTaxonomyFileFieldId, "ValueJSON", JSON.stringify(taxonomyObj));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flowExportTaxonomyFileFields]);

    const title = `Taxonomy Driver File Fields`;

    const renderTaxonomyObject = (rowData: any, data: any) => {
        let jsonData = JSON.parse(rowData.ValueJSON);
        let newValue = jsonData;
        let isSegmentCode = false;
        const currentFlowItem = flowItems.find(x => x.FlowItemId == rowData.ParentFlowItemId);

        // EMS-1301 For Samsumng taxonomy layouts, autocomplete with filter names
        if (
            enabledFeatures.includes("export-taxonomy-values-update-fix") &&
            data.ExportName.toLowerCase() === "segmentcode"
        ) {
            if (jsonData["SEGMENT_NAME"] === "" || jsonData["SEGMENT_DESCRIPTION"] === "") {
                let newValue = jsonData;
                newValue[data.ExportName] = currentFlowItem.FlowItemName;
                newValue["SEGMENT_NAME"] = currentFlowItem.FlowItemName;
                newValue["SEGMENT_DESCRIPTION"] = currentFlowItem.FlowItemName;
                handleFieldChange(rowData.FlowExportTaxonomyFileFieldId, "ValueJSON", JSON.stringify(newValue));
            }

            isSegmentCode = true;
        }

        let textField = (
            <FormControl style={{ width: "250px", margin: "0px 10px" }}>
                <TextField
                    id={"segment-general" + rowData.id}
                    key={"segment-general" + rowData.id}
                    defaultValue={
                        isSegmentCode && jsonData[data.ExportName] == ""
                            ? currentFlowItem.FlowItemName
                            : jsonData[data.ExportName]
                    }
                    helperText={!rowData.ExportName ? "Please add a description." : undefined}
                    inputProps={{ maxLength: 255 }}
                    onBlur={e => {
                        let newValue = jsonData;
                        newValue[data.ExportName] = e.target.value;
                        handleFieldChange(rowData.FlowExportTaxonomyFileFieldId, "ValueJSON", JSON.stringify(newValue));
                    }}
                    onWheel={event => {
                        const eventTarget = event.target as HTMLInputElement;
                        return eventTarget.blur();
                    }}
                />
            </FormControl>
        );

        if (data.DefaultValue.includes("{numberRange:")) {
            //
            const range = data.DefaultValue.substring(
                data.DefaultValue.indexOf(":") + 1,
                data.DefaultValue.indexOf("}")
            ).split(",");
            const max = parseFloat(range[1]);
            const min = parseFloat(range[0]);
            textField = (
                <FormControl style={{ width: "250px", margin: "0px 10px" }}>
                    <NumericalTextField
                        id="taxonomy-numberrange"
                        key={"taxonomy-numberrange" + rowData.id}
                        defaultValue={jsonData[data.ExportName]}
                        value={jsonData[data.ExportName]}
                        helperText={
                            !rowData.ExportName
                                ? `Please enter a range between ${range[0]} and ${range[1]}.`
                                : undefined
                        }
                        numberType="decimal"
                        type="number"
                        inputProps={{ min, max }}
                        onBlur={e => {
                            let newValue = jsonData;
                            let numberValue = parseFloat(e.target.value);
                            numberValue = numberValue >= max ? max : numberValue <= min ? min : numberValue;
                            newValue[data.ExportName] = numberValue.toString();

                            handleFieldChange(
                                rowData.FlowExportTaxonomyFileFieldId,
                                "ValueJSON",
                                JSON.stringify(newValue)
                            );
                        }}
                        onWheel={event => {
                            const eventTarget = event.target as HTMLInputElement;
                            return eventTarget.blur();
                        }}
                    />
                </FormControl>
            );
        } else if (data.DefaultValue.includes("{date:")) {
            const dateFormat = data.DefaultValue.substring(
                data.DefaultValue.indexOf(":") + 1,
                data.DefaultValue.indexOf("}")
            );
            let newDate = moment();
            let thisDate = moment(newDate, dateFormat).format(dateFormat).toString();

            newValue[data.ExportName] = thisDate;

            textField = (
                <FormControl style={{ width: "250px", margin: "0px 10px" }}>
                    <TextField
                        id="taxonomy-date"
                        key={"taxonomy-date" + rowData.id}
                        defaultValue={jsonData[data.ExportName]}
                        helperText={!rowData.ExportName ? `Current date ${dateFormat}.` : undefined}
                        disabled={true}
                        onBlur={e => {
                            let newValue = jsonData;
                            newValue[data.ExportName] = e.target.value;
                            handleFieldChange(
                                rowData.FlowExportTaxonomyFileFieldId,
                                "ValueJSON",
                                JSON.stringify(newValue)
                            );
                        }}
                        onWheel={event => {
                            const eventTarget = event.target as HTMLInputElement;
                            return eventTarget.blur();
                        }}
                    />
                </FormControl>
            );
        } else {
            switch (data.DefaultValue) {
                case "{SegmentCode}": //?
                    break;
                case "{ProviderName}":
                    newValue[data.ExportName] = thisDestination?.item.CompanyName;
                    textField = (
                        <FormControl style={{ width: "250px", margin: "0px 10px" }}>
                            <TextField
                                id="taxonomy-provider"
                                key={"taxonomy-provider" + rowData.id}
                                defaultValue={jsonData[data.ExportName]}
                                disabled={true}
                                inputProps={{ maxLength: 255 }}
                                onBlur={e => {
                                    let newValue = jsonData;
                                    newValue[data.ExportName] = e.target.value;
                                    handleFieldChange(
                                        rowData.FlowExportTaxonomyFileFieldId,
                                        "ValueJSON",
                                        JSON.stringify(newValue)
                                    );
                                }}
                                onWheel={event => {
                                    const eventTarget = event.target as HTMLInputElement;
                                    return eventTarget.blur();
                                }}
                            />
                        </FormControl>
                    );
                    break;
                case "{CompanyName}":
                    {
                        const company = companies.filter(x => x.CompanyID == currentCompanyId)[0];

                        newValue[data.ExportName] = company.CompanyName;

                        textField = (
                            <FormControl style={{ width: "250px", margin: "0px 10px" }}>
                                <TextField
                                    id="taxonomy-companyname"
                                    key={"taxonomy-companyname" + rowData.id}
                                    defaultValue={jsonData[data.ExportName]}
                                    disabled={true}
                                    inputProps={{ maxLength: 255 }}
                                    onBlur={e => {
                                        let newValue = jsonData;
                                        newValue[data.ExportName] = e.target.value;
                                        handleFieldChange(
                                            rowData.FlowExportTaxonomyFileFieldId,
                                            "ValueJSON",
                                            JSON.stringify(newValue)
                                        );
                                    }}
                                    onWheel={event => {
                                        const eventTarget = event.target as HTMLInputElement;
                                        return eventTarget.blur();
                                    }}
                                />
                            </FormControl>
                        );
                    }
                    break;
                case "":
                    break;
                default:
                    if (!data.DefaultValue.includes("{")) {
                        // newValue[data.ExportName] = jsonData[data.ExportName] != "" ? jsonData[data.ExportName] : data.DefaultValue;
                        textField = (
                            <FormControl style={{ width: "250px", margin: "0px 10px" }}>
                                <TextField
                                    id="taxonomy-provider"
                                    key={"taxonomy-provider" + rowData.id}
                                    defaultValue={jsonData[data.ExportName]}
                                    disabled={true}
                                    inputProps={{ maxLength: 255 }}
                                    onBlur={e => {
                                        let newValue = jsonData;
                                        newValue[data.ExportName] =
                                            e.target.value != "" ? e.target.value : data.DefaultValue;
                                        handleFieldChange(
                                            rowData.FlowExportTaxonomyFileFieldId,
                                            "ValueJSON",
                                            JSON.stringify(newValue)
                                        );
                                    }}
                                    onWheel={event => {
                                        const eventTarget = event.target as HTMLInputElement;
                                        return eventTarget.blur();
                                    }}
                                />
                            </FormControl>
                        );
                    }
                    break;
            }
        }

        return <FormControl style={{ width: "250px", margin: "0px 10px" }}>{textField}</FormControl>;
    };

    const columns: Array<Column<any>> = [];

    if (layout != null && layout.LayoutTaxonomyObjects != null) {
        layout.LayoutTaxonomyObjects.forEach(x => {
            columns.push({
                title: x.ExportName,
                tooltip: x.ExportName,
                render: rowData => renderTaxonomyObject(rowData, x),
            });
            if (enabledFeatures.includes("export-taxonomy-values-update-fix")) {
                if (x.ExportName == "SEGMENT_NAME" || x.ExportName == "SEGMENT_DESCRIPTION") {
                    columns.pop();
                }
            }
        });
    }

    const handleFieldChange = (Id: number, attributeName: string, value: string | number | boolean) =>
        dispatch(updateAttribute("flowExportTaxonomyFileFields", Id, attributeName, value));

    const renderTaxonomyForm = () => {
        let taxObjects = Object.keys(flowExportTaxonomyFileFields.byId).map(x => flowExportTaxonomyFileFields.byId[x]);

        let taxonomyObjects = taxObjects.filter(x => x.FlowItemId == props.flowItemId);

        return (
            <div style={{ paddingTop: "20px" }}>
                <Divider style={{ marginBottom: "20px" }} />
                <MaterialTable
                    key={`freewheel-fields-table-${props.flowItemId}`}
                    data={taxonomyObjects}
                    columns={columns}
                    options={{
                        toolbar: false,
                        filtering: false,
                        grouping: false,
                        search: false,
                        headerStyle: { fontSize: 12 },
                        rowStyle: {
                            fontSize: 12,
                            padding: "2px",
                        },
                        paging: false,
                        maxBodyHeight: 400,
                    }}
                />
            </div>
        );
    };

    return (
        <>
            <div className="flow-edit-header">
                <div style={{ flexGrow: 1, position: "relative" }}>
                    <p>{title}</p>
                </div>
            </div>
            <Divider style={{ margin: "20px auto" }} />
            <Paper style={{ marginTop: "30px", padding: "0px 20px 20px 20px", width: "100%" }}>
                <div className="m-t-md">{renderTaxonomyForm()}</div>
            </Paper>
            <Divider style={{ margin: "40px auto 20px auto" }} />
        </>
    );
};
type FlowExportSelector<S> = (state: IAppState, props: Props) => S;
type FlowExportDestinationSelector<S> = (state: IAppState) => S;
export const makeGetExportDestinations = (): FlowExportDestinationSelector<Array<IAdvertisersByCompany>> =>
    // @ts-ignore
    createSelector(
        (state: IAppState) => state.vars.destinations,
        (destinations: Array<IAdvertisersByCompany>): Array<IAdvertisersByCompany> =>
            destinations ? destinations.filter(x => x.AcceptFlag == "Y") : []
    );

export const makeGetFlowExport = (): FlowExportSelector<FlowExport | undefined> =>
    // @ts-ignore
    createSelector(
        (state: IAppState) => getFlowExportsByFlowItemId(state),
        (_, props: Props) => props.flowItemId,
        (flowExportsByFlowItemId: FlowExportsByItemId, flowItemId: number): FlowExport | undefined =>
            flowExportsByFlowItemId[flowItemId]
    );
export default FlowTaxonomyFieldEditForm;
