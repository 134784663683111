import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../actions/actionCreators";
import * as layoutActions from "../../actions/exportLayoutActions2";

import SVFieldList from "./SVFieldList";

import withUpdateStripes from "../misc/withUpdateStripes";

type Props = {
    // Passed in
    scope: boolean,
};

class SVFieldTree extends React.Component<Props> {
    componentDidMount() {
        this.props.updateStripes();
    }

    componentDidUpdate() {
        this.props.updateStripes();
    }

    render() {
        const { svFields, selectedLayout, updateStripes } = this.props;

        return (
            <div className="y-overflow">
                <SVFieldList
                    svFields={svFields}
                    selectedLayout={selectedLayout}
                    key="layout_sv_list"
                    treeViewOnUpdate={updateStripes}
                />
            </div>
        );
    }
}
SVFieldTree.propTypes = {
    selectedLayout: PropTypes.number,
    selectedDataSource: PropTypes.string,
    svFields: PropTypes.array,
    updateStripes: PropTypes.func.isRequired, // From HOC
};

const VariableLayoutTreeS = withUpdateStripes(SVFieldTree);

const mapStateToProps = state => ({
    svFields: state.vars.svFields,
    selectedLayout: state.selected.layout,
    userNameForId: state.varsPersist.userNameForId,
});
const mapDispatchToProps = dispatch => bindActionCreators(Object.assign({}, actionCreators, layoutActions), dispatch);

const SVFieldTreeC = connect(mapStateToProps, mapDispatchToProps)(VariableLayoutTreeS);
export default SVFieldTreeC;
