import React from "react";
import { Table } from "@material-ui/core";
import TableHeader from "../../../material-components/Table/TableHeader";
import TableBody from "../../../material-components/Table/TableBody";
import { FlowItemFieldsUsed } from "../../../../types/stores/flowTypes";

type Props = {
    fieldRestrictions: Array<FlowItemFieldsUsed>;
};

const RestrictedFieldsTable: React.FC<Props> = ({ fieldRestrictions }: Props) => {
    const getDuplicatedRestrictedFields = (restrictionsArray: Array<FlowItemFieldsUsed>) => {
        let data: Array<FlowItemFieldsUsed> = [];
        restrictionsArray.forEach(field => {
            if (
                !data.find(
                    f =>
                        f.FieldName === field.FieldName &&
                        f.FlowItemName === field.FlowItemName &&
                        f.FlowItemType === field.FlowItemType
                )
            ) {
                data.push(field);
            }
        });
        return data;
    };

    const tableHeaders = [
        ["FlowItem", "Flow Item"],
        ["FieldName", "Field Name"],
    ];

    const emptyMessage = ["EmptyMessage", ""];

    let data: Array<Array<Array<string>>> = [];
    let fieldRestrictionsDuplicated = getDuplicatedRestrictedFields(fieldRestrictions);
    fieldRestrictionsDuplicated.forEach(field => {
        data.push([
            ["FlowItem", `${field.FlowItemName} - ${field.FlowItemType}`, "smDown"],
            ["FieldName", field.FieldName, "smDown"],
        ]);
    });

    return (
        <div className="flow-edit-body" id="restrictionTable">
            <Table>
                <TableHeader style={{ fontSize: "22px" }} headers={tableHeaders} ignoreHeaders={tableHeaders} />
                <TableBody
                    style={{ fontSize: "18px" }}
                    data={data}
                    page={0}
                    rowsPerPage={data.length}
                    emptyMessage={emptyMessage}
                />
            </Table>
        </div>
    );
};

export default RestrictedFieldsTable;
