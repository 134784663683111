import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../actions/exportLayoutActions2";

import TreeItem from "../tree/TreeItem";
import TextWithHighlightedSearchTerms from "../tree/TextWithHighlightedSearchTerms";

import { DragSource } from "react-dnd";
import { DragDropTypes } from "../../helpers/constants";

import { ContextMenuTrigger } from "react-contextmenu";

type Props = {
    scope: boolean,
};

class VariableLayoutListEntry extends React.Component<Props> {
    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
        this.handleDoubleClick = this.handleDoubleClick.bind(this);
    }

    handleClick() {
        const { /*variable, */ variableId, setSelectedGlobalVariable } = this.props;
        setSelectedGlobalVariable(parseInt(variableId));
    }

    handleDoubleClick() {
        const { /*variable, */ variableId, selectedLayout, scope } = this.props;
        this.props.AddGlobalVariableToLayoutObjects(parseInt(variableId), selectedLayout, scope);
    }

    componentDidMount() {
        $(this.divNode).bstooltip();
    }

    componentWillUnmount() {
        $(this.divNode).bstooltip("destroy");
    }

    render() {
        const { variable, variableId, selectedVariable, connectDragSource } = this.props;
        if (!variable) {
            return null;
        }
        const active = selectedVariable == variableId;

        const displayText = variable.VariableName;
        return connectDragSource(
            <div
                style={{ disabled: "true" }}
                data-container="body"
                data-toggle="tooltip"
                data-placement="right"
                ref={node => (this.divNode = node)}
            >
                <ContextMenuTrigger
                    id="variable-tree"
                    collect={props => props}
                    variableId={variableId}
                    holdToDisplay={-1}
                >
                    <TreeItem handleClick={this.handleClick} handleDoubleClick={this.handleDoubleClick} active={active}>
                        <TextWithHighlightedSearchTerms text={displayText} />
                    </TreeItem>
                </ContextMenuTrigger>
            </div>
        );
    }
}

// Prop Types /
const propTypes = {
    // Provided from outside
    variableId: PropTypes.any,
    variable: PropTypes.object,
    selectedVariable: PropTypes.any,
    setSelectedLayout: PropTypes.func,
    setSelectedLayout2: PropTypes.func,
    requestSelectedLayoutObjects: PropTypes.func,
    setSelectedGlobalVariable: PropTypes.func,
    AddGlobalVariableToLayoutObjects: PropTypes.func,
    updateLayoutAndPositions: PropTypes.func,
    selectedLayout: PropTypes.any,
    connectDragSource: PropTypes.func.isRequired,
    enabledFeatures: PropTypes.array,
};
VariableLayoutListEntry.propTypes = propTypes;

// Drag and Drop here
function collect(connect, monitor) {
    // React-dnd will inject these props into our component
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    };
}

const fieldSource = {
    canDrag(/* props */) {
        return true;
    },
    beginDrag(props) {
        // Return data describing the dragged item
        return { variableId: props.variableId, scope: props.scope };
    },
};

const VariableLayoutListEntryDND = DragSource(DragDropTypes.GVARIABLE, fieldSource, collect)(VariableLayoutListEntry);

// Redux //
const mapStateToProps = (state, ownProps) => ({
    variableId: ownProps.variableId,
    userId: state.session.userId,
    selectedLayout: state.selected.layout,
    enabledFeatures: state.session.enabledFeatures || [],
});
const mapDispatchToProps = dispatch => bindActionCreators(actionCreators, dispatch);

const VariableLayoutListEntryDNDC = connect(mapStateToProps, mapDispatchToProps)(VariableLayoutListEntryDND);

export default VariableLayoutListEntryDNDC;
