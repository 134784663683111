import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../actions/exportLayoutActions2";

import TreeItem from "../tree/TreeItem";
import TextWithHighlightedSearchTerms from "../tree/TextWithHighlightedSearchTerms";

import { DragSource } from "react-dnd";
import { DragDropTypes } from "../../helpers/constants";

type Props = {
    scope: boolean,
    enabledFeatures: Array<string>,
};

class ObjectListEntry extends React.Component<Props> {
    componentDidMount() {
        $(this.divNode).bstooltip();
    }

    componentWillUnmount() {
        $(this.divNode).bstooltip("destroy");
    }
    render() {
        const { object, selectedObject, objectId, connectDragSource } = this.props;
        if (!object) {
            return null;
        }

        const active = selectedObject == objectId;

        const displayText =
            object.DisplayName != object.ExportName
                ? object.DisplayName + " (" + object.ExportName + ")"
                : object.ExportName;
        return connectDragSource(
            <div data-container="body" data-toggle="tooltip" data-placement="right" ref={node => (this.divNode = node)}>
                <TreeItem active={active}>
                    <TextWithHighlightedSearchTerms text={displayText} />
                </TreeItem>
            </div>
        );
    }
}

// Prop Types /
const propTypes = {
    // Provided from outside
    objectId: PropTypes.any,
    object: PropTypes.object,
    selectedObject: PropTypes.any,
    connectDragSource: PropTypes.func.isRequired,
};
ObjectListEntry.propTypes = propTypes;

// Drag and Drop here
function collect(connect, monitor) {
    // React-dnd will inject these props into our component
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    };
}

const fieldSource = {
    canDrag(/* props */) {
        return true;
    },
    beginDrag(props) {
        // Return data describing the dragged item
        return { object: props.objectId, scope: props.scope };
    },
};

const ObjectListEntryDND = DragSource(DragDropTypes.LAYOUTOBJECT, fieldSource, collect)(ObjectListEntry);

// Redux //
const mapStateToProps = (state, ownProps) => ({
    variableId: ownProps.variableId,
    userId: state.session.userId,
    enabledFeatures: state.session.enabledFeatures || [],
});
const mapDispatchToProps = dispatch => bindActionCreators(actionCreators, dispatch);

const ObjectListEntryDNDC = connect(mapStateToProps, mapDispatchToProps)(ObjectListEntryDND);

export default ObjectListEntryDNDC;
