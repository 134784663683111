import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import ofilter from "../helpers/ofilter";
import update from "immutability-helper";
import { combineReducers } from "redux";
import type { FlowRelationParentLabel } from "../types/flowTypes";
import { getFlowRelationIdsForSelectedFlow } from "./flowRelations";

const myGenericReducer = makeReducerFor("FLOW_RELATION_PARENT_LABEL", "FlowRelationParentLabelId");
const customActions = (state = {}, action) => {
    switch (action.type) {
        //override FLOW_FLOW_RELATION_ID_CHANGE to update the corresponding ids in any relation parent label
        case "FLOW_RELATION_ID_CHANGE": {
            const updates = {};
            let madeAnUpdate = false;
            for (const idChange of action.idChanges) {
                if (idChange == null || !Array.isArray(idChange) || idChange.length < 2) {
                    console.warn(`$FLOW_RELATION_ID_CHANGE: idChange is of incorrect form`); // eslint-disable-line no-console
                    continue;
                }
                const oldKey = idChange[0];
                const newKey = idChange[1];

                // Look for any relation label to update its flowrelationid
                const stateKeysToUpdate = Object.keys(ofilter(v => v.FlowRelationId == oldKey, state));
                if (stateKeysToUpdate.length == 0) {
                    continue;
                }

                for (const key of stateKeysToUpdate) {
                    madeAnUpdate = true;
                    updates[key] = {
                        FlowRelationId: { $set: newKey },
                    };
                }
            }
            return madeAnUpdate ? update(state, updates) : state;
        }
        default:
            return state;
    }
};
const myCustomReducer = combineReducers({
    byId: customActions,
});

const myReducer = (state = {}, action) => myCustomReducer(myGenericReducer(state, action), action);
export default myReducer;

export const getFlowRelationsParentLabelsArray = createSelector(
    state => state.flowRelationParentLabels.byId,
    (flowRelationsParentLabelsById: {| [number]: FlowRelationParentLabel |}): Array<FlowRelationParentLabel> => {
        const r: Array<FlowRelationParentLabel> = Object.values(flowRelationsParentLabelsById);
        return r;
    }
);

export const getFlowRelationParentLabelsForSelectedFlow = createSelector(
    state => getFlowRelationsParentLabelsArray(state),
    state => getFlowRelationIdsForSelectedFlow(state),
    (flowRelationParentLabels: Array<FlowRelationParentLabel>, flowRelationIds: Array<number>) =>
        flowRelationParentLabels.filter(x => flowRelationIds.includes(x.FlowRelationId))
);
