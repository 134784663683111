import { request } from "../helpers/httpInterceptor";
import h from "../helpers";
import { toJson } from "../helpers/typedHelpers";
import {
    SET_NAS_FILES_LIST_LOADING,
    SET_NAS_FILES_LIST,
    ADD_NAS_FILES_DOWNLOAD_QUEUE,
    REMOVE_NAS_FILES_DOWNLOAD_QUEUE,
    SET_NAS_FILES_LIST_ERROR,
    RESET_NAS,
    SET_NAS_FILES_INFO,
} from "../reducers/nas";
import { Dispatch, GetState } from "../types/types";
import { IFileInfo } from "../types/actions/fileInfo";
import {
    IAddNasFilesDownloadQueue,
    IRemoveNasFilesDownloadQueue,
    ISetNasFilesInfo,
    ISetNasFilesList,
} from "../types/actions/nas";

export const searchNasFiles =
    (nasServerId: number, nasFilePath: string) =>
    (dispatch: Dispatch): void => {
        dispatch({ type: SET_NAS_FILES_LIST_LOADING, isLoading: true });
        dispatch({ type: SET_NAS_FILES_LIST_ERROR, error: null });
        dispatch({ type: SET_NAS_FILES_LIST, files: [] });

        fetch(`/api/nasServer/GetFilesFromDeploymentDestinationServer?id=${nasServerId}&path=${nasFilePath}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const response = data as unknown as { files: Array<string>; errorMessage: string };
                dispatch({ type: SET_NAS_FILES_LIST, files: response.files || [] });

                if (response.errorMessage) {
                    dispatch({ type: SET_NAS_FILES_LIST_ERROR, error: response.errorMessage });
                }
            })
            .catch(error => {
                dispatch({ type: SET_NAS_FILES_LIST_ERROR, error: `Error getting file names. Details: ${error}` });
            })
            .finally(() => {
                dispatch({ type: SET_NAS_FILES_LIST_LOADING, isLoading: false });
            });
    };

export const GetNasFileInfo =
    (nasServerId: number, nasFilePath: string) =>
    (dispatch: Dispatch, getState: GetState): void => {
        dispatch(AddNasFilesDownloadQueue(nasFilePath));

        return request(
            `/api/nasServer/getFileInfo?nasServerId=${nasServerId}&path=${nasFilePath}`,
            {
                method: "GET",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(toJson)
            .then(data => {
                if (!data.errorMessage) {
                    const state = getState();
                    const downloadQueue: Array<string> = state.nas.downloadQueue;

                    if (downloadQueue.some(x => x == nasFilePath)) {
                        // If not the file was removed by the user (like request abort)
                        dispatch(
                            addFileInfo({
                                fileName: nasFilePath,
                                filePreview: data.preview,
                                fileQuantity: data.quantity,
                            } as IFileInfo)
                        );
                    }
                } else {
                    dispatch({ type: SET_NAS_FILES_LIST_ERROR, error: data.errorMessage });
                }
            })
            .finally(() => {
                dispatch(RemoveNasFilesDownloadQueue(nasFilePath));
            });
    };

export const ResetNasData =
    () =>
    (dispatch: Dispatch): void => {
        dispatch({ type: RESET_NAS });
    };

export const AddNasFilesDownloadQueue = (file: string): IAddNasFilesDownloadQueue => ({
    type: ADD_NAS_FILES_DOWNLOAD_QUEUE,
    file,
});

export const RemoveNasFilesDownloadQueue = (file: string): IRemoveNasFilesDownloadQueue => ({
    type: REMOVE_NAS_FILES_DOWNLOAD_QUEUE,
    file,
});

export const SetNasFilesList = (files: Array<string>): ISetNasFilesList => ({
    type: SET_NAS_FILES_LIST,
    files,
});

export const SetNasFilesInfo = (filesInfo: Array<IFileInfo>): ISetNasFilesInfo => ({
    type: SET_NAS_FILES_INFO,
    filesInfo,
});

export const addFileInfo =
    (newFileInfo: IFileInfo) =>
    (dispatch: Dispatch, getState: GetState): void => {
        const state = getState();
        const filesInfo: Array<IFileInfo> = state.nas.filesInfo;

        let newFilesInfo = [...filesInfo];
        let currentFileInfo = newFilesInfo.find(x => x.fileName == newFileInfo.fileName);

        if (currentFileInfo) {
            currentFileInfo.filePreview = newFileInfo.filePreview;
            currentFileInfo.fileQuantity = newFileInfo.fileQuantity;
        } else {
            newFilesInfo.push(newFileInfo);
        }

        dispatch(SetNasFilesInfo(newFilesInfo));
    };
