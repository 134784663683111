import React from "react";
import { useSelector } from "react-redux";

// material ui
import MaterialTooltip from "@material-ui/core/Tooltip";

// types
import { IAdvertisersByCompany } from "../../../../types/stores/vars";
import { IAppState } from "../../../../types/stores";
import { Icon } from "@material-ui/core";

type Props = {
    flowItemId: number;
    selectedDestinationId: number | undefined;
    destination: IAdvertisersByCompany;
    onClick: (destinationId: number) => void;
};

const ExportDestinationSquare: React.FC<Props> = ({ flowItemId, selectedDestinationId, destination, onClick }) => {
    const isInternal = useSelector((state: IAppState) => state.session.isInternal);
    const title =
        destination.VerticalName +
        " - " +
        destination.CompanyName +
        (isInternal && destination.FTPFolder != null ? " (" + destination.FTPFolder + ")" : "");
    const selected = destination.PartnerAccessId == selectedDestinationId;
    const tooltip = selected ? "Click to select another destination." : "Click to select this destination.";

    return (
        <div id={"li_" + flowItemId + "_" + destination.PartnerAccessId}>
            <MaterialTooltip title={tooltip}>
                <div className="singleview-square" onClick={() => onClick(destination.PartnerAccessId)}>
                    <div className="singleview-logo">
                        <img
                            src={"/Images/CompanyLogo/" + destination.PartnerAccessId}
                            alt={destination.CompanyName}
                            title=""
                        />{" "}
                    </div>
                </div>
            </MaterialTooltip>
            {selected && (
                <Icon
                    fontSize="large"
                    style={{ color: "#8BC34A", position: "relative", top: "-190px", left: "165px", fontSize: "40px" }}
                >
                    check_circle
                </Icon>
            )}
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "14px",
                    wordWrap: "break-word",
                    width: "200px",
                    textAlign: "center",
                }}
            >
                <span>{title}</span>
            </div>
        </div>
    );
};
export default ExportDestinationSquare;
