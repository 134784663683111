import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateAttribute } from "../../../../actions/flowActions";
import { GetActivationDestinationSchedules } from "../../../../actions/companiesActions";
import { FormControl, InputLabel, ListSubheader, Select, MenuItem, Divider, Button } from "@material-ui/core";
import { IAppState } from "../../../../types/stores";
import { FlowExport } from "../../../../types/stores/flowTypes";
import Tooltip from "@material-ui/core/Tooltip";
import { Dialog } from "@material-ui/core";
import ScheduleAddEdit from "../../../schedule/schedules/ScheduleAddEdit";

type Props = {
    flowExport: FlowExport;
    canEdit: boolean;
};

const ActivationDestinationSchedules: React.FC<Props> = ({ flowExport, canEdit }: Props) => {
    const dispatch = useDispatch();

    const flow = useSelector((state: IAppState) => state.flows.byId[state.selected.flow]);
    const activationDestinationSchedules = useSelector((state: IAppState) => state.vars.activationDestinationSchedules);
    const enabledFeatures = useSelector((state: IAppState) => state.session.enabledFeatures);
    const selectedSchedule = activationDestinationSchedules
        ? activationDestinationSchedules.find(x => x.Id == flow?.ScheduleId)
        : null;

    const [showEditScheduleModal, setEditScheduleModal] = useState(false);

    useEffect(() => {
        if (flowExport.DestinationId && flow?.FlowId) {
            dispatch(GetActivationDestinationSchedules(flowExport.DestinationId, flow.FlowId));
        }
    }, [flow?.isSaving, flow?.FlowId, flowExport.DestinationId, dispatch]);

    const activationDestinationScheduleChanged = newValue => {
        dispatch(updateAttribute("flows", flow.FlowId, "ScheduleId", newValue));
    };

    const hide = () => {
        setEditScheduleModal(false);
    };

    const openEditScheduleModal = () => {
        setEditScheduleModal(true);
    };

    const handleScheduleSaved = (id: string) => {
        hide();
        activationDestinationScheduleChanged(id);
        if (flowExport.DestinationId && flow?.FlowId) {
            dispatch(GetActivationDestinationSchedules(flowExport.DestinationId, flow.FlowId));
        }
    };

    const renderEditScheduleModal = () => (
        <Dialog id="schedule-modal" open={showEditScheduleModal} onClose={hide}>
            <ScheduleAddEdit
                scheduleId={flow && "ScheduleId" in flow ? flow.ScheduleId : ""}
                companyId={flow?.CompanyId}
                isScheduleTemplate={selectedSchedule?.IsScheduleTemplate}
                isExportModal={true}
                onSaveCallback={handleScheduleSaved}
            />
        </Dialog>
    );

    const renderSchedules = () => {
        const schedules = activationDestinationSchedules.filter(x => !x.IsScheduleTemplate);
        const templates = activationDestinationSchedules.filter(x => x.IsScheduleTemplate);
        return (
            <>
                <InputLabel htmlFor="activation-destination-select">Refresh Schedule</InputLabel>
                <Select
                    defaultValue=""
                    id="activation-destination-select"
                    value={flow?.ScheduleId || ""}
                    disabled={!canEdit || (flow && flow.IsLocked)}
                    fullWidth
                    onChange={e => activationDestinationScheduleChanged(e.target.value)}
                >
                    {schedules.length > 0 && (
                        <ListSubheader onClick={e => e.stopPropagation()}>Schedules assigned to flow</ListSubheader>
                    )}
                    {schedules.length > 0 && <Divider />}
                    {schedules.map(s => (
                        <MenuItem key={s.Id} value={s.Id} selected={s.Id == flow?.ScheduleId}>
                            {s.Name}
                        </MenuItem>
                    ))}
                    <ListSubheader onClick={e => e.stopPropagation()}>Schedule templates available</ListSubheader>
                    <Divider />
                    {templates.map(s => (
                        <MenuItem key={s.Id} value={s.Id} selected={s.Id == flow?.ScheduleId}>
                            {s.Name}
                        </MenuItem>
                    ))}
                </Select>
            </>
        );
    };

    const renderEditBtnTooltip = () => {
        if (!flow || flow.FlowId <= 0) {
            return "Please save your flow before creating a schedule";
        }
        if (!selectedSchedule) {
            return "Please select a schedule from the dropdown to edit";
        }
        if (selectedSchedule.IsScheduleTemplate) {
            return "Copy and edit schedule";
        }
        return "Edit schedule";
    };

    return (
        <FormControl fullWidth required>
            <div style={{ display: "flex" }}>
                {renderSchedules()}
                {enabledFeatures.includes("edit-activation-schedules-modal") && (
                    <Tooltip title={renderEditBtnTooltip()}>
                        <div style={{ marginTop: "16px" }}>
                            <Button
                                disabled={!flow || flow.FlowId <= 0 || !flow.ScheduleId || !selectedSchedule}
                                color="primary"
                                onClick={openEditScheduleModal}
                            >
                                <i
                                    className="material-icons"
                                    style={{ height: "30px", width: "30px", fontSize: "15px", paddingTop: "8px" }}
                                >
                                    edit
                                </i>
                            </Button>
                        </div>
                    </Tooltip>
                )}
            </div>
            {enabledFeatures.includes("edit-activation-schedules-modal") && renderEditScheduleModal()}
        </FormControl>
    );
};

export default ActivationDestinationSchedules;
