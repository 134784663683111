import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import MaterialTooltip from "@material-ui/core/Tooltip";

import { showModal } from "../../actions/actionCreators";
import { GetNotificationsCount, ClearUnreadNotifications } from "../../actions/userNotificationActions";
import { IAppState } from "../../types/stores";

const NotificationBell: React.FC = () => {
    const dispatch = useDispatch();

    const [newNotifications, setHasNewNotifications] = useState(false);

    const count = useSelector((state: IAppState) => state.userNotifications.count);
    const hasNewNotifications = useSelector((state: IAppState) => state.userNotifications.newNotifications);

    useEffect(() => {
        dispatch(GetNotificationsCount());
    }, [dispatch]);

    useEffect(() => {
        setHasNewNotifications(true);
        setTimeout(() => {
            setHasNewNotifications(false);
        }, 500);
    }, [hasNewNotifications]);

    useEffect(() => {
        if (hasNewNotifications) {
            setHasNewNotifications(false);
            setTimeout(() => {
                setHasNewNotifications(true);
            }, 500);
        }
    }, [count, hasNewNotifications]);

    const openNotificationModal = () => {
        dispatch(ClearUnreadNotifications());
        dispatch(showModal("USER_NOTIFICATIONS"));
        setHasNewNotifications(false);
    };

    const renderNotification = () => {
        const upperThresholdCount = 999;
        const tooltipText =
            count > 0 ? `You have (${count}) new notifications.` : "You do not have any new notifications.";

        let notificationCount: JSX.Element | null = null;

        if (count > 0 && count < upperThresholdCount) {
            notificationCount = <div className="notification-count">{count}</div>;
        } else if (count > upperThresholdCount) {
            notificationCount = <div className="notification-count">999+</div>;
        }

        return (
            <>
                <MaterialTooltip title={tooltipText} className={`material-notifications-tooltip`}>
                    <IconButton
                        onClick={openNotificationModal}
                        className={`material-notifications-button ${newNotifications ? "notify" : ""}`}
                    >
                        {count > 0 ? (
                            <i className={`material-icons ${newNotifications ? "notify" : ""}`}>notifications_active</i>
                        ) : (
                            <i className={`material-icons`}>notifications_none</i>
                        )}
                    </IconButton>
                </MaterialTooltip>
                {notificationCount}
            </>
        );
    };

    return renderNotification();
};

export default NotificationBell;
