import React, { useState } from "react";
import update from "immutability-helper";
import { Grid } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import Moment from "moment";
import MomentUtils from "@date-io/moment";
import { ScheduleState } from "../ScheduleAddEdit";
import { hourOptions, minuteOptions } from "../../helpers/helpers";
import TimeSelector from "./shared/TimeSelector";

type Props = {
    schedule: ScheduleState;
    setSchedule: React.Dispatch<React.SetStateAction<ScheduleState>>;
    updateUnsavedChanges: () => void;
    disabled?: boolean;
};

const OneTime: React.FC<Props> = ({ schedule, setSchedule, updateUnsavedChanges, disabled }: Props) => {
    const [inputValue, setInputValue] = useState(Moment().format("MM/DD/YYYY"));

    const handleRunDateChange = val => {
        setSchedule(update(schedule, { runDate: { $set: Moment(val).format("MM/DD/YYYY") } }));
        setInputValue(Moment(val).format("MM/DD/YYYY"));
        updateUnsavedChanges();
    };

    const handleHourChange = val => {
        setSchedule(update(schedule, { hour: { $set: val.target.value } }));
        updateUnsavedChanges();
    };

    const handleMinuteChange = val => {
        setSchedule(update(schedule, { minute: { $set: val.target.value } }));
        updateUnsavedChanges();
    };

    return (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
            <div className="inline-block">
                <span>Run on</span>
            </div>
            <Grid item xs={3} style={{ width: "150px" }}>
                <MuiPickersUtilsProvider libInstance={Moment} utils={MomentUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        id="runDate"
                        label="Date"
                        value={Moment(schedule.runDate)}
                        inputValue={
                            schedule.runDate == null ? inputValue : Moment(schedule.runDate).format("MM/DD/YYYY")
                        }
                        style={{ width: "150px" }}
                        onChange={handleRunDateChange}
                        className="material-form-control"
                        KeyboardButtonProps={{
                            "aria-label": "runDate",
                        }}
                        disabled={disabled}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <div className="inline-block" style={{ marginLeft: "60px", width: "15px" }}>
                <span>at</span>
            </div>
            <Grid item xs={2}>
                <TimeSelector label="Hour" value={schedule.hour} onChange={handleHourChange} options={hourOptions} />
            </Grid>
            <Grid item xs={2}>
                <TimeSelector
                    label="Minute"
                    value={schedule.minute}
                    onChange={handleMinuteChange}
                    options={minuteOptions}
                />
            </Grid>
        </div>
    );
};

export default OneTime;
