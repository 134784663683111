import * as React from "react";
import { connect } from "react-redux";
import type { Flow } from "../../../../types/flowTypes";
import type { MapStateToProps } from "react-redux";

import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

type Props = {
    // Passed in
    flowItemId: number,
    isDisabled: boolean,
    // Redux
    flow: ?Flow,
};

type State = {
    lockedOut: boolean,
};

class OfferTemplateButton extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            lockedOut: false,
        };
    }

    generateTemplate = () => {
        const { flowItemId } = this.props;

        this.setState({ lockedOut: true });
        setTimeout(() => {
            this.setState({ lockedOut: false });
        }, 3000);

        const excelLink = `/FlowItemReports/OfferCodeTemplate?flowItemId=${flowItemId}`;
        window.location = excelLink;
    };

    render() {
        const { flow, isDisabled } = this.props;
        const { lockedOut } = this.state;
        const unsavedChanges = flow ? flow.hasUnsavedChanges : false;
        const disabled = lockedOut || unsavedChanges || isDisabled;
        let title = unsavedChanges
            ? "Please save before exporting to Excel"
            : !lockedOut
            ? "Export Offer Import Template"
            : "";

        let buttonContext = lockedOut ? (
            <div>Downloading...</div>
        ) : (
            <div>
                <i className="glyphicon glyphicon-download-alt" /> Offer Template
            </div>
        );

        buttonContext = lockedOut ? <>Downloading...</> : <>Offer Template</>;
        const templateButton = (
            <Tooltip title={title} style={{ fontSize: 12 }} placement="bottom">
                <div>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={"edit-button"}
                        startIcon={<i className="material-icons">save_alt</i>}
                        onClick={() => {
                            this.generateTemplate();
                        }}
                        disabled={disabled}
                    >
                        {buttonContext}
                    </Button>
                </div>
            </Tooltip>
        );

        return templateButton;
    }
}

const mapStateToProps: MapStateToProps<*, *, *> = state => ({
    flow: state.flows.byId[state.selected.flow],
});
const mapDispatchToProps = null;
const OfferTemplateButtonC = connect(mapStateToProps, mapDispatchToProps)(OfferTemplateButton);

export default OfferTemplateButtonC;
