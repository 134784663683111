import React, { useState, useEffect } from "react";
import TreeView from "../tree/TreeView";
import FolderDragDrop from "./FolderDragDrop";
import FolderLabel from "./FolderLabel";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getTreeSearch } from "../../reducers/search";
import { searchTree } from "../../reducers/_folderGeneral";
import { folderMatchesSearchString } from "../../helpers/folder";
import FlowList from "../tree-flow/FlowList";
import FolderLayoutList from "../export-layout/FolderLayoutList";
import type { Folder } from "../../types/types";
import { IAppState } from "../../types/stores/index";

type Props = {
    // Passed In
    folderType: string;
    folder: Folder; // The current folder to draw
    treeViewOnUpdate: () => void; // Used for odd/even row coloring
    showAllDuringSearch?: boolean; // Used for folder search, searched folders are told to show all contents, even if they don't match
    defaultCollapsed?: boolean;
    listComponent: "FlowList" | "FolderLayoutList";
    renamingAndHasNoChildren: boolean; // ReSELECT
    folderContainsSearch: boolean; // RESELECT Do any children of this folder match the current treeSearch?
    schedulesMode?: boolean;
};

const ThingListFolder: React.FC<Props> = (props: Props) => {
    const {
        folderType,
        folder,
        treeViewOnUpdate,
        showAllDuringSearch,
        defaultCollapsed,
        listComponent,
        renamingAndHasNoChildren,
        folderContainsSearch,
        schedulesMode,
    } = props;
    const [folderMatchesSearch, setFolderMatchesSearch] = useState(false);

    const getIsChildRenaming = makeGetIsChildRenaming();
    const treeSearch = useSelector<IAppState, string>(state => getTreeSearch(state));
    const userId = useSelector<IAppState, number | null>(state => state.session.userId);
    const isChildRenaming = useSelector<IAppState, boolean>(state =>
        //@ts-ignore
        getIsChildRenaming(state, props)
    );
    const isRenaming = useSelector<IAppState, boolean>(
        state =>
            state.vars.renamingAudienceFolderType == folder.folderType &&
            state.vars.renamingAudienceFolderId == folder.id
    );
    const enabledFeatures = useSelector((state: IAppState) => state.session.enabledFeatures);

    useEffect(() => {
        treeViewOnUpdate();
    }, [treeViewOnUpdate]);

    useEffect(() => {
        if (treeSearch != null && treeSearch != "") {
            setFolderMatchesSearch(folderMatchesSearchString(folder, treeSearch));
        } else {
            setFolderMatchesSearch(false);
        }
    }, [folder, treeSearch]);

    const components = {
        FlowList,
        FolderLayoutList,
    };

    const renderFolderLabel = (node: Folder) => <FolderLabel node={node} folderType={folderType} />;

    let showAll = showAllDuringSearch || false;

    let collapsed: boolean | undefined = undefined;
    let itemClassName = "";
    let headerMap: null | ((x: any) => JSX.Element) = x => <FolderDragDrop folder={folder}>{x}</FolderDragDrop>;

    if (isRenaming) {
        itemClassName += " renaming";
        headerMap = null; // Don't allow drag and drop
        collapsed = renamingAndHasNoChildren; // Force folder open if renaming something with children
    }

    // Top level folders belonging to me when ALL is active - visually out
    if (folder.level == 0 && userId == folder.userId) {
        itemClassName += " whole-row-mine";
    }

    // Force open if any children are renaming
    if (isChildRenaming) {
        collapsed = false;
    }

    if (treeSearch) {
        const folderIsTopLevelAndMine = folder.level == 0 && userId == folder.userId;

        if (!folderContainsSearch && !folderMatchesSearch && !folderIsTopLevelAndMine) {
            return null;
        } // Don't render folder if not in search results
        if (folderMatchesSearch) {
            showAll = true;
        }
        collapsed = false; // Otherwise it's in the search results and we force open
    }

    const ListTagName = components[listComponent];

    if (
        schedulesMode &&
        !enabledFeatures.includes("schedules-admin") &&
        enabledFeatures.includes("schedule-historical")
    ) {
        // Ensures when not an admin the only the historical folder and non-historical folder for a user shows
        if (folder.isHistorical && !(folder.userId == 0 || folder.userId == userId)) {
            return null;
        }
    }

    return (
        <TreeView
            nodeLabel={renderFolderLabel(folder)}
            key={folder.id}
            headerMap={headerMap}
            collapsed={collapsed}
            defaultCollapsed={defaultCollapsed != null ? defaultCollapsed : true}
            itemClassName={itemClassName}
            onUpdate={treeViewOnUpdate}
        >
            <ListTagName
                folderType={folderType}
                key={folder.id}
                folder={folder}
                treeViewOnUpdate={treeViewOnUpdate}
                showAllDuringSearch={showAll}
                schedulesMode={schedulesMode || false}
            />
        </TreeView>
    );
};

const makeGetIsChildRenaming = () =>
    createSelector(
        (_, props: Props) => props.folder && props.folder.children,
        (state: IAppState) => state.vars.renamingAudienceFolderId,
        (children, renamingAudienceFolderId) => searchTree(children, renamingAudienceFolderId)
    );

export default ThingListFolder;
