import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
// import tryParseJSON from "../helpers/tryParseJSON";
import type { FlowClientVariableD, FlowErrorsByItemId } from "../types/flowTypes";

const myGenericReducer = makeReducerFor("FLOW_CLIENT_VARIABLE", "Id");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowClientVariablesArray = createSelector(
    state => state.flowClientVariables.byId,
    (flowClientVariablesById: {| [number]: FlowClientVariableD |}): Array<FlowClientVariableD> => {
        const r: Array<FlowClientVariableD> = Object.values(flowClientVariablesById);
        return r;
    }
);

export type FlowClientVariablesByItemId = {
    [number]: FlowClientVariableD,
};

export const getFlowClientVariablesForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowClientVariablesArray(state),
    (selectedFlow: number, flowClientVariables: Array<FlowClientVariableD>): Array<FlowClientVariableD> =>
        flowClientVariables.filter(x => x.FlowId == selectedFlow)
);

const clientVariablesToErrorsById = (flowClientVariables: Array<FlowClientVariableD>): FlowErrorsByItemId => {
    const errorsById = {};
    for (const flowClientVariable of flowClientVariables) {
        errorsById[flowClientVariable.FlowId] = validateFlowClientVariable(flowClientVariable);
    }
    return errorsById;
};

export const getClientVariableErrorsForSelectedFlow = createSelector(
    state => getFlowClientVariablesForSelectedFlow(state),
    clientVariablesToErrorsById
);

export const getflowClientVariableErrorsForAllFlows = createSelector(
    state => getFlowClientVariablesArray(state),
    clientVariablesToErrorsById
);

//import { measureFieldsStringToArray } from "../helpers/crosstabHelper";
//////////////////// HELPERS //////////////////////////////
export const validateFlowClientVariable = (flowClientVariable: FlowClientVariableD): Array<string> => {
    const errors = [];

    if (flowClientVariable.FlowId == null || flowClientVariable.FlowId == 0) {
        errors.push("Flow variable must have a linked flow.");
    } else if (flowClientVariable.VariableId == null || flowClientVariable.VariableId == 0) {
        errors.push("Flow variable must have a linked client variable.");
    }

    return errors;
};
