import React from "react";

// Redux + AC
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as flowActions from "../../../../actions/flowActions";
import * as layoutActions from "../../../../actions/exportLayoutActions2";
import type { MapStateToProps } from "react-redux";
import type { FlowSVDedupeD, FlowItem, FlowExport, UpdateAttribute } from "../../../../types/flowTypes";

// Components and helpers
import IconSwitch from "../../../material-components/Misc/IconSwitch";
import { makeGetFlowSVDedupe } from "../../item/FlowSVDedupeEdit";
import { getFlowExportsForSelectedFlow } from "../../../../reducers/flowExports";

import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import { FormControlLabel } from "@material-ui/core";

type Props = {
    // Passed In
    flowItemId: number,
    dedupeKey: string,
    isDisabled: boolean,
    onChangeCallback: () => void,
    updateCustomDedupe: (newValue: boolean) => void,
    // Redux
    flowItem: FlowItem,
    flowSVDedupe: ?FlowSVDedupeD,
    newFlowSVDedupe: (flowItemId: number) => void,
    deleteFlowSVDedupe: (flowSVDedupeId: number) => void,
    setHasUnsavedChanges: (flowItemId: number) => void,
    invalidateItemAndChildren: (flowItemId: number) => void,
    requestClientField: () => void,
    flowExports: Array<FlowExport>,
    updateAttribute: UpdateAttribute,
    clientFieldObject: any,
    enabledFeatures: any,
};
/******************************************************************************
 * OfferDedupeToggle.test.js will need to be updated when removing the feature
 */

class OfferDedupeToggle extends React.Component<Props> {
    componentDidMount() {
        const { requestClientField } = this.props;
        requestClientField();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps != this.props) {
            this.setDedupeKey();
        }
    }

    onDedupeChange = (e: any) => {
        const {
            flowItemId,
            flowSVDedupe,
            newFlowSVDedupe,
            deleteFlowSVDedupe,
            setHasUnsavedChanges,
            dedupeKey,
            updateCustomDedupe,
        } = this.props;
        setHasUnsavedChanges(flowItemId);
        this.props.invalidateItemAndChildren(flowItemId);
        const checked = e.target.checked;
        if (checked) {
            newFlowSVDedupe(flowItemId, dedupeKey, "");
            this.setDedupeKey();
        } else if (flowSVDedupe != null) {
            updateCustomDedupe(false);
            deleteFlowSVDedupe(flowSVDedupe.FlowSVDedupeId);
            this.props.onChangeCallback();
        } else {
            return;
        }
    };

    setDedupeKey = () => {
        const { clientFieldObject, flowSVDedupe, updateAttribute } = this.props;

        // Default to first party key if not set
        if (flowSVDedupe && flowSVDedupe.SVField == null && clientFieldObject) {
            updateAttribute("flowSVDedupes", flowSVDedupe.FlowSVDedupeId, "SVField", clientFieldObject.FieldName, true);
        }
    };

    render() {
        const { flowSVDedupe, flowItem, isDisabled } = this.props;

        let dedupeOn = flowSVDedupe != null;
        const nodeTypeName = flowItem.FlowItemType == "offerMerge" ? "Offer" : "Export";

        const label = (
            <Tooltip title={`ON will dedupe across the inputs in this ${nodeTypeName} node`}>
                <div style={{ fontSize: "16px" }}>
                    <strong>Dedupe&nbsp;</strong>
                    <Icon style={{ fontSize: "16px" }}>info</Icon>
                </div>
            </Tooltip>
        );

        return (
            <FormControlLabel
                labelPlacement="start"
                style={{
                    minWidth: "175px",
                    margin: "0 auto",
                }}
                control={
                    <IconSwitch
                        id="dedupe-on"
                        name="dedupeOn"
                        checked={dedupeOn}
                        onChange={this.onDedupeChange}
                        disabled={isDisabled}
                    />
                }
                label={label}
            />
        );
    }
}

const mapStateToProps: MapStateToProps<*, *, *> = () => {
    const getFlowSVDedupe = makeGetFlowSVDedupe();
    return (state, ownProps) => ({
        flowSVDedupe: getFlowSVDedupe(state, ownProps),
        flowItem: state.flowItems.byId[ownProps.flowItemId],
        flowExports: getFlowExportsForSelectedFlow(state),
        clientFieldObject: state.layoutObjects.clientFieldObject,
        enabledFeatures: state.session.enabledFeatures || [],
    });
};
const mapDispatchToProps = dispatch => bindActionCreators(Object.assign({}, flowActions, layoutActions), dispatch);
const OfferDedupeToggleC = connect(mapStateToProps, mapDispatchToProps)(OfferDedupeToggle);

export default OfferDedupeToggleC;
