import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../actions/actionCreators";
import SVFieldListEntry from "./SVFieldListEntry";
import withUpdateStripes from "../misc/withUpdateStripes";
import { haystackMatchesSearchObject } from "../../reducers/search";

type Props = {
    scope: boolean,
};

class SVFieldList extends React.Component<Props> {
    componentDidMount() {
        this.props.updateStripes();
    }

    componentDidUpdate() {
        this.props.updateStripes();
    }

    renderSVField = field => <SVFieldListEntry field={field} key={field} />;

    render() {
        const { svFields, treeSearch } = this.props;
        let theseFields = svFields;
        if (svFields != null) {
            if (treeSearch) {
                theseFields = svFields.filter(x => haystackMatchesSearchObject(x, treeSearch));
            }
            const elements = theseFields.map(this.renderSVField);

            return <div>{elements}</div>;
        } else {
            return <div />;
        }
    }
}

SVFieldList.propTypes = {
    //Inject by redux
    svFields: PropTypes.any,
    treeSearch: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    thisLayout: PropTypes.any,
    thisField: PropTypes.any,
    // FROM HOC
    updateStripes: PropTypes.func.isRequired,
};

const SVFieldListS = withUpdateStripes(SVFieldList);

import { getTreeSearch } from "../../reducers/search";
const mapStateToProps = state => ({
    treeSearch: getTreeSearch(state),
});

const mapDispatchToProps = dispatch => bindActionCreators(actionCreators, dispatch);
const SVFieldListC = connect(mapStateToProps, mapDispatchToProps)(SVFieldListS);

export default SVFieldListC;
