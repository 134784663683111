//import update from "immutability-helper";
//import clone from "../helpers/clone";
import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { combineReducers } from "redux";
import ofilter from "../helpers/ofilter";
import update from "immutability-helper";
import type { UserCompany, Company } from "../types/types";

const myGenericReducer = makeReducerFor("USERCOMPANY", "ID");

//needed to update user companies when user id changed
const userCompaniesById = (state = {}, action) => {
    switch (action.type) {
        case "USER_ID_CHANGE": {
            const updates = {};
            let madeAnUpdate = false;
            for (const idChange of action.idChanges) {
                if (idChange == null || !Array.isArray(idChange) || idChange.length < 2) {
                    console.warn(`$USER_ID_CHANGE: idChange is of incorrect form`); // eslint-disable-line no-console
                    continue;
                }
                const oldKey = idChange[0];
                const newKey = idChange[1];

                // Look for any flowItems belonging to the oldFlowId
                const stateKeysToUpdate = Object.keys(ofilter(v => v.UserKey == oldKey, state));
                if (stateKeysToUpdate.length == 0) {
                    continue;
                }

                for (const key of stateKeysToUpdate) {
                    madeAnUpdate = true;
                    updates[key] = {
                        UserKey: { $set: newKey },
                    };
                }
            }
            return madeAnUpdate ? update(state, updates) : state;
        }
        default:
            return state;
    }
};

const myCustomReducer = combineReducers({
    byId: userCompaniesById,
});

const myReducer = (state = {}, action) => myCustomReducer(myGenericReducer(state, action), action);
export default myReducer;

export const getAllUserCompanies = createSelector(
    state => state.userCompanies.byId,
    (userCompaniesById: { [number]: UserCompany }): Array<UserCompany> => Object.values(userCompaniesById)
);

import { getArrayOfAllCompanies } from "./companies";
export const getCompaniesForUser = createSelector(
    state => getAllUserCompanies(state),
    state => getArrayOfAllCompanies(state),
    (state, props) => props.userId,
    (allUserCompanies: Array<UserCompany>, companies: Array<Company>, userId: number): Array<Company> => {
        const userCompanies = allUserCompanies.filter(uc => uc.UserKey == userId);
        const companyIds = userCompanies.map(x => x.CompanyID);
        return companies.filter(x => companyIds.includes(x.CompanyID));
    }
);

export const getCompaniesForAdmin = createSelector(
    state => getAllUserCompanies(state),
    state => getArrayOfAllCompanies(state),
    state => state.session.roles,
    state => state.session.userId,
    (
        allUserCompanies: Array<UserCompany>,
        companies: Array<Company>,
        adminRoles: Array<string>,
        adminId: number
    ): Array<Company> => {
        if (adminRoles.includes("Super Admin") || adminRoles.includes("Experian Admin")) {
            return companies;
        }
        const userCompanies = allUserCompanies.filter(uc => uc.UserKey == adminId);
        const companyIds = userCompanies.map(x => x.CompanyID);
        return companies.filter(x => companyIds.includes(x.CompanyID));
    }
);

export const getUserCompaniesForUser = createSelector(
    state => getAllUserCompanies(state),
    (state, props) => props.userId,
    (allUserCompanies: Array<UserCompany>, userId: number): Array<UserCompany> =>
        allUserCompanies.filter(c => c.UserKey == userId)
);
