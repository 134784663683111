import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FileBrowser from "../../../misc/FileBrowser";

import { renderFlowItemType } from "../../../../helpers/typedHelpers";
import { getFlowRelationsForSelectedFlow } from "../../../../reducers/flowRelations";
import { getFlowItemClientVariablesForSelectedFlow } from "../../../../reducers/flowItemClientVariables";

import * as flowActions from "../../../../actions/flowActions";

import type {
    FlowOfferMerge,
    FlowItem,
    FlowRelation,
    FlowItemClientVariable,
    FlowAndItemPermissions,
    UpdateAttribute,
} from "../../../../types/flowTypes";
import { VariableValueType } from "../../../../types/stores/vars";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

type Props = {
    // Passed in
    flowItemId: number,
    offerCodes: Array<any>,
    flowOfferMerges: Array<FlowOfferMerge>,
    handleTabSelect: (tabKey: number) => void,
    // Redux
    flowItems: { [number]: FlowItem },
    flowRelations: Array<FlowRelation>,
    flowItemClientVariables: { [number]: FlowItemClientVariable },
    selectedFlow: number,
    // AC
    updateAttribute: UpdateAttribute,
    newFlowItemClientVariable: (
        variableId: number,
        flowId: number,
        flowItemId: number,
        childFlowItemId: number,
        variableValue: VariableValueType
    ) => void,
    permissions: FlowAndItemPermissions,
};

type State = {
    fileName: string,
    uploading: boolean,
    hasHeaderRow: boolean,
    hasItemColumn: boolean,
    importFile: Array<any>,
    offerMap: Array<any>,
    itemMap: Array<any>,
    availableOffers: Array<any>,
    availableItems: Array<any>,
    errorCells: Array<any>,
};

class OfferCodeImport extends React.Component<Props, State> {
    state = {
        fileName: "",
        uploading: false,
        hasHeaderRow: true,
        hasItemColumn: true,
        importFile: [],
        offerMap: [],
        itemMap: [],
        availableOffers: [],
        availableItems: [],
        errorCells: [],
    };

    componentDidMount() {
        this.getAvailableItems();
        this.getAvailableOffers();
    }

    componentDidUpdate(prevProps, prevState) {
        const { hasItemColumn, hasHeaderRow, availableOffers, importFile } = this.state;
        if (prevState.hasItemColumn != hasItemColumn) {
            this.getAvailableOffers();
        }
        if (prevState.availableOffers != availableOffers || prevState.hasHeaderRow != hasHeaderRow) {
            if (importFile.length > 0) {
                this.processFile();
            }
        }
    }

    resetPage = () => {
        this.setState({
            fileName: "",
            uploading: false,
            hasHeaderRow: true,
            hasItemColumn: true,
            importFile: [],
            offerMap: [],
            itemMap: [],
            errorCells: [],
        });
    };

    selectedFileChanged = (file, uploading) => {
        if (!file) {
            return;
        }
        this.setState({ fileName: file.name, uploading, errorCells: [] });
        this.readFile(file);
    };

    hasHeaderRowChanged = e => {
        this.setState({ hasHeaderRow: e.target.checked, offerMap: [], itemMap: [] });
    };

    hasItemColumnChanged = e => {
        this.setState({ hasItemColumn: e.target.checked, offerMap: [], itemMap: [] });
    };

    offerMappingChanged = (colId, e) => {
        const { offerMap } = this.state;
        const select = e.target;
        let newMap = [];

        if (offerMap.length > 0) {
            // Update Existing map
            newMap = offerMap;
            const mapExist = newMap.find(x => x.colId == colId);
            if (mapExist) {
                mapExist.selectedValue = select ? parseInt(select.value) : -1;
            } else {
                // Add new Map
                newMap.push({ colId, selectedValue: parseInt(select.value) });
            }

            // Reset duplicate mapped value
            if (select) {
                const valueExist = newMap.filter(x => x.selectedValue == parseInt(select.value) && x.colId != colId);
                valueExist.forEach(x => {
                    x.selectedValue = -1;
                });
            }
        } else {
            newMap = [{ colId, selectedValue: parseInt(select.value) }];
            this.setState({ offerMap: newMap });
        }
        this.forceUpdate();
    };

    itemMappingChanged = (rowId, e) => {
        const { itemMap } = this.state;
        const select = e.target;
        let newMap = [];

        if (itemMap.length > 0) {
            newMap = itemMap;

            // Update Existing map
            const mapExist = newMap.find(x => x.rowId == rowId);
            if (mapExist) {
                mapExist.selectedValue = select ? select.value : "";
            } else {
                // Add new Map
                newMap.push({ rowId, selectedValue: select.value });
            }

            // Reset duplicate mapped value
            if (select) {
                const valueExist = newMap.filter(x => x.selectedValue == select.value && x.rowId != rowId);
                valueExist.forEach(x => {
                    x.selectedValue = -1;
                });
            }
        } else {
            newMap = [{ rowId, selectedValue: select.value }];
            this.setState({ itemMap: newMap });
        }
        this.forceUpdate();
    };

    readFile = file => {
        let lineno = 1;
        let fileArray = [];

        const onComplete = () => {
            this.setState({ uploading: false });
            this.processFile();
        };

        this.readLines(
            file,
            line => {
                if (line.length > 0) {
                    let insideQuote = false;
                    const columns = [];
                    let entry = [];
                    line.split("").forEach(character => {
                        if (character === '"') {
                            insideQuote = !insideQuote;
                        } else if (character == "," && !insideQuote) {
                            columns.push(entry.join(""));
                            entry = [];
                        } else {
                            entry.push(character);
                        }
                    });

                    columns.push(entry.join("").trim());

                    fileArray.push({
                        lineId: lineno,
                        cols: columns,
                    });
                }
                lineno++;
            },
            onComplete
        );
        this.setState({ importFile: fileArray });
    };

    readLines = (file, forEachLine, onComplete) => {
        const CHUNK_SIZE = 100000; // 100kb, arbitrarily chosen.
        const decoder = new TextDecoder();
        let offset = 0;
        let results = "";
        const fr = new FileReader();
        fr.onload = () => {
            // Use stream:true in case we cut the file
            // in the middle of a multi-byte character

            results += decoder.decode(fr.result, { stream: true });

            const lines = results.split("\n");
            results = lines.pop(); // In case the line did not end yet.

            for (let i = 0; i < lines.length; ++i) {
                forEachLine(lines[i] + "\n");
            }
            offset += CHUNK_SIZE;
            seek();
        };
        fr.onerror = () => {
            onComplete(fr.error);
        };

        const seek = () => {
            if (offset !== 0 && offset >= file.size) {
                // We did not find all lines, but there are no more lines.
                forEachLine(results); // This is from lines.pop(), before.
                onComplete(); // Done
                return;
            }
            const slice = file.slice(offset, offset + CHUNK_SIZE);
            fr.readAsArrayBuffer(slice);
        };
        seek();
    };

    processFile = () => {
        const { importFile, hasHeaderRow, hasItemColumn, availableOffers, availableItems } = this.state;
        let newOfferMap = [];
        let newItemMap = [];
        if (importFile.length == 0) {
            return;
        }

        if (hasHeaderRow) {
            const firstRow = importFile[0];
            firstRow["cols"].forEach((col, index) => {
                const headerFound = availableOffers.find(x => x.label == col.trim());
                if (headerFound) {
                    newOfferMap.push({ colId: index, selectedValue: headerFound.value });
                }
            });

            this.setState({ offerMap: newOfferMap });

            if (hasItemColumn) {
                const itemColFound = newOfferMap.find(x => x.selectedValue == -99);
                if (itemColFound) {
                    const itemCol = itemColFound.colId;
                    const fileItems = importFile.map(x => x.cols[itemCol].trim());
                    availableItems.forEach(item => {
                        const rowId = fileItems.indexOf(item.label);
                        if (rowId >= 0) {
                            newItemMap.push({ rowId, selectedValue: item.value });
                        }
                    });
                    this.setState({ itemMap: newItemMap });
                }
            }
        }
    };

    createErrorsTable = () => {
        const { importFile, errorCells } = this.state;
        let tableLines = [];

        errorCells.forEach(error => {
            const badValue = importFile[error.rowId]["cols"][error.colId].trim();
            tableLines.push(
                <tr key={`${error.rowId}${error.colId}`}>
                    <td>{error.rowId + 1}</td>
                    <td>{error.colId + 1}</td>
                    <td>{error.offerCode}</td>
                    <td>{badValue}</td>
                </tr>
            );
        });

        const errorsTable = (
            <table id="errors-table" className="">
                <thead className="flowTableHeader">
                    <tr>
                        <th style={{ width: "100px" }}>Row</th>
                        <th style={{ width: "100px" }}>Column</th>
                        <th>Offer Code</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>{tableLines}</tbody>
            </table>
        );
        return errorsTable;
    };

    createTable = () => {
        const { importFile, hasHeaderRow, errorCells } = this.state;
        if (!importFile || importFile.length == 0) {
            return null;
        }

        // If Errors create the error display table
        if (errorCells.length > 0) {
            return this.createErrorsTable();
        }

        // Create Table
        let tableLines = [];
        let cols = [];
        let headerCols = [];

        importFile.forEach((line, lineIndex) => {
            if (hasHeaderRow && lineIndex == 0) {
                return;
            }
            cols = [];
            cols.push(
                <td className={"frozenFirstColumn"} style={{ minWidth: "250px" }} key={-1}>
                    {this.renderItemSelector(lineIndex)}
                </td>
            );
            line["cols"].forEach((col, index) => {
                cols.push(<td key={index}>{col}</td>);
            });
            tableLines.push(<tr key={line.lineId}>{cols}</tr>);

            // Header
            if (lineIndex == 0 || (hasHeaderRow && lineIndex == 1)) {
                headerCols.push(
                    <th key={-1} className={"frozenFirstColumn"}>
                        Item
                    </th>
                );
                line["cols"].forEach((col, index) => {
                    headerCols.push(
                        <td key={index} style={{ minWidth: "150px" }}>
                            {this.renderOfferSelector(index)}
                        </td>
                    );
                });
            }
        });

        const importTable = (
            <table id="assign-offers-table" className="">
                <thead className="flowTableHeader">
                    <tr>{headerCols}</tr>
                </thead>
                <tbody>{tableLines}</tbody>
            </table>
        );

        return importTable;
    };

    renderOfferSelector = colId => {
        const { offerMap, availableOffers } = this.state;
        let selValue = -1;

        if (offerMap.length > 0) {
            const found = offerMap.find(x => x.colId == colId);
            selValue = found ? found.selectedValue : null;
        }

        const options = availableOffers.map((item, index) => (
            <option key={index} value={item.value} className={index % 2 == 0 ? "import-offer-select-gray" : ""}>
                {item.label}
            </option>
        ));

        return (
            <>
                <select
                    id={`rowMap${colId}`}
                    value={selValue}
                    onChange={this.offerMappingChanged.bind(null, colId)}
                    className="import-offer-select-control"
                >
                    {<option value={-1} />}
                    {options}
                </select>
            </>
        );
    };

    renderItemSelector = rowId => {
        const { itemMap, availableItems } = this.state;
        let selValue = -1;

        if (itemMap.length > 0) {
            const found = itemMap.find(x => x.rowId == rowId);
            selValue = found ? found.selectedValue : null;
        }

        const options = availableItems.map((item, index) => (
            <option key={index} value={item.value} className={index % 2 == 0 ? "import-offer-select-gray" : ""}>
                {item.label}
            </option>
        ));

        return (
            <>
                <select
                    id={`rowMap${rowId}`}
                    value={selValue}
                    onChange={this.itemMappingChanged.bind(null, rowId)}
                    className="import-offer-select-control"
                >
                    {<option value={-1} />}
                    {options}
                </select>
            </>
        );
    };

    getParentItemName = flowItem => {
        const { flowRelations, flowItems } = this.props;

        if (flowItem.FlowItemType != "empty") {
            const parentFlowItemType = renderFlowItemType(flowItem.FlowItemType);
            return flowItem.FlowItemName + " - " + parentFlowItemType;
        } else {
            const emptyParentRelations = flowRelations
                .filter(x => x.ChildFlowItemId == flowItem.FlowItemId && x.ParentFlowItemId != 0)
                .map(x => x.ParentFlowItemId);

            if (emptyParentRelations.length > 0) {
                const emptyParentFlowItemId = emptyParentRelations[0];
                const emptyParentFlowItem: FlowItem = flowItems[emptyParentFlowItemId];
                const emptyParentFlowItemType = renderFlowItemType(emptyParentFlowItem.FlowItemType);
                return (
                    flowItem.FlowItemName + " - " + emptyParentFlowItem.FlowItemName + " - " + emptyParentFlowItemType
                );
            }
        }
        return "Item Not Found";
    };

    getAvailableItems = () => {
        const { flowOfferMerges, flowItems } = this.props;
        if (!flowOfferMerges || !flowItems) {
            return [];
        }
        const availableItems = flowOfferMerges.map(x => ({
            label: this.getParentItemName(flowItems[x.ParentFlowItemId]),
            value: x.ParentFlowItemId,
        }));

        if (availableItems && availableItems.length > 0) {
            availableItems.sort((a, b) => {
                if (a["label"] > b["label"]) {
                    return 1;
                }
                if (a["label"] < b["label"]) {
                    return -1;
                }
                return 0;
            });
        }
        this.setState({ availableItems });
    };

    getAvailableOffers = () => {
        const { offerCodes } = this.props;
        const { hasItemColumn } = this.state;
        if (!offerCodes) {
            return [];
        }
        const availableOffers = offerCodes
            .filter(x => x.IsVisible && x.VariableKind == "string")
            .map(x => ({
                label: x.VariableName.trim(),
                value: x.Id,
            }));

        if (availableOffers && availableOffers.length > 0) {
            availableOffers.sort((a, b) => {
                if (a["label"] > b["label"]) {
                    return 1;
                }
                if (a["label"] < b["label"]) {
                    return -1;
                }
                return 0;
            });
        }

        if (hasItemColumn) {
            availableOffers.push({
                label: "Item Name",
                value: -99,
            });
        }
        this.setState({ availableOffers });
    };

    validateValuesWithRegex = () => {
        const { offerMap, importFile, hasHeaderRow } = this.state;
        const { offerCodes } = this.props;
        const errors = [];
        const offersWithRegex = offerCodes.filter(x => x.Regex);
        const offerIds = offerMap.filter(x => x.selectedValue > 0).map(x => x.selectedValue);
        const offersToValidate = offersWithRegex.filter(x => offerIds.includes(x.Id));

        // spin thru the file and validate any offers that have regex
        if (offersToValidate.length > 0) {
            importFile.forEach((row, index) => {
                if (hasHeaderRow && index == 0) {
                    return;
                }

                offersToValidate.forEach(offer => {
                    const mapRow = offerMap.find(x => x.selectedValue == offer.Id);
                    const colId = mapRow.colId;
                    const cellValue = row.cols[colId];
                    if (cellValue) {
                        const reg = new RegExp(offer.Regex);
                        if (!reg.test(cellValue)) {
                            errors.push({ rowId: index, colId, offerCode: offer.VariableName });
                        }
                    }
                });
            });
        }

        return errors;
    };

    validateValuesWithDropDowns = () => {
        const { offerMap, importFile, hasHeaderRow } = this.state;
        const { offerCodes } = this.props;

        const errors = [];
        const offersWithDropdownValues = offerCodes.filter(x => x.DropdownValues);
        const offerIds = offerMap.filter(x => x.selectedValue > 0).map(x => x.selectedValue);
        const offersToValidate = offersWithDropdownValues.filter(x => offerIds.includes(x.Id));

        // spin thru the file and validate any offers that have dropdown values
        if (offersToValidate.length > 0) {
            importFile.forEach((row, index) => {
                if (hasHeaderRow && index == 0) {
                    return;
                }

                offersToValidate.forEach(offer => {
                    const mapRow = offerMap.find(x => x.selectedValue == offer.Id);
                    const colId = mapRow.colId;
                    const cellValue = row.cols[colId];
                    if (cellValue) {
                        let dropdownArr = offer.DropdownValues.split("|");
                        dropdownArr = dropdownArr.map(x => x.trim()).filter(x => x);
                        if (!dropdownArr.includes(cellValue)) {
                            errors.push({ rowId: index, colId, offerCode: offer.VariableName });
                        }
                    }
                });
            });
        }

        return errors;
    };

    assignOfferCodes = () => {
        const { offerMap, itemMap, importFile } = this.state;
        const {
            flowItemClientVariables,
            selectedFlow,
            updateAttribute,
            newFlowItemClientVariable,
            handleTabSelect,
            flowItemId,
        } = this.props;

        // Validate
        const errors = this.validateValuesWithRegex();
        errors.concat(this.validateValuesWithDropDowns());
        this.setState({ errorCells: errors });
        if (errors.length > 0) {
            return;
        }

        const flowItemCVs: Array<FlowItemClientVariable> = Object.values(flowItemClientVariables);

        itemMap.forEach(item => {
            const parentItemId = item.selectedValue;
            const lineId = item.rowId + 1;
            const fileRow = importFile.find(x => x.lineId == lineId);
            if (!fileRow || !parentItemId) {
                return;
            }

            offerMap.forEach(offer => {
                if (!offer.selectedValue || offer.selectedValue < 0) {
                    return;
                }
                const variableId = offer.selectedValue;
                const value = fileRow["cols"][offer.colId].trim();
                const foundItemClientVariable = flowItemCVs.find(
                    x => x.VariableId == variableId && x.FlowItemId == parentItemId && x.ChildFlowItemId == flowItemId
                );

                const newValueObject: VariableValueType = {
                    Kind: "string",
                    ValueString: value,
                    ValueDate: null,
                    FieldId: null,
                };

                if (foundItemClientVariable) {
                    updateAttribute(
                        "flowItemClientVariables",
                        foundItemClientVariable.FlowItemClientVariableId,
                        "VariableValue",
                        newValueObject,
                        true
                    );
                } else {
                    newFlowItemClientVariable(variableId, selectedFlow, parentItemId, flowItemId, newValueObject);
                }
            });
        });
        this.resetPage();
        handleTabSelect(1);
    };

    render() {
        const { permissions } = this.props;
        const { fileName, uploading, hasHeaderRow, hasItemColumn, errorCells } = this.state;
        const table = this.createTable();
        const hasErrors = errorCells.length > 0;
        const buttonDisabled = !permissions.item.canEdit || uploading || fileName.length == 0;

        const offerCodeImport = (
            <div className="row code-offer-import">
                <div>
                    {errorCells.length > 0 && (
                        <div className="alert alert-warning-original flow-item-warnings">
                            Selected file contains invalid values, please fix and re-import
                        </div>
                    )}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className="code-offer-import-top-container">
                            <FileBrowser
                                disabled={!permissions.item.canEdit}
                                fileName={fileName}
                                uploading={uploading}
                                selectedFileChanged={this.selectedFileChanged}
                                fileTypes=".csv"
                            />
                            <Button
                                variant="contained"
                                color="secondary"
                                className={"edit-button"}
                                onClick={() => {
                                    this.assignOfferCodes();
                                }}
                                disabled={buttonDisabled || hasErrors}
                                title={
                                    buttonDisabled
                                        ? ""
                                        : hasErrors
                                        ? "Fix errors"
                                        : "Validate file and apply offer codes"
                                }
                            >
                                Apply
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={"edit-button"}
                                onClick={() => {
                                    this.resetPage();
                                }}
                                disabled={buttonDisabled}
                            >
                                Reset
                            </Button>
                        </div>
                        <div style={{ display: "flex", margin: "5px 30px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={hasHeaderRow}
                                        disabled={!permissions.item.canEdit || uploading || hasErrors}
                                        onChange={this.hasHeaderRowChanged}
                                        color="primary"
                                    />
                                }
                                label="File contains header row"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={hasItemColumn}
                                        disabled={!permissions.item.canEdit || uploading || hasErrors}
                                        onChange={this.hasItemColumnChanged}
                                        color="primary"
                                    />
                                }
                                label="File contains Item column"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-sm-12">
                    {errorCells.length > 0 ? (
                        <div className="offer-import-error-table">{table}</div>
                    ) : (
                        <div className="offer-import-table">{table}</div>
                    )}
                </div>
            </div>
        );

        return offerCodeImport;
    }
}

import type { MapStateToProps } from "react-redux";
const mapStateToProps: MapStateToProps<*, *, *> = state => ({
    selectedFlow: state.selected.flow,
    flowItems: state.flowItems.byId,
    flowRelations: getFlowRelationsForSelectedFlow(state),
    flowItemClientVariables: getFlowItemClientVariablesForSelectedFlow(state),
});

const mapDispatchToProps = dispatch => bindActionCreators(Object.assign({}, flowActions), dispatch);
const OfferCodeImportC = connect(mapStateToProps, mapDispatchToProps)(OfferCodeImport);

export default OfferCodeImportC;
