import { startRequestProgress, updateRequestProgress, endRequestProgress } from "../actions/loadingActions";

export const request = (url, options = { method: "POST", credentials: "same-origin" }, dispatch) => {
    dispatch(startRequestProgress());
    let progress = 0;

    const setProgress = () => {
        // Reset progress a bit if things are taking too long
        if (progress > 100) progress = 20;

        progress = progress + 30;
        dispatch(updateRequestProgress(Math.min(progress, 100)));
    };

    const timer = setInterval(setProgress, 300);

    return fetch(url, options).then(
        res => {
            dispatch(endRequestProgress());
            clearInterval(timer);
            // console.log("Success: Promise time in seconds", (performance.now() - start) / 1000);
            return Promise.resolve(res);
        },
        err => {
            dispatch(endRequestProgress());
            clearInterval(timer);
            // console.log("Error: Promise time in seconds", (performance.now() - start) / 1000);
            return Promise.reject(err);
        }
    );
};
