import clone from "../helpers/clone";
import isEqual from "lodash/isEqual";
import some from "lodash/some";
import { haystackMatchesSearchObject } from "./search";

export function makeFolderReducerForType(folderType) {
    return (state = [], action) => {
        const isCorrectType = action.folderType && action.folderType.toUpperCase() == folderType;
        if (!isCorrectType) {
            return state;
        }
        switch (action.type) {
            case "LOAD_FOLDERS":
                return loadFolders(state, action);
            case "MOVE_FOLDER_TO_FOLDER":
                return moveFolderToFolder(state, action);
            case "DELETE_FOLDER":
                return deleteFolder(state, action);
            case "CREATE_FOLDER":
                return createFolder(state, action);
            case "RENAME_FOLDER":
                return renameFolder(state, action);
            case "ASSIGN_NEW_FOLDER_ID":
                return assignNewFolderId(state, action);
            default:
                return state;
        }
    };
}

function loadFolders(state, action) {
    const newState = createTree(action.data);
    if (isEqual(state, newState)) {
        return state;
    }

    // Any folders with negative IDs, they are unsaved local
    // folders, keep them around.
    const minFolderId = findMinimumFolderId(state);
    if (minFolderId >= 0) {
        return newState;
    }

    for (let i = -1; i >= minFolderId; i--) {
        if (folderIdCreatedRecently(i, state)) {
            copyFolderIdToNewState(i, state, newState);
        }
    }
    return newState;
}

function folderIdCreatedRecently(folderId, state) {
    const folder = searchTree(state, folderId);
    if (!folder) {
        return false;
    }
    if (!folder.created_local) {
        return false;
    }
    const timeDiff = (new Date() - folder.created_local) / 1000;
    return timeDiff < 20;
}

function copyFolderIdToNewState(folderId, oldState, newState) {
    const folder = searchTree(oldState, folderId);
    if (!folder) {
        return;
    }

    const parentFolderInOld = searchTreeReturnParent(oldState, folderId);
    if (!parentFolderInOld || !parentFolderInOld.id) {
        return;
    }
    const parentId = parentFolderInOld.id;

    const parentFolderInNew = searchTree(newState, parentId);
    if (!parentFolderInNew) {
        return;
    }
    if (typeof parentFolderInNew != "object") {
        return;
    }

    const folderCopy = clone(folder);
    if (Array.isArray(parentFolderInNew)) {
        parentFolderInNew[0].children.push(folderCopy);
    } else {
        parentFolderInNew.children.push(folderCopy);
    }
}

function createTree(data, parentId = 0, level = 0) {
    const topLevel = data.filter(node => node.ParentId == parentId);
    const nodes = topLevel.map(node => ({
        name: node.Folder,
        id: node.Id,
        userId: node.UserKey,
        order: node.Physical_Order,
        level,
        children: createTree(data, node.Id, level + 1),
        isCompanyShare: node.IsCompanyShare || false,
        isHistorical: node.IsHistorical || false,
        companyId: node.CompanyId,
        folderType: node.Type,
        isInternalShare: node.IsInternalShare || false,
    }));
    return sortNodes(nodes);
}

function sortNodes(nodes) {
    return nodes.sort((x, y) => {
        const a = x["name"];
        const b = y["name"];
        const comparison = a.toLowerCase().localeCompare(b.toLowerCase());
        if (comparison === 0) {
            return a.localeCompare(b);
        }
        return comparison;
    });
}

function cloneState(state) {
    return clone(state);
}

function deleteFolder(state, action) {
    const { folderId } = action;

    // Make sure our folder has no children
    const folder = searchTree(state, folderId);
    if (folder.children.length > 0) {
        return state;
    }

    const newState = cloneState(state);
    searchAndRemoveFromTree(newState, folderId);
    return newState;
}

function createFolder(state, action) {
    const { parentFolderId, companyId, folderType } = action;

    const newState = cloneState(state);
    const parentFolder = searchTree(newState, parentFolderId);

    // I declare all locally created folders will have ids below 0,
    // that way, when we get an ID back from the server, we just find
    // a negative number id and know to replace it.
    const minFolderId = Math.min(findMinimumFolderId(state), 0) - 1;

    // Historical folders will not belong to any one user (userId = 0)
    let userIdSet = action.userId;
    if (parentFolder.isHistorical && parentFolder.isCompanyShare) {
        userIdSet = 0;
    }

    const newFolder = {
        name: action.newFolderName,
        id: minFolderId,
        userId: userIdSet,
        order: 0,
        level: parentFolder.level + 1,
        children: [],
        created_local: new Date(),
        folderType,
        isCompanyShare: parentFolder.isCompanyShare,
        isHistorical: parentFolder.isHistorical,
    };
    if (newFolder.isCompanyShare) {
        newFolder.companyId = companyId;
    }

    parentFolder.children.push(newFolder);
    parentFolder.children = sortNodes(parentFolder.children);

    return newState;
}

export function findMinimumFolderId(state) {
    const allFolderIds = getAllIds(state);
    return Math.min(...allFolderIds);
}

export function getAllIds(node) {
    let ids = [];
    if (!node) {
        return ids;
    }
    if (Array.isArray(node)) {
        for (const nodeChild of node) {
            ids = ids.concat(getAllIds(nodeChild));
        }
    }
    if (node.id) {
        ids.push(node.id);
    }
    if (node.children) {
        for (const child of node.children) {
            ids = ids.concat(getAllIds(child));
        }
    }
    return ids;
}

export function getAllNames(node) {
    let names = [];
    if (!node) {
        return names;
    }
    if (Array.isArray(node)) {
        for (const nodeChild of node) {
            names = names.concat(getAllNames(nodeChild));
        }
    }
    if (node.name) {
        names.push(node.name);
    }
    if (node.children) {
        for (const child of node.children) {
            names = names.concat(getAllNames(child));
        }
    }
    return names;
}

// The server just told us about a newly saved folder
// Find (any) folder with a negative id and change its
// id to the new folder id
// Works because we assume that we will have at most one unsaved folder
function assignNewFolderId(state, action) {
    const { newFolderId } = action;
    const minFolderId = findMinimumFolderId(state);
    if (minFolderId >= 0) {
        return state;
    }

    const newFolderExists = searchTree(state, newFolderId);
    if (newFolderExists) {
        // Edge case - We have already loaded the new assigned folder from the server..
        // Delete the negative one
        const newState = cloneState(state);
        searchAndRemoveFromTree(newState, minFolderId);
        return newState;
    }

    const newState = cloneState(state);
    const unsavedFolder = searchTree(newState, minFolderId);
    unsavedFolder.id = newFolderId;
    return newState;
}

function renameFolder(state, action) {
    const { folderId, folderNewName } = action;

    const newState = cloneState(state);
    const folder = searchTree(newState, folderId);
    folder.name = folderNewName;

    const parentFolder = searchTreeReturnParent(newState, folderId);
    if (parentFolder && parentFolder.children) {
        parentFolder.children = sortNodes(parentFolder.children);
    }

    return newState;
}

function moveFolderToFolder(state, action) {
    const { folderId, parentFolderId } = action;

    const folder = searchTree(state, folderId);
    const parent = searchTree(state, parentFolderId);
    // First, let's check that both folderIds exist in our tree
    // The action might have been emitted for a different type
    if (folder === null || parent === null) {
        return state;
    }

    // Second, let's check that the new parent isn't a current child of the folder we're moving
    // That's invalid.
    if (searchTree(folder, parent.id)) {
        return state;
    }

    // Deep clone of state
    const newState = cloneState(state);

    // Find element and make a copy of it, not attached to tree
    const elementCopy = clone(searchTree(newState, folderId));

    // Delete element from tree
    searchAndRemoveFromTree(newState, folderId);

    // Copy the copied element to new place
    const newParent = searchTree(newState, parentFolderId);
    newParent.children.push(elementCopy);

    return newState;
}

export function searchTree(node, id) {
    if (!node) {
        return null;
    }
    if (Array.isArray(node)) {
        for (const nodeChild of node) {
            const candidate = searchTree(nodeChild, id);
            if (candidate) {
                return candidate;
            }
        }
        return null;
    }
    if (node.id == id) {
        return node;
    }
    if (node.children) {
        for (const child of node.children) {
            const candidate = searchTree(child, id);
            if (candidate) {
                return candidate;
            }
        }
    }
    return null;
}

function searchAndRemoveFromTree(node, id) {
    if (!node) {
        return null;
    }
    if (Array.isArray(node)) {
        for (const nodeChild of node) {
            searchAndRemoveFromTree(nodeChild, id);
        }
        return null;
    }
    if (node.id == id) {
        return node;
    }
    if (node.children) {
        let i = 0;
        for (const child of node.children) {
            const candidate = searchAndRemoveFromTree(child, id);
            if (candidate) {
                node.children.splice(i, 1);
                return null; // Don't send the deletes all the way up
            }
            i++;
        }
    }
    return null;
}

export function searchTreeReturnParent(node, id) {
    if (!node) {
        return null;
    }
    if (Array.isArray(node)) {
        for (const nodeChild of node) {
            const candidate = searchTreeReturnParent(nodeChild, id);
            if (candidate) {
                return candidate === true ? node : candidate;
            }
        }
        return null;
    }
    if (node.id == id) {
        return true;
    }
    if (node.children) {
        for (const child of node.children) {
            const candidate = searchTreeReturnParent(child, id);
            if (candidate) {
                return candidate === true ? node : candidate;
            }
        }
    }
    return null;
}

///// SELECTORS /////
// These aren't really a reducers, just putting it here for now.
import { numberOfFlowsInFolder } from "./flows";
import { numberOfLayoutsInFolder } from "./exportlayouts";
export const allowedToDeleteFolder = (state, folderId, userId, companyId, isInternal = false) => {
    if (!folderId) {
        return false;
    }

    const isSuperAdmin = state.roles != null ? state.roles.includes("Super Admin") : false;

    const folders = state.folders;
    if (!folders) {
        return false;
    }

    const folder = searchTree(folders, folderId);
    if (!folder) {
        return false;
    }

    const hasNoFolders = folder.children.length == 0;
    const hasNoFlows = folder.folderType != "W" || numberOfFlowsInFolder(state, folder.id) == 0;
    const hasNoLayouts = folder.folderType != "L" || numberOfLayoutsInFolder(state, folder.id) == 0;
    const isRoot = folder.level == 0;
    return (
        hasNoFolders &&
        hasNoFlows &&
        hasNoLayouts &&
        ((!isRoot && (folder.userId == userId || isSuperAdmin)) ||
            (folder.isCompanyShare &&
                folder.companyId == companyId &&
                (folder.level > 1 || folder.folderType == "L")) ||
            (folder.isCompanyShare && folder.companyId === 0 && isInternal && folder.level > 0) ||
            (folder.isInternalShare && isInternal && folder.level > 1))
    );
};

/* Am I allowed to create a folder under this parentFolderId? */
export const allowedToCreateFolder = (state, parentFolderId, userId, companyId, isInternal = false) => {
    if (parentFolderId <= 0) {
        return false;
    }
    const folder = searchTree(state, parentFolderId);
    if (!folder) {
        return false;
    }

    if (folder.isCompanyShare && (folder.folderType == "L") & (folder.level == 0) && folder.companyId == 0) {
        return true;
    }

    if (folder.isCompanyShare && folder.level == 0) {
        return false;
    }

    if (folder.isInternalShare && isInternal) {
        return true;
    }

    return (
        folder.userId == userId ||
        (folder.isCompanyShare && folder.companyId == companyId) ||
        (folder.isCompanyShare && folder.companyId === 0 && isInternal)
    );
};

export const allowedToRenameFolder = (state, folderId, userId, companyId, isInternal = false) => {
    if (!folderId) {
        return false;
    }
    const folder = searchTree(state, folderId);
    if (!folder) {
        return false;
    }

    if (folder.isInternalShare && isInternal && folder.level > 1) {
        return true;
    }

    return (
        (folder.level > 0 && folder.userId == userId) ||
        (folder.isCompanyShare && folder.companyId == companyId && (folder.level > 1 || folder.folderType == "L")) ||
        (folder.isCompanyShare && folder.companyId === 0 && isInternal && folder.level > 0)
    );
};

export const pickNewFolderName = state => {
    const allNames = getAllNames(state);
    let candidate = "New Folder";
    if (!allNames.includes(candidate)) {
        return candidate;
    }
    for (let i = 2; i <= 1000; i++) {
        candidate = `New Folder (${i})`;
        if (!allNames.includes(candidate)) {
            return candidate;
        }
    }
    return "New Folder+"; // Should never reach here, is joke
};

export const fixNameIfDuplicate = (state, name, folderId) => {
    const folder = searchTree(state, folderId);
    if (folder.name == name) {
        return name;
    } // Allowed to "rename" a folder to its current name (noop)

    const allNames = getAllNames(state);
    if (!allNames.includes(name)) {
        return name;
    }
    for (let i = 2; i <= 1000; i++) {
        const candidate = `${name} (${i})`;
        if (!allNames.includes(candidate)) {
            return candidate;
        }
    }
    return name; // should never reach here
};

// Reselected version of above
import { folderReducerKeyForType } from "../helpers/folder";
import { createSelector } from "reselect";

export const makeFilterUsersWithNoFlowFolders = () =>
    createSelector(
        (state, props) => {
            const folderReducerKey = folderReducerKeyForType(props.folderType);
            return state.folders[folderReducerKey];
        },
        state => state.flows.byId,
        state => state.session.userId,
        state => state.varsPersist.userNameForId,
        (folderState, flowState, userId) => {
            const flowIds = Object.keys(flowState).filter(x => flowState[x]["FlowIsActive"]);
            return folderState.filter(topLevelFolder => {
                if ([userId].indexOf(topLevelFolder.userId) > -1) {
                    return true;
                } // Always return mine
                if (topLevelFolder.isCompanyShare) {
                    return true;
                } // Always return mine
                return some(
                    flowIds,
                    flowId => flowState[flowId].FlowCreatedBy == topLevelFolder.userId && flowState[flowId].FlowIsActive
                ); // Return if they have at least one aud
            });
        }
    );

export const makeFilterUsersWithNoLayoutFolders = () =>
    createSelector(
        (state, props) => {
            const folderReducerKey = folderReducerKeyForType(props.folderType);
            return state.folders[folderReducerKey];
        },
        state => state.layouts,
        state => state.session.userId,
        (folderState, layoutState, userId) => {
            const layoutIds = Object.keys(layoutState).filter(x => layoutState[x]["LayoutActive"] == "Y");
            return folderState.filter(topLevelFolder => {
                if ([userId].indexOf(topLevelFolder.userId) > -1) {
                    return true;
                } // Always return mine
                if (topLevelFolder.isCompanyShare) {
                    return true;
                } // Always return mine
                return some(
                    layoutIds,
                    layoutId =>
                        layoutState[layoutId].LayoutCreatedBy == topLevelFolder.userId &&
                        layoutState[layoutId].LayoutActive == "Y"
                ); // Return if they have at least one aud
            });
        }
    );

export const findCompanySharedFolder = (node, companyId, folderType) => {
    // Issue 378 -> added folderType parameter
    if (!node) {
        return null;
    }
    if (Array.isArray(node)) {
        for (const nodeChild of node) {
            const candidate = findCompanySharedFolder(nodeChild, companyId, folderType);
            if (candidate) {
                return candidate;
            }
        }
        return null;
    }
    if (node.isCompanyShare && node.companyId == companyId && node.folderType == folderType && node.level <= 1) {
        return node;
    }
    if (node.children) {
        for (const child of node.children) {
            const candidate = findCompanySharedFolder(child, companyId, folderType);
            if (candidate) {
                return candidate;
            }
        }
    }
    return null;
};

export const findFolderById = (node, folderId) => {
    if (!node) {
        return null;
    }
    if (Array.isArray(node)) {
        for (const nodeChild of node) {
            const candidate = findFolderById(nodeChild, folderId);
            if (candidate) {
                return candidate;
            }
        }
        return null;
    }
    if (node.id == folderId) {
        return node;
    }
    if (node.children) {
        for (const child of node.children) {
            const candidate = findFolderById(child, folderId);
            if (candidate) {
                return candidate;
            }
        }
    }
    return null;
};

export const findUserHistoricalFolder = (companyHistoricalFolder, userName, userId) => {
    if (!companyHistoricalFolder) {
        return null;
    }
    if (Array.isArray(companyHistoricalFolder.children)) {
        for (const node of companyHistoricalFolder.children) {
            if (node.isHistorical && node.name == userName && node.userId == userId) {
                return node;
            }
        }
        return null;
    }

    return null;
};

export const findUserCompanyPersonalFolder = (node, userId, companyId) => {
    if (!node) {
        return null;
    }
    if (Array.isArray(node)) {
        for (const nodeChild of node) {
            const candidate = findUserCompanyPersonalFolder(nodeChild, userId, companyId);
            if (candidate) {
                return candidate;
            }
        }
        return null;
    }
    if (!node.isCompanyShare && node.companyId == companyId && node.userId == userId && node.level == 0) {
        return node;
    }
    if (node.children) {
        for (const child of node.children) {
            const candidate = findUserCompanyPersonalFolder(child, userId, companyId);
            if (candidate) {
                return candidate;
            }
        }
    }
    return null;
};

export const findInternalSharedFolder = (node, folderType) => {
    // Issue 378 -> added folderType parameter
    if (!node) {
        return null;
    }
    if (Array.isArray(node)) {
        for (const nodeChild of node) {
            const candidate = findInternalSharedFolder(nodeChild, folderType);
            if (candidate) {
                return candidate;
            }
        }
        return null;
    }
    if (node.isInternalShare && node.folderType == folderType && node.userId == 0) {
        return node;
    }
    if (node.children) {
        for (const child of node.children) {
            const candidate = findInternalSharedFolder(child, folderType);
            if (candidate) {
                return candidate;
            }
        }
    }
    return null;
};

// returns array of folder ids, including folderId and all of its children (recursively)
export const findAllChildFolderIds = (folders, folderId) => {
    const node = searchTree(folders, folderId);
    return getAllIds(node);
};

export const folderMatchesSearchString = (node, searchString) => {
    if (!node || !node.name) {
        return false;
    }
    return haystackMatchesSearchObject(node.name, searchString);
};
