import React from "react";
import { IField } from "../../types/stores/fieldTypes";

type Props = { field: IField };

const FieldIcon: React.FC<Props> = ({ field }: Props) => {
    let companyIdToUse = field.companyid;
    if (field.companyid == 0) {
        companyIdToUse = 1;
    }
    return <img height={24} style={{ marginTop: "-2px" }} src={"/Images/CompanyFavIcon/" + companyIdToUse} />;
};

export default FieldIcon;
