import _ from "lodash";
export const fieldsHaveNeedsApprovalLabels = (
    fieldIds: Array<number>,
    needsApprovalLabelIds: Array<number>,
    enabledFieldLabels: { number: [number] },
    isInternal: boolean
): boolean => {
    const uniqueFieldIds = [...new Set(fieldIds.map(x => x))];

    // If the user is internal, approval is not required.
    if (isInternal) {
        return false;
    }
    // If there are no approval labels, approval is not required.
    if (needsApprovalLabelIds == null || needsApprovalLabelIds.length == 0) {
        return false;
    }

    const approvalField = uniqueFieldIds.filter(
        x => enabledFieldLabels[x] && _.intersection(enabledFieldLabels[x], needsApprovalLabelIds).length > 0
    );
    return approvalField.length > 0;
};
