import { request } from "../helpers/httpInterceptor";
import h from "../helpers";
import { toJson } from "../helpers/typedHelpers";
import Moment from "moment";
import { Dispatch } from "redux";

export const UPDATE_USER_NOTIFICATIONS_COUNT = "UPDATE_USER_NOTIFICATIONS_COUNT";
export const CLEAR_USER_NOTIFICATIONS_COUNT = "CLEAR_USER_NOTIFICATIONS_COUNT";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";

const defaultDate = Moment().subtract(1, "days").startOf("day").utc().format();

export const getNotifications =
    (date = defaultDate) =>
    (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: GET_NOTIFICATIONS, loading: true, userNotifications: [] });
        return fetch(`/api/notifications`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify({
                Date: date,
            }),
        })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const response = data as unknown as { success: boolean; notifications: Array<any> };
                if (response.success) {
                    dispatch({
                        type: GET_NOTIFICATIONS,
                        userNotifications: response.notifications,
                        loading: false,
                    });
                }
            })
            .catch(error => {
                h.error("Error fetching your notifications.", error);
            });
    };

export const GetNotificationsCount =
    () =>
    (dispatch: Dispatch): void =>
        request(
            `/api/notifications/count`,
            {
                method: "GET",
                credentials: "same-origin",
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(toJson)
            .then(data => {
                if (data.success) {
                    dispatch({ type: UPDATE_USER_NOTIFICATIONS_COUNT, count: data.count });
                }
            })
            .catch(error => {
                throw new Error(error);
            });

export const ClearUnreadNotifications =
    () =>
    (dispatch: Dispatch): void =>
        request(
            `/api/notifications/read`,
            {
                method: "POST",
                credentials: "same-origin",
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(toJson)
            .then(data => {
                if (data.success) {
                    dispatch({ type: CLEAR_USER_NOTIFICATIONS_COUNT });
                }
            })
            .catch(error => {
                throw new Error(error);
            });
