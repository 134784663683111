import update from "immutability-helper";

const defaultState = {
    destinationPartners: {},
    destinationOffers: {},
    destinationVariables: {},
    isLoading: false,
    idTypes: [],
};

export const SET_FREEWHEEL_LOADING = "SET_FREEWHEEL_LOADING";
export const SET_FREEWHEEL_ID_TYPES = "SET_FREEWHEEL_ID_TYPES";
export const SET_FREEWHEEL_DRIVER_FILE_FIELDS = "SET_FREEWHEEL_DRIVER_FILE_FIELDS";
export const UPDATE_DESTINATION_PARTNERS = "UPDATE_DESTINATION_PARTNERS";
export const UPDATE_DESTINATION_OFFERS = "UPDATE_DESTINATION_OFFERS";
export const UPDATE_DESTINATION_VARIABLES = "UPDATE_DESTINATION_VARIABLES";

//reducer for flow-export-distribution-platforms
function flowExportObjects(state = defaultState, action) {
    switch (action.type) {
        case UPDATE_DESTINATION_PARTNERS: {
            return update(state, {
                destinationPartners: {
                    [action.destinationId]: { $set: action.destinationPartners },
                },
            });
        }
        case UPDATE_DESTINATION_OFFERS: {
            return update(state, {
                destinationOffers: {
                    [action.destinationId]: { $set: action.destinationOffers },
                },
            });
        }
        case UPDATE_DESTINATION_VARIABLES: {
            return update(state, {
                destinationVariables: {
                    [action.destinationId]: { $set: action.destinationVariables },
                },
            });
        }
        case SET_FREEWHEEL_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case SET_FREEWHEEL_ID_TYPES:
            return Object.assign({}, state, {
                idTypes: action.idTypes,
            });
        default:
            return state;
    }
}
export default flowExportObjects;
