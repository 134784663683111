import update from "immutability-helper";
import { Notification, NotificationActions } from "../types/stores/notifications";

export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";

function notifications(state: Array<Notification> = [], action: NotificationActions): Array<Notification> {
    switch (action.type) {
        case ADD_NOTIFICATION:
            return [
                ...state,
                {
                    id: action.id,
                    text: action.text,
                    width: action.width,
                    className: action.className,
                    delay: action.delay,
                    dismissed: false,
                    groupName: action.groupName || "",
                    groupArgsList: action.groupArgsList || [],
                    componentName: action.componentName,
                    variant: action.variant || "info",
                },
            ];
        case UPDATE_NOTIFICATION: {
            const i = state.findIndex(x => x.id == action.id);
            if (i == -1) {
                return state;
            }
            return update(state, {
                [i]: {
                    $merge: action.updates,
                },
            });
        }
        case DISMISS_NOTIFICATION:
            return state.map(nfc => (nfc.id === action.id ? { ...nfc, dismissed: true } : nfc));
        default:
            return state;
    }
}

export default notifications;
