const defaultState = {};

function dataLoadItems(state = defaultState, action) {
    switch (action.type) {
        case "SET_TABLE_COLUMNS": {
            return { ...state, tableColumns: { name: action.tableName, columns: action.columns } };
        }
        case "SET_TABLES": {
            return { ...state, tables: action.tables };
        }
        case "SET_CROSSWALK_COLUMNS": {
            return { ...state, crosswalkColumns: action.columns };
        }
        default:
            return state;
    }
}
export default dataLoadItems;
