import React, { useState } from "react";
import MaterialModal from "./MaterialModal";
import { ReactNodeLike } from "prop-types";

type GenericConfirmModalProps = {
    body: string | ReactNodeLike;
    buttonText: string | JSX.Element;
    cancelText?: string;
    confirmText: string;
    onConfirm: () => void;
    title: string | JSX.Element;
    cnBtnStyle: string;
    buttonDisabled: boolean;
};

const GenericConfirmModal: React.FC<GenericConfirmModalProps> = ({
    title,
    body,
    buttonText,
    onConfirm,
    confirmText = "Confirm",
    cancelText = "Cancel",
    cnBtnStyle = "",
    buttonDisabled = false,
}: GenericConfirmModalProps) => {
    const [isOpen, setOpen] = useState(false);

    const onButtonClick = () => setOpen(true);

    const onClose = e => {
        setOpen(false);
        if (e) {
            e.stopPropagation();
        }
    };

    const confirm = e => {
        onConfirm && onConfirm();
        onClose(e);
    };

    const modal = (
        <MaterialModal
            id="generic-confirm-modal"
            isOpen={isOpen}
            title={title}
            showPrimaryButton={true}
            primaryButtonText={confirmText || ""}
            onPrimaryButtonRequest={confirm}
            onClose={e => onClose(e)}
            cancelButtonText={cancelText || ""}
        >
            {body}
        </MaterialModal>
    );

    return (
        <button
            className={cnBtnStyle || ""}
            disabled={Boolean(buttonDisabled)}
            onClick={!isOpen ? onButtonClick : onClose}
            onMouseDown={e => e.preventDefault()}
        >
            {buttonText}
            {modal}
        </button>
    );
};

export default GenericConfirmModal;
