import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import createRootReducer from "./reducers/index";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

const defaultState = {
    /*
    fields: {},
    modals:
    userId:
    companyId:
    */
};

// Figure out if we're on a single-page-app
const createHistoryOptions = {};
const pathName = window.location && window.location.pathname ? window.location.pathname : "";
if (pathName.toLowerCase() == "/de" || pathName.match(/^\/de\//i)) {
    createHistoryOptions["basename"] = "/de";
}
if (pathName.toLowerCase() == "/ae" || pathName.match(/^\/ae\//i)) {
    createHistoryOptions["basename"] = "/ae";
}

const history = createBrowserHistory(createHistoryOptions);
export { history };

import { createEpicMiddleware } from "redux-observable";
import rootEpic from "./actions/epics/index";
const epicMiddleware = createEpicMiddleware();

// Batch store notifications on request-animation-frame see https://github.com/tappleby/redux-batched-subscribe/issues/24
import batchedSubscribeMiddleware from "./batching/middleware";
import batchedSubscribeEnhancer from "./batching/enhancer";

// Add debounce redux subscription notifications [middleware]
const middleware = [batchedSubscribeMiddleware, thunk, epicMiddleware, routerMiddleware(history)];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Uncomment the next two lines to log all actions to console
//import logger from "redux-logger";
//middleware.push(logger);

// Localstorage via redux-persist and localforage

// Using debounced subscription notifications (enhancer)
const store = createStore(
    createRootReducer(history),
    defaultState,
    composeEnhancers(batchedSubscribeEnhancer, applyMiddleware(...middleware))
);

// Attach epics
epicMiddleware.run(rootEpic);

// This is a bit of a hack to load session information (IE, am I logged in) as soon as possible
// before apps start rendering even
// Needs this in the layout file:
// top.SessionInfo = @Html.Raw(Json.Encode( OmniActivation.Lib.Util.AllSessionInformation() ));
import { setSessionInfo } from "./actions/sessionActions";
if (top.SessionInfo) {
    store.dispatch(setSessionInfo(top.SessionInfo));
}

// DataEngine: Always turn on 'tab navigation'
import { setVar } from "./actions/actionCreators";
store.dispatch(setVar("dataEngineUsesTabNav", true));

// Channels2: Always turn on splash page on first load
store.dispatch(setVar("isShowingChannels2Splash", true));

/* eslint-disable no-undef */
if (process != null && process.env.NODE_ENV == "development" && module.hot) {
    module.hot.accept("./reducers/index", () => {
        const newCreateRootReducer = require("./reducers/index").default;
        store.replaceReducer(newCreateRootReducer(history));
    });
}
/* eslint-enable no-undef */

export default store;
