import { combineReducers } from "@reduxjs/toolkit";
import { Reducer } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";

import automation from "./automation";
import selected from "./selected";
import fields from "./fields";
import filters from "./filters";
import layouts from "./exportlayouts";
import layoutObjects from "./exportlayoutobjects";
import layoutfields from "./layoutfields";
import reportFields from "./reportFields";
import reports from "./reports";
import folders from "./folders";
import makeAWish from "./makeAWish";
import modals from "./modals";
import notifications from "./notifications";
import vars from "./vars";
import varsPersist from "./varsPersist";
import schedules from "./schedules";
import search from "./search";
import session from "./session";
import crosstab from "./crosstab";
import distributionList from "./distributionList";
import features from "./features";
import clientVariables from "./clientVariables";
import sources from "./sources";
import accessList from "./accessList";
import flows from "./flows";
import flowCannedReports from "./flowCannedReports";
import flowCannedReportColumns from "./flowCannedReports";
import flowCases from "./flowCases";
import flowClientVariables from "./flowClientVariables";
import flowCloud from "./flowCloud";
import flowDeploymentQueue from "./flowDeploymentQueue";
import countLog from "./flowCountLog";
import flowEmpties from "./flowEmpties";
import flowExportReports from "./flowExportReports";
import flowExports from "./flowExports";
import flowOutputs from "./flowOutputs";
import flowFilters from "./flowFilters";
import flowScripts from "./flowScripts";
import flowScriptsDBUI from "./flowScriptsDBUI";
import flowScriptResults from "./flowScriptResults";
import flowScriptResultsHistory from "./flowScriptResultsHistory";
import flowDataLoads from "./flowDataLoads";
import flowDataLoadColumns from "./flowDataLoadColumns";
import flowFromClouds from "./flowFromClouds";
import flowItemClientVariables from "./flowItemClientVariables";
import flowItems from "./flowItems";
import flowMerges from "./flowMerges";
import flowOfferMerges from "./flowOfferMerges";
import flowRelations from "./flowRelations";
import flowReports from "./flowReports";
import flowModels from "./flowModels";
import flowSingleViews from "./flowSingleViews";
import flowSplits from "./flowSplits";
import flowSVDedupes from "./flowSVDedupes";
import flowToClouds from "./flowToClouds";
import flowVars from "./flowVars";
import flowItemEndpoints from "./flowItemEndpoints";
import flowItemOfferCodes from "./flowItemOfferCodes";
import flowOffloads from "./flowOffloads";
import flowOffloadColumns from "./flowOffloadColumns";
import flowGuideSettings from "./flowGuideSettings";
import flowSegmentSplits from "./flowSegmentSplits";
import flowSegmentSplitOffers from "./flowSegmentSplitOffers";
import userAdmin from "./userAdmin";
import users from "./users";
import companies from "./companies";
import userCompanies from "./userCompanies";
import roles from "./roles";
import sites from "./sites";
import companySeats from "./companySeats";
import flowExportObjects from "./flowExportObjects";
import flowExportDistributionPlatforms from "./flowExportDistributionPlatforms";
import flowExportTemplateFields from "./flowExportTemplateFields";
import flowExportPinterestTemplateFields from "./flowExportPinterestTemplateFields";
import flowExportTikTokTemplateFields from "./flowExportTikTokTemplateFields";
import flowExportTradeDeskTemplateFields from "./flowExportTradeDeskTemplateFields";
import dataLoadItems from "./dataLoadItems";
import loadingStates from "./loadingStates";
import flowRelationParentLabels from "./flowRelationParentLabels";
import userNotifications from "./userNotifications";
import fieldLabels from "./fieldLabels";
import fieldLabels3 from "./fieldLabels3";
import accountManage from "./accountManage";
import cachedData from "./cachedData";
import resetPassword from "./resetPassword";
import forgotPassword from "./forgotPassword";
import flowDesigner from "./flowDesigner";
import flowItemEdit from "./flowItemEdit";
import dashboardWidgets from "./dashboardWidgets";
import imageControl from "./imageControl";
import flowMultiSelection from "./flowMultiSelection";
import fileImport from "./fileImport";
import layoutsDesigner from "./layoutsDesigner";
import adminCompanies from "./adminCompanies";
import activationSchedules from "./activationSchedules";
import joinEdit from "./joinEdit";
import adminDesigner from "./adminDesigner";
import treeBehaviors from "./treeBehaviors";
import companyEdit from "./companyEdit";
import appSettings from "./appSettings";
import destinationGroupingParents from "./destinationGroupingParents";
import wiki from "./wiki";
import flowScriptKeywords from "./flowScriptKeywords";
import dataConnect from "./dataConnect";
import fieldsByCompany from "./fieldsByCompany";
import flowItemResultTables from "./flowItemResultTables";
import aws from "./aws";
import nas from "./nas";
import fileImportQueue from "./fileImportQueue";
import tableau from "./tableau";
import discoverySegment from "./discoverySegment";
import flowControlData from "./flowControlData";
import flowControlStatus from "./flowControlStatus";
import flowExternalServices from "./flowExternalServices";
import flowExternalServiceParameters from "./flowExternalServiceParameters";
import flowExternalServiceInputs from "./flowExternalServiceInputs";
import flowExternalServiceHardcodes from "./flowExternalServiceHardcodes";
import fileUploadSettings from "./fileUploadSettings";
import externalServiceLayoutData from "./externalServiceLayoutData";
import workflowClientData from "./workflowClient";
import workflowBillingData from "./workflowBillingData";
import umbrellaData from "./umbrellaData";
import flowExpressions from "./flowExpressions";
import flowExpressionConstraints from "./flowExpressionConstraints";
import logMessage from "./logMessage";
import flowExportFreewheelDriverFileFields from "./freewheel";
import flowExportTaxonomyFileFields from "./taxonomyLayout";
import flowExportXandrDriverFields from "./flowExportXandrDriverFields";
import deploymentDestination from "./deploymentDestination";
import cannedReportDetail from "./flowCannedReportDetail";
import flowDescriptions from "./flowDescriptions";
import reassignedItemsTo from "./reassignedItemsTo";
import snowflakeInstanceData from "./snowflakeInstanceData";
import updateExperianAssets from "./updateExperianAssets";
import featuresAuditLog from "./featuresAuditLog";
import login from "./login";
import flowMultiExports from "./flowMultiExports";
import xandrRateCategories from "./xandrRateCategories";
import saaAdvertisers from "./saaAdvertisers";
import flowItemDescriptions from "./flowItemDescriptions";
import { IAppState } from "../types/stores";

// *******************************************************************************
// ***** Please put reducers in alphabetical order
// *******************************************************************************
export default (history: History<any>): Reducer<IAppState | any> =>
    combineReducers({
        accessList,
        accountManage,
        activationSchedules,
        adminCompanies,
        adminDesigner,
        appSettings,
        destinationGroupingParents,
        automation,
        aws,
        nas,
        cachedData,
        cannedReportDetail,
        clientVariables,
        companies,
        companyEdit,
        companySeats,
        countLog,
        crosstab,
        dashboardWidgets,
        dataConnect,
        dataLoadItems,
        deploymentDestination,
        discoverySegment,
        distributionList,
        externalServiceLayoutData,
        features,
        fieldLabels,
        fieldLabels3,
        fields,
        fieldsByCompany,
        fileImport,
        fileImportQueue,
        fileUploadSettings,
        filters,
        flowCannedReports,
        flowCannedReportColumns,
        flowCases,
        flowClientVariables,
        flowCloud,
        flowControlData,
        flowControlStatus,
        flowDataLoadColumns,
        flowDataLoads,
        flowDeploymentQueue,
        flowDescriptions,
        flowDesigner,
        flowEmpties,
        flowExportDistributionPlatforms,
        flowExportFreewheelDriverFileFields,
        flowExportTaxonomyFileFields,
        flowExportObjects,
        flowExportPinterestTemplateFields,
        flowExportTikTokTemplateFields,
        flowExportReports,
        flowExports,
        flowExportTemplateFields,
        flowExportTradeDeskTemplateFields,
        flowExportXandrDriverFields,
        flowExpressionConstraints,
        flowExpressions,
        flowExternalServiceHardcodes,
        flowExternalServiceInputs,
        flowExternalServiceParameters,
        flowExternalServices,
        flowFilters,
        flowFromClouds,
        flowGuideSettings,
        flowItemClientVariables,
        flowItemDescriptions,
        flowItemEdit,
        flowItemEndpoints,
        flowItemOfferCodes,
        flowItemResultTables,
        flowItems,
        flowMerges,
        flowModels,
        flowMultiExports,
        flowMultiSelection,
        flowOfferMerges,
        flowOffloadColumns,
        flowOffloads,
        flowOutputs,
        flowRelationParentLabels,
        flowRelations,
        flowReports,
        flows,
        flowScriptKeywords,
        flowScriptResults,
        flowScriptResultsHistory,
        flowScripts,
        flowScriptsDBUI,
        flowSegmentSplitOffers,
        flowSegmentSplits,
        flowSingleViews,
        flowSplits,
        flowSVDedupes,
        flowToClouds,
        flowVars,
        folders,
        forgotPassword,
        imageControl,
        joinEdit,
        layoutfields,
        layoutObjects,
        layouts,
        layoutsDesigner,
        loadingStates,
        login,
        logMessage,
        makeAWish,
        modals,
        notifications,
        reassignedItemsTo,
        reportFields,
        reports,
        resetPassword,
        roles,
        router: connectRouter(history),
        saaAdvertisers,
        schedules,
        search,
        selected,
        session,
        sites,
        snowflakeInstanceData,
        sources,
        tableau,
        treeBehaviors,
        umbrellaData,
        updateExperianAssets,
        featuresAuditLog,
        userAdmin,
        userCompanies,
        userNotifications,
        users,
        vars,
        varsPersist,
        wiki,
        workflowBillingData,
        workflowClientData,
        xandrRateCategories,
    });
