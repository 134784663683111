import update from "immutability-helper";

function sources(state = {}, action) {
    switch (action.type) {
        case "UPDATE_JSSOURCE_LOAD_STATUS": {
            if (!action.source || !action.status) {
                return state;
            }
            return update(state, {
                [action.source]: { $set: action.status },
            });
        }
        default:
            return state;
    }
}

export default sources;
