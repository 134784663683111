import { IQueueFile, IFileChunk } from "../stores/fileImportQueue";

// need to create the constants outside of the ActionTypes object
// if not, can't use typeof in the action types
export const ADD_TO_QUEUE = "ADD_TO_QUEUE";
export const SET_FILE_CHUNKS = "SET_FILE_CHUNKS";
export const UPDATE_FILE_CHUNK = "UPDATE_FILE_CHUNK";
export const UPDATE_ACTIVE_CONNECTIONS = "UPDATE_ACTIVE_CONNECTIONS";
export const INC_ACTIVE_CONNECTIONS = "INC_ACTIVE_CONNECTIONS";
export const DEC_ACTIVE_CONNECTIONS = "DEC_ACTIVE_CONNECTIONS";
export const UPDATE_FILE_REQUESTS_FAILED = "UPDATE_FILE_REQUESTS_FAILED";
export const DELETE_FROM_QUEUE = "DELETE_FROM_QUEUE";

export type AddFileToQueue = {
    type: typeof ADD_TO_QUEUE;
    file: IQueueFile;
};

export type SetFileChunks = {
    type: typeof SET_FILE_CHUNKS;
    guid: string;
    chunks: Array<IFileChunk>;
};

export type UpdateFileChunk = {
    type: typeof UPDATE_FILE_CHUNK;
    guid: string;
    newChunk: IFileChunk;
};

export type UpdateActiveConnections = {
    type: typeof UPDATE_ACTIVE_CONNECTIONS;
    activeConnections: number;
};

export type IncActiveConnections = {
    type: typeof INC_ACTIVE_CONNECTIONS;
};

export type DecActiveConnections = {
    type: typeof DEC_ACTIVE_CONNECTIONS;
};

export type UpdateFileRequestsFailed = {
    type: typeof UPDATE_FILE_REQUESTS_FAILED;
    guid: string;
    requestsFailed: number;
};

export type DeleteFromQueue = {
    type: typeof DELETE_FROM_QUEUE;
    guid: string;
};

export type ActionType =
    | AddFileToQueue
    | SetFileChunks
    | UpdateFileChunk
    | UpdateActiveConnections
    | IncActiveConnections
    | DecActiveConnections
    | UpdateFileRequestsFailed
    | DeleteFromQueue;
