import { IFileUploadSetting } from "./fileUploadSettings";

export const PENDING = 1;
export const IN_PROGRESS = 2;
export const COMPLETED = 3;
export const ERROR = 4;

export enum FILE_UPLOAD_STATUS {
    PENDING = 1,
    IN_PROGRESS = 2,
    COMPLETED = 3,
    ERROR = 4,
}

export type IChunkStatus = FILE_UPLOAD_STATUS;

export interface IFileImportQueueStore {
    threadLimit: number;
    activeConnections: number;
    files: Array<IQueueFile>;
    fileUploadSetting: IFileUploadSetting | null;
}

export interface IQueueFile {
    guid: string;
    size: number;
    fileName: string;
    importName: string;
    requestsFailed: number;
    chunks: Array<IFileChunk>;
}

export type IFileChunk = {
    chunkNumber: number;
    chunkStatus: IChunkStatus;
};

export interface IFileMasterLogEntry {
    Action: string;
    DisplayStatus: string;
    FileId: number;
    FileName: string;
    FolderName: string;
    PercentComplete?: string | number;
    RecordCount: number;
    SourceId?: number;
    TableKey: number;
    UploadedOn: string;
    UploaderName: string;
}
