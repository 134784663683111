import ofilter from "../helpers/ofilter";
import { uniqArray } from "../helpers/typedHelpers";
import update from "immutability-helper";
import { combineReducers } from "redux";

// We need a reducer to process the FLOW_ITEM_ID_CHANGE inside FlowSubItems (filter, case, merge, etc..).
// Any rows that refer to the old FlowItemId need to be updated as well.
//
// We can also use this for any other logic to be shared between all the FlowSubItems (filter, case, merge, etc...)
const flowSubItemsById = (state = {}, action) => {
    switch (action.type) {
        case "FLOW_ITEM_ID_CHANGE": {
            const updates = {};
            let madeAnUpdate = false;
            for (const idChange of action.idChanges) {
                if (idChange == null || !Array.isArray(idChange) || idChange.length < 2) {
                    console.warn(`$FLOW_ITEM_ID_CHANGE: idChange is of incorrect form`); // eslint-disable-line no-console
                    continue;
                }
                const oldKey = idChange[0];
                const newKey = idChange[1];

                // Look for any flowItems belonging to the oldFlowId
                const updateKeysItem = Object.keys(ofilter(v => v.FlowItemId == oldKey, state));
                const updateKeysParent = Object.keys(ofilter(v => v.ParentFlowItemId == oldKey, state));
                const updateKeysChild = Object.keys(ofilter(v => v.ChildFlowItemId == oldKey, state));
                const allUpdateKeys = uniqArray(updateKeysItem.concat(updateKeysParent).concat(updateKeysChild));

                if (allUpdateKeys.length == 0) {
                    continue;
                }

                for (const key of allUpdateKeys) {
                    madeAnUpdate = true;
                    let myUpdates = updates[key] != null ? updates[key] : {};

                    if (updateKeysItem.includes(key)) {
                        myUpdates.FlowItemId = { $set: newKey };
                    }
                    if (updateKeysParent.includes(key)) {
                        myUpdates.ParentFlowItemId = { $set: newKey };
                    }
                    if (updateKeysChild.includes(key)) {
                        myUpdates.ChildFlowItemId = { $set: newKey };
                    }

                    updates[key] = myUpdates;
                }
            }
            return madeAnUpdate ? update(state, updates) : state;
        }
        default:
            return state;
    }
};
const subItemReducer = combineReducers({
    byId: flowSubItemsById,
});

export default subItemReducer;
