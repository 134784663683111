import { Dispatch } from "redux";
import { FlowDescription } from "../types/stores/flowTypes";
import { addThing } from "./flowActions";

export const newFlowDescription =
    (flowItemId: number) =>
    (dispatch: Dispatch): void => {
        const thing: FlowDescription = {
            FlowDescriptionId: 0,
            FlowItemId: flowItemId,
            Description: "",
        };

        dispatch(addThing("flowDescriptions", thing));
    };
