// States for data connection
import {
    ADD_TO_DATACONNECT_SELECTED_FIELDS,
    REMOVE_FROM_DATACONNECT_SELECTED_FIELDS,
    REMOVE_ALL_FROM_DATACONNECT_SELECTED_FIELDS,
    ADD_TO_DATACONNECT_SELECTED_FIELDNAMES,
    REMOVE_FROM_DATACONNECT_SELECTED_FIELDNAMES,
    REMOVE_ALL_FROM_DATACONNECT_SELECTED_FIELDNAMES,
    SET_SELECTED_DATACONNECT_FIELD_ID,
    SET_SELECTED_DATACONNECT_FIELD_NAME,
    SET_QUICKSTART_MODEL,
    SET_PRESELECTED_FILTERS,
} from "../actions/dataConnectActions";

function dataConnect(
    state = {
        selectedFieldId: null,
        selectedFields: [],
        selectedFieldName: null,
        selectedFieldNames: [],
        model: null,
        preselectedFilters: [],
    },
    action
) {
    switch (action.type) {
        case ADD_TO_DATACONNECT_SELECTED_FIELDS:
            return {
                ...state,
                selectedFields: [...new Set(state.selectedFields.concat(action.fieldIdToAdd))],
            };
        case ADD_TO_DATACONNECT_SELECTED_FIELDNAMES:
            return {
                ...state,
                selectedFieldNames: [...new Set(state.selectedFieldNames.concat(action.fieldNameToAdd))],
            };
        case REMOVE_FROM_DATACONNECT_SELECTED_FIELDS:
            // Might need to separate for array and single removal?
            if (Array.isArray(action.fieldIdToRemove)) {
                // Array removal
                return {
                    ...state,
                    selectedFields: state.selectedFields.filter(item => !action.fieldIdToRemove.includes(item)),
                };
            } else {
                // Single removal
                return {
                    ...state,
                    selectedFields: state.selectedFields.filter(fieldId => fieldId !== action.fieldIdToRemove),
                };
            }
        case REMOVE_FROM_DATACONNECT_SELECTED_FIELDNAMES:
            // Might need to separate for array and single removal?
            if (Array.isArray(action.fieldNameToRemove)) {
                // Array removal
                return {
                    ...state,
                    selectedFieldNames: state.selectedFieldNames.filter(
                        item => !action.fieldNameToRemove.includes(item)
                    ),
                };
            } else {
                // Single removal
                return {
                    ...state,
                    selectedFieldNames: state.selectedFieldNames.filter(
                        fieldName => fieldName !== action.fieldNameToRemove
                    ),
                };
            }
        case REMOVE_ALL_FROM_DATACONNECT_SELECTED_FIELDS:
            return {
                ...state,
                selectedFields: [],
            };
        case REMOVE_ALL_FROM_DATACONNECT_SELECTED_FIELDNAMES:
            return {
                ...state,
                selectedFieldNames: [],
            };
        case SET_SELECTED_DATACONNECT_FIELD_ID: {
            return Object.assign({}, state, {
                selectedFieldId: action.fieldId,
            });
        }
        case SET_SELECTED_DATACONNECT_FIELD_NAME: {
            return Object.assign({}, state, {
                selectedFieldName: action.fieldName,
            });
        }
        case SET_QUICKSTART_MODEL: {
            return Object.assign({}, state, {
                model: action.model,
            });
        }
        case SET_PRESELECTED_FILTERS: {
            return Object.assign({}, state, {
                preselectedFilters: action.preselectedFilters,
            });
        }
        default:
            return state;
    }
}
export default dataConnect;
