import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import type { User } from "../types/types";

const myGenericReducer = makeReducerFor("USER", "Id");
export default myGenericReducer;

export const getArrayOfAllUsers = createSelector(
    state => state.users.byId,
    (usersById: { [number]: User }): Array<User> => Object.values(usersById)
);
