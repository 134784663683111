export const LOAD_ENABLED_FIELD_LABELS = "LOAD_ENABLED_FIELD_LABELS";
export const LOAD_ENABLED_FIELD_LABELS3 = "LOAD_ENABLED_FIELD_LABELS3";
export const LOAD_ENABLED_FIELD_VISIBILITY_TYPES = "LOAD_ENABLED_FIELD_VISIBILITY_TYPES";
export const LOAD_VISIBILITY_COMPANY_USERS = "LOAD_VISIBILITY_COMPANY_USERS";
export const LOAD_ENABLED_FIELD_COMPANIES = "LOAD_ENABLED_FIELD_COMPANIES";
export const LOAD_FIELD_RATES = "LOAD_FIELD_RATES";
export const LOAD_TAXMAN_FIELD_RATES = "LOAD_TAXMAN_FIELD_RATES";
export const LOAD_DISTRIBUTION_COUNTS = "LOAD_DISTRIBUTION_COUNTS";

export type FieldRate = {
    FieldRateId: number;
    FieldUniqueKey: number;
    UmbrellaId: number;
    CPM: number;
    CPMCapTTD: number;
    PercentOfMedia: number;
    PercentOfMediaTTD: number;
};

export type TaxmanFieldRate = {
    FieldRateId: number;
    FieldUniqueKey: number;
    CPM: number;
    CPMCapTTD: number;
    PercentOfMedia: number;
    PercentOfMediaTTD: number;
};

export type DistributionCount = {
    FieldUniqueKey: number;
    Fieldcount: number;
};

/// type for representing a state object that contains fieldids as the key, and an array of ids as the value.
/// for example enabledFieldLables => { fieldId: [labelId1, labelId2...]}, enabledFieldVisibilityTypes => { fieldId: [visibilityId1, visibilityId2 ]}
export type ItemIdsByFieldId = {
    [key: number]: Array<number>;
};

/// state that holds an object with the fieldId as the key and the field rate object as the value
export type FieldRateByFieldId = {
    [key: number]: FieldRate;
};

export type TaxmanFieldRateByFieldId = {
    [key: number]: TaxmanFieldRate;
};

// fieldDistributionCounts
export type FieldDistributionCountsById = {
    [key: number]: DistributionCount;
};

export type IFieldsByCompanyStore = {
    enabledFieldLabels: ItemIdsByFieldId;
    enabledFieldLabels3: ItemIdsByFieldId;
    enabledFieldVisibilityTypes: ItemIdsByFieldId;
    visibilityByCompanyUsers: ItemIdsByFieldId;
    enabledFieldCompanies: ItemIdsByFieldId;
    fieldRates: FieldRateByFieldId;
    taxmanFieldRates: TaxmanFieldRateByFieldId;
    fieldDistributionCounts: FieldDistributionCountsById;
};

export type LoadEnabledFieldLabelsAction = {
    type: typeof LOAD_ENABLED_FIELD_LABELS;
    enabledFieldLabels: ItemIdsByFieldId;
    fieldId?: number;
};

export type LoadEnabledFieldLabelsAction3 = {
    type: typeof LOAD_ENABLED_FIELD_LABELS3;
    enabledFieldLabels3: ItemIdsByFieldId;
    fieldId?: number;
};

export type LoadEnabledFieldVisibilityTypesAction = {
    type: typeof LOAD_ENABLED_FIELD_VISIBILITY_TYPES;
    enabledFieldVisibilityTypes: ItemIdsByFieldId;
};

export type LoadVisibilityCompanyUsersAction = {
    type: typeof LOAD_VISIBILITY_COMPANY_USERS;
    visibilityByCompanyUsers: ItemIdsByFieldId;
};

export type LoadEnabledFieldCompaniesAction = {
    type: typeof LOAD_ENABLED_FIELD_COMPANIES;
    enabledFieldCompanies: ItemIdsByFieldId;
};

export type LoadFieldRatesAction = {
    type: typeof LOAD_FIELD_RATES;
    fieldRates: FieldRateByFieldId;
};

export type LoadTaxmanFieldRatesAction = {
    type: typeof LOAD_TAXMAN_FIELD_RATES;
    taxmanFieldRates: TaxmanFieldRateByFieldId;
};

export type LoadFieldDistributionCountsAction = {
    type: typeof LOAD_DISTRIBUTION_COUNTS;
    fieldDistributionCounts: FieldDistributionCountsById;
};

export type IFieldsByCompanyActions =
    | LoadEnabledFieldLabelsAction
    | LoadEnabledFieldLabelsAction3
    | LoadEnabledFieldVisibilityTypesAction
    | LoadVisibilityCompanyUsersAction
    | LoadEnabledFieldCompaniesAction
    | LoadFieldRatesAction
    | LoadTaxmanFieldRatesAction
    | LoadFieldDistributionCountsAction;
