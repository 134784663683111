import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import { getFlowExportsForSelectedFlow, getFlowExportsArray } from "./flowExports";
import type { FlowExportTikTokTemplateFields, FlowItem, FlowErrorsByItemId, FlowExport } from "../types/flowTypes";
import { DeploySettings } from "../types/stores/companyTable";
import subItemReducer from "./_genericFlowSubItemReducer";

const myGenericReducer = makeReducerFor("FLOW_EXPORT_TIKTOK_TEMPLATE_FIELDS", "FlowExportTemplateId");
const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowExportTikTokTemplateFieldsArray = createSelector(
    state => state.flowExportTikTokTemplateFields.byId,
    (flowExportTikTokTemplateFieldsById: {|
        [number]: FlowExportTikTokTemplateFields,
    |}): Array<FlowExportTikTokTemplateFields> => {
        const r: Array<FlowExportTikTokTemplateFields> = Object.values(flowExportTikTokTemplateFieldsById);
        return r;
    }
);

export type FlowExportTikTokTemplateFieldsByItemId = {
    [number]: Array<FlowExportTikTokTemplateFields>,
};

export const getFlowExportTikTokTemplateFieldsByFlowItemId = createSelector(
    state => getFlowExportTikTokTemplateFieldsArray(state),
    (flowExportTikTokTemplateFields: Array<FlowExportTikTokTemplateFields>): FlowExportTikTokTemplateFieldsByItemId =>
        flowExportTikTokTemplateFields.reduce((acc, row) => {
            if (acc[row.FlowItemId] == null) {
                acc[row.FlowItemId] = [];
            }
            acc[row.FlowItemId].push(row);
            return acc;
        }, {})
);

export const getFlowExportTikTokTemplateFieldsForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowExportTikTokTemplateFieldsByFlowItemId(state),
    (
        selectedFlow: number,
        flowItems: Array<FlowItem>,
        flowExportTikTokTemplateFieldsByItemId: FlowExportTikTokTemplateFieldsByItemId
    ): Array<FlowExportTikTokTemplateFields> => {
        let result = [];
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        for (const itemId of itemIds) {
            const templateFields = flowExportTikTokTemplateFieldsByItemId[itemId];
            if (templateFields != null) {
                result = result.concat(templateFields);
            }
        }
        return result;
    }
);

const templateFieldsToErrorsById = (
    flowExports: Array<FlowExport>,
    flowExportTikTokTemplateFields: Array<FlowExportTikTokTemplateFields>,
    destinations: Array<any>
): FlowErrorsByItemId => {
    const errorsById = {};
    for (const flowExport of flowExports) {
        errorsById[flowExport.FlowItemId] = validateExportTikTokTemplateFields(
            flowExport,
            flowExportTikTokTemplateFields,
            destinations
        );
    }
    return errorsById;
};

export const getExportTikTokTemplateErrorsForSelectedFlow = createSelector(
    state => getFlowExportsForSelectedFlow(state),
    state => getFlowExportTikTokTemplateFieldsForSelectedFlow(state),
    state => state.vars.destinations,
    templateFieldsToErrorsById
);

export const getExportTikTokTemplateErrorsForAllFlows = createSelector(
    state => getFlowExportsArray(state),
    state => getFlowExportTikTokTemplateFieldsArray(state),
    state => state.vars.destinations,
    templateFieldsToErrorsById
);

export const validateExportTikTokTemplateFields = (
    flowExport: FlowExport,
    flowExportTikTokTemplateFields: Array<FlowExportTikTokTemplateFields>,
    destinations: Array<any>
) => {
    const errors = [];

    if (!destinations || (destinations && destinations.length == 0)) {
        return errors;
    }

    const templateFields = flowExportTikTokTemplateFields.filter(x => x.FlowItemId == flowExport.FlowItemId);

    // No destination selected
    const destination = destinations.find(x => x.PartnerAccessId == flowExport.DestinationId);

    if (!destination || destination.DeploySetting != DeploySettings.DeployTikTokDriverFile) {
        return errors;
    }

    // Missing data
    if (templateFields.length == 0 && destination.DeploySetting == DeploySettings.DeployTikTokDriverFile) {
        errors.push("TikTok Driver File Fields missing.");
        return errors;
    }

    // Validations
    const noTargetingAudienceName = templateFields.filter(
        x => x.TargetingAudienceName == null || x.TargetingAudienceName == ""
    );
    const noAdvertiserID = templateFields.filter(x => x.AdvertiserID == null || x.AdvertiserID == "");

    const audienceNameLength = templateFields.filter(
        x => x.TargetingAudienceName != null && x.TargetingAudienceName.length > 65
    );

    if (noTargetingAudienceName.length > 0) {
        errors.push("All Driver File Targeting Audience Name must be populated.");
    }

    if (noAdvertiserID.length > 0) {
        errors.push("All Driver File Advertiser ID must be populated.");
    }

    if (audienceNameLength.length > 0) {
        errors.push("One or More Audience Names exceed 65 characters - please edit the Audience Name to be less than 65 characters.");
    }

    return errors;
};
