import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    updateAttribute,
    goEditFlowItem,
    deleteFlowExportReport,
    deleteFlowItem,
    stillSimpleFlow,
    newFlowExportReport,
} from "../../../../actions/flowActions";
import { Button, Divider, TextField, FormControl, ThemeProvider, createTheme } from "@material-ui/core";
import GenericConfirmModal from "../../../modals/GenericConfirmModal";
import { FlowExportReport } from "../../../../types/stores/flowTypes";
import { IAppState } from "../../../../types/stores";

import MaterialTable from "@material-table/core";

type Props = {
    flowExport: any;
    canEdit: boolean;
    reports: Array<FlowExportReport>;
};

const FlowExportReportsTable: React.FC<Props> = ({ flowExport, canEdit, reports }: Props) => {
    const dispatch = useDispatch();

    const [isStillSimpleFlow, setIsStillSimpleFlow] = useState(false);

    const flow = useSelector((state: IAppState) => state.flows.byId[state.selected.flow]);
    const allFlowItems = useSelector((state: IAppState) => state.flowItems.byId);

    useEffect(() => {
        if (flow && flow.FlowBaseType > 0) {
            setIsStillSimpleFlow(dispatch(stillSimpleFlow()));
        }
    }, [flow, dispatch]);

    const toggleIsRenaming = flowItemId => {
        dispatch(updateAttribute("flowItems", flowItemId, "isRenaming", !allFlowItems[flowItemId].isRenaming));
    };

    const editReportName = (e, flowItemId) =>
        dispatch(updateAttribute("flowItems", flowItemId, "FlowItemName", e.target.value, true));

    const editNameKeyUp = (e, flowItemId) => {
        if (e.key == "Enter" || e.key == "Escape") {
            editReportName(e, flowItemId);
            dispatch(updateAttribute("flowItems", flowItemId, "isRenaming", false));
            toggleIsRenaming(flowItemId);
        }
    };

    const renderReportName = cellInfo => {
        if (!reports || reports.length <= 0 || reports[cellInfo.tableData.id] == null) return null;
        const item = allFlowItems[reports[cellInfo.tableData.id].FlowItemId];
        if (item == null) {
            return <div>Unknown</div>;
        }

        return (
            <div style={{ display: "flex" }}>
                {item.isRenaming ? (
                    <FormControl style={{ width: "-webkit-fill-available", padding: "5px 15px" }}>
                        <TextField
                            type="text"
                            defaultValue={item.FlowItemName}
                            onBlur={e => editReportName(e, item.FlowItemId)}
                            onKeyUp={e => editNameKeyUp(e, item.FlowItemId)}
                            onMouseDown={e => e.stopPropagation()}
                            inputProps={{ maxLength: 150 }}
                        />
                    </FormControl>
                ) : (
                    <span className="parent-item-label" style={{ width: "-webkit-fill-available" }}>
                        {item.FlowItemName}
                    </span>
                )}
            </div>
        );
    };

    const editReport = id => dispatch(goEditFlowItem(id));

    const deleteReport = (flowExportReportId, flowItemId) => {
        dispatch(deleteFlowExportReport(flowExportReportId));
        dispatch(deleteFlowItem(flowItemId));
    };

    const renderActions = cellInfo => {
        if (!reports || reports.length <= 0 || reports[cellInfo.tableData.id] == null) return null;

        const flowExportReportId = reports[cellInfo.tableData.id].FlowExportReportId;
        const item = allFlowItems[reports[cellInfo.tableData.id].FlowItemId];

        const confirmDeleteBody = <div>{`Are you sure you want to delete this row?`}</div>;
        const confirmDeleteText = "Delete Row";

        return (
            <div style={{ display: "flex" }}>
                <Button
                    className={"edit-button"}
                    onClick={() => toggleIsRenaming(item.FlowItemId)}
                    disabled={!canEdit || (flow && flow.IsLocked)}
                >
                    Rename
                </Button>
                <Button
                    className={"edit-button"}
                    onClick={() => editReport(reports[cellInfo.tableData.id].FlowItemId)}
                    disabled={!canEdit || (flow && flow.IsLocked)}
                >
                    Edit
                </Button>
                <span style={{ position: "relative", top: "5px" }}>
                    <GenericConfirmModal
                        onConfirm={() => deleteReport(flowExportReportId, reports[cellInfo.tableData.id].FlowItemId)}
                        body={confirmDeleteBody}
                        confirmText={confirmDeleteText}
                        title="Confirm Delete"
                        buttonText={
                            <i
                                className={`material-icons material-icon-hover-style ${
                                    !canEdit || (flow && flow.IsLocked) ? "" : "clickable"
                                }`}
                            >
                                close
                            </i>
                        }
                        buttonDisabled={!canEdit || (flow && flow.IsLocked)}
                        cnBtnStyle="normal-transparent-button"
                    />
                </span>
            </div>
        );
    };

    const addExportReport = () => {
        if (flowExport.ExportId != null) {
            dispatch(newFlowExportReport(flowExport.FlowItemId, flowExport.ExportId));
        }
    };

    const columns = [
        {
            title: "Report Name",
            render: renderReportName,
            headerStyle: {
                backgroundColor: "#0075bc",
                color: "#fff",
                fontWeight: 400,
                fontSize: "14px",
            },
        },
        {
            title: "",
            render: renderActions,
            headerStyle: {
                backgroundColor: "#0075bc",
                color: "#fff",
                fontWeight: 400,
                fontSize: "14px",
            },
            width: 200,
        },
    ];

    const theme = createTheme({
        overrides: {
            MuiTable: {
                root: {
                    marginTop: "0px !important",
                },
            },
            MuiTableCell: {
                root: {
                    fontSize: "14px",
                },
            },
        },
    });

    return flowExport.ExportId != null && !isStillSimpleFlow ? (
        <>
            <br />
            <br />
            <div className="flow-edit-header">
                <div style={{ flexGrow: 1 }}>
                    <p>Reports</p>
                    <span className="helper-text">Create, edit, and remove reports for this export node</span>
                </div>
                <div className="flow-edit-header-actions">
                    <Button
                        variant="contained"
                        color="secondary"
                        className={"edit-button"}
                        onClick={addExportReport}
                        startIcon={<i className="material-icons">add</i>}
                        disabled={!canEdit || (flow && flow.IsLocked)}
                    >
                        Create Report
                    </Button>
                </div>
            </div>
            <Divider style={{ margin: "20px auto" }} />
            <div className="flow-edit-body">
                <ThemeProvider theme={theme}>
                    <MaterialTable
                        data={reports}
                        columns={columns}
                        options={{
                            showTitle: false,
                            toolbar: false,
                            filtering: false,
                            grouping: false,
                            search: false,
                            sorting: true,
                            paging: false,
                            tableLayout: "fixed",
                            headerStyle: { fontSize: 14, position: "sticky" },
                            rowStyle: {
                                fontSize: "14px",
                                padding: "2px",
                            },
                            maxBodyHeight: 400,
                        }}
                        style={{
                            margin: "20px 0px",
                        }}
                    />
                </ThemeProvider>
            </div>
        </>
    ) : null;
};

export default FlowExportReportsTable;
