import React from "react";

import PropTypes from "prop-types";

const TreeItem = ({ active, handleClick, handleDoubleClick, children, staticClasses = "", isOver, canDrop }) => {
    const activeClass = active ? " active " : "";
    const isOverClass = isOver && canDrop ? " is-over " : "";
    const wholeRowClasses = "whole-row " + staticClasses + activeClass + isOverClass;
    const staticDivClasses = "tree-view_item " + staticClasses + activeClass + isOverClass;
    return (
        <div>
            {/* eslint-disable react/no-unknown-property */}
            <div
                className={wholeRowClasses}
                unselectable="on"
                role="presentation"
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
            >
                &nbsp;
            </div>
            {/* eslint-disable react/no-unknown-property */}
            <div className={staticDivClasses} onClick={handleClick} onDoubleClick={handleDoubleClick}>
                {children}
            </div>
        </div>
    );
};

TreeItem.propTypes = {
    active: PropTypes.bool,
    handleClick: PropTypes.func,
    handleDoubleClick: PropTypes.func,
    children: PropTypes.node,
    staticClasses: PropTypes.string,
    isOver: PropTypes.bool,
    canDrop: PropTypes.bool,
};

export default TreeItem;
