import { TextField, MenuItem } from "@material-ui/core";
import React from "react";

type Props = {
    label: string;
    options: Array<string>;
    value?: number;
    onChange: (val: any) => void;
};

const TimeSelector: React.FC<Props> = ({ label, options, onChange, value }: Props) => (
    <TextField select label={label} value={value?.toString() || ""} onChange={onChange} fullWidth>
        {options.map(s => (
            <MenuItem key={s} value={s}>
                {s.length == 1 ? "0" + s : s}
            </MenuItem>
        ))}
    </TextField>
);
export default TimeSelector;
