import React, { useState } from "react";
import { ScheduleState } from "../ScheduleAddEdit";
import Moment from "moment";
import update from "immutability-helper";
import MomentUtils from "@date-io/moment";
import { Grid, MenuItem, TextField } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import TimeSelector from "./shared/TimeSelector";
import { hourOptions, minuteOptions } from "../../helpers/helpers";
import { ScheduleTimeType } from "../../../../enums/schedules";

type Props = {
    schedule: ScheduleState;
    setSchedule: React.Dispatch<React.SetStateAction<ScheduleState>>;
    updateUnsavedChanges: () => void;
    disabled?: boolean;
};

const TimeFrequency: React.FC<Props> = ({ schedule, setSchedule, updateUnsavedChanges, disabled }: Props) => {
    const [inputValue, setInputValue] = useState(Moment().format("MM/DD/YYYY"));
    const [endDate, setEndDate] = useState(Moment().add(1, "y").format("MM/DD/YYYY"));

    const handleRunDateChange = val => {
        setSchedule({ ...schedule, runDate: Moment(val).format("MM/DD/YYYY") });
        setInputValue(Moment(val).format("MM/DD/YYYY"));
        updateUnsavedChanges();
    };

    const handleHourChange = val => {
        setSchedule({ ...schedule, hour: val.target.value });
        updateUnsavedChanges();
    };

    const handleMinuteChange = val => {
        setSchedule({ ...schedule, minute: val.target.value });
        updateUnsavedChanges();
    };

    const handleEndDateChange = val => {
        setSchedule(update(schedule, { endDate: { $set: val } }));
        setEndDate(Moment(val).format("MM/DD/YYYY"));
        updateUnsavedChanges();
    };

    return (
        <>
            <Grid item xs={4}>
                <TextField
                    id="time-count-input"
                    type="number"
                    label="Every"
                    value={schedule.TimeCount || ""}
                    onChange={e => {
                        setSchedule({ ...schedule, TimeCount: parseInt(e.target.value) });
                        updateUnsavedChanges();
                    }}
                    InputProps={{ inputProps: { min: 1 } }}
                    disabled={disabled}
                    fullWidth
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    key={"time-type-selector"}
                    select
                    label="Time Type"
                    value={schedule.TimeType || ""}
                    onChange={e => {
                        setSchedule({ ...schedule, TimeType: parseInt(e.target.value) });
                        updateUnsavedChanges();
                    }}
                    disabled={disabled}
                    fullWidth
                >
                    <MenuItem key={ScheduleTimeType.Day} value={ScheduleTimeType.Day}>
                        {schedule.TimeCount && schedule.TimeCount > 1 ? "Days" : "Day"}
                    </MenuItem>
                    <MenuItem key={ScheduleTimeType.Week} value={ScheduleTimeType.Week}>
                        {schedule.TimeCount && schedule.TimeCount > 1 ? "Weeks" : "Week"}
                    </MenuItem>
                    <MenuItem key={ScheduleTimeType.Month} value={ScheduleTimeType.Month}>
                        {schedule.TimeCount && schedule.TimeCount > 1 ? "Months" : "Month"}
                    </MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
                <MuiPickersUtilsProvider libInstance={Moment} utils={MomentUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        id="runDate"
                        label={schedule.RunCount > 0 ? "Next Run" : "Starting on"}
                        value={Moment(schedule.runDate)}
                        inputValue={
                            schedule.runDate == null ? inputValue : Moment(schedule.runDate).format("MM/DD/YYYY")
                        }
                        style={{ width: "100%" }}
                        onChange={handleRunDateChange}
                        KeyboardButtonProps={{
                            "aria-label": "runDate",
                        }}
                        disabled={disabled}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3}>
                <TimeSelector
                    key={"hour-selector"}
                    label="Hour"
                    value={schedule.hour}
                    onChange={handleHourChange}
                    options={hourOptions}
                />
            </Grid>
            <Grid item xs={3}>
                <TimeSelector
                    key={"minute-selector"}
                    label="Minute"
                    value={schedule.minute}
                    onChange={handleMinuteChange}
                    options={minuteOptions}
                />
            </Grid>
            {schedule.errors && (schedule.errors.runDate || schedule.errors.timeType) && (
                <Grid item xs={12}>
                    {schedule.errors.timeType && <span className="help-block">{schedule.errors.timeType}</span>}
                    {schedule.errors.runDate && <span className="help-block">{schedule.errors.runDate}</span>}
                </Grid>
            )}
            <div style={{ display: "flex", alignItems: "flex-end", width: "500px" }}>
                <Grid item xs={6}>
                    <MuiPickersUtilsProvider libInstance={Moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            clearable={true}
                            disableToolbar
                            variant="inline"
                            format="MM/DD/YYYY"
                            id="endDate"
                            label="End Date"
                            disablePast
                            allowKeyboardControl
                            autoOk
                            value={Moment(schedule.endDate) || endDate}
                            inputValue={
                                schedule.endDate == null ? endDate : Moment(schedule.endDate).format("MM/DD/YYYY")
                            }
                            onChange={handleEndDateChange}
                            className="material-form-control"
                            KeyboardButtonProps={{
                                "aria-label": "endDate",
                            }}
                            disabled={disabled}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </div>
        </>
    );
};
export default TimeFrequency;
