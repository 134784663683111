import React from "react";
import { useDispatch } from "react-redux";
import { Tooltip, Icon } from "@material-ui/core";
import IconSwitch from "../../../material-components/Misc/IconSwitch";

import { FlowExport } from "../../../../types/stores/flowTypes";
import { updateAttribute } from "../../../../actions/flowActions";

interface IProps {
    flowExport: FlowExport;
    splitOverwrite: boolean;
    disabled: boolean;
}

export const FlowExportSeperateFilesToggle: React.FC<IProps> = ({ flowExport, splitOverwrite, disabled }: IProps) => {
    const dispatch = useDispatch();

    const hasSeperateFilesChanged = (e: any) => {
        const isChecked = e.target.checked;
        dispatch(updateAttribute("flowExports", flowExport.FlowExportId, "IsSeperateFiles", isChecked));
        dispatch(updateAttribute("flowItems", flowExport.FlowItemId, "HasResultTable", false));
    };

    const renderSeperateFilesSwitch = () => {
        let content = (
            <IconSwitch
                id="seperatefiles-on"
                name="IsSeparateFilesOn"
                checked={flowExport.IsSeperateFiles}
                onChange={hasSeperateFilesChanged}
                disabled={disabled || splitOverwrite}
            />
        );

        return content;
    };

    return (
        <div style={{ display: "flex", marginLeft: "40px" }}>
            <div style={{ display: "flex", placeSelf: "flex-end" }}>
                <div
                    style={{
                        display: "flex",
                        fontSize: "17px",
                        position: "relative",
                        top: "5px",
                        marginRight: "30px",
                    }}
                >
                    <strong>Separate Files&nbsp;</strong>
                    <Tooltip title={`ON will allow to choose appropriate custom layout`} style={{ fontSize: "14px" }}>
                        <Icon>info</Icon>
                    </Tooltip>
                </div>
                <div style={{ display: "flex" }}>{renderSeperateFilesSwitch()}</div>
            </div>
        </div>
    );
};
export default FlowExportSeperateFilesToggle;
