const oreduce = (f, acc, o) => Object.keys(o).reduce((acc, k) => f(acc, o[k], k, o), acc); // Don't know how to fix this

/* eslint-disable no-confusing-arrow */ const ofilter = (f, o) =>
    oreduce((acc, v, k, o) => (f(v, k, o) ? Object.assign(acc, { [k]: v }) : acc), {}, o);

export default ofilter;

/*
Example:

let data = {
  item1: { key: 'sdfd', value:'sdfd' },
  item2: { key: 'sdfd', value:'sdfd' },
  item3: { key: 'sdfd', value:'sdfd' }
}

// filter data where k ("key") is not equal to 'item2'
let result = ofilter ((v,k)=> k !== 'item2', data)

console.log(result)

Result is:
{
  "item1": { "key": "sdfd", "value": "sdfd" },
  "item3": { "key": "sdfd", "value": "sdfd" }
}
*/
