import clone from "../helpers/clone";
import mergeDeep from "../helpers/mergeDeep";
import { IVarPersistStore, VarsPersistTypes } from "../types/stores/vars";

export const SET_PREMIUMFIELDS = "SET_PREMIUMFIELDS";
export const LOAD_SCRIPT_OBJECTS = "LOAD_SCRIPT_OBJECTS";
export const LOAD_UIDB_SCRIPT_OBJECTS = "LOAD_UIDB_SCRIPT_OBJECTS";
export const UPDATE_SCRIPT_OBJECTS = "UPDATE_SCRIPT_OBJECTS";
export const UPDATE_UIDB_SCRIPT_OBJECTS = "UPDATE_UIDB_SCRIPT_OBJECTS";
export const SET_USER_NAMES = "SET_USER_NAMES";
export const SET_VAR_PERSIST = "SET_VAR_PERSIST";

function vars(
    state: IVarPersistStore = {
        premiumFields: {},
        userNameForId: {},
        filtersChecksum: "",
        fieldsChecksum: "",
        scriptObjects: null,
        dbuiScriptObjects: null,
        value: "",
        map: "",
        variable: "",
    },
    action: VarsPersistTypes
): IVarPersistStore {
    switch (action.type) {
        case SET_PREMIUMFIELDS:
            return { ...state, premiumFields: action.premiumFields };
        case LOAD_SCRIPT_OBJECTS:
            return { ...state, scriptObjects: action.objects };
        case LOAD_UIDB_SCRIPT_OBJECTS:
            return { ...state, dbuiScriptObjects: action.objects };
        case UPDATE_SCRIPT_OBJECTS: {
            const newState = clone(state);
            newState.scriptObjects.filter(x => x.Database == action.catalog)[0].SchemaAndTables[action.schema][
                action.table
            ] = action.columns.map(x => x.label);
            return { ...state, scriptObjects: newState.scriptObjects };
        }
        case UPDATE_UIDB_SCRIPT_OBJECTS: {
            const newState = clone(state);
            newState.dbuiScriptObjects.filter(x => x.Database == action.catalog)[0].SchemaAndTables[action.schema][
                action.table
            ] = action.columns.map(x => x.label);
            return { ...state, dbuiScriptObjects: newState.dbuiScriptObjects };
        }
        case SET_USER_NAMES: {
            let newMap = clone(state.userNameForId);
            newMap = mergeDeep(newMap, action.map);
            return { ...state, userNameForId: newMap };
        }
        case SET_VAR_PERSIST:
            return { ...state, [action.variable]: action.value };
        default:
            return state;
    }
}
export default vars;
