import React from "react";
import PropTypes from "prop-types";
import { ContextMenu, MenuItem } from "react-contextmenu";

import { bindActionCreators } from "redux";
import { connect as reduxConnect } from "react-redux";
import * as actionCreators from "../../actions/actionCreators";
import * as layoutActions from "../../actions/exportLayoutActions2";

import h from "../../helpers";
import connectMenu from "../../helpers/connectMenu";

class LayoutContextMenu extends React.Component {
    constructor() {
        super();
        this.clickArchive = this.clickArchive.bind(this);
        this.clickDelete = this.clickDelete.bind(this);
    }

    render() {
        const identifier = "layout-tree";

        const data = this.props.item ? this.props.item.layoutId : {};

        const layout = this.props.layouts[data];
        //const seperatorStyle = { borderBottom: "1px solid #ddd", marginBottom: "1px", marginTop: "1px", marginRight: "20px", marginLeft: "20px" };
        return (
            <ContextMenu id={identifier} currentItem={this.currentItem} className={`${identifier} exp`}>
                <MenuItem onClick={this.clickArchive}>
                    {layout ? (layout.LayoutActive == "N" ? "Restore" : "Archive") : null}
                </MenuItem>
                <MenuItem onClick={this.clickDelete}>Delete</MenuItem>
            </ContextMenu>
        );
    }

    clickDelete(e, data) {
        const layout = this.props.layouts[data.layoutId];
        if (!layout) {
            h.error("Unable to delete layout");
            return;
        }

        this.props.showModal("LAYOUT_DELETE", {
            layout,
        });
    }

    clickArchive(e, data) {
        const layout = this.props.layouts[data.layoutId];
        if (!layout) {
            h.error("Unable to archive layout");
            return;
        }
        const layoutName = layout && layout.LayoutName ? layout.LayoutName : "(unknown)";

        this.props.showModal("ARCHIVE", {
            archiveActionCreator: this.props.requestArchiveLayout,
            restoreActionCreator: this.props.recoverArchiveLayout,
            archiveId: data.layoutId,
            archiveName: layoutName,
            archiveType: "layout",
            isActive: layout.LayoutActive,
            isDestination: layout.DestinationLayout,
            folders: this.props.folders,
        });
    }
}

LayoutContextMenu.propTypes = {
    // Injected from react-contextmenu
    item: PropTypes.object,
    // Injected from Redux
    layouts: PropTypes.object,
    userId: PropTypes.number,
    folders: PropTypes.object,
    // Injected from Redux (ActionCreators)
    showModal: PropTypes.func.isRequired,
    requestArchiveLayout: PropTypes.func,
    recoverArchiveLayout: PropTypes.func,
    roles: PropTypes.array,
};

export const ConnectedLayoutContextMenu1 = connectMenu("layout-tree")(LayoutContextMenu);

// redux
const mapStateToProps = state => ({
    layouts: state.layouts,
    userId: state.session.userId,
    roles: state.session.roles,
    folders: state.folders.layoutFolders,
});
const mapDispatchToProps = dispatch => bindActionCreators(Object.assign({}, actionCreators, layoutActions), dispatch);
const ConnectedLayoutContextMenu2 = reduxConnect(mapStateToProps, mapDispatchToProps)(ConnectedLayoutContextMenu1);

export default ConnectedLayoutContextMenu2;
