import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../actions/actionCreators";
import * as imageControlActions from "../../actions/imageControlActions";

import CompanySelect from "./CompanySelect";
import NotificationBell from "../userNotifications/NotificationBell";
import FileUploadIcon from "./FileUploadIcon";

class AeLocalNavRight extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            logoChangedKey: new Date(),
        };
    }

    static propTypes = {
        // Redux
        enabledFeatures: PropTypes.array,
        isAuthenticated: PropTypes.bool,
        pwExpired: PropTypes.bool,
        userId: PropTypes.number,
        userEmail: PropTypes.string,
        selectedCompany: PropTypes.number,
        // Injected by Redux (action creator)
        hasCompanyImageChanged: PropTypes.bool,
        NotifyUpdateCompanyImages: PropTypes.func,
        companyLogo: PropTypes.any,
        GetCompanyLogo: PropTypes.func,
    };

    hasFeature = feature => this.props.enabledFeatures.map(x => x.toLowerCase()).includes(feature.toLowerCase());

    renderUserLink() {
        let linkTitle = `Logged in as ${this.props.userEmail}`;
        let linkText = "Account";
        let linkIcon = null;

        let link = "/ae/account/preferences";
        return (
            <a href={link} id="runasuserreact" title={linkTitle}>
                {linkText}
                {linkIcon}
            </a>
        );
    }

    componentDidMount() {
        const { GetCompanyLogo, selectedCompany } = this.props;
        GetCompanyLogo(selectedCompany);
    }

    componentDidUpdate(prevProps) {
        const { NotifyUpdateCompanyImages } = this.props;

        if (prevProps.hasCompanyImageChanged != this.props.hasCompanyImageChanged) {
            // if the company logo has changed we set a new "key"/date to force the image to update
            this.setState({ logoChangedKey: new Date() });

            NotifyUpdateCompanyImages(false);
        }
    }

    render() {
        const { isAuthenticated, pwExpired, companyLogo } = this.props;

        const { logoChangedKey } = this.state;
        if (!isAuthenticated) {
            return null;
        }

        return (
            <React.Fragment>
                {!pwExpired && <FileUploadIcon />}
                {!pwExpired && (
                    <li>
                        <img
                            id={`react_header_logo`}
                            src={`${companyLogo}?hasChangedKey=${logoChangedKey}`}
                            alt={`react header logo`}
                        />
                    </li>
                )}
                <li>{!pwExpired && <CompanySelect isUpdateOnChange={true} />}</li>
                {!pwExpired ? (
                    <li>
                        <NotificationBell />
                    </li>
                ) : null}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    enabledFeatures: state.session.enabledFeatures || [], // Must be specifed because of localstorage persistence, initial state isn't always set :(
    isAuthenticated: state.session.isAuthenticated,
    pwExpired: state.session.pwExpired,
    userId: state.session.userId,
    userEmail: state.session.userEmail,
    selectedCompany: state.session.companyId,
    hasCompanyImageChanged: state.imageControl.hasUpdated,
    companyLogo: state.imageControl.logo,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(Object.assign({}, actionCreators, imageControlActions), dispatch);
const AeLocalNavRightC = connect(mapStateToProps, mapDispatchToProps)(AeLocalNavRight);

export default AeLocalNavRightC;
