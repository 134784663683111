import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import type { FlowItemOfferCode, FlowItem } from "../types/flowTypes";
import { getFlowItemsForSelectedFlow } from "./flowItems";

const myGenericReducer = makeReducerFor("FLOW_ITEM_OFFER_CODE", "FlowItemOfferCodeId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowItemOfferCodesArray = createSelector(
    state => state.flowItemOfferCodes.byId,
    (flowItemOfferCodesById: {| [number]: FlowItemOfferCode |}): Array<FlowItemOfferCode> => {
        const r: Array<FlowItemOfferCode> = Object.values(flowItemOfferCodesById);
        return r;
    }
);

export type FlowItemOfferCodesByItemId = {
    [number]: Array<FlowItemOfferCode>,
};

export const getFlowItemOfferCodesByFlowItemId = createSelector(
    state => getFlowItemOfferCodesArray(state),
    (flowItemOfferCodes: Array<FlowItemOfferCode>): FlowItemOfferCodesByItemId =>
        flowItemOfferCodes.reduce((acc, row) => {
            if (acc[row.FlowItemId] == null) {
                acc[row.FlowItemId] = [];
            }
            acc[row.FlowItemId].push(row);
            return acc;
        }, {})
);

export const getFlowItemOfferCodesForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowItemOfferCodesByFlowItemId(state),
    (
        selectedFlow: number,
        flowItems: Array<FlowItem>,
        flowItemOfferCodesByItemId: FlowItemOfferCodesByItemId
    ): Array<FlowItemOfferCode> => {
        let result = [];
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        for (const itemId of itemIds) {
            const offers = flowItemOfferCodesByItemId[itemId];
            if (offers != null) {
                result = result.concat(offers);
            }
        }
        return result;
    }
);
