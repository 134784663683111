import update from "immutability-helper";
import clone from "../helpers/clone";

const defaultState = {
    schedules: [], // List of schedules belonging to the current companyid
    mode: "list", // the "mode" of the ScheduleMain component - extracted here so we can show correct buttons in modal
    editId: null,
    isSaving: 0,
    isLoading: false,
    triggersByScheduleId: {},
    triggersWithUnsavedChanges: [],
    isUnsavedChanges: false,
};

function schedules(state = defaultState, action) {
    switch (action.type) {
        case "SCHEDULE_SET_UNSAVED_CHANGES": {
            return update(state, {
                isUnsavedChanges: { $set: action.value },
            });
        }
        case "CLEAR_TRIGGER_UNSAVED_LIST": {
            return update(state, {
                triggersWithUnsavedChanges: {
                    $set: [],
                },
            });
        }
        case "UPDATE_SCHEDULE_TRIGGER_LIST": {
            // scheduleId
            // triggers
            return update(state, {
                triggersByScheduleId: {
                    [action.scheduleId]: { $set: action.triggers },
                },
            });
        }
        case "UPDATE_SCHEDULE_LIST": {
            return update(state, {
                schedules: { $set: action.schedules },
            });
        }
        case "SET_SCHEDULE_MODE": {
            return update(state, {
                mode: { $set: action.mode },
            });
        }
        case "SET_SCHEDULE_ACTIVE": {
            const newState = clone(state);
            newState.schedules = newState.schedules.map(item => {
                if (item.Id != action.scheduleId) {
                    return item;
                }
                return {
                    ...item,
                    IsActive: action.isActive,
                };
            });
            return newState;
        }
        case "SET_SCHEDULE_TEMPLATE": {
            const newState = clone(state);
            newState.schedules = newState.schedules.map(item => {
                if (item.Id != action.scheduleId) {
                    return item;
                }
                return {
                    ...item,
                    IsScheduleTemplate: action.isScheduleTemplate,
                };
            });
            return newState;
        }
        case "SET_SCHEDULE_CONSECUTIVE": {
            const newState = clone(state);
            newState.schedules = newState.schedules.map(item => {
                if (item.Id != action.scheduleId) {
                    return item;
                }
                return {
                    ...item,
                    IsConsecutive: action.IsConsecutive,
                };
            });
            return newState;
        }
        case "SET_SCHEDULE_EDITID": {
            return update(state, {
                editId: { $set: action.editId },
            });
        }
        case "SCHEDULE_SAVE_START": {
            return update(state, {
                isSaving: { $set: state.isSaving + 1 },
            });
        }
        case "SCHEDULE_SAVE_FINISH": {
            return update(state, {
                isSaving: { $set: state.isSaving - 1 },
            });
        }
        case "SCHEDULE_LOAD_START": {
            return update(state, {
                isLoading: { $set: true },
            });
        }
        case "SCHEDULE_LOAD_FINISH": {
            return update(state, {
                isLoading: { $set: false },
            });
        }
        default:
            return state;
    }
}
export default schedules;
