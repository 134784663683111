import React from "react";
import BouncingDots from "./loading/BouncingDots";
// This is a static Loading component - it will always
// show the loading pulse animation.

interface Props {
    message?: string;
    height?: string;
    marginTop?: string;
    spaceAround?: boolean;
}

export const Loading: React.FC<Props> = ({ height, message, marginTop, spaceAround }: Props) => {
    const style = {
        justifyContent: spaceAround ? "space-around" : "undefined",
        maxHeight: height ? height : "150px",
        marginTop: marginTop ? marginTop : "auto",
    };

    return (
        <div className="loading-container" style={style}>
            <BouncingDots />
            {message && <div>{message}</div>}
        </div>
    );
};
export default Loading;
