import { fixReactSelectValue } from "../helpers";
import clone from "./clone";
import type { MeasureField } from "../types/types";

import tryParseJSON from "../helpers/tryParseJSON";
import isEqual from "lodash/isEqual";
import getEmptyQueryBuilderGroup from "../helpers/getEmptyQueryBuilderGroup";
import { CrosstabMeasureType } from "../types/types";

export const filterJsonChanged = (oldJson, newJson) => {
    // Sometimes the capitalization of fields changes?
    if (typeof oldJson == "string") {
        oldJson = oldJson.toLowerCase();
    }
    if (typeof newJson == "string") {
        newJson = newJson.toLowerCase();
    }

    let oldO = tryParseJSON(oldJson) || {};
    let newO = tryParseJSON(newJson) || {};

    //sets the fields needed for empty groups and old audiences validation
    if (isEqual(oldO, {})) {
        oldO = getEmptyQueryBuilderGroup(false);
    }
    if (isEqual(newO, {})) {
        newO = getEmptyQueryBuilderGroup(false);
    }
    if (oldO.valid === undefined) {
        oldO.valid = true;
    }

    return checkForIncludeExcludeChanges(oldO, newO);

    //let oldOWithValid = Object.assign({}, oldO, {valid: true});
    //if (isEqual(oldO, newO) || isEqual(oldOWithValid, newO) || isEqual(fixEmptyIncludeExcludeJson(oldJson), fixEmptyIncludeExcludeJson(newJson))) {
    //    return false;
    //}
};

const checkForIncludeExcludeChanges = (oldJson, newJson) => {
    //check for condition change
    if (!isEqual(oldJson.condition, newJson.condition)) {
        return true;
    }
    //not sure if this property should be evaluated
    if (!isEqual(oldJson.valid, newJson.valid)) {
        return true;
    }
    // check for NOT condition changed
    const oldNot = oldJson.not !== undefined ? oldJson.not : false;
    const newNot = newJson.not !== undefined ? newJson.not : false;
    if (!isEqual(oldNot, newNot)) {
        return true;
    }

    const oldDesc = oldJson.description !== undefined ? oldJson.description : "";
    //if feature was disabled, set same description as oldJson to avoid edit mode because of description was removed
    const newDesc = newJson.description !== undefined ? newJson.description : oldDesc;

    if (!isEqual(oldDesc, newDesc)) {
        return true;
    }

    //look for rule changes
    if (oldJson.rules.length != newJson.rules.length) {
        return true;
    }

    for (let i = 0; i < oldJson.rules.length; i++) {
        //if there is a group instead of a rule
        if (oldJson.rules[i].rules !== undefined && newJson.rules[i].rules !== undefined) {
            //calls itself for subgroups
            if (checkForIncludeExcludeChanges(oldJson.rules[i], newJson.rules[i])) {
                return true;
            }
        } else if (!isEqual(oldJson.rules[i], newJson.rules[i])) {
            return true;
        }
    }
    return false;
};

export const isAnyMeasureFieldInvalid = measureFields => {
    // if it is a field equality type, must have 2 fields
    const measureTypeField = measureFields[0];
    if (
        measureTypeField &&
        measureTypeField.measureType &&
        (measureTypeField.measureType == CrosstabMeasureType.FIELD_EQUALITY ||
            measureTypeField.measureType == CrosstabMeasureType.FIELD_DIFF)
    ) {
        if (measureFields.filter(x => x.fieldKey && x.fieldKey != 0).length < 2) {
            return true;
        }
        if (measureTypeField.measureType == CrosstabMeasureType.FIELD_DIFF) {
            const fieldDataType =
                measureTypeField.dateDiffOptions && measureTypeField.dateDiffOptions.fieldDataType
                    ? measureTypeField.dateDiffOptions.fieldDataType
                    : "D";

            if (
                fieldDataType == "D" &&
                (!measureFields[0].dateDiffOptions ||
                    !measureFields[0].dateDiffOptions.dateMeasureType ||
                    measureFields[0].dateDiffOptions.dateMeasureType == "")
            ) {
                return true;
            }
            if (fieldDataType != "N" && fieldDataType != "D") {
                return true;
            }
        }
    } else if (
        measureTypeField &&
        measureTypeField.measureType &&
        measureTypeField.measureType == CrosstabMeasureType.FIELD_BANDING
    ) {
        if (isFieldBandingCriteriaInvalid(measureFields)) {
            return true;
        }
    } else if (
        measureTypeField &&
        measureTypeField.measureType &&
        measureTypeField.measureType == CrosstabMeasureType.HOUSE_EXPAND
    ) {
        if (!measureTypeField.houseExpandOptions) {
            return true;
        }
        const houseExpandOptions = measureTypeField.houseExpandOptions;
        if (!houseExpandOptions.originalFlag || !houseExpandOptions.additionalFlag) {
            return true;
        }
    } else {
        for (const measureField of measureFields) {
            // Split type below
            if (measureField.measureType == CrosstabMeasureType.SPLIT || measureField.isSplit) {
                // Must have options and have correct splitOptions filled out
                if (measureField.splitOptions == null) {
                    return true;
                }
                const so = measureField.splitOptions;
                if (so.absoluteOrRelative == "absolute" && so.absoluteNumRows == null) {
                    return true;
                }
                if (so.absoluteOrRelative == "relative" && so.relativePercentRows == null) {
                    return true;
                }
            } else if (measureField.measureType == CrosstabMeasureType.FLAG || measureField.isHardcoded) {
                if (!measureField.hardcodedValue || measureField.hardcodedValue == "") {
                    return true;
                }
            } else if (
                !Object.prototype.hasOwnProperty.call(measureField, "aggregationOperator") ||
                measureField.aggregationOperator == null
            ) {
                // Non-Split type - must have aggregation operator
                return true;
            }
        }
    }
    return false;
};

const isFieldBandingCriteriaInvalid = measureFields => {
    if (measureFields.length <= 1) {
        return true;
    }
    let bandValues = []; //needed to check if there are repeated field band values
    for (let i = 0; i < measureFields.length; i++) {
        //first measure field contains only the default band value
        if (i == 0) {
            if (!measureFields[i].fieldBandOptions || !measureFields[i].fieldBandOptions.bandValue) {
                return true;
            }
            bandValues = bandValues.concat(measureFields[i].fieldBandOptions.bandValue);
        } else {
            //check the band values and querybuilder content
            const fieldBandOptions = measureFields[i].fieldBandOptions;
            if (!fieldBandOptions || !fieldBandOptions.bandValue || !fieldBandOptions.queryBuilderContent) {
                return true;
            }
            //shouldn't allow empty query builders
            if (!fieldBandOptions.queryBuilderContent.valid || fieldBandOptions.queryBuilderContent.rules.length <= 0) {
                return true;
            }
            if (bandValues.filter(x => x == fieldBandOptions.bandValue).length > 0) {
                return true;
            } else {
                //add the band value to check with the next ones
                bandValues = bandValues.concat(fieldBandOptions.bandValue);
            }
        }
    }
    return false;
};

export const measureFieldsArrayToString = (measureFields: Array<MeasureField>) => {
    if (!Array.isArray(measureFields)) {
        console.warn("measureFieldsArrayToString: I was given measureFields that's not an array."); // eslint-disable-line no-console
        return "";
    }
    const newMeasureFields = clone(measureFields).map(x => {
        if (Object.prototype.hasOwnProperty.call(x, "aggregationOperator")) {
            x.aggregationOperator = fixReactSelectValue(x.aggregationOperator);
        }
        if (Object.prototype.hasOwnProperty.call(x, "windowOperator")) {
            x.windowOperator = fixReactSelectValue(x.windowOperator);
        }
        return x;
    });
    return JSON.stringify(newMeasureFields);
    // Deprecated format:
    //return measureFields.map(x => `${x.fieldKey}|${fixReactSelectValue(x.operator)}`).join(",");
};

export const measureFieldsStringToArray = (measureFieldsString: ?string) => {
    if (typeof measureFieldsString != "string") {
        return [];
    }
    if (measureFieldsString == "") {
        return [];
    }
    try {
        return JSON.parse(measureFieldsString);
    } catch (ex) {
        // console.warn("Unable to parse measureField json", ex); // eslint-disable-line no-console
        return [];
    }
    /*
    Deprecated format:
    return measureFieldsString
        .split(/,\s* /)
        .filter(Boolean)
        .map(x => ({ fieldKey: parseInt(x.split("|")[0]), operator: x.split("|")[1] }));
    */
};

// The crosstab Fields is simpler.  It's only a CSV of numbers.

export const crosstabFieldKeysArrayToString = (fieldKeys: Array<number>) => {
    if (!Array.isArray(fieldKeys)) {
        console.warn("crosstabFieldKeysArrayToString: I was given fieldKeys that's not an array."); // eslint-disable-line no-console
        return "";
    }
    return fieldKeys.join(",");
};
export const crosstabFieldKeysStringToArray = (fieldKeysString: string): Array<number> => {
    if (typeof fieldKeysString != "string") {
        return [];
    }
    return fieldKeysString
        .split(/,\s*/)
        .filter(Boolean)
        .map(x => parseInt(x));
};
