import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAttribute } from "../../../../actions/flowActions";
import IconSwitch from "../../../material-components/Misc/IconSwitch";
import {
    Checkbox,
    Divider,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    TextField,
    Tooltip,
} from "@material-ui/core";
import { IAppState } from "../../../../types/stores";
import { getIdTypes } from "../../../../actions/freewheelActions";
import { FlowExportFreewheelDriverFileFields, IIdType } from "../../../../types/stores/freewheel";
import MaterialTable, { Column } from "@material-table/core";
import NumericalTextField from "../../../material-components/Misc/NumericalTextField";
import { formatName, formatNameMagnite } from "../../../../helpers";
import { DeploySettings } from "../../../../types/stores/companyTable";
import { notifyBlue } from "../../../../actions/notifyActions";

type Props = {
    flowItemId: number;
    flowExportFreewheelDriverFileFields: Array<FlowExportFreewheelDriverFileFields>;
    deploySetting: number;
    companyName: string;
};

const FlowExportFreewheelDriverFileForm: React.FC<Props> = ({
    flowItemId,
    flowExportFreewheelDriverFileFields,
    deploySetting,
}: Props) => {
    const dispatch = useDispatch();

    // state
    const [selectedIdTypes, setSelectedIdTypes] = useState(flowExportFreewheelDriverFileFields[0]?.IdTypes || null);
    const [networkId, setNetworkId] = useState(flowExportFreewheelDriverFileFields[0]?.NetworkId || "");
    const [templateRows, setTemplateRows] = useState(flowExportFreewheelDriverFileFields);
    const [currentCompanyName, setCompanyName] = useState(flowExportFreewheelDriverFileFields[0]?.CompanyName || null);

    // props
    const idTypes = useSelector<IAppState, Array<IIdType>>(state => state.flowExportObjects.idTypes || []);
    const isLoading = useSelector((state: IAppState) => state.flowExportObjects.isLoading);
    const currentCompanyId = useSelector((state: IAppState) => state.session.companyId);
    const companies = useSelector((state: IAppState) => state.session.companies);
    const isInternal = useSelector((state: IAppState) => state.session.isInternal);
    const company = companies.find(c => c.CompanyID == currentCompanyId);
    const companyName = company ? company.CompanyName : "";
    const alphaNumericOnly = /[^a-z0-9]/gi;

    useEffect(() => {
        dispatch(getIdTypes());
    }, [dispatch]);

    useEffect(() => {
        if (idTypes && idTypes.length > 0 && selectedIdTypes == null) {
            const selectedIdTypes = idTypes.map(x => x.DriveName).join(",");
            setSelectedIdTypes(selectedIdTypes);
        }
    }, [idTypes, selectedIdTypes]);

    useEffect(() => {
        if (selectedIdTypes && flowExportFreewheelDriverFileFields) {
            for (const field of flowExportFreewheelDriverFileFields) {
                if (field.IdTypes == null || field.IdTypes != selectedIdTypes) {
                    dispatch(
                        updateAttribute(
                            "flowExportFreewheelDriverFileFields",
                            field.FlowExportFreewheelDriverFileFieldId,
                            "IdTypes",
                            selectedIdTypes
                        )
                    );
                }
            }
        }
    }, [dispatch, selectedIdTypes, flowExportFreewheelDriverFileFields]);

    // Load templateRows to populate Driver Field table
    useEffect(() => {
        if (templateRows.length != flowExportFreewheelDriverFileFields.length) {
            setTemplateRows(flowExportFreewheelDriverFileFields);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flowExportFreewheelDriverFileFields.length]);

    let driverTitleName = "";
    let isFreewheel = false;
    let isMagnite = false;

    if (deploySetting == DeploySettings.DeployFreewheelDriverFile) {
        driverTitleName = "Freewheel";
        isFreewheel = true;
    } else if (deploySetting == DeploySettings.DeployMagniteDriverFile) {
        driverTitleName = "Magnite";
        isMagnite = true;
    }

    let idTypesTooltipMessage = `Type of Ids being loaded to ${driverTitleName}.`;

    if (isFreewheel) {
        idTypesTooltipMessage += ` If you want all MAIDs, select both Apple and Android. You can select more than one item.`;
    }

    const title = `${driverTitleName} Driver File Fields`;

    // This changes the field specific settings for freewheel driver file fields
    const handleFieldChange = (
        driverFileFieldId: number,
        attributeName: string,
        value: string | number | boolean | null
    ) => {
        dispatch(updateAttribute("flowExportFreewheelDriverFileFields", driverFileFieldId, attributeName, value));
    };

    // This changes global field settings for freewheel driver file fields
    const handleSettingChange = (attributeName: string, value: Array<string> | string) => {
        if (attributeName == "IdTypes") {
            let newIdTypes =
                typeof value === "string"
                    ? value
                    : value.map(x => idTypes.find(y => x == y.DriveName)?.DriveName).join(",");

            // Ensure no leading or trailing commas
            newIdTypes = newIdTypes.replace(/(^,)|(,$)/g, "");

            setSelectedIdTypes(newIdTypes);
        } else {
            flowExportFreewheelDriverFileFields.forEach(field =>
                dispatch(
                    updateAttribute(
                        "flowExportFreewheelDriverFileFields",
                        field.FlowExportFreewheelDriverFileFieldId,
                        attributeName,
                        value
                    )
                )
            );
        }
    };

    // This changes the field specific settings for freewheel driver file fields
    const handleFieldCompanyNameChange = (attributeName: string, value: string) => {
        setCompanyName(value);

        if (value.length >= 500)
            dispatch(notifyBlue("Company Name is maximum of 500 chars, If exceeds 500 it will be truncated."));

        flowExportFreewheelDriverFileFields.forEach(field =>
            dispatch(
                updateAttribute(
                    "flowExportFreewheelDriverFileFields",
                    field.FlowExportFreewheelDriverFileFieldId,
                    attributeName,
                    value
                )
            )
        );
    };

    if (currentCompanyName == null) {
        handleFieldCompanyNameChange("CompanyName", companyName);
    }
    // Form Contents(All are Required)
    // ----------------
    // FlowId - id of the flow  - No UI - Same for all fields
    // FlowItemId - id of the export node  - No UI - Same for all fields
    // FlowItemName - name of the export node  - No UI - Same for all fields

    // IdTypes - table driven by FreewheelIdTypes (comma separated ip,idfa,aaid,ctv) - Same for all fields
    const renderIdTypes = () => (
        <>
            {isMagnite && (
                <FormControl style={{ width: "350px", margin: "0px 10px" }}>
                    <Tooltip title={currentCompanyName || ""}>
                        <TextField
                            id="magnite-company-name-id"
                            key="magnite-company-name-key"
                            disabled={!isInternal}
                            onChange={e => handleFieldCompanyNameChange("CompanyName", e.target.value)}
                            value={currentCompanyName}
                            label="Company Name"
                            inputProps={{ maxLength: 500 }}
                        ></TextField>
                    </Tooltip>
                </FormControl>
            )}
            <FormControl style={{ width: "250px", margin: "0px 10px" }}>
                <InputLabel>
                    <Tooltip title={idTypesTooltipMessage}>
                        <div>Id Types</div>
                    </Tooltip>
                </InputLabel>
                <Select
                    labelId="freewheel-id-types"
                    id="freewheel-id-types-checkbox"
                    multiple
                    value={selectedIdTypes?.split(",") || []}
                    onChange={e => handleSettingChange("IdTypes", e.target.value as Array<string>)}
                    MenuProps={{
                        getContentAnchorEl: null,
                    }}
                    disabled={isMagnite}
                    renderValue={value => {
                        let selected = value as unknown as Array<string>;
                        return (
                            <Tooltip title={idTypesTooltipMessage}>
                                <div>
                                    {idTypes
                                        .filter(x => selected.includes(x.DriveName))
                                        .map(x => x.IdType)
                                        .join(", ")}
                                </div>
                            </Tooltip>
                        );
                    }}
                >
                    {idTypes.length == 0 ? (
                        isLoading ? (
                            <MenuItem disabled value="">
                                Loading...
                            </MenuItem>
                        ) : (
                            <MenuItem disabled value="">
                                No Id Types Available
                            </MenuItem>
                        )
                    ) : (
                        idTypes.map(type => (
                            <MenuItem key={type.Id} value={type.DriveName}>
                                <Checkbox
                                    checked={(selectedIdTypes?.split(",") || []).indexOf(type.DriveName) > -1}
                                    color="primary"
                                />
                                <ListItemText primary={type.IdType} />
                            </MenuItem>
                        ))
                    )}
                </Select>
            </FormControl>
        </>
    );

    // SegmentId - concatenated FlowId + ParentFlowItemId (hardcoded) - No UI - Can be different between fields

    // SegmentKey - segment code in export (hardcoded) - Can be different between fields
    // can have multiple SegmentKeys
    // one per line on driver file
    // 25 bytes max, alpha only, no spaces or pipes (same size limitation as segment code in exports)

    // SegmentName - Friendly Name - alpha only, no spaces or pipes, 25 characters max - Can be different between fields
    const renderSegmentName = (rowData: FlowExportFreewheelDriverFileFields) => {
        const rows = [...templateRows];

        const column = (
            <FormControl style={{ width: "250px", margin: "0px 10px" }}>
                <TextField
                    id="segment-name"
                    key={"segment-name" + rowData.FlowExportFreewheelDriverFileFieldId}
                    defaultValue={rowData.SegmentName}
                    helperText={!rowData.SegmentName ? "Please add a segment name." : undefined}
                    inputProps={{ maxLength: isMagnite ? 255 : 25 }}
                    onChange={e => {
                        let newValue = "";

                        if (isMagnite) {
                            newValue = formatNameMagnite(e.target.value);
                        } else {
                            newValue = formatName(e.target.value);
                        }

                        rows[rowData.tableData.id].SegmentName = newValue;
                        setTemplateRows(rows);
                    }}
                    onBlur={e => {
                        let newValue = "";

                        if (isMagnite) {
                            newValue = formatNameMagnite(e.target.value);
                        } else {
                            newValue = formatName(e.target.value);
                        }

                        handleFieldChange(rowData.FlowExportFreewheelDriverFileFieldId, "SegmentName", newValue);
                    }}
                />
            </FormControl>
        );

        return column;
    };

    // SubGroupDescription - Friendly Name - alpha only, no spaces or pipes, 250 characters max - Can be different between fields
    const renderSubGroupDescription = (rowData: FlowExportFreewheelDriverFileFields) => {
        const rows = [...templateRows];
        const column = (
            <FormControl style={{ width: "250px", margin: "0px 10px" }}>
                <TextField
                    id="segment-sub-group-description"
                    key={"segment-sub-group-description" + rowData.FlowExportFreewheelDriverFileFieldId}
                    defaultValue={rowData.SubGroupDescription}
                    helperText={!rowData.SubGroupDescription ? "Please add a sub group description." : undefined}
                    inputProps={{ maxLength: 200 }}
                    onChange={e => {
                        let newValue = formatNameMagnite(e.target.value);

                        rows[rowData.tableData.id].SubGroupDescription = newValue;
                        setTemplateRows(rows);
                    }}
                    onBlur={e => {
                        let newValue = formatNameMagnite(e.target.value);
                        handleFieldChange(
                            rowData.FlowExportFreewheelDriverFileFieldId,
                            "SubGroupDescription",
                            newValue
                        );
                    }}
                />
            </FormControl>
        );

        return column;
    };

    // SegmentDescription - Friendly Name - alpha only, no spaces or pipes, 255 characters max - Can be different between fields
    const renderSegmentDescription = (rowData: FlowExportFreewheelDriverFileFields) => {
        const rows = [...templateRows];
        const column = (
            <FormControl style={{ width: "250px", margin: "0px 10px" }}>
                <TextField
                    id="segment-description"
                    key={"segment-description" + rowData.FlowExportFreewheelDriverFileFieldId}
                    defaultValue={rowData.SegmentDescription}
                    helperText={!rowData.SegmentDescription ? "Please add a segment description." : undefined}
                    inputProps={{ maxLength: 255 }}
                    onChange={e => {
                        let newValue = formatNameMagnite(e.target.value);

                        rows[rowData.tableData.id].SegmentDescription = newValue;
                        setTemplateRows(rows);

                        if (currentCompanyName != null) {
                            handleFieldCompanyNameChange("CompanyName", currentCompanyName);
                        }
                    }}
                    onBlur={e => {
                        let newValue = formatNameMagnite(e.target.value);
                        handleFieldChange(rowData.FlowExportFreewheelDriverFileFieldId, "SegmentDescription", newValue);
                    }}
                />
            </FormControl>
        );

        return column;
    };

    // NetworkId: Source Company's NetworkId on Driver - 25 byte max - alphanumeric no space or pipe - Same for all fields
    const renderNetworkId = () => (
        <Tooltip title="Network Id provided by Freewheel. This indicates what clients will have access to data being ingested.">
            <FormControl style={{ width: "250px", margin: "0px 10px" }}>
                <TextField
                    id="network-id"
                    label="Network Id"
                    value={networkId}
                    inputProps={{ maxLength: 25 }}
                    onChange={e => setNetworkId(e.target.value.replace(alphaNumericOnly, ""))}
                    onBlur={e => handleSettingChange("NetworkId", e.target.value.replace(alphaNumericOnly, ""))}
                />
            </FormControl>
        </Tooltip>
    );

    // Custom - Default On - Disabled - Can be different between fields
    const renderCustomToggle = (rowData: FlowExportFreewheelDriverFileFields) => {
        const rows = [...templateRows];
        const column = (
            <Tooltip title="Indicates a Custom or syndicated segment.Currently only custom allowed.">
                <IconSwitch
                    id={"custom-" + rowData.FlowExportFreewheelDriverFileFieldId}
                    name="Custom"
                    onChange={e => {
                        rows[rowData.tableData.id].Custom = e.target.checked;
                        setTemplateRows(rows);
                        handleFieldChange(rowData.FlowExportFreewheelDriverFileFieldId, "Custom", e.target.checked);
                    }}
                    checked={rowData.Custom}
                    disabled
                />
            </Tooltip>
        );

        return column;
    };

    /*
        Format price.
        If value is empty and less than 0, set to 1.
        If value is greater than 99.99, set to 99.99
        If not a number, return current price.
        Else return number in 2 decimals
    */
    const formatPrice = (newValue: string, currentValue: number) => {
        let newPrice = parseFloat(newValue);

        if (newValue == "" || parseFloat(newValue) < 0) {
            newPrice = 1;
        } else if (isNaN(parseFloat(newValue))) {
            newPrice = currentValue;
        } else if (parseFloat(newValue) > 99.99) {
            newPrice = 99.99;
        } else {
            newPrice = parseFloat(newValue);
        }

        // Fix to 2 decimals
        newPrice = parseFloat((Math.round(newPrice * 100) / 100).toFixed(2));

        return newPrice;
    };

    /*
        Format price.
        If value is empty, less than 0, or greater than 4, set to empty.
        If not a number, return current price.
        Else return number in 2 decimals
    */
    const formatPriceMagnite = (newValue: string, currentValue: number) => {
        if (newValue == "") {
            return null;
        }

        if (parseFloat(newValue) < 0 || parseFloat(newValue) > 4) {
            return null;
        }

        let newPrice = parseFloat(newValue);

        if (isNaN(parseFloat(newValue))) {
            newPrice = currentValue;
        } else {
            newPrice = parseFloat(newValue);
        }

        // Fix to 2 decimals
        newPrice = parseFloat((Math.round(newPrice * 100) / 100).toFixed(2));

        return newPrice;
    };

    // Price - ##.## - Not 0 - Can be different between fields
    const renderPrice = (rowData: FlowExportFreewheelDriverFileFields) => {
        const rows = [...templateRows];
        const column = (
            <FormControl style={{ width: isMagnite ? "100px" : "250px", margin: "0px 10px" }}>
                <NumericalTextField
                    id={isMagnite ? "price-magnite" : "price"}
                    key={"price-" + rowData.FlowExportFreewheelDriverFileFieldId}
                    value={rowData.Price}
                    helperText={
                        rowData.Price == null
                            ? isMagnite
                                ? "Range 0.00 - 4.00"
                                : "Price cannot be set to lower than 0."
                            : undefined
                    }
                    numberType="decimal"
                    onChange={e => {
                        let newPrice: string | number | boolean | null;

                        if (isMagnite) {
                            newPrice =
                                formatPriceMagnite(e.target.value, rowData.Price) != null
                                    ? formatPriceMagnite(e.target.value, rowData.Price)
                                    : null;
                        } else {
                            newPrice = formatPrice(e.target.value, rowData.Price) || "";
                        }

                        rows[rowData.tableData.id].Price = newPrice as number;
                        setTemplateRows(rows);
                    }}
                    onBlur={e => {
                        let newPrice: string | number | boolean | null;

                        if (isMagnite) {
                            newPrice =
                                formatPriceMagnite(e.target.value, rowData.Price) != null
                                    ? formatPriceMagnite(e.target.value, rowData.Price)
                                    : null;
                        } else {
                            newPrice = formatPrice(e.target.value, rowData.Price) || "";
                        }

                        handleFieldChange(rowData.FlowExportFreewheelDriverFileFieldId, "Price", newPrice);
                    }}
                />
            </FormControl>
        );

        return column;
    };

    const getColumns = useMemo(() => {
        let columns: Array<Column<FlowExportFreewheelDriverFileFields>> = [
            {
                title: "Segment Name",
                tooltip: "Segment friendly name.",
                render: rowData => renderSegmentName(rowData),
            },
        ];

        if (isMagnite) {
            columns.push({
                title: "Sub-Group Description",
                tooltip: "Sub-Group description.",
                render: rowData => renderSubGroupDescription(rowData),
            });

            columns.push({
                title: "Segment Description",
                tooltip: "Segment description.",
                render: rowData => renderSegmentDescription(rowData),
            });
        }

        columns.push({
            title: "Price",
            tooltip: "Cost per Thousand(CPM) Rate. Percent of media spend is not supported.",
            render: rowData => renderPrice(rowData),
        });

        if (isFreewheel) {
            columns.push({
                title: "Custom",
                tooltip: "Indicates a Custom or syndicated segment.Currently only custom allowed.",
                render: rowData => renderCustomToggle(rowData),
                align: "right",
                width: "50px",
            });
        }

        return columns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFreewheel, isMagnite, templateRows.length]);

    const renderFreewheelDriverFileForm = () => (
        <div style={{ paddingTop: "20px" }}>
            <p>File Settings</p>
            <Divider style={{ marginBottom: "20px" }} />
            {renderIdTypes()}
            {isFreewheel && renderNetworkId()}
            <div style={{ height: "20px" }} />
            <p>Fields Settings</p>
            <Divider style={{ marginBottom: "20px" }} />
            <MaterialTable
                key={`freewheel-fields-table-${flowItemId}`}
                data={templateRows}
                columns={getColumns}
                options={{
                    showTitle: false,
                    toolbar: false,
                    filtering: false,
                    grouping: false,
                    search: false,
                    sorting: true,
                    paging: false,
                    columnResizable: true,
                    tableLayout: "fixed",
                    headerStyle: { fontSize: 14, position: "sticky" },
                    rowStyle: {
                        fontSize: "14px",
                        padding: "2px",
                    },
                    maxBodyHeight: 400,
                }}
            />
        </div>
    );

    return (
        <>
            <div className="flow-edit-header">
                <div style={{ flexGrow: 1, position: "relative" }}>
                    <p>{title}</p>
                </div>
            </div>
            <Divider style={{ margin: "20px auto" }} />
            <Paper style={{ marginTop: "30px", padding: "0px 20px 20px 20px", width: "100%" }}>
                <div className="m-t-md">{renderFreewheelDriverFileForm()}</div>
            </Paper>
            <Divider style={{ margin: "40px auto 20px auto" }} />
        </>
    );
};

export default FlowExportFreewheelDriverFileForm;
