import React from "react";
import debounce from "debounce";

function withUpdateStripes<T>(PassedComponent: React.ComponentType<T>): React.ComponentType<T> {
    const WithUpdateStripes = (props: T) => {
        const updateRowClasses = () => {
            $(".whole-row:even").addClass("even").removeClass("odd");
            $(".whole-row:odd").removeClass("even").addClass("odd");
        };

        const debouncedUpdateRowClasses = debounce(updateRowClasses, 32);

        return <PassedComponent {...props} updateStripes={debouncedUpdateRowClasses} />;
    };

    return WithUpdateStripes;
}
export default withUpdateStripes;
