const tableBodyStyle = {
    widgetTableRow: {
        fontSize: "1.1em",
        whiteSpace: "nowrap",
        maxWidth: "600px",
        overflow: "hidden",
    },
    widgetTableCell: {
        fontSize: "1.2em",
        padding: "10px",
        whiteSpace: "nowrap",
        maxWidth: "100px",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    pageTableRow: {
        fontSize: "1em",
        whiteSpace: "nowrap",
        maxWidth: "600px",
        overflow: "hidden",
    },
    pageTableCell: {
        fontSize: "0.8em",
        padding: "10px",
        whiteSpace: "nowrap",
        maxWidth: "125px",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    loadingCell: {
        fontSize: "1.2em",
        padding: "4px",
    },
    widgetEmptyCell: {
        fontSize: "1.2em",
        padding: "10px",
    },
    pageEmptyCell: {
        fontSize: "0.8em",
        padding: "10px",
    },
};

export default tableBodyStyle;
