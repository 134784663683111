import { FlowExportTaxonomyFileFields } from "../types/flowTypes";
import { Destination } from "../types/types";

export const FLOW_EXPORT_TAXONOMY_TEMPLATE_FIELDS = "FLOW_EXPORT_TAXONOMY_FIELDS";

import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import { getFlowExportsForSelectedFlow, getFlowExportsArray } from "./flowExports";
import type { FlowItem, FlowErrorsByItemId, FlowExport } from "../types/stores/flowTypes";

const myGenericReducer = makeReducerFor("FLOW_EXPORT_TAXONOMY_FIELDS", "FlowExportTaxonomyFileFieldId");
import subItemReducer from "./_genericFlowSubItemReducer";
import { IAppState } from "../types/stores/index";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowExportTaxonomyFileFieldsArray = createSelector(
    (state: IAppState) => state.flowExportTaxonomyFileFields.byId,
    (flowExportTaxonomyFileFieldsById: {
        [number: number]: FlowExportTaxonomyFileFields;
    }): Array<FlowExportTaxonomyFileFields> => {
        const r: Array<FlowExportTaxonomyFileFields> = Object.values(flowExportTaxonomyFileFieldsById);
        return r;
    }
);

export type FlowExportTaxonomyFileFieldsByItemId = {
    [number: number]: Array<FlowExportTaxonomyFileFields>;
};

export const getFlowExportTaxonomyFileFieldsByFlowItemId = createSelector(
    (state: IAppState) => getFlowExportTaxonomyFileFieldsArray(state),
    (flowExportTaxonomyFileFields: Array<FlowExportTaxonomyFileFields>): FlowExportTaxonomyFileFieldsByItemId =>
        flowExportTaxonomyFileFields.reduce((acc, row) => {
            if (acc[row.FlowItemId] == null) {
                acc[row.FlowItemId] = [];
            }
            acc[row.FlowItemId].push(row);
            return acc;
        }, {})
);

export const getFlowExportTaxonomyFileFieldsForSelectedFlow = createSelector(
    (state: IAppState) => getFlowItemsForSelectedFlow(state),
    (state: IAppState) => getFlowExportTaxonomyFileFieldsByFlowItemId(state),
    (
        flowItems: Array<FlowItem>,
        flowExportTaxonomyFileFieldsByItemId: FlowExportTaxonomyFileFieldsByItemId
    ): Array<FlowExportTaxonomyFileFields> => {
        let result: Array<FlowExportTaxonomyFileFields> = [];
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        for (const itemId of itemIds) {
            const driverFileFields = flowExportTaxonomyFileFieldsByItemId[itemId];
            if (driverFileFields != null) {
                result = result.concat(driverFileFields);
            }
        }
        return result;
    }
);

const driverFileFieldsToErrorsById = (
    flowExports: Array<FlowExport>,
    flowExportTaxonomyFileFields: Array<FlowExportTaxonomyFileFields>,
    destinations: Array<any>
): FlowErrorsByItemId => {
    const errorsById = {};
    for (const flowExport of flowExports) {
        errorsById[flowExport.FlowItemId] = validateExportTaxonomyFileFields(
            flowExport,
            flowExportTaxonomyFileFields,
            destinations
        );
    }
    return errorsById;
};

export const getExportTaxonomyFileFieldErrorsForSelectedFlow = createSelector(
    (state: IAppState) => getFlowExportsForSelectedFlow(state),
    (state: IAppState) => getFlowExportTaxonomyFileFieldsForSelectedFlow(state),
    (state: IAppState) => state.vars.destinations,
    driverFileFieldsToErrorsById
);

export const getExportTaxonomyFileFieldErrorsForAllFlows = createSelector(
    (state: IAppState) => getFlowExportsArray(state),
    (state: IAppState) => getFlowExportTaxonomyFileFieldsArray(state),
    (state: IAppState) => state.vars.destinations,
    driverFileFieldsToErrorsById
);

export const validateExportTaxonomyFileFields = (
    flowExport: FlowExport,
    flowExportTaxonomyFileFields: Array<FlowExportTaxonomyFileFields>,
    destinations: Array<Destination>
): Array<string> => {
    const errors: Array<string> = [];
    const errorFields: Array<string> = [];

    if (!destinations || (destinations && destinations.length == 0)) {
        return errors;
    }
    // No destination selected

    const destination = destinations.find(x => x.PartnerAccessId == flowExport.DestinationId);
    if (!destination) {
        return errors;
    }

    const taxonomyFieldsList = flowExportTaxonomyFileFields.filter(x => x.FlowItemId == flowExport.FlowItemId);

    taxonomyFieldsList.forEach(taxonomyFields => {
        let taxonomyField = JSON.parse(taxonomyFields.ValueJSON);
        const obj = Object.entries(taxonomyField);

        obj.forEach(([key, value]) => {
            if (value == "" || value == "NaN") {
                errorFields.push(key);
            }
        });
    });
    if (errorFields.length > 0) {
        const initialValue = "";
        const errorFieldString = errorFields.reduce(
            (accumulator, currentValue) => accumulator + "'" + currentValue + "'" + " ",
            initialValue
        );
        errors.push("The following Taxonomy fields need a value: " + errorFieldString);
    }

    return errors;
};
