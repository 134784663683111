import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import FeatureGate from "../FeatureGate";
import LocalNavHardLink from "./LocalNavHardLink";

import Tooltip from "@material-ui/core/Tooltip";
import { IAppState } from "../../types/stores";

///////////////////////////////////////////////////////////////////////////
///////// !!! PROPS INTERFACE MUST BE SAME AS LOCALNAVHARDLINK !!! ////////
///////////////////////////////////////////////////////////////////////////
// Fix me?
// This is because we're transitioning between SPA and non-SPA
// and SoftLink needs to call HardLink if we're not on the SPA
// Is there a better solution?

// TODO === Add FlowType | Change Active detection

type Props = {
    id: string;
    destination: string;
    isActive?: (match, location) => boolean;
    isSubHeader?: boolean;
    openTab?: boolean;
    isCollapsed?: boolean;
    iconClass?: string;
    text: string;
    collapsedIcon?: string;
    feature?: string | Array<string>;
    featureArrayMode?: "AND" | "OR";
    removeLi?: boolean;
};

const LocalNavSoftLink: React.FC<Props> = (props: Props) => {
    const {
        id,
        destination,
        isActive,
        isSubHeader,
        openTab,
        isCollapsed,
        iconClass,
        text,
        collapsedIcon,
        feature,
        featureArrayMode,
        removeLi,
    } = props;
    const isOnSPA = useSelector((state: IAppState) => state.vars.isOnSPA);

    if (!isOnSPA) {
        const modifiedProps = { ...props };
        if (modifiedProps.destination != null) {
            modifiedProps.destination = ("/ae/" + modifiedProps.destination).replace(/\/+/g, "/"); // XXX Hack, need something better than this, very fragile
        }
        return <LocalNavHardLink {...modifiedProps} />;
    }

    const navLink = (
        <NavLink
            to={destination}
            className={id !== "" && id != null ? id : ""}
            activeClassName="active"
            isActive={isActive}
            id={id}
            style={!isSubHeader ? { color: "#575755", textDecoration: "none" } : { color: "#FFF" }}
            target={openTab ? "_blank" : ""}
        >
            {!isCollapsed ? (
                <span style={!isSubHeader ? { color: "#575755", textDecoration: "none" } : { color: "#FFF" }}>
                    {iconClass ? <i className={iconClass} /> : null}
                    {text}
                </span>
            ) : (
                <Tooltip title={text} style={{ fontSize: 12 }} placement="right">
                    {collapsedIcon ? (
                        <img className={`collapsed-icon`} src={collapsedIcon} />
                    ) : iconClass ? (
                        <i className="material-icons">{iconClass}</i>
                    ) : (
                        <div />
                    )}
                </Tooltip>
            )}
        </NavLink>
    );

    return (
        <FeatureGate feature={feature} featureArrayMode={featureArrayMode}>
            {removeLi ? navLink : <li>{navLink}</li>}
        </FeatureGate>
    );
};

export default LocalNavSoftLink;
