import React from "react";
import { useSelector } from "react-redux";

import { IAppState } from "../../types/stores";

interface IPassedComponent {
    WrappedComponent?: { name?: any };
}

const withModalWrapper: any =
    <T extends {}>(PassedComponent: React.ComponentType<T> & IPassedComponent, modalName: string) =>
    (props: any) => {
        PassedComponent.displayName =
            PassedComponent && PassedComponent.WrappedComponent
                ? PassedComponent.WrappedComponent.name
                : PassedComponent.displayName;

        // props
        const isOpen = useSelector<IAppState, boolean>(
            state => state.modals.filter(item => item.modalType == modalName).length == 1
        );
        const modalProps = useSelector<IAppState, any>(state => {
            const myModals = state.modals.filter(item => item.modalType == modalName);
            if (myModals.length == 1) {
                return myModals[0].modalProps;
            }
            return {};
        });
        return <PassedComponent isOpen={isOpen} modalProps={modalProps} {...props} />;
    };

export default withModalWrapper;
