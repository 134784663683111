// States for tree loading behaviors
const TOGGLE_FLOW_TREE_LOADING = "TOGGLE_FLOW_TREE_LOADING";
const TOGGLE_LAYOUT_TREE_LOADING = "TOGGLE_LAYOUT_TREE_LOADING";
const SET_FLOW_LOADING_EXCLUDE = "SET_FLOW_LOADING_EXCLUDE";

function treeBehaviors(
    state = {
        isFlowTreeLoading: false,
        isFlowTreeLoadingExclude: false,
        isLayoutTreeLoading: false,
    },
    action
) {
    switch (action.type) {
        case TOGGLE_FLOW_TREE_LOADING:
            return Object.assign({}, state, {
                isFlowTreeLoading: action.isLoading,
            });
        case TOGGLE_LAYOUT_TREE_LOADING:
            return Object.assign({}, state, {
                isLayoutTreeLoading: action.isLoading,
            });
        case SET_FLOW_LOADING_EXCLUDE:
            return Object.assign({}, state, {
                isFlowTreeLoadingExclude: action.isFlowTreeLoadingExclude,
            });
        default:
            return state;
    }
}

export default treeBehaviors;
