function modals(state = [], action) {
    // action.modalType
    // action.modalProps
    switch (action.type) {
        case "SHOW_MODAL":
            // Only show one at most of any modal
            if (state.filter(item => item.modalType === action.modalType).length > 0) {
                return state;
            }
            return [
                ...state,
                {
                    modalType: action.modalType,
                    modalProps: action.modalProps,
                },
            ];
        case "HIDE_MODAL":
            return state.filter(item => item.modalType !== action.modalType);
        default:
            return state;
    }
}
export default modals;
