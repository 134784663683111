import { AutomationActions, IAutomationStore } from "../types/stores/manageAutomation";

export const SET_USER_AUTOMATION_ITEMS = "SET_USER_AUTOMATION_ITEMS";

const initialState: IAutomationStore = {
    userAutomationItems: [],
};

function automation(state = initialState, action: AutomationActions): IAutomationStore {
    switch (action.type) {
        case SET_USER_AUTOMATION_ITEMS:
            return Object.assign({}, state, {
                userAutomationItems: action.userAutomationItems,
            });
        default:
            return state;
    }
}

export default automation;
