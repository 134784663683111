// States for manage layouts designer tools
const TOGGLE_LAYOUTS_LISTING = "TOGGLE_LAYOUTS_LISTING";
const TOGGLE_LAYOUTS_LISTING_PINNABLE = "TOGGLE_LAYOUTS_LISTING_PINNABLE";
const LOADING_LAYOUT_OBJECTS = "LOADING_LAYOUT_OBJECTS";

function layoutsDesigner(
    state = {
        isLayoutsListingOpen: true,
        isLayoutsListingPinned: true,
        isLoadingLayoutObjects: false,
    },
    action
) {
    switch (action.type) {
        case TOGGLE_LAYOUTS_LISTING:
            return Object.assign({}, state, {
                isLayoutsListingOpen: action.isOpen,
            });
        case TOGGLE_LAYOUTS_LISTING_PINNABLE: {
            return Object.assign({}, state, {
                isLayoutsListingPinned: action.isPinned,
            });
        }
        case LOADING_LAYOUT_OBJECTS: {
            return Object.assign({}, state, {
                isLoadingLayoutObjects: action.isLoading,
            });
        }
        default:
            return state;
    }
}
export default layoutsDesigner;
