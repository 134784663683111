import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import ofilter from "../helpers/ofilter";
import update from "immutability-helper";
import { combineReducers } from "redux";
import { getFlowItemsForSelectedFlow } from "./flowItems";
// import tryParseJSON from "../helpers/tryParseJSON";
import type { FlowExportDistributionPlatform, FlowItem } from "../types/flowTypes";

//reducer for flow-export-distribution-platforms
const myGenericReducer = makeReducerFor("FLOW_EXPORT_DISTRIBUTION_PLATFORM", "FlowExportDistributionPlatformId");
const customActions = (state = {}, action) => {
    switch (action.type) {
        //override FLOW_ITEM_ID_CHANGE to update the corresponding ids in any distribution platform
        case "FLOW_ITEM_ID_CHANGE": {
            const updates = {};
            let madeAnUpdate = false;
            for (const idChange of action.idChanges) {
                if (idChange == null || !Array.isArray(idChange) || idChange.length < 2) {
                    console.warn(`$FLOW_ITEM_ID_CHANGE: idChange is of incorrect form`); // eslint-disable-line no-console
                    continue;
                }
                const oldKey = idChange[0];
                const newKey = idChange[1];

                // Look for any distribution platform to upate its FlowItemId
                const stateKeysToUpdate = Object.keys(ofilter(v => v.FlowItemId == oldKey, state));
                if (stateKeysToUpdate.length == 0) {
                    continue;
                }
                for (const key of stateKeysToUpdate) {
                    madeAnUpdate = true;
                    updates[key] = {
                        FlowItemId: { $set: newKey },
                    };
                }
            }
            return madeAnUpdate ? update(state, updates) : state;
        }
        default:
            return state;
    }
};
const myCustomReducer = combineReducers({
    byId: customActions,
});

const myReducer = (state = {}, action) => myCustomReducer(myGenericReducer(state, action), action);
export default myReducer;

export const getFlowExportDistributionPlatformsArray = createSelector(
    state => state.flowExportDistributionPlatforms.byId,
    (flowExportDistributionPlatformsById: {|
        [number]: FlowExportDistributionPlatform,
    |}): Array<FlowExportDistributionPlatform> => {
        const r: Array<FlowExportDistributionPlatform> = Object.values(flowExportDistributionPlatformsById);
        return r;
    }
);

export const getFlowExportDistributionPlatformsForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowExportDistributionPlatformsArray(state),
    (
        flowItems: Array<FlowItem>,
        distributionPlatforms: Array<FlowExportDistributionPlatform>
    ): Array<FlowExportDistributionPlatform> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        const result = distributionPlatforms.filter(x => itemIds.includes(x.FlowItemId));
        return result;
    }
);
