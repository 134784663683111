import React from "react";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const OffIcon = (
    <i
        className="material-icons"
        style={{
            color: "#000",
            fontSize: "12px",
            position: "relative",
        }}
    >
        close
    </i>
);

const StyledSwitch = withStyles({
    switchBase: {
        color: grey[300],
        opacity: 1,
        "&$checked": {
            color: grey[500],
        },
        "&$checked + $track": {
            backgroundColor: grey[400],
        },
    },
    checked: {},
    track: {},
})(Switch);

const IconSwitch = ({ id, name, style, checked, onChange, disabled }) => (
    <StyledSwitch
        id={id}
        name={name}
        style={style}
        checkedIcon={
            <span className="MuiSwitch-thumb">
                <i
                    className="material-icons"
                    style={{
                        color: "#FFF",
                        fontSize: "12px",
                        position: "relative",
                    }}
                >
                    done
                </i>
            </span>
        }
        icon={<span className="MuiSwitch-thumb">{OffIcon}</span>}
        checked={checked}
        onChange={onChange}
        color="primary"
        disabled={disabled}
    />
);

IconSwitch.defaultProps = {
    disabled: false,
};

IconSwitch.propTypes = {
    id: PropTypes.string.isRequired, //id of the element - ensure distinct
    name: PropTypes.string.isRequired, //name of the input
    style: PropTypes.object, //other styles to be applied
    checked: PropTypes.bool.isRequired, //is the switch on or off
    onChange: PropTypes.func.isRequired, //what to do on toggle
    disabled: PropTypes.bool, //can the switch be toggled
};

export default IconSwitch;
