import React from "react";
import { useDispatch, useSelector } from "react-redux";
import FieldListEntry from "./FieldListEntry";
import FieldListFolder from "./FieldListFolder";
import { setSelectedFields } from "../../actions/fieldTreeActions";
import { fieldMatchesSearchString } from "../../reducers/fields";
import { getTreeSearch, getModalTreeSearch } from "../../reducers/search";
import { createDeepEqualSelector } from "../../helpers/typedHelpers";
import { FieldVisibilityTypes } from "../../helpers/constants";
import { IAppState } from "../../types/stores/index";
import { IFieldsStore } from "../../types/stores/fields";
import { IFieldLabelsWithProperties } from "../../types/stores/fieldLabels";
import { IFieldLabelsWithProperties3 } from "../../types/stores/fieldLabels3";
import { IField } from "../../types/stores/fieldTypes";
import type { FlowFilter } from "../../types/flowTypes";
import tryParseJSON from "../../helpers/tryParseJSON";
import { FieldClassification } from "../../enums/FieldClassifications";
import { getFlowFiltersForSelectedFlow } from "../../reducers/flowFilters";

type Props = {
    editable: boolean;
    fieldKey: string | number;
    treeViewOnUpdate: () => void;
    showAllDuringSearch: boolean;
    TreeType: number;
    isPIIOnly: boolean;
    showHiddenFields: boolean;
    ctrlShift: boolean;
    isModal: boolean;
    showTabs: boolean;
    tabType: number;
};

interface IChildrenIDStruct {
    folderIds: Array<string | number>;
    fieldIds: Array<string | number>;
}

const FieldList: React.FC<Props> = (props: Props) => {
    const {
        editable,
        treeViewOnUpdate,
        showAllDuringSearch,
        TreeType,
        showHiddenFields,
        ctrlShift,
        isModal,
        showTabs,
        tabType,
    } = props;

    const dispatch = useDispatch();

    const getMyFieldIDs = makeGetMyFieldIDs(tabType, showTabs);
    const getFields = makeGetFields();

    const fields: IFieldsStore = useSelector((state: IAppState) => getFields(state, props));
    const childrenIdStruct: IChildrenIDStruct = useSelector((state: IAppState) => getMyFieldIDs(state, props));
    const selectedFields = useSelector((state: IAppState) => state.selected.selectedFields);
    const flowFilters = useSelector((state: IAppState) => state.flowFilters);
    const flowFiltersInFlow = useSelector((state: IAppState) => getFlowFiltersForSelectedFlow(state));

    const onCtrlShift = (lastSelected: number, newSelected: number): void => {
        const { fieldIds } = childrenIdStruct;

        const fieldsByFolder = Object.values(fields.byFolder);
        const fieldList = fieldIds.map(x => {
            const field = fieldsByFolder.find(y => y.workid! == x);
            return field ? field.fieldkey : null;
        });

        const indexA = fieldList.indexOf(lastSelected);
        const indexB = fieldList.indexOf(newSelected);
        if (indexA > indexB) {
            for (let i = indexB; i <= indexA; i++) {
                let field = fieldList[i];
                if (!selectedFields.includes(field)) {
                    dispatch(setSelectedFields(field));
                }
            }
        } else if (indexB > indexA) {
            for (let i = indexA; i <= indexB; i++) {
                let field = fieldList[i];
                if (!selectedFields.includes(field)) {
                    dispatch(setSelectedFields(field));
                }
            }
        }
    };

    const renderField = (workid: string | number) => {
        const { byFolder, byId } = fields;
        const flowFiltersById = flowFilters.byId;
        const field = byFolder[workid];
        let grayoutField = false;
        if (field.IsThirdParty && !editable) {
            const theseFilters = Object.values<FlowFilter>(flowFiltersById);
            for (const theseFilter of theseFilters) {
                if (flowFiltersInFlow.filter(x => x.FlowItemId == theseFilter.FlowItemId).length > 0) {
                    const includeObject = tryParseJSON(theseFilter.FlowFilterCriteria) || {};

                    if (includeObject.rules) {
                        for (const rule of includeObject.rules) {
                            let filterField = byId[rule.id];
                            if (filterField?.IsThirdParty && field.companyid !== filterField.companyid) {
                                grayoutField = true;
                                break;
                            }
                        }
                    }
                }
                if (grayoutField) break;
            }
        }

        return (
            <FieldListEntry
                fieldKey={field.fieldkey}
                key={workid}
                TreeType={TreeType}
                showHiddenFields={showHiddenFields}
                ctrlShift={field.IsThirdParty ? false : ctrlShift}
                onCtrlShift={onCtrlShift}
                isGrayout={grayoutField}
            />
        );
    };

    const renderFolder = (workid: string | number) => (
        <FieldListFolder
            key={workid}
            fieldKey={workid}
            TreeType={TreeType}
            showHiddenFields={showHiddenFields}
            showAllDuringSearch={showAllDuringSearch}
            treeViewOnUpdate={treeViewOnUpdate}
            editable={editable}
            ctrlShift={ctrlShift}
            isModal={isModal}
            tabType={tabType}
            showTabs={showTabs}
        />
    );

    if (!fields || !fields.byParent) {
        return null;
    }

    const { folderIds, fieldIds } = childrenIdStruct;
    if (folderIds.length + fieldIds.length == 0) {
        return <span>(empty)</span>;
    }

    const folderElements = folderIds.map(renderFolder);
    const fieldElements = fieldIds.map(renderField);

    return (
        <div>
            {folderElements}
            {fieldElements}
        </div>
    );
};

export const sortChildIds = (
    childIds: Array<string | number>,
    fieldsById: { [workId: number]: IField }
): Array<string | number> =>
    childIds.sort((a, b) => {
        if (a == "1") {
            return -1;
        }
        if (b == "1") {
            return 1;
        } // Force experian to top
        if (fieldsById[a]["sort"] > fieldsById[b]["sort"]) {
            return 1;
        } // Sort by "Sort"
        if (fieldsById[a]["sort"] < fieldsById[b]["sort"]) {
            return -1;
        }

        const stringA: string | null = fieldsById[a]["text"];
        const stringB: string | null = fieldsById[b]["text"];

        if (!stringA || !stringB) {
            return 1;
        }
        return stringA.localeCompare(stringB); // Tiebreak by name
    });

const makeGetFields = () =>
    createDeepEqualSelector(
        (state: IAppState, props: { TreeType: number }) => {
            let fields: IFieldsStore;
            if (props.TreeType == FieldVisibilityTypes.EXPORT) {
                fields = state.layoutfields;
            } else if (props.TreeType == FieldVisibilityTypes.REPORT) {
                fields = state.reportFields;
            } else {
                fields = state.fields;
            }
            return fields;
        },
        (fields: IFieldsStore) => fields
    );

const makeGetMyFieldIDs = (tabType: number, showTabs: boolean) =>
    createDeepEqualSelector(
        //@ts-ignore
        (state: IAppState, props: Props) => {
            let fields: IFieldsStore;
            if (props.TreeType == FieldVisibilityTypes.EXPORT) {
                fields = state.layoutfields;
            } else if (props.TreeType == FieldVisibilityTypes.REPORT) {
                fields = state.reportFields;
            } else {
                fields = state.fields;
            }
            return fields.byFolder;
        },
        (state: IAppState, props: Props) => {
            let fields: IFieldsStore;
            if (props.TreeType == FieldVisibilityTypes.EXPORT) {
                fields = state.layoutfields;
            } else if (props.TreeType == FieldVisibilityTypes.REPORT) {
                fields = state.reportFields;
            } else {
                fields = state.fields;
            }

            if (!fields || !fields.byParent || !Object.prototype.hasOwnProperty.call(fields.byParent, props.fieldKey)) {
                return null;
            }
            return fields.byParent[props.fieldKey];
        },
        (state: IAppState, props: Props) => (props.isModal ? getModalTreeSearch(state) : getTreeSearch(state)),
        (_, props: Props) => props.showAllDuringSearch,
        (_, props: Props) => props.isPIIOnly,
        (_, props: Props) => props.editable,
        (state: IAppState) => state.adminDesigner.fieldVisibilityFilter,
        (state: IAppState) => state.fieldsByCompany.enabledFieldVisibilityTypes,
        (state: IAppState) => state.search.selectedFieldLabels,
        (state: IAppState) => state.search.selectedFieldLabels3,
        (state: IAppState) => state.fieldLabels.labels,
        (state: IAppState) => state.fieldLabels3.labels,
        (state: IAppState) => state.session.companyId,
        (
            fieldsById: { [workId: number]: IField },
            childIds: Array<string | number>,
            treeSearch: string | Array<string>,
            showAllDuringSearch: boolean,
            isPIIOnly: boolean,
            editable: boolean,
            fieldVisibilityFilter: Array<number>,
            enabledFieldVisibilityTypes: { [fieldId: number]: Array<number> },
            fieldLabelIds: Array<number>,
            fieldLabelIds3: number,
            fieldLabels: Array<IFieldLabelsWithProperties>,
            fieldLabels3: Array<IFieldLabelsWithProperties3>,
            sessionCompanyId: number | undefined
        ) => {
            if (childIds == null) {
                return { folderIds: [], fieldIds: [] };
            }
            if (isPIIOnly) {
                let piiFields: Array<IField> = [];
                Object.keys(fieldsById).map(x => {
                    const field: IField = fieldsById[x];
                    if (field.IsPII) {
                        piiFields.push(field);
                    }
                });
                childIds = piiFields.map(x => x.workid!.toString());
            }

            childIds = sortChildIds(childIds, fieldsById);
            let folderIds = childIds.filter(x => !fieldsById[x].isLeaf);
            let fieldIds = childIds.filter(x => fieldsById[x].isLeaf);
            if (showTabs) {
                const isExperianFolder = item => !item.isLeaf && item.companyid === 0;
                const isExperianField = item => item.isLeaf && item.companyid === 0;
                const isMarketplaceFolder = item => !item.isLeaf && item.IsThirdParty && item.companyid !== 0;
                const isMarketplaceField = item =>
                    item.isLeaf && item.FieldClassification === FieldClassification.ThirdParty && item.companyid !== 0;
                const isMyDataFolder = item => !item.isLeaf && !item.IsThirdParty && item.companyid != 0;
                const isMyDataField = item =>
                    item.isLeaf && item.companyid != 0 && item.FieldClassification !== FieldClassification.ThirdParty;
                const isOtherFolder = item =>
                    !item.isLeaf && item.companyid != 0 && !item.IsThirdParty && item.companyid !== sessionCompanyId;
                const isOtherField = item =>
                    item.isLeaf &&
                    item.companyid != 0 &&
                    item.FieldClassification !== FieldClassification.ThirdParty &&
                    item.companyid !== sessionCompanyId;

                if (tabType == 0) {
                    //Experian
                    folderIds = childIds.filter(x => isExperianFolder(fieldsById[x]));
                    fieldIds = childIds.filter(x => isExperianField(fieldsById[x]));
                } else if (tabType == 1) {
                    //Marketplace, Need top level items and any third party data sources
                    folderIds = childIds.filter(x => isMarketplaceFolder(fieldsById[x]));
                    fieldIds = childIds.filter(x => isMarketplaceField(fieldsById[x]));
                } else if (tabType == 2) {
                    //my data
                    folderIds = childIds.filter(x => isMyDataFolder(fieldsById[x]));
                    fieldIds = childIds.filter(x => isMyDataField(fieldsById[x]));
                } else if (tabType == 3) {
                    // Other: This tab is visible only for Admin > Edit Fields.
                    // Filter items with companyid not 0 ,sessionCompanyId, nor third - party
                    folderIds = childIds.filter(x => isOtherFolder(fieldsById[x]));
                    fieldIds = childIds.filter(x => isOtherField(fieldsById[x]));

                    //Check for misconfigured fields
                    if (fieldIds.length === 0) {
                        //This checks for mismatches between the folders and fields in the hierarchy.
                        const hierarchyIsBroken = item => {
                            // Retrieve the parent item from fieldsById
                            const parent = fieldsById[item.parent];

                            if (!parent) {
                                return false;
                            }

                            // Check if the hierarchy is broken based on the defined rules
                            const hierarchyMismatch =
                                (!isExperian(item) && isExperian(parent)) ||
                                (isExperian(item) && !isExperian(parent)) ||
                                (!isMarketplace(item) && isMarketplace(parent)) ||
                                (isMarketplace(item) && !isMarketplace(parent)) ||
                                (!isMyData(item) && isMyData(parent)) ||
                                (isMyData(item) && !isMyData(parent));

                            if (hierarchyMismatch) {
                                return true;
                            }

                            // Recursively check the parent item
                            return hierarchyIsBroken(parent);
                        };

                        const isExperian = item => isExperianFolder(item) || isExperianField(item);
                        const isMarketplace = item => isMarketplaceFolder(item) || isMarketplaceField(item);
                        const isMyData = item => isMyDataFolder(item) || isMyDataField(item);

                        fieldIds = Array.from(
                            new Set(
                                Object.keys(fieldsById)
                                    .map(Number)
                                    .filter(id => {
                                        const item = fieldsById[id];
                                        return hierarchyIsBroken(item);
                                    })
                            )
                        );
                    }
                }
            } else if (!editable) {
                // MarketPlace feature is Off, filter out any ThirdParty data
                folderIds = childIds.filter(x => !fieldsById[x].isLeaf && !fieldsById[x].IsThirdParty);
                fieldIds = childIds.filter(x => fieldsById[x].isLeaf && !fieldsById[x].IsThirdParty);
            }

            const selectedFieldLabels = fieldLabels
                .filter(x => fieldLabelIds.includes(x.FieldLabelId))
                .map(x => x.FieldLabelName);

            const selectedFieldLabels3 = fieldLabels3
                .filter(x => fieldLabelIds3 == x.FieldLabelId)
                .map(x => x.FieldLabelName);

            if (selectedFieldLabels.length > 0) {
                fieldIds = fieldIds.filter(x => {
                    const fieldIdLabels = fieldsById[x].FieldLabels && fieldsById[x].FieldLabels.split(", ");
                    return fieldIdLabels ? fieldIdLabels.some(label => selectedFieldLabels.includes(label)) : false;
                });
            }

            if (selectedFieldLabels3.length > 0) {
                fieldIds = fieldIds.filter(x => {
                    //const fieldIdLabels2 = fieldsById[x].UmbrellaFieldId && fieldsById[x].UmbrellaFieldId.split(", ");
                    const fieldIdLabels3 = fieldsById[x].UmbrellaFieldId != null ? fieldsById[x].UmbrellaFieldId : "";
                    return fieldIdLabels3 ? fieldIdLabels3 == Number(selectedFieldLabels3) : false;
                });
            }

            if (editable) {
                fieldIds = fieldIds.filter(
                    x =>
                        // x isn't a fieldkey any more, its a workid
                        (fieldsById[x] &&
                            // none option is selected in visibility filter and field doesn't have visibility types assigned
                            fieldVisibilityFilter.includes(FieldVisibilityTypes.NONE) &&
                            !enabledFieldVisibilityTypes[fieldsById[x].fieldkey]) ||
                        // field has visibility types assigned
                        (enabledFieldVisibilityTypes[fieldsById[x].fieldkey] &&
                            // the field has at least one of the selected visibility types in the filter
                            fieldVisibilityFilter.filter(f =>
                                enabledFieldVisibilityTypes[fieldsById[x].fieldkey].includes(f)
                            ).length > 0)
                );
            }

            if (treeSearch && !showAllDuringSearch) {
                fieldIds = fieldIds.filter(fieldId => fieldMatchesSearchString(fieldsById[fieldId], treeSearch));
            }
            return { folderIds, fieldIds };
        }
    );

export default FieldList;
