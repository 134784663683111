import React from "react";
import ThingListFolder from "../tree-general/ThingListFolder";

import { connect } from "react-redux";
import { createSelector } from "reselect";

import { getTreeSearch } from "../../reducers/search";
import { layoutFolderIdContainsSearch } from "../../reducers/exportlayouts";
import { folderReducerKeyForType } from "../../helpers/folder";
import { allowedToDeleteFolder } from "../../reducers/_folderGeneral";

import type { Folder } from "../../types/types";

type Props = {
    // Flow
    folderType: string,
    folder: Folder, // The current folder to draw
    treeViewOnUpdate: () => void, // Used for odd/even row coloring
    showAllDuringSearch?: boolean, // Used for folder search, searched folders are told to show all contents, even if they don't match
    // Redux
    folderContainsSearch: boolean, // Do any children of this folder match the current treeSearch?
};

const LayoutListFolder = (props: Props) => (
    <ThingListFolder listComponent="FolderLayoutList" renamingAndHasNoChildren={false} {...props} />
);

const makeGetFolderContainsSearch = () =>
    createSelector(
        state => state.layouts,
        (state, props) => {
            const folderReducerKey = folderReducerKeyForType(props.folderType);
            return state.folders[folderReducerKey];
        },
        (state, props) => props.folder.id,
        state => getTreeSearch(state),
        (layoutState, folders, folderId, treeSearch) => {
            if (treeSearch == null || treeSearch == "") {
                return false;
            }
            return layoutFolderIdContainsSearch(layoutState, folders, folderId, treeSearch);
        }
    );

const makeGetRenamingAndHasNoChildren = () =>
    createSelector(
        state => state.layouts,
        (state, props) => state.folders[folderReducerKeyForType(props.folderType)],
        (state, props) => props.folder.id,
        state => state.vars.renamingAudienceFolderId,
        state => state.session.userId,
        (
            layouts: Object,
            allFolders: Array<Folder>,
            folderId: number,
            renamingAudienceFolderId: number,
            userId: number
        ): boolean => {
            // Don't bother computing if not renaming - optimization to avoid recursive search on lots of updates
            if (renamingAudienceFolderId != folderId) {
                return false;
            }
            return allowedToDeleteFolder({ layouts, audienceFolders: allFolders }, folderId, userId);
        }
    );

import type { MapStateToProps } from "react-redux";
const mapStateToProps: MapStateToProps<*, *, *> = () => {
    const getFolderContainsSearch = makeGetFolderContainsSearch();
    const getRenamingAndHasNoChildren = makeGetRenamingAndHasNoChildren();
    return (state, ownProps) => ({
        folderContainsSearch: getFolderContainsSearch(state, ownProps),
        renamingAndHasNoChildren: getRenamingAndHasNoChildren(state, ownProps),
    });
};
const mapDispatchToProps = null;
const LayoutListFolderC = connect(mapStateToProps, mapDispatchToProps)(LayoutListFolder);

export default LayoutListFolderC;
