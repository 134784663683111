import clone from "../helpers/clone";
import mergeDeep from "../helpers/mergeDeep";
import ofilter from "../helpers/ofilter";
import { combineReducers } from "redux";
import update from "immutability-helper";

const makeReducerFor = (actionPrefix: string, idField: string) => {
    const thingsById = (state = {}, action) => {
        switch (action.type) {
            case `${actionPrefix}_REPLACE_LIST`: {
                // Directly replace entire list, but keep "0" or below if we have it (an unsaved one)
                let newState = action.things;
                if (Array.isArray(newState) && newState.length == 0) {
                    // Bug, when loading no flows, shape is wrong
                    newState = {};
                }

                const belowEqualZeroIds: Array<number> = Object.keys(state)
                    .map(x => parseInt(x, 10))
                    .filter(x => x <= 0);
                for (const id of belowEqualZeroIds) {
                    if (typeof state[id] === "object") {
                        newState[id] = clone(state[id]);
                    }
                }

                return newState;
            }
            case `${actionPrefix}_MERGEOVERWRITE_LIST`: {
                // Keep the old list, but things that are provided are overwritten entirely
                let things = clone(action.things);
                if (Array.isArray(things) && things.length == 0) {
                    // Bug, when loading no flows, shape is wrong
                    things = {};
                }
                let newState = clone(state);
                for (const campaignIdToDelete of Object.keys(things)) {
                    delete newState[campaignIdToDelete];
                }
                newState = mergeDeep(newState, things);
                return newState;
            }
            case `${actionPrefix}_MERGE_LIST`: {
                // mergeBehavior == "merge" Merge in data
                // Don't merge in data from server over campaigns that have unsaved local changes
                let things = clone(action.things);
                if (Array.isArray(things) && things.length == 0) {
                    // Bug, when loading no flows, shape is wrong
                    things = {};
                }
                const stateWithUnsavedChanges = ofilter(v => v["hasUnsavedChanges"], state);
                for (const campIdWithUnsavedChanges of Object.keys(stateWithUnsavedChanges)) {
                    delete things[campIdWithUnsavedChanges];
                }
                const newState = mergeDeep(clone(state), things);
                return newState;
            }
            case `${actionPrefix}_CHANGE_ATTRIBUTE`: {
                return update(state, {
                    [action.id]: {
                        [action.attribute]: { $set: action.value },
                    },
                });
            }
            case `${actionPrefix}_CHANGE_MULTIPLE_ATTRIBUTE`: {
                let updatesToMake = {};
                for (const update of action.updates) {
                    updatesToMake[update.attribute] = { $set: update.value };
                }

                return update(state, {
                    [action.id]: updatesToMake,
                });
            }
            case `${actionPrefix}_ADD`: {
                return update(state, {
                    [action.id]: {
                        $set: action.thing,
                    },
                });
            }
            case `${actionPrefix}_DELETE`: {
                return update(state, {
                    $unset: [action.id],
                });
            }
            case `${actionPrefix}_ID_CHANGE`: {
                if (action.idChanges == null) {
                    console.warn(`${actionPrefix}_ID_CHANGE: Can't find idChanges`); // eslint-disable-line no-console
                    return state;
                }

                const newState = clone(state);
                for (const idChange of action.idChanges) {
                    if (idChange == null || !Array.isArray(idChange) || idChange.length < 2) {
                        console.warn(`${actionPrefix}_ID_CHANGE: idChange is of incorrect form`); // eslint-disable-line no-console
                        continue;
                    }
                    const oldKey = idChange[0];
                    const newKey = idChange[1];

                    newState[newKey] = clone(newState[oldKey]);
                    delete newState[oldKey];

                    newState[newKey][idField] = newKey;
                }

                return newState;
            }
            case `${actionPrefix}_COPY_TO_NEW_ID`: {
                const newId = action.newId;
                const oldId = action.oldId;
                if (newId == null || oldId == null || state[oldId] == null) {
                    console.warn(`${actionPrefix}_COPY_TO_NEW_ID: Invalid action`); // eslint-disable-line no-console
                    return state;
                }

                const newRows = {};
                newRows[newId] = clone(state[oldId]);
                return update(state, {
                    $merge: newRows,
                });
            }
            default:
                return state;
        }
    };
    const theReducer = combineReducers({
        byId: thingsById,
    });
    return theReducer;
};
export default makeReducerFor;
