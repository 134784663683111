import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsArray, getFlowItemsForSelectedFlow } from "./flowItems";
import { getArrayOfClientVariables } from "./clientVariables";
import { getFlowRelationsForSelectedFlow } from "./flowRelations";
import type {
    FlowOfferMerge,
    FlowItem,
    FlowErrorsByItemId,
    FlowRelation,
    FlowItemOfferCode,
    FlowItemClientVariable,
} from "../types/flowTypes";
import { IClientVariable } from "../types/stores/vars";
const myGenericReducer = makeReducerFor("FLOW_OFFER_MERGE", "FlowOfferMergeId");
import subItemReducer from "./_genericFlowSubItemReducer";
import { getFlowItemClientVariablesForSelectedFlow, getFlowItemClientVariablesArray } from "./flowItemClientVariables";
import { getFlowItemOfferCodesForSelectedFlow, getFlowItemOfferCodesArray } from "./flowItemOfferCodes";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowOfferMergesArray = createSelector(
    state => state.flowOfferMerges.byId,
    (flowOfferMergesById: {| [number]: FlowOfferMerge |}): Array<FlowOfferMerge> => {
        const r: Array<FlowOfferMerge> = Object.values(flowOfferMergesById);
        return r;
    }
);

export type FlowOfferMergesByItemId = {
    [number]: Array<FlowOfferMerge>,
};

export const getFlowOfferMergesByFlowItemId = createSelector(
    state => getFlowOfferMergesArray(state),
    (flowOfferMerges: Array<FlowOfferMerge>): FlowOfferMergesByItemId =>
        flowOfferMerges.reduce((acc, row) => {
            if (acc[row.FlowItemId] == null) {
                acc[row.FlowItemId] = [];
            }
            acc[row.FlowItemId].push(row);
            return acc;
        }, {})
);

export const getFlowOfferMergesForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowOfferMergesByFlowItemId(state),
    (
        selectedFlow: number,
        flowItems: Array<FlowItem>,
        flowOfferMergesByItemId: FlowOfferMergesByItemId
    ): Array<FlowOfferMerge> => {
        let result = [];
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        for (const itemId of itemIds) {
            const merges = flowOfferMergesByItemId[itemId];
            if (merges != null) {
                result = result.concat(merges);
            }
        }
        return result;
    }
);

export const getFlowOfferMergesForSelectedFlowItem = createSelector(
    state => state.selected.flowItem,
    state => getFlowOfferMergesByFlowItemId(state),
    (selectedFlowItem: number, flowOfferMergesByItemId: FlowOfferMergesByItemId): Array<FlowOfferMerge> =>
        flowOfferMergesByItemId[selectedFlowItem]
);

const errorChecker = (
    flowOfferMerges: Array<FlowOfferMerge>,
    flowItems: Array<FlowItem>,
    flowRelations: Array<FlowRelation>,
    clientVariables: Array<IClientVariable>,
    flowItemClientVariables: Array<IClientVariable>,
    flowItemOfferCodes: Array<FlowItemOfferCode>,
    fieldsById: Array<Object>,
    hasUnknownFieldFeature: boolean
): FlowErrorsByItemId => {
    const errors = {};
    const itemIds = flowItems.filter(fi => fi.FlowItemType.toLowerCase() == "offermerge").map(fi => fi.FlowItemId);
    for (const itemId of itemIds) {
        const theseMerges = flowOfferMerges.filter(x => x.FlowItemId == itemId);
        errors[itemId] = validateFlowOfferMerges(
            itemId,
            theseMerges,
            flowRelations,
            clientVariables,
            flowItemClientVariables,
            flowItemOfferCodes,
            fieldsById,
            hasUnknownFieldFeature
        );
    }
    return errors;
};

export const getOfferMergeErrorsForSelectedFlow = createSelector(
    state => getFlowOfferMergesForSelectedFlow(state),
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowRelationsForSelectedFlow(state),
    state => getArrayOfClientVariables(state),
    state => getFlowItemClientVariablesForSelectedFlow(state),
    state => getFlowItemOfferCodesForSelectedFlow(state),
    state => state.fields.byId,
    state => state.session.enabledFeatures.includes("offer-unknown-field-validation") || false,
    errorChecker
);

export const getOfferMergeErrorsForAllFlows = createSelector(
    state => getFlowOfferMergesArray(state),
    state => getFlowItemsArray(state),
    state => getFlowRelationsForSelectedFlow(state),
    state => getArrayOfClientVariables(state),
    state => getFlowItemClientVariablesArray(state),
    state => getFlowItemOfferCodesArray(state),
    state => state.fields.byId,
    state => state.session.enabledFeatures.includes("offer-unknown-field-validation") || false,
    errorChecker
);

//////////////////// HELPERS /////////////////////////////

// ***** Must pass an array of all FlowOfferMerge rows belonging to a particular itemId. *****
const validateFlowOfferMerges = (
    flowItemId: number,
    flowOfferMerges: Array<FlowOfferMerge>,
    flowRelations: Array<FlowRelation>,
    clientVariables: Array<any>, // Array<IClientVariable>
    flowItemClientVariables: Array<FlowItemClientVariable>,
    flowItemOfferCodes: Array<FlowItemOfferCode>,
    fieldsById: Array<Object>,
    hasUnknownFieldFeature: boolean
): Array<string> => {
    const errors = [];

    const offerCodes = Object.values(clientVariables).filter(x => x.VariableScope == "OfferCode");

    if (offerCodes.length == 0) {
        return ["No offer codes configured."];
    } else if (flowOfferMerges.length == 0) {
        return ["Must have at least one offer assigned via parent."];
    }

    let needsParent = false;
    let hasOneNonSuppression = false;
    flowOfferMerges.forEach(x => {
        const mergeRelations = flowRelations.filter(z => z.ParentFlowItemId == 0 && z.ChildFlowItemId == x.FlowItemId);
        if (mergeRelations.length > 0 && !needsParent) {
            // Impossible?
            errors.push("Offer items must have a parent item assigned.");
            needsParent = true;
        }
        if (!x.FlowOfferMergeIsSuppresion && !hasOneNonSuppression) {
            hasOneNonSuppression = true;
        }
    });
    if (!hasOneNonSuppression) {
        errors.push("Offer Merge items must have at least one item that isn't a suppression.");
    }

    const parentFlowItemIds = flowOfferMerges.map(x => x.ParentFlowItemId);
    const regexOffers = offerCodes.filter(x => x.Regex != null);
    let hasInvalidRegex = false;
    for (const offerCode of regexOffers) {
        const flowItemOfferCode = flowItemOfferCodes.find(
            x => x.VariableId == offerCode.Id && x.FlowItemId == flowItemId
        );
        if (flowItemOfferCode && flowItemOfferCode.IsVisible) {
            const regex = new RegExp(offerCode.Regex);
            if (
                flowItemClientVariables.filter(
                    x =>
                        offerCode.Id == x.VariableId &&
                        // for some reason, flowItemClientVariables are saved with FlowItemId = offer.ParentFlowItemId
                        parentFlowItemIds.includes(x.FlowItemId) &&
                        x.VariableValue &&
                        !regex.test(x.VariableValue.ValueString)
                ).length > 0
            ) {
                hasInvalidRegex = true;
                break;
            }
        }
    }

    if (hasInvalidRegex) {
        errors.push("Offers have invalid values.");
    }

    // Check for Offer Fields no longer available
    if (fieldsById && hasUnknownFieldFeature) {
        let hasUnknownFields = false;
        flowItemClientVariables.forEach(itemVariable => {
            // Not type Field or no field assigned
            if (itemVariable.VariableValue.Kind != "field" || itemVariable.VariableValue.FieldId == null) {
                return;
            }

            // Not Visible
            const itemOffer = flowItemOfferCodes.find(x => x.VariableId == itemVariable.VariableId);
            if (itemOffer && !itemOffer.IsVisible) {
                return;
            }

            // Not Found
            if (!fieldsById[itemVariable.VariableValue.FieldId]) {
                hasUnknownFields = true;
            }
        });

        if (hasUnknownFields) {
            errors.push("Offer has fields that are no longer available.");
        }
    }

    return errors;
};
