import { ISelectedReassignedUserStore } from "../types/stores/accountManage";

export const SET_REASSIGNED_ITEMS_TO = "SET_REASSIGNED_ITEMS_TO";

type SetReassignedItemsToAction = {
    type: typeof SET_REASSIGNED_ITEMS_TO;
    selectedUser: number;
};

type ActionType = SetReassignedItemsToAction;

function reassignedItemsTo(
    state: ISelectedReassignedUserStore = {
        reassiendUserId: null,
    },
    action: ActionType
): ISelectedReassignedUserStore {
    switch (action.type) {
        case SET_REASSIGNED_ITEMS_TO:
            return Object.assign({}, state, {
                reassiendUserId: action.selectedUser,
            });
        default:
            return state;
    }
}

export default reassignedItemsTo;
