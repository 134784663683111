import * as React from "react";

import * as audienceFolderActions from "../../actions/audienceFolderActions";
import * as actionCreators from "../../actions/actionCreators";
import * as flowActions from "../../actions/flowActions";
import * as layoutActions from "../../actions/exportLayoutActions2";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { DropTarget } from "react-dnd";
import { DragSource } from "react-dnd";
import { DragDropTypes } from "../../helpers/constants";

import { folderTypeToMenuId } from "../../helpers/folder";
import { ContextMenuTrigger } from "react-contextmenu";
import { folderReducerKeyForType } from "../../helpers/folder";
import { createDeepEqualSelector } from "../../helpers/typedHelpers";

import type { Folder } from "../../../types/types";
import type { Flow } from "../../types/flowTypes";
import type { ConnectDragSource, ConnectDropTarget } from "react-dnd";

type Props = {
    // Directly passed by user
    folder: Folder,
    children: React.Element<any>, // Force exactly one child
    // ?
    isOver: boolean,
    canDrop: boolean,
    // Injected by ReactDnD
    connectDragSource: ConnectDragSource,
    connectDropTarget: ConnectDropTarget,
    // Redux
    isInternal: boolean,
    companyId: number,
    userId: number,
    topLevelFolderIds: Array<number>,
    flows: { [number]: Flow },
    selectedFlowIds: Array<number>,
    // Redux AC
    requestMoveFolderToFolder: (folderType: string, folderId: number, parentFolderId: number) => void,
    requestMoveFlowToFolder: (flowId: number, folderId: number) => void,
    requestMoveFlowsToFolder: (flowIds: Array<number>, folderId: number) => void,
    requestMoveLayoutToFolder: (layoutId: number, folderId: number) => void,
};

class FolderDragDrop extends React.Component<Props> {
    render() {
        let { children, connectDropTarget, connectDragSource, isOver, canDrop } = this.props;
        const { folder } = this.props;
        // Pass isOver, canDrop props to child element
        if (children != null && typeof children == "object") {
            children = React.cloneElement(children, { isOver, canDrop });
        }

        const menuId = folderTypeToMenuId(folder.folderType);
        if (!menuId) {
            return children;
        }

        const rendered = (
            <div>
                <ContextMenuTrigger
                    id={menuId}
                    collect={props => props}
                    folderId={folder.id}
                    folderName={folder.name}
                    holdToDisplay={-1}
                >
                    {children}
                </ContextMenuTrigger>
            </div>
        );

        //return connectDragSource(connectDropTarget(rendered));
        // Stupid workaround for flow
        const a = connectDropTarget(rendered);
        if (a != null) {
            return connectDragSource(a);
        }
        return a;
    }
}

const checkFolderPermissions = (props: Props, userId: number, companyId: number) => {
    const folderBelongsToMe = props.folder.userId == userId && props.folder.userId == props.userId;
    const belongsToSameCompany = props.companyId == companyId;
    const folderIsCompanyTemplate = props.folder.isCompanyShare && props.folder.companyId == companyId;
    const folderIsSystemTemplateAndImInternal =
        props.folder.isCompanyShare && props.folder.companyId == 0 && props.isInternal && props.folder.level > 0;
    const folderIsInternalShare = props.folder.isInternalShare;
    return (
        (folderBelongsToMe && belongsToSameCompany) ||
        folderIsCompanyTemplate ||
        folderIsSystemTemplateAndImInternal ||
        //new rule is anyone can move folders to any internal shared folder
        folderIsInternalShare
    );
};

const flowsCanBeDropedInFolder = (folder: Folder, flows: Array<Flow>, userId: number) => {
    for (const flow of flows) {
        if (!flowCanBeDroppedInFolder(folder, flow, userId)) {
            return false;
        }
    }
    return true;
};

const flowCanBeDroppedInFolder = (folder: Folder, flow: Flow, userId: number) => {
    const flowCanBeDropped = folder.isHistorical
        ? flow.IsLockedPermanently && folder.userId == 0
        : !flow.IsLockedPermanently && folder.userId == userId;
    return flowCanBeDropped;
};

const folderTarget = {
    canDrop(props: Props, monitor) {
        const itemType = monitor.getItemType();

        if (itemType == DragDropTypes.LAYOUT) {
            // Layout is being dragged
            const dropped = monitor.getItem();
            if (props.folder.folderType != "L") {
                return false;
            }
            return (
                dropped.LayoutCompanyID == props.companyId &&
                (props.folder.companyId == 0 || props.folder.companyId == dropped.LayoutCompanyID)
            );
        }

        if (itemType == DragDropTypes.FOLDER) {
            // Folder dropped onto folder
            const dropped: Folder = monitor.getItem();
            if (dropped.folderType != props.folder.folderType) {
                return false;
            }
            if (dropped.folderType == "L") {
                return (
                    props.folder.id != dropped.id &&
                    (props.folder.companyId == dropped.companyId || props.folder.companyId == 0)
                );
            }
            return (
                props.folder.id != dropped.id &&
                props.folder.isCompanyShare == dropped.isCompanyShare &&
                ((props.folder.userId == dropped.userId &&
                    !props.folder.isCompanyShare &&
                    !dropped.isCompanyShare) /* Is Mine and is not shared */ ||
                    (props.folder.companyId == dropped.companyId &&
                        props.folder.isCompanyShare &&
                        dropped.isCompanyShare) ||
                    (props.folder.isInternalShare && dropped.isInternalShare))
            );
            // Can add hierarchy logic, too, right now it's just in the reducer
        }
        if (itemType == DragDropTypes.FLOW) {
            // Flow is being dragged
            const dropped: Flow = monitor.getItem();
            if (props.folder.folderType != "W") {
                return false;
            }
            const userId = dropped.FlowCreatedBy;
            const companyId = dropped.FlowCompanyId;

            const { selectedFlowIds } = props;
            //Is multiselect enabled and includes the flow
            if (selectedFlowIds.length > 0 && selectedFlowIds.includes(dropped.FlowId)) {
                const flowsArray: Array<Flow> = Object.values(props.flows);
                const selectedFlows = flowsArray.filter(x => selectedFlowIds.includes(x.FlowId));
                return (
                    flowsCanBeDropedInFolder(props.folder, selectedFlows, userId) &&
                    checkFolderPermissions(props, userId, companyId)
                );
            }

            return (
                flowCanBeDroppedInFolder(props.folder, dropped, userId) &&
                checkFolderPermissions(props, userId, companyId)
            );
        }

        return true;
    },
    drop(props: Props, monitor) {
        const itemType = monitor.getItemType();
        if (itemType == DragDropTypes.FOLDER) {
            // Folder dropped onto folder
            const dropped: Folder = monitor.getItem();
            props.requestMoveFolderToFolder(dropped.folderType, dropped.id, props.folder.id);
        }
        if (itemType == DragDropTypes.FLOW) {
            //Flow was dropped, request to move
            const dropped: Flow = monitor.getItem();
            const { requestMoveFlowToFolder, requestMoveFlowsToFolder, folder, selectedFlowIds } = props;
            if (selectedFlowIds.length > 0 && selectedFlowIds.includes(dropped.FlowId)) {
                requestMoveFlowsToFolder(selectedFlowIds, folder.id);
            } else {
                requestMoveFlowToFolder(dropped.FlowId, folder.id);
            }
        }
        if (itemType == DragDropTypes.LAYOUT) {
            //Layout was dropped, request to move
            const dropped = monitor.getItem();
            const { requestMoveLayoutToFolder, folder } = props;
            requestMoveLayoutToFolder(dropped.LayoutID, folder.id);
        }
    },
};
function collectTarget(connect, monitor) {
    // React-dnd will inject these props into our component
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
    };
}
let FolderDragDropConnected = DropTarget(
    [DragDropTypes.FOLDER, DragDropTypes.FLOW, DragDropTypes.LAYOUT],
    folderTarget,
    collectTarget
)(FolderDragDrop);

// Drag and Drop Source (Folders can be picked up and dropped on things, unless they're a root level folder)
const folderSource = {
    canDrag(props: Props) {
        // Must be your own, and can't be a top level folder
        // Or can be a shared company level folder
        return (
            !props.topLevelFolderIds.includes(props.folder.id) &&
            (props.folder.userId == props.userId ||
                (props.folder.companyId == props.companyId &&
                    props.folder.isCompanyShare &&
                    (props.folder.level > 1 || props.folder.folderType == "L")) ||
                props.folder.isInternalShare)
        );
    },
    beginDrag(props: Props) {
        // Return data describing the dragged item
        return props.folder;
    },
};
function collectSource(connect, monitor) {
    // React-dnd will inject these props into our component
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    };
}
FolderDragDropConnected = DragSource(DragDropTypes.FOLDER, folderSource, collectSource)(FolderDragDropConnected);

// Selectors
const makeGetTopLevelFolderIds = () =>
    createDeepEqualSelector(
        (_, props) => props.folder && props.folder.folderType,
        state => state.folders,
        (folderType: string, folders: any): Array<number> => {
            const folderReducerKey = folderReducerKeyForType(folderType);
            if (
                folders == null ||
                folderReducerKey == null ||
                !Object.prototype.hasOwnProperty.call(folders, folderReducerKey)
            ) {
                return [];
            }
            return folders[folderReducerKey].map(x => x.id);
        }
    );

// Redux
import type { MapStateToProps } from "react-redux";
const mapStateToProps: MapStateToProps<*, *, *> = () => {
    const getTopLevelFolderIds = makeGetTopLevelFolderIds();
    return (state, ownProps) => ({
        topLevelFolderIds: getTopLevelFolderIds(state, ownProps),
        userId: state.session.userId,
        companyId: state.session.companyId,
        isInternal: state.session.isInternal,
        flows: state.flows.byId,
        selectedFlowIds: state.flowMultiSelection.selectedFlowIds,
    });
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(Object.assign({}, audienceFolderActions, actionCreators, flowActions, layoutActions), dispatch);
FolderDragDropConnected = connect(mapStateToProps, mapDispatchToProps)(FolderDragDropConnected);

export default FolderDragDropConnected;
