import { ICompanySeat } from "../types/stores/companyTable";

const COMPANYSEAT_REPLACE_LIST = "COMPANYSEAT_REPLACE_LIST";

type CompanySeatReplaceList = {
    type: typeof COMPANYSEAT_REPLACE_LIST;
    companySeats: Array<ICompanySeat>;
};

function companySeats(state: Array<ICompanySeat> = [], action: CompanySeatReplaceList): Array<ICompanySeat> {
    switch (action.type) {
        case "COMPANYSEAT_REPLACE_LIST": {
            const newState = action.companySeats;
            return newState;
        }

        default:
            return state;
    }
}

export default companySeats;
