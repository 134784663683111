import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";

// core components
import styles from "../../../styles/dashComponentStyles/Table/TableBodyStyle";

//auto left first col and right rest unless specified alignment
function alignCell(row, entry) {
    if (entry[3] == "left" || entry[3] == "center" || entry[3] == "right") {
        return entry[3];
    } else if (row[0] == entry) {
        return "left";
    } else return "right";
}

function CustomTableBody(props) {
    const {
        classes,
        data,
        page,
        rowsPerPage,
        emptyMessage,
        widget,
        expandableData,
        customLeadingColumnWidth,
        ...rest
    } = props;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const hiddenRows =
        expandableData && expandableData.length > 0
            ? expandableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : [];

    return (
        <TableBody {...rest}>
            {data.length > 0 ? (
                data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((dataRow, rKey) => (
                    <React.Fragment key={"row-" + rKey}>
                        <TableRow className={widget ? classes.widgetTableRow : classes.pageTableRow} key={rKey} hover>
                            {dataRow.map(
                                (dataEntry, eKey) =>
                                    dataEntry && (
                                        <Hidden
                                            key={eKey}
                                            xlDown={dataEntry[2] == "xlDown"}
                                            lgDown={dataEntry[2] == "lgDown"}
                                            mdDown={dataEntry[2] == "mdDown"}
                                            smDown={dataEntry[2] == "smDown"}
                                            xsDown={dataEntry[2] == "xsDown"}
                                        >
                                            <TableCell
                                                className={widget ? classes.widgetTableCell : classes.pageTableCell}
                                                style={
                                                    customLeadingColumnWidth
                                                        ? {
                                                              maxWidth: eKey == 0 ? customLeadingColumnWidth : "125px",
                                                          }
                                                        : null
                                                }
                                                key={dataEntry[0] + eKey}
                                                align={alignCell(dataRow, dataEntry)}
                                            >
                                                {dataEntry[1]}
                                            </TableCell>
                                        </Hidden>
                                    )
                            )}
                        </TableRow>
                        {hiddenRows.length > 0 && hiddenRows[rKey] && hiddenRows[rKey][0] && (
                            <tr>
                                <td colSpan={5}>
                                    <Collapse
                                        in={hiddenRows[rKey][0]}
                                        style={{ display: "block" }}
                                        key={"statuses-" + rKey}
                                    >
                                        {hiddenRows[rKey][1]}
                                    </Collapse>
                                </td>
                            </tr>
                        )}
                    </React.Fragment>
                ))
            ) : (
                <TableRow className={widget ? classes.widgetTableRow : classes.pageTableRow}>
                    <Hidden
                        xlDown={emptyMessage[2] == "xlDown"}
                        lgDown={emptyMessage[2] == "lgDown"}
                        mdDown={emptyMessage[2] == "mdDown"}
                        smDown={emptyMessage[2] == "smDown"}
                        xsDown={emptyMessage[2] == "xsDown"}
                    >
                        <TableCell
                            className={widget ? classes.widgetEmptyCell : classes.pageEmptyCell}
                            key={emptyMessage[0]}
                            colSpan={12}
                            align="center"
                        >
                            {emptyMessage[1]}
                        </TableCell>
                    </Hidden>
                </TableRow>
            )}
            {data.length > 0 && emptyRows > 0 && (
                <TableRow
                    className={widget ? classes.widgetTableRow : classes.pageTableRow}
                    style={{ height: 35 * emptyRows }}
                >
                    <TableCell className={widget ? classes.widgetTableCell : classes.pageTableCell} colSpan={12} />
                </TableRow>
            )}
        </TableBody>
    );
}

CustomTableBody.propTypes = {
    customLeadingColumnWidth: PropTypes.string,
    classes: PropTypes.object,
    data: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    emptyMessage: PropTypes.array.isRequired,
    widget: PropTypes.bool,
    expandableData: PropTypes.array,
};

export default withStyles(styles)(CustomTableBody);
