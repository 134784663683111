import { AppSettingsActions, IAppSettingsStore } from "../types/stores/appSettings";

export const SET_APP_SETTINGS = "SET_APP_SETTINGS";
export const SET_LOADING_APP_SETTINGS = "SET_LOADING_APP_SETTINGS";
export const SET_SAVE_COMPLETED_APP_SETTINGS = "SET_SAVE_COMPLETED_APP_SETTINGS";
export const SET_IS_SAVING_APP_SETTINGS = "SET_IS_SAVING_APP_SETTINGS";
export const SET_APP_SETTINGS_LIST = "SET_APP_SETTINGS_LIST";
export const SET_LOADING_APP_SETTINGS_LIST = "SET_LOADING_APP_SETTINGS_LIST";

const initialState: IAppSettingsStore = {
    appSettings: [],
    appSettingsList: [],
    isSaving: false,
    isLoading: false,
    isLoadingList: false,
    completed: false,
};

function appSettings(state = initialState, action: AppSettingsActions): IAppSettingsStore {
    switch (action.type) {
        case SET_APP_SETTINGS:
            return Object.assign({}, state, {
                appSettings: action.appSettings,
            });
        case SET_LOADING_APP_SETTINGS:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case SET_SAVE_COMPLETED_APP_SETTINGS:
            return Object.assign({}, state, {
                completed: action.completed,
            });
        case SET_IS_SAVING_APP_SETTINGS:
            return Object.assign({}, state, {
                isSaving: action.isSaving,
            });
        case SET_LOADING_APP_SETTINGS_LIST:
            return Object.assign({}, state, {
                isLoadingList: action.isLoadingList,
            });
        case SET_APP_SETTINGS_LIST:
            return Object.assign({}, state, {
                appSettingsList: action.appSettingsList,
            });
        default:
            return state;
    }
}

export default appSettings;
