import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import type { FlowToCloud, FlowItem, FlowErrorsByItemId, FlowRelation } from "../types/flowTypes";

const myGenericReducer = makeReducerFor("FLOW_TO_CLOUD", "FlowToCloudId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowToCloudsArray = createSelector(
    state => state.flowToClouds.byId,
    (flowToCloudsById: {| [number]: FlowToCloud |}): Array<FlowToCloud> => {
        const r: Array<FlowToCloud> = Object.values(flowToCloudsById);
        return r;
    }
);

export type FlowToCloudsByItemId = {
    [number]: FlowToCloud,
};

export const getFlowToCloudsByFlowItemId = createSelector(
    state => getFlowToCloudsArray(state),
    (flowToClouds: Array<FlowToCloud>): FlowToCloudsByItemId =>
        flowToClouds.reduce((acc, row) => {
            acc[row.FlowItemId] = row;
            return acc;
        }, {})
);

export const getFlowToCloudsForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowToCloudsByFlowItemId(state),
    (
        selectedFlow: number,
        flowItems: Array<FlowItem>,
        flowToCloudsByItemId: FlowToCloudsByItemId
    ): Array<FlowToCloud> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        const result = [];
        for (const itemId of itemIds) {
            const filter = flowToCloudsByItemId[itemId];
            if (filter != null) {
                result.push(filter);
            }
        }
        return result;
    }
);

const toCloudsToErrorsById = (
    flowToClouds: Array<FlowToCloud>,
    flowRelations: Array<FlowRelation>
): FlowErrorsByItemId => {
    const errorsById = {};
    for (const flowToCloud of flowToClouds) {
        errorsById[flowToCloud.FlowItemId] = validateFlowToCloud(flowToCloud, flowRelations);
    }
    return errorsById;
};

import { getFlowRelationsForSelectedFlow, getFlowRelationsForAllFlows } from "./flowRelations";

export const getToCloudErrorsForSelectedFlow = createSelector(
    state => getFlowToCloudsForSelectedFlow(state),
    state => getFlowRelationsForSelectedFlow(state),
    toCloudsToErrorsById
);

export const getToCloudErrorsForAllFlows = createSelector(
    state => getFlowToCloudsArray(state),
    state => getFlowRelationsForAllFlows(state),
    toCloudsToErrorsById
);

//////////////////// HELPERS //////////////////////////////

export const validateFlowToCloud = (flowToCloud: FlowToCloud, flowRelations: Array<FlowRelation>): Array<string> => {
    const errors = [];

    if (flowToCloud.FlowItemId == 0 || flowToCloud.FlowItemId == null) {
        errors.push("No flow item selected.");
    } else if (flowToCloud.ExpireDays == 0 || flowToCloud.ExpireDays == null) {
        errors.push("Please select number of days to expire.");
    }

    let needsParent = false;

    if (flowRelations == null) {
        errors.push("To Cloud items must have a parent assigned.");
        needsParent = true;
        return errors;
    }

    const parentZeroRelations = flowRelations.filter(
        z => z.ParentFlowItemId == 0 && z.ChildFlowItemId == flowToCloud.FlowItemId
    );
    const allRelations = flowRelations.filter(z => z.ChildFlowItemId == flowToCloud.FlowItemId);
    if ((parentZeroRelations.length > 0 || allRelations.length == 0) && !needsParent) {
        errors.push("To Cloud items must have a parent assigned.");
        needsParent = true;
    }

    return errors;
};
