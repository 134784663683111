import React, { useState, useEffect, useRef, useCallback } from "react";

type Props = {
    defaultValue: string;
    onChange: (val: string) => void;
    isScheduleTemplate?: boolean;
    disabled?: boolean;
};

const CronStringEditor: React.FC<Props> = ({ defaultValue, onChange, isScheduleTemplate, disabled }) => {
    const [value, setValue] = useState(defaultValue);
    const cronSelectRef = useRef<any | null>(null);
    const handleChange = useCallback(
        val => {
            if (val && !disabled) {
                setValue(val);
                onChange && onChange(val);
            }
        },
        [disabled, onChange]
    );

    useEffect(() => {
        if (value) {
            $(cronSelectRef.current).cron({
                initial: value,
                hideEveryMinute: true,
                hideEveryHour: true,
                hideEveryDay: isScheduleTemplate ? true : false,
                hideEveryWeek: isScheduleTemplate ? true : false,
                onChange() {
                    handleChange($(this).cron("value"));
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div ref={cronSelectRef} style={{ display: "flex", flexFlow: "row wrap" }} />
        </>
    );
};

export default CronStringEditor;
