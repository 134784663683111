import { ISnowflakeInstance } from "../stores/snowflakeInstance";

export const SET_SNOWFLAKE_INSTANCES = "SET_SNOWFLAKE_INSTANCES";
export const SET_SELECTED_SNOWFLAKE_INSTANCE = "SET_SELECTED_SNOWFLAKE_INSTANCE";
export const SET_SNOWFLAKE_INSTANCES_LOADING = "SET_SNOWFLAKE_INSTANCES_LOADING";

export interface ISetSnowflakeInstances {
    type: typeof SET_SNOWFLAKE_INSTANCES;
    instances: Array<ISnowflakeInstance>;
}
export interface ISetSelectedInstance {
    type: typeof SET_SELECTED_SNOWFLAKE_INSTANCE;
    selectedInstance: number;
}
export interface ISetIsLoading {
    type: typeof SET_SNOWFLAKE_INSTANCES_LOADING;
    isLoading: boolean;
}

export type IActionTypes = ISetSnowflakeInstances | ISetSelectedInstance | ISetIsLoading;
