import { FlowRelation, FlowFilter, FlowCase, FlowItemFieldsUsed } from "../types/stores/flowTypes";
import { getAncestorsFlowItemIds } from "../reducers/flowRelations";
import tryParseJSON from "./tryParseJSON";
import getFieldIdsFromRules from "./getFieldIdsFromRules";
import { FieldClassification } from "../enums/FieldClassifications";

// Looks for all fieldIds related to parent flow items (filter and case)
export const getAncestorFieldIdsForFlowItemId = (
    flowItemId: number,
    flowRelations: Array<FlowRelation>,
    flowFilters: Array<FlowFilter>,
    flowCases: Array<FlowCase>,
    includeSelf: boolean = true
): Array<number> => {
    let results: Array<number> = [];
    const parents: Array<number> = includeSelf ? [flowItemId] : [];
    getAncestorsFlowItemIds(flowRelations, flowItemId, parents);

    for (const parent of parents) {
        const filter = flowFilters.find(x => x.FlowItemId == parent);
        const c = flowCases.find(x => x.FlowItemId == parent && !x.IsBalance);
        let fieldIds: Array<number> = [];
        if (filter) {
            let criteria = tryParseJSON(filter.FlowFilterCriteria) || {};
            fieldIds = getFieldIdsFromRules(criteria);
        } else if (c) {
            let criteria = tryParseJSON(c.FlowCaseCriteria) || {};
            fieldIds = getFieldIdsFromRules(criteria);
        }

        if (fieldIds.length > 0) {
            results = results.concat(fieldIds);
        }
    }
    return results;
};

export const getAncestorFilterFieldIdsForFlowItemId = (
    flowItemId: number,
    flowRelations: Array<FlowRelation>,
    flowFilters: Array<FlowFilter>
): Array<number> => {
    let results: Array<number> = [];
    const parents: Array<number> = [];
    getAncestorsFlowItemIds(flowRelations, flowItemId, parents);

    for (const parent of parents) {
        const filter = flowFilters.find(x => x.FlowItemId == parent);
        let fieldIds: Array<number> = [];
        if (filter) {
            let criteria = tryParseJSON(filter.FlowFilterCriteria) || {};
            fieldIds = getFieldIdsFromRules(criteria);
        }

        if (fieldIds.length > 0) {
            results = results.concat(fieldIds);
        }
    }
    return results;
};
export const checkIfIndirectDestinationHas1POnlyParent = (ancestorFields: Array<FlowItemFieldsUsed>): boolean => {
    const uniqueFlowItemIds = [...new Set(ancestorFields.map(item => item.FlowItemId))];
    let has1pOnlyFlowItem = false;
    for (let flowItemId of uniqueFlowItemIds) {
        const fields = ancestorFields.filter(item => item.FlowItemId === flowItemId);
        if (!fields.some(field => field.FieldClassification !== FieldClassification.FirstParty)) {
            has1pOnlyFlowItem = true;
        }
    }

    return has1pOnlyFlowItem;
};
