import { RequestFlowSave } from "../types/stores/flowTypes";
//returns a flow save object with the default values used previously by the function
export const requestFlowSaveObject = (): RequestFlowSave => ({
    overwrite: true,
    flowItemId: -1, // Why?
    copyOfferCodes: false,
    copyVariables: false,
    selectedCompany: 0,
    isRunChildren: false,
    processEntireFlow: false,
    isSaveAs: false,
    deployFlowExportAfterSave: false,
    tableKey: 0,
});
