// Global notifications under the notification bell

function userNotifications(
    state = {
        count: 0,
        newNotifications: false,
        userNotifications: [],
        loading: false,
    },
    action
) {
    switch (action.type) {
        case "UPDATE_USER_NOTIFICATIONS_COUNT":
            //console.log("UPDATE_USER_NOTIFICATIONS_COUNT", action);
            return Object.assign({}, state, {
                count: action.count,
                newNotifications: true,
            });

        case "CLEAR_USER_NOTIFICATIONS_COUNT":
            //console.log("CLEAR_USER_NOTIFICATIONS_COUNT", action);
            return Object.assign({}, state, {
                count: 0,
                newNotifications: false,
            });
        case "GET_NOTIFICATIONS": {
            return Object.assign({}, state, {
                userNotifications: action.userNotifications,
                loading: action.loading,
            });
        }
        default:
            return state;
    }
}

export default userNotifications;
