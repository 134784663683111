import { addThing, _deleteThing, getDefaultDate } from "./flowActions";

import { Dispatch, GetState } from "../types/types";
import { FlowExportXandrDriverFields } from "../types/stores/flowExportXandrDriverFields";
import { FlowItem, FlowPrefixAndEntityType } from "../types/stores/flowTypes";
import { getFlowExportXandrDriverFieldsByFlowItemId } from "../reducers/flowExportXandrDriverFields";

export const newFlowExportXandrDriverFields =
    (flowItemId: number, parentId: number, billingCategoryId?: number) =>
    (dispatch: Dispatch, getState: GetState): void => {
        const state = getState();
        const parent: FlowItem = state.flowItems.byId[parentId];

        // searching for any previously selected billing category id
        if (!billingCategoryId) {
            const existingFields = getFlowExportXandrDriverFieldsByFlowItemId(state)[flowItemId];
            billingCategoryId = existingFields && existingFields.length > 0 ? existingFields[0].BillingCategoryId : 0;
        }

        const thing: FlowExportXandrDriverFields = {
            XandrDriverFieldId: 0,
            FlowItemId: flowItemId,
            ParentFlowItemId: parentId,
            AudienceName: parent?.FlowItemName || "",
            Description: "",
            ExpansionType: "HOUSEHOLD",
            TTL: 90,
            ExpirationDate: getDefaultDate(),
            BillingCategoryId: billingCategoryId,
            DataSegementTypeId: "Audience",
        };
        dispatch(addThing("flowExportXandrDriverFields", thing));
    };

export const deleteFlowExportXandrDriverFields =
    (id: number) =>
    (dispatch: Dispatch): void => {
        dispatch(_deleteThing("flowExportXandrDriverFields", id));
    };

export const xandrDriverFieldsPrefixAndEntity: FlowPrefixAndEntityType = {
    actionPrefix: "FLOW_EXPORT_XANDR_DRIVER_FIELDS",
    stateKey: "flowExportXandrDriverFields",
    id: "XandrDriverFieldId",
    flowItemType: "FlowExportXandrDriverFields",
    deleteAc: deleteFlowExportXandrDriverFields,
};
