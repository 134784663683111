import React from "react";
import FlowItemToggle from "./FlowItemToggle";

type Props = {
    // Passed In
    flowItemId: number;
    disabled?: boolean;
};

const FlowItemMaxQtyToggle: React.FC<Props> = ({ flowItemId, disabled = false }: Props) => {
    if (flowItemId == null) {
        return null;
    }

    return (
        <FlowItemToggle
            flowItemId={flowItemId}
            parameterName="IsMaxQtyOn"
            toggleLabel="Max Quantity"
            onTooltip="Allows setting max quantity for each row"
            offTooltip=""
            flowItemType="Export"
            onText="ON"
            offText="OFF"
            isInvalidateItemAndChildren={true}
            isDisabled={disabled}
        />
    );
};

export default FlowItemMaxQtyToggle;
