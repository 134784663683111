import { Grid } from "@material-ui/core";
import update from "immutability-helper";
import React from "react";
import NumericalTextField from "../../../material-components/Misc/NumericalTextField";
import ScheduleCronStringEditor from "../../cron/ScheduleCronStringEditor";
import { ScheduleState } from "../ScheduleAddEdit";

type Props = {
    schedule: ScheduleState;
    setSchedule: React.Dispatch<React.SetStateAction<ScheduleState>>;
    updateUnsavedChanges: () => void;
    isScheduleTemplate?: boolean;
    disabled?: boolean;
};

const EndAfterNOccurances: React.FC<Props> = ({
    schedule,
    setSchedule,
    updateUnsavedChanges,
    isScheduleTemplate,
    disabled,
}: Props) => {
    const handleNOccurrencesChange = e => {
        const num = parseInt(e.target.value);
        if (!isNaN(num) && num >= 1) {
            setSchedule(
                update(schedule, {
                    nOccurrences: { $set: num },
                })
            );
            updateUnsavedChanges();
        } else if (e.target.value == "") {
            setSchedule(
                update(schedule, {
                    nOccurrences: { $set: null },
                })
            );
            updateUnsavedChanges();
        }
    };

    return (
        <>
            <ScheduleCronStringEditor
                schedule={schedule}
                setSchedule={setSchedule}
                updateUnsavedChanges={updateUnsavedChanges}
                isScheduleTemplate={isScheduleTemplate}
                disabled={disabled}
            />
            <div style={{ display: "flex", alignItems: "flex-end", width: "500px" }}>
                <Grid item xs={2} style={{ padding: 0 }}>
                    <span>End after</span>
                </Grid>
                <Grid item xs={2}>
                    <NumericalTextField
                        onBlur={handleNOccurrencesChange}
                        id="number"
                        label="Occurrences"
                        className="material-form-control inline-block"
                        style={{ width: "100px" }}
                        value={typeof schedule.nOccurrences == "undefined" ? "" : schedule.nOccurrences}
                        disabled={disabled}
                    />
                </Grid>
            </div>
        </>
    );
};

export default EndAfterNOccurances;
