// React
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeliveryType } from "../../../../enums/company";

// Components
import {
    Button,
    Collapse,
    Divider,
    FormControl,
    InputLabel,
    ListItem,
    MenuItem,
    Paper,
    Select,
    TextField,
} from "@material-ui/core";
import MaterialTable, { Column } from "@material-table/core";

// Types
import { IAppState } from "../../../../types/stores";
import { FlowExportXandrDriverFields } from "../../../../types/stores/flowExportXandrDriverFields";
import { Flow, FlowRelation } from "../../../../types/stores/flowTypes";

// Selectors
import { getFlowExportXandrDriverFieldsByFlowItemId } from "../../../../reducers/flowExportXandrDriverFields";
import { getFlowRelationsForSelectedFlow } from "../../../../reducers/flowRelations";

// Actions
import { updateAttribute, getDefaultDate } from "../../../../actions/flowActions";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

// Utils
import MomentUtils from "@date-io/moment";
import { newFlowExportXandrDriverFields } from "../../../../actions/flowExportXandrDriverFieldActions";
import MaterialTableTextField from "../../../shared/MaterialTableTextField";
import { Autocomplete } from "@material-ui/lab";
import { XandrRateCategory } from "../../../../types/stores/xandrRateCategory";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

type Props = {
    flowItemId: number;
    canEdit: boolean;
    tapadDeliveryTypeId: DeliveryType;
};

const ExpandLess = <i className="material-icons">expand_less</i>;
const ExpandMore = <i className="material-icons">expand_more</i>;

const XandrDriverFields: React.FC<Props> = ({ flowItemId, canEdit, tapadDeliveryTypeId }) => {
    const dispatch = useDispatch();
    // local state
    const [selectedCategory, setSelectedCategory] = useState<XandrRateCategory | null>(null);
    const [isOpenGlobalAssignment, setIsOpenGlobalAssignment] = useState(true);
    const [globalExpirationDate, setGlobalExpirationDate] = useState<string | null>(getDefaultDate());
    const [globalExpansionType, setGlobalExpansionType] = useState("HOUSEHOLD");
    const [deliveryType, setDeliveryType] = useState(0);

    const tapadDeliveryTypes = useSelector((state: IAppState) => state.vars.tapadDeliveryTypes);

    // redux selectors
    const flowRelations: Array<FlowRelation> = useSelector((state: IAppState) =>
        getFlowRelationsForSelectedFlow(state)
    );
    const xandrDriverFields = useSelector(
        (state: IAppState) => getFlowExportXandrDriverFieldsByFlowItemId(state)[flowItemId]
    );
    const xandrRateCategories = useSelector((state: IAppState) => state.xandrRateCategories.categories);
    const flow: Flow = useSelector((state: IAppState) => state.flows.byId[state.selected.flow]);
    const rateCategoriesLoading = useSelector((state: IAppState) => state.xandrRateCategories.isLoading);

    const parentRelations = flowRelations.filter(x => x.ChildFlowItemId == flowItemId && x.ParentFlowItemId != 0);

    useEffect(() => {
        const thisDeliveryType = tapadDeliveryTypes.find(x => x.TapadDeliveryTypeId == tapadDeliveryTypeId);

        if (
            xandrDriverFields &&
            deliveryType != undefined &&
            tapadDeliveryTypeId != undefined &&
            deliveryType != 0 &&
            thisDeliveryType?.DeliveryType != DeliveryType.ThirdPartyCustom
        ) {
            for (let i = 0; i < xandrDriverFields.length; i++) {
                xandrDriverFields[i].BillingCategoryId = 0;
            }
        }
        if (thisDeliveryType) {
            setDeliveryType(thisDeliveryType.DeliveryType);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tapadDeliveryTypeId, tapadDeliveryTypes]);
    useEffect(() => {
        for (const relation of parentRelations) {
            if (!xandrDriverFields || !xandrDriverFields.find(x => x.ParentFlowItemId == relation.ParentFlowItemId)) {
                dispatch(
                    newFlowExportXandrDriverFields(
                        flowItemId,
                        relation.ParentFlowItemId,
                        selectedCategory ? selectedCategory.data_category_id : 0
                    )
                );
            }
        }
    }, [dispatch, parentRelations, xandrDriverFields, flowItemId, selectedCategory]);

    useEffect(() => {
        if (selectedCategory == null && xandrDriverFields && xandrDriverFields.length > 0) {
            const dataCategoryId = xandrDriverFields[0].BillingCategoryId;
            const category = xandrRateCategories.find(x => x.data_category_id == dataCategoryId);
            if (category) {
                setSelectedCategory(category);
            }
        }
    }, [xandrDriverFields, selectedCategory, xandrRateCategories]);

    const columns: Array<Column<FlowExportXandrDriverFields>> =
        deliveryType && DeliveryType.ThirdPartyCustom == deliveryType
            ? [
                  {
                      title: "Audience Name",
                      field: "AudienceName",
                      render: row => renderTextField(row, "AudienceName"),
                  },
                  {
                      title: "Description",
                      field: "Description",
                      render: row => renderTextField(row, "Description"),
                  },
                  {
                      title: "Expansion Type",
                      field: "ExpansionType",
                      render: row => renderExpansionType(row),
                  },
                  {
                      title: "Expiration Date",
                      field: "ExpirationDate",
                      render: row => renderExpirationDate(row),
                  },
                  {
                      title: "Rate Type",
                      render: () => <div>CPM</div>,
                  },
                  {
                      title: "Xandr Rate Category",
                      field: "BillingCategoryId",
                      render: row => renderXandrRateCategories(row),
                  },
              ]
            : [
                  {
                      title: "Audience Name",
                      field: "AudienceName",
                      render: row => renderTextField(row, "AudienceName"),
                  },
                  {
                      title: "Description",
                      field: "Description",
                      render: row => renderTextField(row, "Description"),
                  },
                  {
                      title: "Expansion Type",
                      field: "ExpansionType",
                      render: row => renderExpansionType(row),
                  },
                  {
                      title: "Expiration Date",
                      field: "ExpirationDate",
                      render: row => renderExpirationDate(row),
                  },
                  {
                      title: "Rate Type",
                      render: () => <div>CPM</div>,
                  },
              ];

    const renderTextField = (row: FlowExportXandrDriverFields, property: string) => (
        <MaterialTableTextField
            id={`XanderDriverField_${row.XandrDriverFieldId}_${property}`}
            value={row[property]}
            onChange={newValue =>
                dispatch(
                    updateAttribute("flowExportXandrDriverFields", row.XandrDriverFieldId, property, newValue, true)
                )
            }
            disabled={!canEdit || (flow && flow.IsLocked)}
        />
    );

    const renderExpansionType = (row: FlowExportXandrDriverFields) => (
        <Select
            key={`XanderDriverField_${row.XandrDriverFieldId}_ExpansionType`}
            style={{ fontSize: "14px", width: "100%" }}
            value={row.ExpansionType}
            onChange={e =>
                dispatch(
                    updateAttribute(
                        "flowExportXandrDriverFields",
                        row.XandrDriverFieldId,
                        "ExpansionType",
                        e.target.value,
                        true
                    )
                )
            }
        >
            <MenuItem key={2} value={"HOUSEHOLD"}>
                Household
            </MenuItem>
        </Select>
    );

    const renderXandrRateCategories = (row: FlowExportXandrDriverFields) => (
        <Select
            key={`XanderDriverField_${row.XandrDriverFieldId}_ExpansionType`}
            style={{ fontSize: "14px", width: "100%" }}
            value={row.BillingCategoryId}
            onChange={e =>
                dispatch(
                    updateAttribute(
                        "flowExportXandrDriverFields",
                        row.XandrDriverFieldId,
                        "BillingCategoryId",
                        e.target.value,
                        true
                    )
                )
            }
        >
            {xandrRateCategories.map(x => (
                <MenuItem key={"xander-rate-category-" + x.data_category_id} value={x.data_category_id}>
                    {`${x.category_name} (${x.category_price})`}
                </MenuItem>
            ))}
        </Select>
    );

    const renderExpirationDate = (row: FlowExportXandrDriverFields) => (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <DateTimePicker
                key={`XanderDriverField_${row.XandrDriverFieldId}_ExpirationDate`}
                format={"MMM DD, YYYY HH:mm"}
                value={row.ExpirationDate}
                disabled={!canEdit || (flow && flow.IsLocked)}
                onChange={e => {
                    const newDateTime = e ? e.utc().format() : null;
                    dispatch(
                        updateAttribute(
                            "flowExportXandrDriverFields",
                            row.XandrDriverFieldId,
                            "ExpirationDate",
                            newDateTime,
                            true
                        )
                    );
                }}
            />
        </MuiPickersUtilsProvider>
    );

    const handleGlobalDateTimeChange = (globalDate: MaterialUiPickersDate) => {
        const newDateTime = globalDate ? globalDate.utc().format() : null;
        setGlobalExpirationDate(newDateTime);
    };

    const assignGlobalValues = () => {
        for (const field of xandrDriverFields) {
            dispatch(
                updateAttribute(
                    "flowExportXandrDriverFields",
                    field.XandrDriverFieldId,
                    "ExpansionType",
                    globalExpansionType
                )
            );

            dispatch(
                updateAttribute(
                    "flowExportXandrDriverFields",
                    field.XandrDriverFieldId,
                    "ExpirationDate",
                    globalExpirationDate
                )
            );

            dispatch(
                updateAttribute(
                    "flowExportXandrDriverFields",
                    field.XandrDriverFieldId,
                    "BillingCategoryId",
                    selectedCategory?.data_category_id || 0
                )
            );
        }
    };

    return (
        <>
            <div className="flow-edit-header">
                <div style={{ flexGrow: 1, position: "relative" }}>
                    <p>Xandr Driver File Fields</p>
                </div>
            </div>
            <Divider style={{ margin: "20px auto" }} />
            <ListItem
                key="button-global-fields-assignment"
                style={{ display: "flex", justifyContent: "center" }}
                button
                onClick={() => setIsOpenGlobalAssignment(!isOpenGlobalAssignment)}
            >
                <span style={{ display: "flex", fontSize: "16px" }}>Global Fields Assignment</span>
                <span style={{ display: "flex", position: "absolute", right: "0", fontSize: "16px" }}>
                    {isOpenGlobalAssignment ? ExpandLess : ExpandMore}
                </span>
            </ListItem>
            <Collapse key="container-global-fields-assignment" in={isOpenGlobalAssignment} timeout="auto" unmountOnExit>
                <div className="row">
                    <div className="col col-sm-4 col-md-3">
                        <FormControl fullWidth style={{ margin: "10px 0 0" }}>
                            <InputLabel shrink style={{ fontSize: 14 }}>
                                Expansion Type
                            </InputLabel>
                            <Select
                                key={`XanderDriverField_global_ExpansionType`}
                                style={{ fontSize: "14px", width: "100%" }}
                                value={"HOUSEHOLD"}
                                onChange={e => setGlobalExpansionType(e.target.value as string)}
                            >
                                <MenuItem key={2} value={"HOUSEHOLD"}>
                                    Household
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col col-sm-4 col-md-3">
                        {deliveryType && DeliveryType.ThirdPartyCustom == deliveryType ? (
                            <FormControl fullWidth style={{ margin: "10px 0 0" }}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={xandrRateCategories}
                                    value={selectedCategory}
                                    getOptionLabel={option => option.category_name}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="CPM ($)"
                                            helperText={
                                                !selectedCategory
                                                    ? "* Please contact your Experian admin to raise a CPM request if not available."
                                                    : ""
                                            }
                                        />
                                    )}
                                    onChange={(_e, value) => setSelectedCategory(value)}
                                    loading={rateCategoriesLoading}
                                    loadingText={"Loading Categories..."}
                                    fullWidth
                                />
                            </FormControl>
                        ) : null}
                    </div>
                </div>
                <div className="row">
                    <div className="col col-sm-4 col-md-3">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DateTimePicker
                                label="Expiration Date"
                                style={{ fontSize: "14px", width: "100%", margin: "10px 0 0" }}
                                format={"MMM DD, YYYY HH:mm"}
                                value={globalExpirationDate}
                                onChange={e => handleGlobalDateTimeChange(e)}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="col col-sm-4 col-md-6" style={{ height: "58px" }}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={assignGlobalValues}
                            style={{ position: "absolute", bottom: "0" }}
                        >
                            Assign
                        </Button>
                    </div>
                </div>
            </Collapse>
            <Paper style={{ marginTop: "30px", width: "100%" }}>
                <div className="m-t-md">
                    <MaterialTable
                        key={`freewheel-fields-table-${flowItemId}`}
                        data={xandrDriverFields}
                        columns={columns}
                        options={{
                            toolbar: false,
                            filtering: false,
                            grouping: false,
                            search: false,
                            headerStyle: {
                                backgroundColor: "#0075bc",
                                color: "#fff",
                                fontWeight: 400,
                                fontSize: "14px",
                            },
                            rowStyle: {
                                fontSize: 12,
                                padding: "2px",
                            },
                            paging: false,
                            maxBodyHeight: 400,
                        }}
                    />
                </div>
            </Paper>
            <Divider style={{ margin: "40px auto 20px auto" }} />
        </>
    );
};

export default XandrDriverFields;
