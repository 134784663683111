import { push } from "connected-react-router";

import { of } from "rxjs";
import { ofType } from "redux-observable";
import { ajax } from "rxjs/ajax";
import {
    filter,
    debounceTime,
    distinctUntilChanged,
    tap,
    map,
    catchError,
    switchAll,
    mergeMap,
    startWith,
} from "rxjs/operators";

import { combineEpics } from "redux-observable";

import h from "../../helpers";
import { logTreeSearch, logDataDiscoverySearch } from "../../helpers/gaTrackers";

interface IAction {
    text: string;
    bundles: Array<any>;
    isModal: boolean;
}

interface IWordVec {
    matches: Array<Array<object>>;
    didYouMean: Array<Array<object>>;
    error: string;
}

interface IDeApiSearch {
    result: Array<any>; //dynamic so use any type
    conceptualSearchMatches: Array<string>;
    conceptualActive: boolean;
    didYouMeans: Array<{ key: string; value: Array<string> }>;
}

// Debounce a user's typing and stop them from searching for more than 1 character
const typeEpic = action$ =>
    action$.pipe(
        ofType("TREESEARCH_USER_TYPED"),
        filter(
            (action: IAction) => action.text.length > 2 || action.text.length == 0 || action.text.toLowerCase() == "tv"
        ),
        debounceTime(280),
        distinctUntilChanged(
            (prev, curr) => prev === curr,
            (action: IAction) => action.text
        ), // Only if the value has changed
        map((action: IAction) => ({
            type: action.isModal ? "SET_MODAL_TREESEARCH" : "SET_TREESEARCH",
            text: action.text.toLowerCase(),
        }))
    );

// Run conceptual search after they finish typing if appropriate
const conceptualEpic = (action$, state$) =>
    action$.pipe(
        ofType("SET_TREESEARCH"),
        filter((action: IAction) => action.text.length > 2 || action.text.toLowerCase() == "tv"), // Run if available, even if turned off - it's easier for when they turn on
        map((action: IAction) => action.text.toLowerCase()),
        tap((text: string) => logTreeSearch(text)),
        map((text: string) => text.split(/\s+/)),
        switchAll(),
        filter((word: string) => !state$.value.search.wordVecs[word]),
        filter((word: string) => /\S/.test(word)),
        mergeMap((text: string) =>
            ajax.getJSON(`/Search/WordVector/${text}`).pipe(
                map(data => {
                    const response = data as unknown as IWordVec;
                    return {
                        type: "WORDVEC_RESULTS",
                        text,
                        matches: response.matches,
                        error: response.error || false,
                    };
                }),
                catchError(error =>
                    of({
                        type: "WORDVEC_ERROR",
                        payload: error.xhr.response,
                        error: true,
                    })
                ),
                startWith({
                    type: "WORDVEC_SEARCHING",
                })
            )
        )
    );

// Run conceptual search after they finish typing if appropriate
const modalConceptualEpic = (action$, state$) =>
    action$.pipe(
        ofType("SET_MODAL_TREESEARCH"),
        filter((action: IAction) => action.text.length > 2 || action.text.toLowerCase() == "tv"), // Run if available, even if turned off - it's easier for when they turn on
        map((action: IAction) => action.text.toLowerCase()),
        tap((text: string) => logTreeSearch(text)),
        map((text: string) => text.split(/\s+/)),
        switchAll(),
        filter((word: string) => !state$.value.search.modalWordVecs[word]),
        filter((word: string) => /\S/.test(word)),
        mergeMap((text: string) =>
            ajax.getJSON(`/Search/WordVector/${text}`).pipe(
                map(data => {
                    const response = data as unknown as IWordVec;
                    return {
                        type: "MODAL_WORDVEC_RESULTS",
                        text,
                        matches: response.matches,
                        error: response.error || false,
                    };
                }),
                catchError(error =>
                    of({
                        type: "MODAL_WORDVEC_ERROR",
                        payload: error.xhr.response,
                        error: true,
                    })
                ),
                startWith({
                    type: "MODAL_WORDVEC_SEARCHING",
                })
            )
        )
    );

const discoveryEpic = (action$, state$) =>
    action$.pipe(
        ofType("SUBMIT_DISCOVERYSEARCH"),
        mergeMap((action: IAction) => {
            const state = state$.value;
            const score = state.search.discoveryConceptualScoreThreshold;
            const nummatches = state.search.discoveryConceptualMaxMatches;
            logDataDiscoverySearch(action.text.toLowerCase());
            return ajax.getJSON(`/deapi/search/${action.text.toLowerCase()}/${score}/${nummatches}`).pipe(
                // This is word/score/nummatches - FieldTree defaults to 40/45
                map(data => {
                    const response = data as unknown as IDeApiSearch;
                    return {
                        type: "DISCOVERYSEARCH_RESULTS",
                        text: action.text,
                        result: response.result,
                        conceptualSearchMatches: response.conceptualSearchMatches,
                        conceptualActive: response.conceptualActive,
                        didYouMeans: response.didYouMeans,
                        error: false,
                    };
                }),
                catchError(error =>
                    of({
                        type: "DISCOVERYSEARCH_ERROR",
                        payload: error.xhr.response,
                        error: true,
                    })
                ),
                /* .takeUntil(action$.ofType('WORDVEC_CANCELED')) */
                startWith({ type: "DISCOVERYSEARCH_SEARCHING" })
            );
        })
    );

// Bundle only search with blank term.
const discoveryBundleEpic = action$ =>
    action$.pipe(
        ofType("SUBMIT_DISCOVERY_BUNDLE_ONLY_SEARCH"),
        mergeMap((action: IAction) => {
            const bundlesCsv = action.bundles.join(",");
            const searchUrl = "/deapi/searchall_bundles?" + h.serialize({ bundlesCsv });
            //const state = store.getState();
            return ajax.getJSON(searchUrl).pipe(
                // This is word/score/nummatches - FieldTree defaults to 40/45
                map(data => {
                    const response = data as unknown as IDeApiSearch;
                    return {
                        type: "DISCOVERYSEARCH_RESULTS",
                        text: "", //action.text,
                        result: response.result,
                        /*
                            conceptualSearchMatches: response.conceptualSearchMatches,
                            conceptualActive: response.conceptualActive,
                            didYouMeans: response.didYouMeans,
                            */
                        error: false,
                    };
                }),
                catchError(error =>
                    of({
                        type: "DISCOVERYSEARCH_ERROR",
                        payload: error.xhr.response,
                        error: true,
                    })
                ),
                /* .takeUntil(action$.ofType('WORDVEC_CANCELED')) */
                startWith({ type: "DISCOVERYSEARCH_SEARCHING" })
            );
        })
    );

const redirAfterDiscoveryEpic = action$ =>
    action$.pipe(
        ofType("DISCOVERYSEARCH_RESULTS"),
        map((action: IAction) =>
            push((action.text == "" ? "/discover_blank/" : "/discover/") + encodeURIComponent(action.text))
        )
    );

const searchEpics = combineEpics(
    typeEpic,
    conceptualEpic,
    modalConceptualEpic,
    discoveryEpic,
    discoveryBundleEpic,
    redirAfterDiscoveryEpic
);
export { searchEpics };
export default searchEpics;
