const fileNameTemplates = [
    "{AudienceName}",
    "{PartnerName}",
    "{CompanyName}",
    "{FlowId}",
    "{FlowItemId}", // {ID}
    "{FlowName}",
    "{FlowItemName}",
    "{CompanyID}",
    "{ParentFlowItemId}",
    "{date:yyyyMMddHHmmss}",
    "{date:yyyyMMdd}",
    "{date:yyyy-MM-dd}",
    "{date:yyyy-MMdd}",
    "{date:yyMMdd}",
];
export default fileNameTemplates;
