import React from "react";
import { Notification as NotificationType, IDismissNotification } from "../../types/stores/notifications";
import Snackbar from "./Snackbar";

type Props = {
    notification: NotificationType;
    onDismiss: () => IDismissNotification;
};

const Notification: React.FC<Props> = ({ notification, onDismiss }: Props) => {
    let message: JSX.Element;
    if (typeof notification.text == "string") {
        message = <div id="message-id" dangerouslySetInnerHTML={{ __html: notification.text }} />;
    } else {
        message = notification.text as JSX.Element;
    }

    return <Snackbar onClose={onDismiss} message={message} variant={notification.variant || "info"} />;
};
export default Notification;
