import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ObjectListEntry from "./ObjectListEntry";
import withUpdateStripes from "../misc/withUpdateStripes";
import { layoutObjectMatchesSearchString } from "../../reducers/exportlayouts";
import { getTreeSearch } from "../../reducers/search";

const ObjectList = ({ objects, selectedExportReport, updateStripes }) => {
    const treeSearch = useSelector(state => getTreeSearch(state));
    useEffect(() => {
        updateStripes();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const renderObject = objectId => {
        const object = objects[objectId];
        if (treeSearch && treeSearch != "" && !layoutObjectMatchesSearchString(object, treeSearch)) {
            return null;
        }
        return (
            <ObjectListEntry
                objectId={object.Id}
                object={object}
                key={object.Id}
                selectedExportReport={selectedExportReport}
            />
        );
    };

    let objectIds = Object.keys(objects);

    const layoutElements = objectIds.map(renderObject);

    return <div>{layoutElements}</div>;
};

ObjectList.propTypes = {
    //Inject by redux
    objects: PropTypes.any,
    treeSearch: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    selectedExportReport: PropTypes.any,
    // FROM HOC
    updateStripes: PropTypes.func.isRequired,
};

export default withUpdateStripes(ObjectList);
