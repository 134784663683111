import { fetchT } from "../helpers/typedFetch";
import {
    SetXandrRateCategoriesAction,
    SetXandrRateCategoriesLoadingAction,
    SET_XANDR_RATE_CATEGORIES,
    SET_XANDR_RATE_CATEGORIES_LOADING,
    XandrRateCategory,
} from "../types/stores/xandrRateCategory";
import { Dispatch } from "../types/types";
import h from "../helpers";

export const setXandrRateCategoriesLoading = (isLoading: boolean): SetXandrRateCategoriesLoadingAction => ({
    type: SET_XANDR_RATE_CATEGORIES_LOADING,
    isLoading,
});

export const setXandrRateCategories = (categories: Array<XandrRateCategory>): SetXandrRateCategoriesAction => ({
    type: SET_XANDR_RATE_CATEGORIES,
    categories,
});

export const requestXandrRateCategories =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(setXandrRateCategoriesLoading(true));
        fetchT<Array<XandrRateCategory>>("/api/XandrIntegration/GetXandrRateCategories", "GET")
            .then(categories => {
                dispatch(setXandrRateCategories(categories));
                dispatch(setXandrRateCategoriesLoading(false));
            })
            .catch(error => {
                dispatch(setXandrRateCategoriesLoading(false));
                h.error("Error loading Xandr Rate Categories.", error);
            });
    };
