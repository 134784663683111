import { FILE_UPLOAD_STATUS, IFileImportQueueStore, IQueueFile } from "../types/stores/fileImportQueue";
import {
    ActionType,
    ADD_TO_QUEUE,
    SET_FILE_CHUNKS,
    UPDATE_FILE_CHUNK,
    UPDATE_ACTIVE_CONNECTIONS,
    INC_ACTIVE_CONNECTIONS,
    DEC_ACTIVE_CONNECTIONS,
    UPDATE_FILE_REQUESTS_FAILED,
    DELETE_FROM_QUEUE,
} from "../types/actions/fileImportQueue";
import { createSelector } from "reselect";
import { IAppState } from "../types/stores";
import { MAX_CONSECUTIVE_REQUESTS_FAILED } from "../actions/fileImportQueueActions";
const THREAD_LIMIT: number = 2;

const defaultState: IFileImportQueueStore = {
    activeConnections: 0,
    threadLimit: THREAD_LIMIT,
    files: [],
    fileUploadSetting: null,
};

function fileImportQueue(state = defaultState, action: ActionType): IFileImportQueueStore {
    switch (action.type) {
        case ADD_TO_QUEUE: {
            // creating a copy of the files array and adding the new file
            const newFiles = [...state.files];
            newFiles.push(action.file);

            return {
                ...state,
                files: newFiles,
            };
        }

        case SET_FILE_CHUNKS: {
            // look for the file index
            const index = state.files.findIndex(x => x.guid === action.guid);
            // update the file chunks
            if (index >= 0) {
                const newState = { ...state };
                newState.files[index].chunks = action.chunks;
                return newState;
            }
            return state;
        }

        case UPDATE_FILE_CHUNK: {
            // look for the file index
            const index = state.files.findIndex(x => x.guid === action.guid);
            if (index >= 0 && action.newChunk) {
                const newState = { ...state };
                const files = newState.files;
                // look for the chunk being updated
                const chunks = files[index].chunks;
                const { newChunk } = action;
                const chunkIndex = chunks.findIndex(x => x.chunkNumber == newChunk.chunkNumber);
                // update the chunk properties.
                if (chunkIndex >= 0) {
                    chunks[chunkIndex] = newChunk;
                }
                newState.files = [...files];
                return newState;
            }
            return state;
        }

        case UPDATE_FILE_REQUESTS_FAILED: {
            // look for the file index
            const index = state.files.findIndex(x => x.guid === action.guid);
            // update the file chunks
            if (index >= 0) {
                const newState = { ...state };
                newState.files[index].requestsFailed = action.requestsFailed;
                return newState;
            }
            return state;
        }

        case DELETE_FROM_QUEUE: {
            // look for the file index
            const index = state.files.findIndex(x => x.guid === action.guid);
            // delete the file state
            if (index >= 0) {
                const newState = { ...state };
                newState.files.splice(index, 1);
                return newState;
            }
            return state;
        }

        case UPDATE_ACTIVE_CONNECTIONS:
            return { ...state, activeConnections: action.activeConnections };

        case INC_ACTIVE_CONNECTIONS:
            return { ...state, activeConnections: state.activeConnections + 1 };

        case DEC_ACTIVE_CONNECTIONS:
            return { ...state, activeConnections: state.activeConnections - 1 };

        default:
            return state;
    }
}
export default fileImportQueue;

export const fileUploadsInProgress = createSelector(
    (state: IAppState) => state.fileImportQueue.files,
    (files: Array<IQueueFile>): Array<IQueueFile> =>
        files.filter(
            x =>
                x.chunks &&
                // chunks are pending to be sent or in progress
                (x.chunks.filter(
                    c => c.chunkStatus == FILE_UPLOAD_STATUS.PENDING || c.chunkStatus == FILE_UPLOAD_STATUS.IN_PROGRESS
                ).length > 0 ||
                    // or chunks have failed, but we still have retry chances
                    x.chunks.filter(
                        c =>
                            c.chunkStatus == FILE_UPLOAD_STATUS.ERROR &&
                            x.requestsFailed < MAX_CONSECUTIVE_REQUESTS_FAILED
                    ).length > 0)
        )
);

export const fileUploadsFailed = createSelector(
    (state: IAppState) => state.fileImportQueue.files,
    (files: Array<IQueueFile>): Array<IQueueFile> =>
        files.filter(
            f =>
                f.chunks &&
                f.chunks.filter(
                    c =>
                        c.chunkStatus == FILE_UPLOAD_STATUS.ERROR && f.requestsFailed >= MAX_CONSECUTIVE_REQUESTS_FAILED
                ).length > 0
        )
);
