import React from "react";
import FlowItemToggle from "./FlowItemToggle";

type Props = {
    // Passed In
    flowItemId: number;
    isDisabled: boolean;
};

const FlowItemQAToggle: React.FC<Props> = ({ flowItemId, isDisabled }: Props) => {
    if (flowItemId == null) {
        return null;
    }

    return (
        <FlowItemToggle
            flowItemId={flowItemId}
            parameterName="IsComputeQA"
            toggleLabel="Calculate QA"
            onTooltip="QA information will be available after calculating."
            offTooltip="Calculation will be faster."
            flowItemType=""
            onText="ON"
            offText="OFF"
            isInvalidateItemAndChildren={false}
            isDisabled={isDisabled}
        />
    );
};

export default FlowItemQAToggle;
