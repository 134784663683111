import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import type { Flow } from "../types/flowTypes";
import type { Folder } from "../types/types";

//// REDUCER ///
const myGenericReducer = makeReducerFor("FLOW", "FlowId");
// Check Git History for a version with a custom reducer as well

export default myGenericReducer;

//////////// HELPERS /////////////////////
import { haystackMatchesSearchObject } from "./search";
import { folderMatchesSearchString } from "../helpers/folder";
import { searchTree, searchTreeReturnParent } from "./_folderGeneral";
export const flowMatchesSearchString = (flow: Flow, searchString: string | Array<string>) => {
    if (!flow || !flow.FlowName) {
        return false;
    }
    return (
        haystackMatchesSearchObject(flow.FlowName, searchString) ||
        haystackMatchesSearchObject(flow.FlowId.toString(), searchString)
    );
};

// flowFolderIdContainsSearch -
// Used in tree search - does any of this folder's children have the search string
export const flowFolderIdContainsSearch = (
    flow2state: any, // state.flows
    folders: Array<Folder>,
    folderId: number,
    searchString: string | Array<string>
) => {
    const flows: { [number]: Flow } = flow2state.byId;

    // Look for flows that match
    for (const id in flows) {
        let flowId = parseInt(id);
        if (!flowMatchesSearchString(flows[flowId], searchString)) {
            continue;
        }
        if (flowIdIsInFolder(flows, flowId, folderId)) {
            return true;
        }
    }

    // Recursively call children
    const folder = searchTree(folders, folderId);
    if (folder != null && folder.children) {
        for (const child of folder.children) {
            if (folderMatchesSearchString(child, searchString)) {
                return true;
            }
            if (flowFolderIdContainsSearch(flow2state, folders, child.id, searchString)) {
                return true;
            }
        }
    }

    // Check parents, too.
    return flowFolderIdParentsContainSearch(folders, folderId, searchString);
};

// flowIdIsInFolder
// Wants only "state.flows.byId" as state
const flowIdIsInFolder = (stateById: { [number]: Flow }, flowId: number, folderId: number) => {
    // This function is a candidate for removal now that we don't have the "E" workaround
    const flows = stateById;
    return flows[flowId].FlowFolderId == folderId;
};

export const flowIsInFolderAndSubFolders = (flowFolderId: number, folder: Folder) => {
    if (folder.id == flowFolderId) {
        return true;
    }
    if (folder.children.some(x => flowIsInFolderAndSubFolders(flowFolderId, x))) {
        return true;
    }
    return false;
};

// Wants the full flow2state
const flowFolderIdParentsContainSearch = (
    folders: Array<Folder>,
    folderId: number,
    searchString: string | Array<string>
) => {
    const parent = searchTreeReturnParent(folders, folderId);
    if (parent == null) {
        return false;
    }

    if (Array.isArray(parent)) {
        // Special case for top level is array (I prob should have made a root node :( )
        for (const element of parent) {
            if (folderMatchesSearchString(element, searchString)) {
                return true;
            }
        }
        return false;
    }
    if (folderMatchesSearchString(parent, searchString)) {
        return true;
    }
    return flowFolderIdParentsContainSearch(folders, parent.id, searchString);
};

//Wants state containing flows.byId
//If is a label, byId was causing the button to show up every time
export const numberOfFlowsInFolder = (state: any, folderId: number) => {
    const { flows, isLabel } = state;
    if (isLabel) {
        if (flows && flows.byId) {
            const flowsValues: Array<Flow> = Object.values(flows.byId);
            return flowsValues.filter(x => x.FlowFolderId == folderId).length;
        } else {
            return -1;
        }
    } else if (flows) {
        const flowsValues: Array<Flow> = Object.values(flows);
        return flowsValues.filter(x => x.FlowFolderId == folderId).length;
    } else {
        return -1;
    }
};

export const numberOfFlows = (state: any, createdBy: number) => {
    const { flows } = state;
    if (flows && flows.byId) {
        const flowsValues: Array<Flow> = Object.values(flows.byId);
        return flowsValues.filter(x => x.FlowCreatedBy == createdBy);
    } else {
        return {};
    }
};

/////////// SELECTORS ///////////////////
export const getFlowIds = createSelector(
    state => state.flows.byId,
    (flowsById: { [number]: Flow }): Array<number> => Object.keys(flowsById).map(x => parseInt(x, 10))
);
