import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Fade from "@material-ui/core/Fade";

type Props = {
    progress: number;
    show: boolean;
};

const LinearLoadingBar: React.FC<Props> = ({ progress, show }: Props) => (
    <Fade
        in={show}
        style={{
            transitionDelay: "0ms",
        }}
        unmountOnExit
    >
        <LinearProgress className="header-loading-bar" variant="determinate" value={progress} />
    </Fade>
);

export default LinearLoadingBar;
