import React from "react";
import { useDispatch, useSelector } from "react-redux";
import NotifyTimer from "./NotifyTimer";
import { dismissNotifyMessage } from "../../actions/notifyActions";
import { IAppState } from "../../types/stores";

const style: React.CSSProperties = {
    position: "fixed",
    zIndex: 10000,
    padding: "6px 12px 6px 12px",
    top: 0,
    right: 0,
};

const NotifyList: React.FC = () => {
    const dispatch = useDispatch();

    const notificationsFromState = useSelector((state: IAppState) => state.notifications || []);
    const notifications = notificationsFromState.filter(x => !x.dismissed);

    return (
        <div style={style}>
            <div style={{ minWidth: "225px", height: "1px" }} />
            {notifications == null
                ? null
                : notifications.map(x => (
                      <NotifyTimer
                          key={x.id}
                          notification={x}
                          timeout={x.delay || 4000}
                          onDismiss={() => dispatch(dismissNotifyMessage(x.id))}
                      />
                  ))}
        </div>
    );
};

export default NotifyList;
