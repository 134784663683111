import * as React from "react";
import { connect } from "react-redux";
import type { MapStateToProps } from "react-redux";

type Props = {
    children: React.Node,
    renderIfFail: React.Node,
    feature: string | Array<string>,
    featureArrayMode: string, // "AND" or "OR" - describes how an array of features should work
    enabledFeatures: Array<string>,
    featureMode: string, // "INCLUDES" or "NOT" - describes if a feature string is or is not allowed on
};
class FeatureGate extends React.Component<Props> {
    static defaultProps = {
        featureArrayMode: "OR",
        featureMode: "INCLUDES",
    };

    isPassFeatureCheck = () => {
        const { feature, enabledFeatures, featureArrayMode, featureMode } = this.props;
        if (!feature) {
            return true;
        }
        if (typeof feature == "string") {
            if (featureMode.toLowerCase() == "not") {
                return !enabledFeatures.includes(feature.toLowerCase());
            }
            return enabledFeatures.includes(feature.toLowerCase());
        }
        if (Array.isArray(feature)) {
            const passedFeatures = feature.filter(x => enabledFeatures.includes(x.toLowerCase()));
            if (featureArrayMode.toLowerCase() == "and") {
                return passedFeatures.length == feature.length; // "AND"
            }
            return passedFeatures.length > 0; // "OR"
        }
        /* eslint-disable no-console */
        console.warn("isPassFeatureCheck:  Unknown style of feature passed, should be string or array", feature);
        /* eslint-enable no-console */
    };

    render() {
        const { children, renderIfFail } = this.props;
        const failCase = renderIfFail != null ? renderIfFail : null;
        return this.isPassFeatureCheck() ? children : failCase;
    }
}

const mapStateToProps: MapStateToProps<*, *, *> = state => ({
    enabledFeatures: state.session.enabledFeatures || [],
});
const mapDispatchToProps = null;
const FeatureGateC = connect(mapStateToProps, mapDispatchToProps)(FeatureGate);

export default FeatureGateC;
