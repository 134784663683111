import clone from "../helpers/clone";
import update from "immutability-helper";

function accessList(state = {}, action) {
    switch (action.type) {
        case "SET_COMPANY_ACCESS_LIST":
            return { ...state, companyAccessList: action.companyAccessList };
        case "SET_COMPANY_RECEIVED_ACCESS_LIST":
            return { ...state, companyReceivedAccessList: action.companyReceivedAccessList };
        case "SET_COMPANY_ACCESS_FEATURES":
            return { ...state, companyAccessFeatures: action.companyAccessFeatures };

        case "UPDATE_MIN_SV_COUNT_IS_SAVING": {
            if (!action.companyId) {
                return state;
            }

            const newList = clone(state.companyReceivedAccessList);
            const itemIndex = newList.map(x => x.CompanyId).indexOf(action.companyId);
            if (itemIndex == -1) {
                return state;
            }

            newList[itemIndex].IsSaving = action.isSaving;
            return update(state, {
                companyReceivedAccessList: { $set: newList },
            });
        }

        case "UPDATE_MIN_SV_COUNT_VALUE": {
            if (!action.companyId) {
                return state;
            }

            const newList = clone(state.companyReceivedAccessList);
            const itemIndex = newList.map(x => x.CompanyId).indexOf(action.companyId);
            if (itemIndex == -1) {
                return state;
            }

            newList[itemIndex].NewMinSingleViewCount = action.minSVCount;
            return update(state, {
                companyReceivedAccessList: { $set: newList },
            });
        }
        case "SET_MIN_SV_COUNT_VALUE": {
            if (!action.companyId) {
                return state;
            }

            const newList = clone(state.companyReceivedAccessList);
            const itemIndex = newList.map(x => x.CompanyId).indexOf(action.companyId);
            if (itemIndex == -1) {
                return state;
            }

            newList[itemIndex].MinSingleViewCount = parseInt(action.minSVCount);
            delete newList[itemIndex].NewMinSingleViewCount;
            return update(state, {
                companyReceivedAccessList: { $set: newList },
            });
        }
        case "SET_DEFAULT_MIN_SV_COUNT": {
            if (!action.defaultMinSingleViewCount && action.defaultMinSingleViewCount != 0) {
                return state;
            }
            return { ...state, defaultMinSingleViewCount: action.defaultMinSingleViewCount };
        }
        case "IS_SAVING_DEFAULT_MIN_SINGLEVIEW_COUNT": {
            return { ...state, isSavingDefaultMinSingleViewCount: action.isSaving };
        }
        default:
            return state;
    }
}
export default accessList;
