import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

const radioButtonProps = {
    id: PropTypes.string.isRequired, //id of the element - ensure distinct
    name: PropTypes.string.isRequired, //name of the input
    onChange: PropTypes.func.isRequired, //what to do
    options: PropTypes.array.isRequired, //pass in labels and values
    valChecked: PropTypes.string.isRequired, // current value
    disabled: PropTypes.bool, //can the radio be toggled
    row: PropTypes.bool, // radio direction horizontal
};

type RadioButtonProps = PropTypes.InferProps<typeof radioButtonProps>;

const RadioButton: React.FC<RadioButtonProps> = ({
    id,
    name,
    onChange,
    options,
    disabled,
    row,
    valChecked,
}: RadioButtonProps) => {
    const [value, setValue] = useState(valChecked);

    useEffect(() => {
        setValue(valChecked || "");
    }, [value, valChecked]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        setValue((event.target as HTMLInputElement).value);

    return (
        <div>
            <FormControl component="fieldset">
                <RadioGroup
                    row={row!}
                    id={id}
                    aria-label="method"
                    value={value}
                    name={name}
                    onChange={e => {
                        onChange(e);
                        handleChange(e);
                    }}
                >
                    {options.map(x => (
                        <FormControlLabel
                            key={x.value}
                            value={x.value}
                            control={<Radio color="primary" />}
                            label={<span style={{ fontSize: "16px" }}>{x.label}</span>}
                            disabled={disabled || false}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </div>
    );
};

RadioButton.defaultProps = {
    options: [
        { label: "Yes", value: "y" },
        { label: "No", value: "n" },
    ],
    disabled: false,
    row: false,
};

export default RadioButton;
