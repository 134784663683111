import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../actions/actionCreators";
import * as layoutActions from "../../actions/exportLayoutActions2";

import VariableLayoutList from "./VariableLayoutList";

import withUpdateStripes from "../misc/withUpdateStripes";

type Props = {
    // Passed in
    scope: boolean,
};

class VariableLayoutTree extends React.Component<Props> {
    componentDidMount() {
        this.props.updateStripes();
    }

    componentDidUpdate() {
        this.props.updateStripes();
    }

    render() {
        const { variables, selectedLayout, layouts, updateStripes, scope } = this.props;
        const layout = layouts[selectedLayout];
        let isDestination = false;
        if (layout != null) {
            isDestination = layout.DestinationLayout;
        }
        if (!isDestination && scope == "DestinationOffer") {
            return <div />;
        }
        return (
            <div className="y-overflow">
                <VariableLayoutList
                    variables={variables}
                    selectedLayout={selectedLayout}
                    key="layout_count_list"
                    treeViewOnUpdate={updateStripes}
                    scope={scope}
                />
            </div>
        );
    }
}
VariableLayoutTree.propTypes = {
    selectedLayout: PropTypes.number,
    selectedDataSource: PropTypes.string,
    variables: PropTypes.object,
    layouts: PropTypes.object,
    updateStripes: PropTypes.func.isRequired, // From HOC
};

const VariableLayoutTreeS = withUpdateStripes(VariableLayoutTree);

const mapStateToProps = state => ({
    variables: state.clientVariables.byId,
    selectedLayout: state.selected.layout,
    userNameForId: state.varsPersist.userNameForId,
    layouts: state.layouts || [],
});
const mapDispatchToProps = dispatch => bindActionCreators(Object.assign({}, actionCreators, layoutActions), dispatch);

const VariableLayoutTreeSConnected = connect(mapStateToProps, mapDispatchToProps)(VariableLayoutTreeS);
export default VariableLayoutTreeSConnected;
