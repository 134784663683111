import React from "react";
import h from "../helpers";

import { plural, singular } from "../helpers/pluralize";

const pluralizeInsideQuotes = text => {
    const quoteRegex = /^"([^"]*)"$/;
    const matches = quoteRegex.exec(text);
    if (matches) {
        // Word was surrounded in quotes
        return '"' + plural(matches[1]) + '"';
    }
    return plural(text);
};

const singularizeInsideQuotes = text => {
    const quoteRegex = /^"([^"]*)"$/;
    const matches = quoteRegex.exec(text);
    if (matches) {
        // Word was surrounded in quotes
        return '"' + singular(matches[1]) + '"';
    }
    return singular(text);
};

/* treeSearch an be a string or an array (implicit OR) */
// Any words surrounded by double quotes will be highlighted in whole words only
const _highlightForSearch = (text, treeSearch) => {
    if (!treeSearch || !text) {
        return text;
    }

    if (!Array.isArray(treeSearch)) {
        treeSearch = [treeSearch];
    }

    const plurals = treeSearch.map(x => pluralizeInsideQuotes(x));
    const singulars = treeSearch.map(x => singularizeInsideQuotes(x));
    treeSearch = singulars.concat(plurals);

    treeSearch = [...new Set(treeSearch)]; // unique array
    treeSearch = treeSearch.sort((a, b) => b.length - a.length); // Longest strings first
    //let treeSearchLowercase = treeSearch.map( x => x.toLowerCase() );

    //let divider = ":::DIV:::";
    let textWithDividers = text;

    // I'm going to use very strange dividers here.
    // I need something that's one character long and that will never be input by the user, these seem to do
    const dividerBegin = "\f"; // form feed
    const dividerEnd = "\t"; // vertical tab

    textWithDividers = h.addDiffDividersToStringDoubleQuoteIsWordBoundary(
        textWithDividers,
        treeSearch,
        dividerBegin,
        dividerEnd,
        true
    );

    const stack = [];
    const resultComponents = [];
    let thisResultComponent = "";
    const str = textWithDividers;
    let start = false; // Are we inside parenthesis?

    // We are going to highlight everything that matched the search.
    // This is tricky if someone searched 'gender' with conceptual search.  We might get a result like
    // (Fe(male)) purchaser, since both 'female' and 'male' matched.  Hence the manual parsing with a stack.
    if (!str) {
        return str;
    } // Why??
    for (let i = 0; i < str.length; i++) {
        switch (str[i]) {
            case "\f": // This is our (
                stack.push("(");
                if (!start) {
                    resultComponents.push(thisResultComponent);
                    thisResultComponent = "";
                    start = true;
                    continue;
                }
                //result += str[i];
                break;
            case "\t": // This is our )
                if (stack[stack.length - 1] === "(") {
                    stack.pop();
                    if (stack.length === 0) {
                        const thisResultComponentWithHighlight = (
                            <span key={"search-" + thisResultComponent + i} className="search-match">
                                {thisResultComponent}
                            </span>
                        );
                        resultComponents.push(thisResultComponentWithHighlight);
                        thisResultComponent = "";
                        start = false;
                        continue;
                    }
                    //result += str[i];
                } else {
                    //not balanced!
                }

                break;
            default:
                thisResultComponent += str[i];
        }
    }
    if (thisResultComponent != "") {
        resultComponents.push(thisResultComponent);
    }
    return resultComponents;

    /*
    let textArray = textWithDividers.split(divider);
    return textArray.map((text, i) => {
        //if (text.toLowerCase() == treeSearch.toLowerCase()) {
        if (treeSearchLowercase.includes(text.toLowerCase())) {
            return <span key={"search-" + text + i} className="search-match">{text}</span>;
        }
        return text;
    });
    */
};
import memoize from "../helpers/memoize";
export const highlightForSearch = memoize(_highlightForSearch);
export default highlightForSearch;
