import { IImageControl } from "../types/stores/companyTable";
import { UPDATE_COMPANY_IMAGES, GET_COMPANY_LOGO, ActionType } from "../actions/imageControlActions";

function imageControl(
    state: IImageControl = {
        hasUpdated: false,
        logo: "",
    },
    action: ActionType
): IImageControl {
    switch (action.type) {
        case UPDATE_COMPANY_IMAGES:
            return Object.assign({}, state, {
                hasUpdated: action.hasUpdated,
            });
        case GET_COMPANY_LOGO:
            return Object.assign({}, state, {
                logo: action.logo,
            });
        default:
            return state;
    }
}
export default imageControl;
