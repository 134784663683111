import React from "react";
import { useSelector } from "react-redux";
import { fileUploadsFailed, fileUploadsInProgress } from "../../reducers/fileImportQueue";
import { Box, CircularProgress, Tooltip, Typography } from "@material-ui/core";
import { FILE_UPLOAD_STATUS } from "../../types/stores/fileImportQueue";

const FileUploadIcon: React.FC = () => {
    const filesInProgress = useSelector(fileUploadsInProgress);
    const filesFailed = useSelector(fileUploadsFailed);

    if ((!filesInProgress || filesInProgress.length <= 0) && (!filesFailed || filesFailed.length <= 0)) {
        return null;
    }

    const initialCount = 0;
    const totalChunks = filesInProgress.reduce(
        (accumulator, currentValue) => accumulator + currentValue.chunks.length,
        initialCount
    );
    const chunksCompleted = filesInProgress.reduce((accumulator, currentValue) => {
        const completedCount = currentValue.chunks.filter(x => x.chunkStatus == FILE_UPLOAD_STATUS.COMPLETED).length;
        return accumulator + completedCount;
    }, initialCount);
    const chunkOverallWeight = totalChunks > 0 ? 100 / totalChunks : 0;
    const overallProgress = Math.round(chunksCompleted * chunkOverallWeight);
    const inProgressText =
        filesInProgress.length > 0
            ? `You have ${filesInProgress.length} upload(s) in progress, do not close this tab while the process is not finished.`
            : "";

    const failedText = filesFailed.length > 0 ? `${filesFailed.length} upload(s) failed.` : "";

    return (
        <li>
            <Tooltip title={`${inProgressText} ${failedText}`}>
                <Box position="relative" display="inline-flex" style={{ padding: "5px 13px" }}>
                    <CircularProgress
                        color={filesFailed.length > 0 ? "secondary" : "primary"}
                        value={overallProgress}
                    />
                    <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography
                            variant="caption"
                            component="div"
                            color="textSecondary"
                        >{`${overallProgress}%`}</Typography>
                    </Box>
                </Box>
            </Tooltip>
        </li>
    );
};
export default FileUploadIcon;
