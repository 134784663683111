import React from "react";
import { ContextMenu, MenuItem, ContextMenuTriggerProps } from "react-contextmenu";
import { useDispatch, useSelector } from "react-redux";
import {
    setEditFolder,
    setAddField,
    setSelectedField,
    requestNewFieldFolder,
    startRenamingFieldFolder,
    requestDeleteFieldFolder,
} from "../../actions/fieldTreeActions";
import connectMenu from "../../helpers/connectMenu";
import { IField } from "../../types/stores/fieldTypes";
import { IAppState } from "../../types/stores/index";
import h from "../../helpers";

interface MenuItemProps {
    item: IField;
    hasChildren: boolean;
}
type Props = { item: ContextMenuTriggerProps & MenuItemProps };

const FieldEditContextMenu: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch();

    const { item } = props;

    const fieldsByParent = useSelector((state: IAppState) => state.fields.byParent || []);
    const defaultParentFolderIds = useSelector<IAppState, Array<number>>(
        state => state.session.defaultParentFolderIds || []
    );

    const clickEdit = (_, data: { item: IField }) => {
        dispatch(setAddField(false));
        if (item.item.isLeaf) {
            dispatch(setEditFolder(false));
            dispatch(setSelectedField(data.item.fieldkey));
            h.throwGTMTrigger("menu_click", "context menu", "Edit", "Edit Field", data.item.fieldkey.toString());
        } else {
            dispatch(setEditFolder(true));
            dispatch(setSelectedField(data.item.workid));
            h.throwGTMTrigger("menu_click", "context menu", "Edit", "Edit Folder", data.item.workid.toString());
        }
    };

    const clickNew = (_, data: { item: IField }) => dispatch(requestNewFieldFolder(data.item.workid));

    const clickRename = (_, data: { item: IField }) => dispatch(startRenamingFieldFolder(data.item.workid));

    const clickDelete = (_, data: { item: IField }) => dispatch(requestDeleteFieldFolder(data.item.workid));

    const hasChildren = () => {
        if (!item || !item.item || !fieldsByParent) {
            return false;
        }
        const folder = item.item as IField;
        let isDefaultParentFolder = false;
        if (defaultParentFolderIds.includes(folder.workid!)) {
            isDefaultParentFolder = true;
        }
        return Boolean(
            (folder.workid && fieldsByParent[folder.workid] && fieldsByParent[folder.workid].length > 0) ||
                isDefaultParentFolder
        );
    };

    return (
        <ContextMenu id="edit-context">
            <MenuItem onClick={clickEdit}>Edit</MenuItem>
            {item && item.item && !item.item.isLeaf && (
                <div>
                    <MenuItem onClick={clickNew}>New Folder</MenuItem>
                    <MenuItem onClick={clickRename}>Rename Folder</MenuItem>
                    <MenuItem onClick={clickDelete} disabled={hasChildren()}>
                        Delete Folder
                    </MenuItem>
                </div>
            )}
        </ContextMenu>
    );
};

export default connectMenu("edit-context")(FieldEditContextMenu);
