import isEqual from "lodash/isEqual";
import clone from "../helpers/clone";
import { combineReducers } from "redux";
import { FlowClientVariableD, FlowItemOfferCode } from "../types/stores/flowTypes";
import { createSelector } from "reselect";
import naturalCompare from "natural-compare";
import { getFlowClientVariablesForSelectedFlow } from "./flowClientVariables";
import { getFlowItemOfferCodesForSelectedFlow } from "./flowItemOfferCodes";
import {
    IClientVariable,
    ILoadVariablesAction,
    VariableValueType,
    ClientVariableWithValue,
    ClientVariableActionTypes,
} from "../types/stores/vars";
import { IAppState } from "../types/stores/index";

export const LOAD_CLIENT_VARIABLES = "LOAD_CLIENT_VARIABLES";

function variablesById(
    state = {},
    action: ClientVariableActionTypes
): {
    [id: number]: IClientVariable;
} {
    switch (action.type) {
        case LOAD_CLIENT_VARIABLES: {
            let newState;
            const newAction: ILoadVariablesAction = clone(action);
            let vars = newAction.normalizedData.entities.variables;
            if (vars == null) {
                vars = {};
            }

            // Directly replace entire list, but keep "0" if we have it (an unsaved one)
            newState = vars;
            if (typeof state[0] === "object") {
                newState[0] = clone(state[0]);
            }

            if (isEqual(state, newState)) {
                return state;
            }
            return newState;
        }
        default: {
            return state;
        }
    }
}

function allVariables(state: Array<number> = [], action: ClientVariableActionTypes): Array<number> {
    switch (action.type) {
        case "LOAD_CLIENT_VARIABLES": {
            let newState: Array<number> = [];
            const actionIdList = action.normalizedData.result.variables;
            newState = actionIdList;

            if (isEqual(state, newState)) {
                return state;
            }
            return newState;
        }
        default: {
            return state;
        }
    }
}

const clientVariablesReducer = combineReducers({
    byId: variablesById,
    allIds: allVariables,
});

export default clientVariablesReducer;

// SELECTORS / RESELECT
export const getArrayOfClientVariables = createSelector(
    (state: IAppState) => state.clientVariables.allIds,
    (state: IAppState) => state.clientVariables.byId,
    (allVariableIds: Array<number>, variablesById: { [id: number]: IClientVariable }): Array<IClientVariable> =>
        allVariableIds.map(x => variablesById[x])
);

export const getAllClientVariableNames = createSelector(
    (state: IAppState) => getArrayOfClientVariables(state),
    (clientVariables: Array<IClientVariable>): Array<string> => clientVariables.map(x => x.VariableName)
);

// Gets all clientVariableIds, but they're sorted by variable name order
export const getAllClientVariableIds = createSelector(
    (state: IAppState) => state.clientVariables.allIds,
    (state: IAppState) => state.clientVariables.byId,
    (allVariableIds: Array<number>, variablesById: { [id: number]: IClientVariable }): Array<number> =>
        allVariableIds.sort((a, b) => {
            const nameA = variablesById[a].VariableName.toUpperCase();
            const nameB = variablesById[b].VariableName.toUpperCase();
            return naturalCompare(nameA, nameB);
        })
);

export const getClientVariablesWithValuesForSelectedFlow = createSelector(
    (state: IAppState) => getAllClientVariableIds(state),
    (state: IAppState) => state.clientVariables.byId,
    (state: IAppState) => getFlowClientVariablesForSelectedFlow(state),
    (state: IAppState) => getFlowItemOfferCodesForSelectedFlow(state),
    (
        allVariableIds: Array<number>,
        variablesById: { [id: number]: IClientVariable },
        flowVariablesArray: Array<FlowClientVariableD>,
        flowOfferCodeArray: Array<FlowItemOfferCode>
    ): Array<ClientVariableWithValue> => {
        const rows: Array<ClientVariableWithValue> = [];
        for (const id of allVariableIds) {
            const variableValue: { VariableValue: VariableValueType | null } = { VariableValue: null };
            const isVisible: { IsVisible: boolean } = { IsVisible: true };
            const sortOrder: { SortOrder: number | null } = { SortOrder: null };
            // Variables
            const thisFlowVariableArray = flowVariablesArray.filter(x => x.VariableId == id);
            if (thisFlowVariableArray.length == 1) {
                // It's possible to also stick the Id of the "FlowVariable" row on this?
                // Might make for easier saving.
                variableValue.VariableValue = thisFlowVariableArray[0].VariableValue;
                isVisible.IsVisible = thisFlowVariableArray[0].IsVisible;
            }
            // Offer Codes
            const thisFlowOfferCodeArray = flowOfferCodeArray.filter(x => x.VariableId == id);
            if (thisFlowOfferCodeArray.length == 1) {
                isVisible.IsVisible = thisFlowOfferCodeArray[0].IsVisible;
                sortOrder.SortOrder = thisFlowOfferCodeArray[0].SortOrder;
            }
            const row: ClientVariableWithValue = Object.assign(
                {},
                variablesById[id],
                variableValue,
                isVisible,
                sortOrder
            );
            rows.push(row);
        }
        return rows;
    }
);
