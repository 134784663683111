import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TreeItem from "../tree/TreeItem";
import FlowIcon from "../flows/FlowIcon";
import FlowListEntryText from "./FlowListEntryText";

import MaterialTooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import * as flowActions from "../../actions/flowActions";
import * as flowDesignerActions from "../../actions/flowDesignerActions";

import { ContextMenuTrigger } from "react-contextmenu";
import { DragSource } from "react-dnd";
import { DragDropTypes } from "../../helpers/constants";
import { canMultiSelect } from "../../helpers/flowMultiSelect";

import { parseDateToTimeZoneWithTime } from "../../helpers/parseDate";

import { createSelector } from "reselect";
import { getFlowPermissionsFromProps } from "../../reducers/flowItems";
import type { MapStateToProps } from "react-redux";
import type { Flow, FlowPermissions } from "../../types/flowTypes";
import type { ConnectDragSource } from "react-dnd";

type Props = {
    // Passed in
    flowId: number,
    treeViewOnUpdate: () => void, // Used for odd/even row coloring
    showLockedFlows: boolean,
    // Redux
    isActive: boolean,
    isMultiSelectOn: boolean,
    flow: ?Flow,
    tooltipText: any,
    userId: number,
    selectedFlowIds: Array<number>,
    flowPermissions: FlowPermissions,
    // AC
    setSelectedFlow: (flowId: number) => void,
    flowMultiSelectionRemove: (flowId: number) => void,
    flowMultiSelectionAdd: (flowId: number) => void,
    // Injected by Drag and Drop
    isDragging: boolean,
    connectDragSource: ConnectDragSource,
    enabledFeatures: Array<String>,
    pathname: ?any,
    SetPreviouslyClosedFlow: (hasClosed: boolean) => void,
    SetPreviouslySelectedFolder: (selectedFolderId: any) => void,
};

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 300,
        fontSize: 12,
        border: "1px solid #dadde9",
        boxShadow:
            "rgba(0, 0, 0, 0.2) 0px 1px 5px 0px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px",
    },
}))(MaterialTooltip);

class FlowListEntry extends React.Component<Props> {
    static defaultProps = {
        showLockedFlows: true,
    };
    componentDidMount() {
        this.props.treeViewOnUpdate();
    }

    componentDidUpdate() {
        this.props.treeViewOnUpdate();
    }

    handleClick = () => {
        const {
            flowPermissions,
            flow,
            userId,
            selectedFlowIds,
            enabledFeatures,
            flowMultiSelectionAdd,
            flowMultiSelectionRemove,
            SetPreviouslyClosedFlow,
            SetPreviouslySelectedFolder,
            setSelectedFlow,
            isMultiSelectOn,
        } = this.props;

        if (selectedFlowIds.length > 0 && flow && canMultiSelect(flowPermissions, flow, userId, enabledFeatures)) {
            if (selectedFlowIds.includes(flow.FlowId)) {
                flowMultiSelectionRemove(flow.FlowId);
            } else {
                flowMultiSelectionAdd(flow.FlowId);
            }
        } else if (flow && !isMultiSelectOn) {
            SetPreviouslySelectedFolder(null);
            SetPreviouslyClosedFlow(false);
            setSelectedFlow(flow.FlowId);
        }
    };

    render() {
        const {
            flowId,
            isActive,
            tooltipText,
            connectDragSource,
            isDragging,
            flow,
            showLockedFlows,
            userId,
            flowPermissions,
            enabledFeatures,
        } = this.props;
        
        if (!enabledFeatures.includes("schedule-historical") && !showLockedFlows && flow && (flow.IsLocked || flow.IsLockedPermanently)) {
            return null;
        }
        let fle = (
            <div style={{ opacity: isDragging ? 0.5 : 1 }}>
                <ContextMenuTrigger id="flow-tree-flow" collect={props => props} flowId={flowId} holdToDisplay={-1}>
                    <TreeItem handleClick={this.handleClick} active={isActive}>
                        <FlowIcon
                            id={flowId}
                            className={`${"tree-icon"}`}
                            showMultiSelectIcon={canMultiSelect(flowPermissions, flow, userId, enabledFeatures)}
                        />
                        <FlowListEntryText flowId={flowId} />
                    </TreeItem>
                </ContextMenuTrigger>
            </div>
        );
        if (tooltipText != null && tooltipText != "") {
            fle = (
                <div>
                    <HtmlTooltip placement="right" title={<>{tooltipText}</>}>
                        <div>{fle}</div>
                    </HtmlTooltip>
                </div>
            );
        }
        return connectDragSource(fle);
    }
}

// Drag and Drop Source, 'campaigns' can be picked up and dragged onto things //
function collect(connect, monitor) {
    // React-dnd will inject these props into our component
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    };
}
const flowSource = {
    canDrag(props: Props) {
        if (props.flow == null) {
            return false;
        }
        return props.flow.FlowCreatedBy == props.userId || props.flow.IsLockedPermanently;
    },
    beginDrag(props) {
        // Return data describing the dragged item
        if (props.flow == null) {
            return {};
        }
        return props.flow;
    },
};
const FlowListEntryWithDND = DragSource(DragDropTypes.FLOW, flowSource, collect)(FlowListEntry);

const makeGetTooltip = () =>
    createSelector(
        (state, props) => state.flows.byId[props.flowId || 0],
        state => state.session.userSettings["time-zone-iana"],
        state => state.varsPersist.userNameForId,
        (flow: ?Flow, timeZoneIana: string, userNameForId: ?Object) => getTooltip(flow, timeZoneIana, userNameForId)
    );

function getTooltip(flow, timeZoneIana, userNameForId) {
    if (!flow) {
        return "";
    }
    const lastCalcDate = flow.FlowLastCalculated;
    const ownerName = userNameForId ? userNameForId[flow.FlowCreatedBy] : null;
    const executedByOtherUser = userNameForId ? userNameForId[flow.ExecutedBy] : null;
    let lastExecuted = "";
    let flowDescription = "";

    if (lastCalcDate != null) {
        const formattedCalcDate = parseDateToTimeZoneWithTime(lastCalcDate, timeZoneIana);
        lastExecuted = `Last executed on ${formattedCalcDate}.`;
    } else {
        lastExecuted = "Not executed.";
    }
    if (flow.FlowDescription != null && flow.FlowDescription != "") {
        flowDescription = "Description:" + flow.FlowDescription;
    } else {
        flowDescription = "";
    }

    let tooltipContent = (
        <div>
            <b>{flow.FlowName}</b>
            <br />
            <span>{lastExecuted}</span>
            {flow.FlowCreatedBy != flow.ExecutedBy && (
                <span>
                    <br /> Last executed By {executedByOtherUser}
                </span>
            )}
            {ownerName && (
                <span>
                    <br />
                    Owner: {ownerName}
                </span>
            )}
            {flowDescription != "" && (
                <span>
                    <br />
                    {flowDescription}
                </span>
            )}
        </div>
    );

    return tooltipContent;
}

const mapStateToProps: MapStateToProps<*, *, *> = () => (state, ownProps) => {
    const getTooltip = makeGetTooltip();
    return {
        flow: state.flows.byId[ownProps.flowId || 0],
        isActive: state.selected.flow == ownProps.flowId,
        userId: state.session.userId,
        tooltipText: getTooltip(state, ownProps),
        enabledFeatures: state.session.enabledFeatures || [],
        pathname: state.router && state.router.location && state.router.location.pathname,
        selectedFlowIds: state.flowMultiSelection.selectedFlowIds,
        isMultiSelectOn: state.flowMultiSelection.isMultiSelectOn,
        flowPermissions: getFlowPermissionsFromProps(),
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(Object.assign({}, flowActions, flowDesignerActions), dispatch);
const FlowListEntryC = connect(mapStateToProps, mapDispatchToProps)(FlowListEntryWithDND);

export default FlowListEntryC;
