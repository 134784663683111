import clone from "../helpers/clone";

function flowCloud(state = {}, action) {
    switch (action.type) {
        case "SET_FLOW_CLOUD_OBJECTS": {
            const newState = clone(state);
            const flowCloud = action.flowCloud;
            newState.cloudFlows = flowCloud.cloudFlows;
            newState.cloudFlowItems = flowCloud.cloudFlowItems;
            newState.cloudUsers = flowCloud.cloudUsers;
            return newState;
        }
        case "RESET_CLOUD_FLOWS": {
            const newState = clone(state);
            newState.cloudFlows = [];
            return newState;
        }
        default:
            return state;
    }
}
export default flowCloud;
