import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import { getFlowExportsForSelectedFlow, getFlowExportsArray } from "./flowExports";
import type { FlowExportTemplateFields, FlowItem, FlowErrorsByItemId, FlowExport } from "../types/flowTypes";

const myGenericReducer = makeReducerFor("FLOW_EXPORT_TEMPLATE_FIELDS", "FlowExportTemplateId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowExportTemplateFieldsArray = createSelector(
    state => state.flowExportTemplateFields.byId,
    (flowExportTemplateFieldsById: {| [number]: FlowExportTemplateFields |}): Array<FlowExportTemplateFields> => {
        const r: Array<FlowExportTemplateFields> = Object.values(flowExportTemplateFieldsById);
        return r;
    }
);

export type FlowExportTemplateFieldsByItemId = {
    [number]: Array<FlowExportTemplateFields>,
};

export const getFlowExportTemplateFieldsByFlowItemId = createSelector(
    state => getFlowExportTemplateFieldsArray(state),
    (flowExportTemplateFields: Array<FlowExportTemplateFields>): FlowExportTemplateFieldsByItemId =>
        flowExportTemplateFields.reduce((acc, row) => {
            if (acc[row.FlowItemId] == null) {
                acc[row.FlowItemId] = [];
            }
            acc[row.FlowItemId].push(row);
            return acc;
        }, {})
);

export const getFlowExportTemplateFieldsForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowExportTemplateFieldsByFlowItemId(state),
    (
        selectedFlow: number,
        flowItems: Array<FlowItem>,
        flowExportTemplateFieldsByItemId: FlowExportTemplateFieldsByItemId
    ): Array<FlowExportTemplateFields> => {
        let result = [];
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        for (const itemId of itemIds) {
            const templateFields = flowExportTemplateFieldsByItemId[itemId];
            if (templateFields != null) {
                result = result.concat(templateFields);
            }
        }
        return result;
    }
);

const templateFieldsToErrorsById = (
    flowExports: Array<FlowExport>,
    flowExportTemplateFields: Array<FlowExportTemplateFields>,
    destinations: Array<any>,
    templateFieldsFeature: boolean
): FlowErrorsByItemId => {
    const errorsById = {};
    if (templateFieldsFeature) {
        for (const flowExport of flowExports) {
            errorsById[flowExport.FlowItemId] = validateExportTemplateFields(
                flowExport,
                flowExportTemplateFields,
                destinations
            );
        }
    }
    return errorsById;
};

export const getExportTemplateErrorsForSelectedFlow = createSelector(
    state => getFlowExportsForSelectedFlow(state),
    state => getFlowExportTemplateFieldsForSelectedFlow(state),
    state => state.vars.destinations,
    state => state.session.enabledFeatures.includes("flow-export-template-fields"),
    templateFieldsToErrorsById
);

export const getExportTemplateErrorsForAllFlows = createSelector(
    state => getFlowExportsArray(state),
    state => getFlowExportTemplateFieldsArray(state),
    state => state.vars.destinations,
    state => state.session.enabledFeatures.includes("flow-export-template-fields"),
    templateFieldsToErrorsById
);

export const validateExportTemplateFields = (
    flowExport: FlowExport,
    flowExportTemplateFields: Array<FlowExportTemplateFields>,
    destinations: Array<any>
) => {
    const errors = [];

    if (!destinations || (destinations && destinations.length == 0)) {
        return errors;
    }

    const exportTemplateFields = flowExportTemplateFields.filter(x => x.FlowItemId == flowExport.FlowItemId);
    const destination = destinations.find(x => x.PartnerAccessId == flowExport.DestinationId);

    // No destination selected
    if (!destination) {
        return errors;
    }

    // Missing data
    if (exportTemplateFields.length == 0 && destination.DeployAdditionalTemplate) {
        errors.push("Driver File Fields missing.");
        return errors;
    }

    // Validations
    const noDescr = exportTemplateFields.filter(x => x.Description == null || x.Description == "");
    const noAdIds = exportTemplateFields.filter(x => x.AdAccountIds == null || x.AdAccountIds == "");
    let hasInvalidAdIds = false;

    for (const field of exportTemplateFields) {
        if (field.AdAccountIds != null && field.AdAccountIds != "") {
            let ids = field.AdAccountIds.split("|");
            for (const id of ids) {
                if (id.length > 0 && id.length < 5) {
                    hasInvalidAdIds = true;
                    break;
                }
            }
        }
        if (hasInvalidAdIds) {
            break;
        }
    }

    const noSource = exportTemplateFields.filter(x => x.CustomerFileSource == null || x.CustomerFileSource == "");

    if (noDescr.length > 0) {
        errors.push("All Driver File Descriptions must be populated.");
    }
    if (noAdIds.length > 0) {
        errors.push("All Driver File Ad Account Ids must be populated.");
    }
    if (hasInvalidAdIds) {
        errors.push("All Driver File Ad Account Ids must be at least 5 bytes.");
    }
    if (noSource.length > 0) {
        errors.push("All Driver File Customer File Source must be populated.");
    }
    return errors;
};
