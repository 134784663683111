import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow, getFlowItemsArray } from "./flowItems";
import tryParseJSON from "../helpers/tryParseJSON";
import type { FlowFilter, FlowItem, FlowErrorsByItemId, FlowCase } from "../types/flowTypes";
import { DestinationFieldRestriction } from "../types/stores/vars";
const myGenericReducer = makeReducerFor("FLOW_FILTER", "FlowFilterId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowFiltersByFlowItemId = createSelector(
    state => getFlowFiltersArray(state),
    (flowFilters: Array<FlowFilter>): FlowFiltersByItemId =>
        flowFilters.reduce((acc, row) => {
            acc[row.FlowItemId] = row;
            return acc;
        }, {})
);

export type FlowFiltersByItemId = {
    [number]: FlowFilter,
};

export const getFlowFiltersArray = createSelector(
    state => state.flowFilters.byId,
    (flowFiltersById: FlowFiltersByItemId): Array<FlowFilter> => {
        const r: Array<FlowFilter> = Object.values(flowFiltersById);
        return r;
    }
);

export const getFlowFiltersForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowFiltersByFlowItemId(state),
    (selectedFlow: number, flowItems: Array<FlowItem>, flowFiltersByItemId: FlowFiltersByItemId): Array<FlowFilter> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        const result = [];
        for (const itemId of itemIds) {
            const filter = flowFiltersByItemId[itemId];
            if (filter != null) {
                result.push(filter);
            }
        }
        return result;
    }
);

const filtersToErrorsById = (
    flowFilters: Array<FlowFilter>,
    fieldsById: Array<Object>,
    fieldRestrictions: Array<DestinationFieldRestriction>,
    flowCases: Array<FlowCase>,
    flowItems: Array<FlowItem>
): FlowErrorsByItemId => {
    const errorsById = {};
    for (const flowFilter of flowFilters) {
        errorsById[flowFilter.FlowItemId] = validateFlowFilter(
            flowFilter,
            fieldsById,
            fieldRestrictions,
            flowFilters,
            flowCases,
            flowItems
        );
    }
    return errorsById;
};

import { getFlowCasesForSelectedFlow, getFlowCasesArray } from "./flowCases";
export const getFilterErrorsForSelectedFlow = createSelector(
    state => getFlowFiltersForSelectedFlow(state),
    state => state.fields.byId,
    state => state.vars.destinationFieldRestrictions,
    state => getFlowCasesForSelectedFlow(state),
    state => getFlowItemsForSelectedFlow(state),
    filtersToErrorsById
);

export const getFilterErrorsForAllFlows = createSelector(
    state => getFlowFiltersArray(state),
    state => state.fields.byId,
    state => state.vars.destinationFieldRestrictions,
    state => getFlowCasesArray(state),
    state => getFlowItemsArray(state),
    filtersToErrorsById
);
//////////////////// HELPERS //////////////////////////////

import { getFlowFilterFieldIds, getFlowCaseFieldIds } from "../actions/flowActions";
export const validateFlowFilter = (
    flowFilter: FlowFilter,
    fieldsById: Array<Object>,
    fieldRestrictions: Array<DestinationFieldRestriction>,
    flowFilters: Array<FlowFilter>,
    flowCases: Array<FlowCase>,
    flowItems: Array<FlowItem>
): Array<string> => {
    const errors = [];
    if (flowFilter.FlowFilterCriteria == "") {
        errors.push("Criteria cannot be blank.");
        return errors;
    }
    const includeObject = tryParseJSON(flowFilter.FlowFilterCriteria) || {};
    const rules = includeObject.rules || [];
    const filterFieldIds = getFlowFilterFieldIds(flowFilter);
    if (rules.length == 0) {
        errors.push("Filter must have at least one rule.");
    } else if (!includeObject.valid) {
        errors.push("Filter rules are not completely filled out.");
    } else if (!fieldsById || filterFieldIds.filter(x => !fieldsById[parseInt(x)]).length > 0) {
        errors.push("Filter has fields no longer available.");
    }
    if (fieldRestrictions != null && fieldRestrictions.length > 0) {
        const restrictedFields = fieldRestrictions.filter(
            x => x.CompanyIsDataProvider && filterFieldIds.includes(x.FieldKey.toString())
        );
        if (restrictedFields.length > 0) {
            const filterItem = flowItems.find(x => x.FlowItemId == flowFilter.FlowItemId);
            if (
                restrictedFieldUsed(filterItem.FlowId, flowItems, flowFilters, flowCases, restrictedFields, fieldsById)
            ) {
                errors.push("Filter uses restricted fields.");
            }
        }
    }

    return errors;
};

export const restrictedFieldUsed = (
    flowId: number,
    flowItems: Array<FlowItem>,
    flowFilters: Array<FlowFilter>,
    flowCases: Array<FlowCase>,
    restrictedFields: Array<DestinationFieldRestriction>,
    fieldsById: Array<Object>
) => {
    /********************************************************
     * Not effecient to call 'getAllFlowFieldIds' for every
     * item but not sure how to do differently since it's
     * used for selected flow AND ALL flows
     ********************************************************/
    const flowFieldIds = getAllFlowFieldIds(flowId, flowItems, flowFilters, flowCases);
    const restrictedCompanies = Array.from(new Set(restrictedFields.map(x => x.DestinationId)));

    for (const id of flowFieldIds) {
        const field = fieldsById[id];
        if (field && restrictedCompanies.includes(field.companyid)) {
            return true;
        }
    }

    return false;
};

/****************************************************************
 *  This is a version of ../actions/flowActions/getFlowFieldIds()
 *  which needs to be dispatched, so couldn't use here.
 *
 *  Also not checking Reports
 ***************************************************************/
export const getAllFlowFieldIds = (
    flowId: number,
    flowItems: Array<FlowItem>,
    flowFilters: Array<FlowFilter>,
    flowCases: Array<FlowCase>
) => {
    const thisFlowItems = flowItems.filter(f => f.FlowId == flowId);
    let fieldIds: Array<string> = [];

    //flow items that contain query builder
    const filters = thisFlowItems.filter(f => f.FlowItemType == "filter");
    const cases = thisFlowItems.filter(f => f.FlowItemType == "case");

    // Filters
    for (const filter of filters) {
        const filterItem = flowFilters.find(x => x.FlowItemId == filter.FlowItemId);
        const filterFields = getFlowFilterFieldIds(filterItem);
        if (filterFields.length > 0) {
            fieldIds = fieldIds.concat(filterFields);
        }
    }
    // Cases
    for (const flowCase of cases) {
        const caseItems = flowCases.filter(x => x.FlowItemId == flowCase.FlowItemId);
        const caseFields = getFlowCaseFieldIds(caseItems);
        if (caseFields.length > 0) {
            fieldIds = fieldIds.concat(caseFields);
        }
    }

    if (fieldIds.length > 0) {
        //removes duplicates
        fieldIds = Array.from(new Set(fieldIds));
    }
    return fieldIds;
};
