import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import type { FlowItem, FlowExternalServiceParameter } from "../types/stores/flowTypes";

const myGenericReducer = makeReducerFor("FLOW_EXTERNAL_SERVICE_PARAMETER", "FlowServiceParameterId");
import subItemReducer from "./_genericFlowSubItemReducer";
import { IAppState } from "../types/stores";
import { IFlowExternalServiceParameterStore } from "../types/stores/flowExternalServiceParameter";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const myReducer = (state = {}, action: any): IFlowExternalServiceParameterStore =>
    subItemReducer(myGenericReducer(state, action), action);

export default myReducer;

export type FlowExternalServiceParameterById = {
    [key: number]: FlowExternalServiceParameter;
};
export type FlowExternalServiceParameterByItemId = {
    [key: number]: Array<FlowExternalServiceParameter>;
};

export const getFlowExternalServiceParametersArray = createSelector(
    (state: IAppState) => state.flowExternalServiceParameters.byId,
    (flowExternalServiceParameterById: FlowExternalServiceParameterById): Array<FlowExternalServiceParameter> => {
        const r: Array<FlowExternalServiceParameter> = Object.values(flowExternalServiceParameterById);
        return r;
    }
);

// eslint-disable-line
export const getFlowExternalServiceParametersByFlowItemId = createSelector(
    (state: IAppState) => getFlowExternalServiceParametersArray(state),
    (flowExternalServiceParameter: Array<FlowExternalServiceParameter>): FlowExternalServiceParameterByItemId =>
        flowExternalServiceParameter.reduce((acc, row) => {
            if (!acc[row.FlowItemId]) {
                acc[row.FlowItemId] = [];
            }
            acc[row.FlowItemId].push(row);
            return acc;
        }, {})
);

export const getFlowExternalServiceParametersForSelectedFlow = createSelector(
    (state: IAppState) => getFlowItemsForSelectedFlow(state),
    (state: IAppState) => getFlowExternalServiceParametersByFlowItemId(state),
    (
        flowItems: Array<FlowItem>,
        flowExternalServiceParametersByItemId: FlowExternalServiceParameterByItemId
    ): Array<FlowExternalServiceParameter> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        let result: Array<FlowExternalServiceParameter> = [];
        for (const itemId of itemIds) {
            const params = flowExternalServiceParametersByItemId[itemId];
            if (params != null) {
                result = result.concat(params);
            }
        }
        return result;
    }
);

//@ts-ignore
export const getFlowExternalServiceParametersForSelectedFlowItem = createSelector(
    (state: IAppState) => state.selected.flowItem,
    (state: IAppState) => getFlowExternalServiceParametersByFlowItemId(state),
    (flowItemId, flowExternalServiceParametersByItemId): Array<FlowExternalServiceParameter> =>
        flowExternalServiceParametersByItemId[flowItemId] || []
);
