import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { updateAttribute } from "../../../../actions/flowActions";

import Table from "@material-ui/core/Table";
import TableHeader from "../../../material-components/Table/TableHeader";
import TableBody from "../../../material-components/Table/TableBody";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const ExportTemplateFields = ({ destinationId, flowExportTemplateFields, canEdit }) => {
    const dispatch = useDispatch();

    // state
    const [templateRows, setTemplateRows] = useState([]);

    // props
    const flow = useSelector(state => state.flows.byId[state.selected.flow]);
    const flowItems = useSelector(state => state.flowItems.byId);

    const renderTemplateRows = useCallback(() => {
        let rows = [];
        if (flowExportTemplateFields != null) {
            for (const template of flowExportTemplateFields) {
                const parentItem = flowItems[template.ParentFlowItemId];
                rows.push({
                    TemplateId: template.FlowExportTemplateId,
                    ParentFlowItemId: template.ParentFlowItemId,
                    ItemName: parentItem.FlowItemName,
                    Description: template.Description || "",
                    AdAccountIds: template.AdAccountIds || "",
                    FileSource: template.CustomerFileSource || "",
                });
            }
        }
        setTemplateRows(rows);
    }, [flowExportTemplateFields, flowItems]);

    // effects
    useEffect(() => {
        renderTemplateRows();
    }, [destinationId, flowExportTemplateFields, renderTemplateRows]);

    const renderTemplateTable = () => {
        const tableHeaders = [
            ["ItemName", "Item", "smDown", "left"],
            ["Description", "Description", "smDown", "left"],
            ["AdAccountIds", "Ad Account Ids", "smDown", "left"],
            ["FileSource", "Customer File Source", "smDown", "left"],
        ];
        const emptyMessage = ["EmptyMessage", "No Driver Fields"];
        let data = [];
        templateRows.forEach((item, index) => {
            data.push([
                ["ItemName", item.ItemName, "smDown", "left"],
                ["Description", renderDescriptionColumn(item, index), "smDown", "left"],
                ["AdAccountIds", renderAdIdColumn(item, index), "smDown", "left"],
                ["FileSource", renderFileSourceColumn(item), "smDown", "left"],
            ]);
        });

        return (
            <div id="templateFieldsTable">
                <Table size={"medium"}>
                    <TableHeader style={{ fontSize: "20px" }} headers={tableHeaders} ignoreHeaders={tableHeaders} />
                    <TableBody
                        style={{ fontSize: "18px" }}
                        data={data}
                        page={0}
                        rowsPerPage={data.length}
                        emptyMessage={emptyMessage}
                    />
                </Table>
            </div>
        );
    };

    const renderDescriptionColumn = (item, index) => {
        const rows = [...templateRows];
        const descriptionColumn = (
            <TextField
                key={"template-description-" + item.TemplateId}
                type="text"
                value={item.Description}
                disabled={!canEdit || (flow && flow.IsLocked)}
                inputProps={{
                    style: { fontSize: "16px" },
                }}
                onChange={e => {
                    rows[index].Description = e.target.value;
                    return setTemplateRows(rows);
                }}
                onBlur={e => handleDescriptionChange(item.TemplateId, e)}
            />
        );

        return descriptionColumn;
    };

    const handleDescriptionChange = (TemplateId, e) => {
        dispatch(updateAttribute("flowExportTemplateFields", TemplateId, "Description", e.target.value));
    };

    const renderAdIdColumn = (item, index) => {
        const rows = [...templateRows];
        const adIdColumn = (
            <TextField
                key={"template-adid-" + item.TemplateId}
                type="text"
                value={item.AdAccountIds}
                disabled={!canEdit || (flow && flow.IsLocked)}
                inputProps={{
                    style: { fontSize: "16px" },
                }}
                onChange={e => {
                    rows[index].AdAccountIds = checkAdIds(e.target.value);
                    return setTemplateRows(rows);
                }}
                onBlur={e => handleAdIdChange(item.TemplateId, e)}
            />
        );

        return adIdColumn;
    };

    const handleAdIdChange = (TemplateId, e) => {
        dispatch(updateAttribute("flowExportTemplateFields", TemplateId, "AdAccountIds", checkAdIds(e.target.value)));
    };

    const checkAdIds = value => value.replace(/[^0-9|]/g, "");

    const renderFileSourceColumn = item => {
        const sourceColumn = (
            <Select
                key={"template-source-" + item.TemplateId}
                style={{ fontSize: "14px", width: "100%" }}
                value={item.FileSource}
                onChange={e => handleFileSourceChange(item.TemplateId, e)}
                disabled={!canEdit || (flow && flow.IsLocked)}
                displayEmpty
            >
                <MenuItem key={0} value={"PARTNER_PROVIDED_ONLY"}>
                    PARTNER_PROVIDED_ONLY
                </MenuItem>
                <MenuItem key={1} value={"BOTH_USER_AND_PARTNER_PROVIDED"}>
                    BOTH_USER_AND_PARTNER_PROVIDED
                </MenuItem>
                <MenuItem key={2} value={"USER_PROVIDED_ONLY"}>
                    USER_PROVIDED_ONLY
                </MenuItem>
            </Select>
        );

        return sourceColumn;
    };

    const handleFileSourceChange = (TemplateId, e) => {
        dispatch(updateAttribute("flowExportTemplateFields", TemplateId, "CustomerFileSource", e.target.value));
    };

    return (
        <>
            <div className="flow-edit-header">
                <div style={{ flexGrow: 1, position: "relative" }}>
                    <p>Driver File Fields</p>
                </div>
            </div>
            <Divider style={{ margin: "20px auto" }} />
            <Paper style={{ marginTop: "50px", width: "100%" }}>
                <div className="m-t-md">{renderTemplateTable()}</div>
            </Paper>
            <Divider style={{ margin: "40px auto 20px auto" }} />
        </>
    );
};

ExportTemplateFields.propTypes = {
    destinationId: PropTypes.number,
    flowExportTemplateFields: PropTypes.array,
    canEdit: PropTypes.bool,
};

export default ExportTemplateFields;
