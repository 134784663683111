import React, { useEffect } from "react";
import { makeFilterUsersWithNoFlowFolders } from "../../reducers/_folderGeneral";
import { useSelector } from "react-redux";
import FlowList from "./FlowList";
import FlowFolderContextMenu from "./FlowFolderContextMenu";
import FlowTreeContextMenu from "./FlowTreeContextMenu";
import withUpdateStripes from "../misc/withUpdateStripes";
import { sortTopLevelFolders } from "../../helpers/folder";
import withScrolling, { createVerticalStrength, createHorizontalStrength } from "react-dnd-scrolling";
import { Folder } from "../../types/shared";
import { IAppState } from "../../types/stores";

const Scrollzone = withScrolling("div");
const vStrength = createVerticalStrength(150);
const hStrength = createHorizontalStrength(150);

type Props = {
    // Passed In
    folderType?: string;
    schedulesMode?: boolean;
    // From withUpdateStripes HOC
    updateStripes?: () => void;
};

const FlowTree: React.FC<Props> = ({ updateStripes, schedulesMode, folderType = "W" }) => {
    const filterUsersWithNoFlowFolders = makeFilterUsersWithNoFlowFolders();

    const flowFolders = useSelector(state => filterUsersWithNoFlowFolders(state, { folderType }));

    const userNameForId = useSelector((state: IAppState) => state.varsPersist.userNameForId);
    const userId = useSelector((state: IAppState) => state.session.userId!);
    const companyId = useSelector((state: IAppState) => state.session.companyId!);
    const enabledFeatures = useSelector((state: IAppState) => state.session.enabledFeatures);

    useEffect(() => {
        updateStripes && updateStripes();
    }, [updateStripes]);

    let folders = sortTopLevelFolders(flowFolders, userNameForId, userId);
    if (schedulesMode) {
        if (!enabledFeatures.includes("schedules-admin") && !enabledFeatures.includes("schedule-historical")) {
            // Only non-historical folders that belong to my user
            folders = folders.filter(f => f.userId == userId && !f.isHistorical);
        } else if (!enabledFeatures.includes("schedules-admin") && enabledFeatures.includes("schedule-historical")) {
            // My User's Historical folder and non-historical folders
            folders = folders.filter(
                f => f.userId == userId || (f.isHistorical && (f.userId == 0 || f.userId == userId))
            );
        } else if (enabledFeatures.includes("schedules-admin") && !enabledFeatures.includes("schedule-historical")) {
            // All non-historical folders that belong to my user
            folders = folders.filter(f => !f.isHistorical);
        }
        // Else - show all folders
    }

    const topLevelFolder: Folder = {
        folderType,
        children: folders,
        name: "",
        id: 0,
        order: 1,
        userId,
        companyId,
        isCompanyShare: false,
        level: -1,
        isInternalShare: false,
        isHistorical: false,
    };

    return (
        <Scrollzone className="scrollyrolly react_flows" verticalStrength={vStrength} horizontalStrength={hStrength}>
            <FlowList
                folderType={folderType}
                folder={topLevelFolder}
                treeViewOnUpdate={updateStripes}
                showAllDuringSearch={false}
                schedulesMode={schedulesMode}
            />
            <FlowFolderContextMenu />
            <FlowTreeContextMenu />
        </Scrollzone>
    );
};
export default withUpdateStripes(FlowTree);
