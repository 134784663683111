import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import FeatureGate from "../FeatureGate";
import * as actionCreators from "../../actions/actionCreators";
import { bindActionCreators } from "redux";

class LocalNavMenu extends Component<*, *> {
    state = {
        anchorEl: null,
    };

    static propTypes = {
        // Passed In
        children: PropTypes.any,
        text: PropTypes.string.isRequired,
        feature: PropTypes.any,
        featureArrayMode: PropTypes.string, // "AND" or "OR" - describes how an array of features should work
        // Redux
        enabledFeatures: PropTypes.array,
        // Injected by Redux (action creator)
        isCollapsed: PropTypes.bool,
        collapsedIcon: PropTypes.string,
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleOpenMenu = e => {
        this.setState({ anchorEl: e.currentTarget });
    };

    render() {
        const { text, children, feature, featureArrayMode, isCollapsed, collapsedIcon } = this.props;
        const { anchorEl } = this.state;

        const menuItems = children.map((child, key) => {
            if (child == false || child == null) {
                return;
            }

            const unclickableChildren = ["divider", "description"];

            if (child && child.props && unclickableChildren.includes(child.props.name)) {
                return child;
            }

            if (child && child.props && child.props.feature) {
                return (
                    <FeatureGate
                        key={key}
                        feature={child.props.feature}
                        featureArrayMode={child.props.featureArrayMode || "OR"}
                    >
                        <MenuItem
                            disableGutters={true}
                            style={{
                                fontSize: 13,
                                color: "#575755 !important",
                                textDecoration: "none",
                            }}
                            onClick={this.handleClose}
                        >
                            {child}
                        </MenuItem>
                    </FeatureGate>
                );
            }

            if ((child && !child.props) || (child && !child.props.feature)) {
                return (
                    <MenuItem
                        disableGutters={true}
                        key={key}
                        style={{
                            fontSize: 13,
                            color: "#575755 !important",
                            textDecoration: "none",
                        }}
                        onClick={this.handleClose}
                    >
                        {child}
                    </MenuItem>
                );
            }
        });

        return (
            <FeatureGate feature={feature} featureArrayMode={featureArrayMode}>
                {!isCollapsed ? (
                    <span aria-controls={`${text}-menu`} onClick={this.handleOpenMenu}>
                        {text}
                    </span>
                ) : (
                    <span aria-controls={`${text}-menu`}>
                        <Tooltip
                            title={text}
                            style={{
                                fontSize: 12,
                            }}
                            placement="right"
                        >
                            <img onClick={this.handleOpenMenu} className={"collapsed-icon"} src={collapsedIcon} />
                        </Tooltip>
                    </span>
                )}

                <Menu
                    id={`${text}-menu`}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                    className="left-nav-menu"
                >
                    {menuItems}
                </Menu>
            </FeatureGate>
        );
    }
}

const mapStateToProps = state => ({
    enabledFeatures: state.session.enabledFeatures || [],
});

const mapDispatchToProps = dispatch => bindActionCreators(actionCreators, dispatch);

const LocalNavMenuC = connect(mapStateToProps, mapDispatchToProps)(LocalNavMenu);

export default LocalNavMenuC;
