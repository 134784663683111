import { haystackMatchesSearchObject } from "../reducers/search";

// Below needs to line up with reducers/folders.js - please update both
export const folderReducerKeyForType = folderType => {
    if (typeof folderType == "string") {
        folderType = folderType.toUpperCase();
    }
    if (folderType == "W") {
        return "flowFolders";
    }
    if (folderType == "L") {
        return "layoutFolders";
    }
    console.log("folderReducerKeyForType: Warning: Unknown type [" + folderType + "]"); // eslint-disable-line no-console
    return null;
};

export const folderTypeToSegmentType = folderType => {
    if (typeof folderType == "string") {
        folderType = folderType.toUpperCase();
    }
    if (folderType == "W") {
        return "FlowType";
    }
    console.log("folderTypeToSegmentType: Warning: Unknown type [" + folderType + "]"); // eslint-disable-line no-console
    return null;
};

export const folderTypeToMenuId = folderType => {
    if (typeof folderType == "string") {
        folderType = folderType.toUpperCase();
    }
    if (folderType == "W") {
        return "flow-tree-folder";
    } else if (folderType == "L") {
        return "layout-tree-folder";
    }
    console.log("folderTypeToMenuId: Warning: Unknown type [" + folderType + "]"); // eslint-disable-line no-console
    return null;
};

export const sortTopLevelFolders = (folders, userNameForId, userId) => {
    if (userNameForId == null) {
        userNameForId = {};
    }
    if (folders.length < 2) {
        return folders;
    }
    folders.sort((x, y) => {
        if (x.isHistorical && !y.isHistorical) {
            return 1;
        }
        if (y.isHistorical && !x.isHistorical) {
            return -1;
        }
        if (x.name === "Archived Layouts") {
            return 1;
        }
        if (y.name === "Archived Layouts") {
            return -1;
        }
        const a = userNameForId[x["userId"]] || "UserID " + x["userId"];
        const b = userNameForId[y["userId"]] || "UserID " + y["userId"];
        if (typeof a != "string" || typeof b != "string") {
            return 0;
        }
        const comparison = a.toLowerCase().localeCompare(b.toLowerCase());
        if (comparison === 0) {
            return a.localeCompare(b);
        }
        return comparison;
    });

    // Move current login user folder to top
    folders.forEach((item, i) => {
        if (item.userId === userId) {
            folders.splice(i, 1);
            folders.unshift(item);
        }
    });

    return folders;
};

export const folderMatchesSearchString = (node, searchString) => {
    if (!node || !node.name) {
        return false;
    }
    return haystackMatchesSearchObject(node.name, searchString);
};
