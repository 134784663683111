import { batchedSubscribe } from "redux-batched-subscribe";
import State from "./state";

import debounce from "debounce";
const notifySetter = freshNotify => {
    State.notify = freshNotify;
};
const debouncedNotifySetter = debounce(notifySetter, 100, true);

export default batchedSubscribe(debouncedNotifySetter);
