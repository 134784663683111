const tableHeaderStyle = {
    widgetHeaderRow: {
        fontSize: "1.1em",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        maxWidth: "600px",
        overflow: "hidden",
    },
    widgetHeaderCell: {
        fontSize: "1.2em",
        fontWeight: "bold",
        padding: "10px",
        cursor: "pointer",
        whiteSpace: "nowrap",
        maxWidth: "100px",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    pageHeaderRow: {
        fontSize: "1em",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        maxWidth: "600px",
        overflow: "hidden",
    },
    pageHeaderCell: {
        fontSize: "0.8em",
        fontWeight: "bold",
        padding: "10px",
        cursor: "pointer",
        whiteSpace: "nowrap",
        maxWidth: "125px",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    activeSortIcon: {
        height: "10px",
        width: "10px",
        fontSize: "10px",
        opacity: 1,
    },
    inactiveSortIcon: {
        height: "10px",
        width: "10px",
        fontSize: "10px",
        opacity: 0,
    },
};

export default tableHeaderStyle;
