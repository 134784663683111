// States for flow item edit tools
const TOGGLE_FLOW_EDIT_LISTING = "TOGGLE_FLOW_EDIT_LISTING";
const TOGGLE_FLOW_EDIT_LISTING_PINNABLE = "TOGGLE_FLOW_EDIT_LISTING_PINNABLE";

function flowItemEditSettings(
    state = {
        isFlowItemListingOpen: true,
        isFlowItemListingPinned: true,
        flowScriptAppendText: null,
    },
    action
) {
    switch (action.type) {
        case TOGGLE_FLOW_EDIT_LISTING:
            //console.log("TOGGLE_FLOW_EDIT_LISTING", action);
            return Object.assign({}, state, {
                isFlowItemListingOpen: action.isOpen,
            });
        case TOGGLE_FLOW_EDIT_LISTING_PINNABLE:
            return Object.assign({}, state, {
                isFlowItemListingPinned: action.isPinned,
            });
        case "UPDATE_FLOW_SCRIPT_APPEND_TEXT":
            return Object.assign({}, state, {
                flowScriptAppendText: action.appendText,
            });
        default:
            return state;
    }
}
export default flowItemEditSettings;
