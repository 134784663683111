import React from "react";
import PropTypes from "prop-types";
import { connect, MapStateToProps } from "react-redux";
import { bindActionCreators } from "redux";
import { createSelector } from "reselect";
import { ContextMenuTrigger } from "react-contextmenu";
import Tooltip from "@material-ui/core/Tooltip";
import { DragSource } from "react-dnd";

import * as actionCreators from "../../actions/exportLayoutActions2";
import TreeItem from "../tree/TreeItem";
import TextWithHighlightedSearchTerms from "../tree/TextWithHighlightedSearchTerms";
import { DragDropTypes } from "../../helpers/constants";
import { parseDateFromJson } from "../../helpers/parseDate";

class LayoutListEntry extends React.Component {
    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
        this.handleDoubleClick = this.handleDoubleClick.bind(this);
    }

    handleClick() {
        const { layoutId, setSelectedLayout2 } = this.props;
        setSelectedLayout2(parseInt(layoutId));
    }

    handleDoubleClick() {}

    componentDidMount() {
        $(this.divNode).bstooltip();
    }

    componentWillUnmount() {
        $(this.divNode).bstooltip("destroy");
    }

    render() {
        const { layout, layoutId, selectedLayout, connectDragSource, isDragging, tooltipText } = this.props;
        if (!layout || layout.LayoutID == null) {
            return null;
        }
        const active = selectedLayout == layoutId;
        const displayText = layout.LayoutName;
        let entry = null;
        let toolTipText = tooltipText;
        let isActiveDestinationLayout = null;
        if (layout.IsActiveDestinationLayout) {
            isActiveDestinationLayout = (
                <i className="material-icons" style={{ margin: "2px", fontSize: "15px" }}>
                    star
                </i>
            );
            toolTipText = "The current active destination layout.";
        }
        const errorIcon = layout.IsValid ? null : <i className={"glyphicon glyphicon-warning-sign"} />;
        const color = layout.IsValid ? null : "red";
        entry = (
            <div
                style={{ opacity: isDragging ? 0.5 : 1 }}
                data-container="body"
                data-toggle="tooltip"
                data-placement="right"
                ref={node => (this.divNode = node)}
            >
                <Tooltip placement="right" title={toolTipText}>
                    <div style={{ color }}>
                        <ContextMenuTrigger
                            id="layout-tree"
                            collect={props => props}
                            layoutId={layoutId}
                            holdToDisplay={-1}
                        >
                            <TreeItem
                                handleClick={this.handleClick}
                                handleDoubleClick={this.handleDoubleClick}
                                active={active}
                            >
                                {errorIcon}
                                {isActiveDestinationLayout} {"  "}
                                <TextWithHighlightedSearchTerms text={displayText} />
                            </TreeItem>
                        </ContextMenuTrigger>
                    </div>
                </Tooltip>
            </div>
        );

        if (connectDragSource) {
            return connectDragSource(entry);
        }
        return entry;
    }
}

LayoutListEntry.propTypes = {
    // Provided from outside
    layoutId: PropTypes.any,
    layout: PropTypes.object,
    selectedLayout: PropTypes.any,
    setSelectedLayout: PropTypes.func,
    setSelectedLayout2: PropTypes.func,
    requestSelectedLayoutObjects: PropTypes.func,
    isDragging: PropTypes.bool,
    connectDragSource: PropTypes.func,
    tooltipText: PropTypes.string,
};

function collect(connect, monitor) {
    // React-dnd will inject these props into our component
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    };
}
const layoutSource = {
    canDrag(props) {
        if (props.layout == null) {
            return false;
        }
        return true;
    },
    beginDrag(props) {
        // Return data describing the dragged item
        if (props.layout == null) {
            return {};
        }
        return props.layout;
    },
};
const LayoutListEntryWithDND = DragSource(DragDropTypes.LAYOUT, layoutSource, collect)(LayoutListEntry);

const makeGetTooltip = () =>
    createSelector(
        (state, ownProps) => state.layouts[ownProps.layoutId],
        state => state.varsPersist.userNameForId,
        state => state.session.userSettings["time-zone-iana"],
        (layout, userNameForId, timeZoneIana) => getTooltip(layout, userNameForId, timeZoneIana)
    );

function getTooltip(layout, userNameForId, timeZoneIana) {
    if (!layout) {
        return "";
    }

    const { LayoutDateCreated, LayoutUpdated } = layout;
    let toolTipText = "";
    try {
        if (LayoutUpdated != null) {
            toolTipText = `Last Saved on ${parseDateFromJson(LayoutUpdated, timeZoneIana)}`;
        } else if (LayoutDateCreated != null) {
            toolTipText = `Created on ${parseDateFromJson(LayoutDateCreated, timeZoneIana)}`;
        }

        const userName =
            userNameForId[layout.LayoutUpdatedBy != null ? layout.LayoutUpdatedBy : layout.LayoutCreatedBy];
        if (userName) {
            toolTipText += ` by ${userName}.`;
        }
        if (!layout.IsValid) {
            toolTipText += ` Has invalid objects.`;
        }
    } catch (err) {
        console.log("Error adding layout tooltip: " + err.message); // eslint-disable-line no-console
    }

    return toolTipText;
}

// Redux //
const mapStateToProps: MapStateToProps<*, *, *> = () => {
    const getTooltip = makeGetTooltip();

    return (state, ownProps) => ({
        layout: state.layouts[ownProps.layoutId],
        layoutId: ownProps.layoutId,
        userId: state.session.userId,
        tooltipText: getTooltip(state, ownProps),
        selectedLayout: state.selected.layout,
    });
};

const mapDispatchToProps = dispatch => bindActionCreators(actionCreators, dispatch);

const LayoutListEntryConnected = connect(mapStateToProps, mapDispatchToProps)(LayoutListEntryWithDND);

export default LayoutListEntryConnected;
