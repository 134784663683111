import clone from "../helpers/clone";
import { createSelector } from "reselect";
import update from "immutability-helper";

const defaultState = {
    globalVariables: {},
    selectedLayoutObjects: [],
    selectedTaxonomyLayoutObjects: [],
    objects: [],
    clientFieldObject: {},
    layoutFields: [],
    fileExtensions: [],
    selectedLayoutFiles: [],
};
function layoutObjects(state = defaultState, action) {
    switch (action.type) {
        case "SET_GLOBAL_VARIABLES":
            return { ...state, globalVariables: action.variables };
        case "SET_LAYOUT_SELECTED_OBJECTS":
            return { ...state, selectedLayoutObjects: action.objects, loading: action.loading };
        case "SET_LAYOUT_SELECTED_TAXONOMY_OBJECTS":
            return { ...state, selectedTaxonomyLayoutObjects: action.taxonomyObjects, loading: action.loading };
        case "SET_ALL_LAYOUT_OBJECTS":
            return { ...state, objects: action.objects };
        case "SET_CLIENT_FIELD_OBJECT":
            return { ...state, clientFieldObject: action.field };
        case "UPDATE_THIS_LAYOUT_DESTINATION": {
            const newState = clone(state);

            newState.objects
                .filter(x => x.Layout.LayoutID == action.LayoutId)[0]
                .LayoutObjects.forEach(x => {
                    if (x.ObjectType == 5) {
                        x.DisplayName = action.ExportName;
                        x.ExportName = action.ExportName;
                    }
                });

            return newState;
        }
        case "ADD_LAYOUT_OBJECT": {
            const newState = clone(state);

            newState.selectedLayoutObjects.push(action.object);
            return newState;
        }
        case "ADD_TAXONOMY_LAYOUT_OBJECT": {
            const newState = clone(state);

            newState.selectedTaxonomyLayoutObjects.push(action.object);
            return newState;
        }
        case "UPDATE_LAYOUT_FIELDS": {
            return update(state, {
                layoutFields: {
                    [action.layoutId]: { $set: action.layoutFields },
                },
            });
        }
        case "SET_FILE_EXTENSIONS":
            return { ...state, fileExtensions: action.fileExtensions };
        case "SET_LAYOUT_SELECTED_FILES":
            return { ...state, selectedLayoutFiles: action.selectedLayoutFiles };
        default:
            return state;
    }
}
export const getLayoutObjects = createSelector(
    state => state.layoutObjects.objects,
    (layoutObjects: any): any => layoutObjects
);
export const getTaxonomyLayoutObjects = createSelector(
    state => state.layoutObjects.taxonomyObjects,
    (layoutObjects: any): any => layoutObjects
);
export default layoutObjects;
