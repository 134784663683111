import React, { useRef, useState, useEffect } from "react";
import { TextField, TextFieldProps } from "@material-ui/core";

type NumericalTextFieldProps = {
    numberType?: "numeric" | "decimal" | "integer" | "currency" | "percent";
} & TextFieldProps;

function NumericalTextField({ numberType = "numeric", ...props }: NumericalTextFieldProps): JSX.Element {
    let textfieldProps: TextFieldProps = { ...props };
    let regex: RegExp;

    const [localValue, setLocalValue] = useState(props.value ? (props.value as string | number) :
        props.id == "price-magnite" ? (props.value as string | number) : "");
    const prevValueRef = useRef(props.value ? (props.value as string | number) : "");

    const USDollar = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    useEffect(() => {
        if (props.value != undefined && props.value != prevValueRef.current) {
            setLocalValue(props.value as string | number);
            prevValueRef.current = props.value as string | number;
        }
    }, [props.value]);

    if (numberType == "numeric") {
        regex = /^[0-9]*$/;
    } else if (numberType == "decimal" || numberType == "currency" || numberType == "percent") {
        // allow 0, 0., 0.0, 01, 1, 1., 1.1, ., "" - need to parse out more on blur
        regex = /^([0-9]*?)\.?\d*$/;
    } else if (numberType == "integer") {
        regex = /^-?[0-9]*$/;
    } else {
        regex = /^[0-9]*$/;
    }

    const onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
        const reg = new RegExp(regex);
        let value = e.target.value.replace(/[^0-9.]/g, '');

        if (reg.test(value)) {
            if (numberType == "decimal") {
                // prevent 0., 1., ., .0, .1, 01., 01
                const reg2 = new RegExp(/^[0-9]*\.$/);
                if (value == "." || value == "") {
                    value = "";
                } else if (reg2.test(value)) {
                    value = parseInt(value).toString();
                } else {
                    value = parseFloat(value).toString();
                }
            } else if (numberType == "numeric" || numberType == "integer") {
                if (value && !isNaN(parseInt(value))) {
                    // prevent 01, 00, -00, -01
                    value = parseInt(value).toString();
                }
            } else if (numberType == "currency") {
                if (value == "." || value == "") {
                    value = "";
                } else {
                    value = USDollar.format(parseFloat(value)).toString();
                }
            } else if (numberType == "percent") {
                // prevent 0., 1., ., .0, .1, 01., 01
                const reg2 = new RegExp(/^[0-9]*\.$/);
                if (value == "." || value == "") {
                    value = "";
                } else if (reg2.test(value)) {
                    value = parseInt(value).toString() + "%";
                } else {
                    value =  parseFloat(value).toString() + "%";
                }
            }

            setLocalValue(value);

            if (props.onBlur) {
                props.onBlur(e);
            }
        } else {
            setLocalValue(prevValueRef.current || "");
        }
    };

    const onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
        let value = e.target.value.replace(/[^0-9.]/g, '');
        const reg = new RegExp(regex);

        if (reg.test(value)) {
            setLocalValue(e.target.value);

            if (props.onChange) {
                props.onChange(e);
            }
        }
    };

    textfieldProps.value = localValue;
    textfieldProps.onChange = e => onChange(e);
    textfieldProps.onBlur = e => onBlur(e);

    return (
        // putting type="number" as a prop will add the up/down arrows back
        <TextField {...textfieldProps} />
    );
}

export default NumericalTextField;
