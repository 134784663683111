//get an array of the field ids in query builder rules
export default function getFieldIdsFromRules(rules) {
    let fieldIds = [];
    if (rules) {
        if (rules.condition) {
            const groupFieldIds = getFieldIdsFromRules(rules.rules);
            if (groupFieldIds && groupFieldIds.length > 0) {
                fieldIds = fieldIds.concat(groupFieldIds);
            }
        } else if (rules.length > 0) {
            for (const rule of rules) {
                if (rule.condition) {
                    const groupFieldIds = getFieldIdsFromRules(rule.rules);
                    if (groupFieldIds && groupFieldIds.length > 0) {
                        fieldIds = fieldIds.concat(groupFieldIds);
                    }
                } else if (rule.id) {
                    fieldIds.push(rule.id);
                }
            }
        }
    }
    return fieldIds;
}
