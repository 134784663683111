import React from "react";
import TreeView from "../tree/TreeView";
import FieldList, { sortChildIds } from "./FieldList";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { ContextMenuTrigger } from "react-contextmenu";
import {
    fieldContainsSearchString,
    fieldContainsSearchLabels,
    fieldContainsSearchLabels3,
    fieldMatchesSearchString,
} from "../../reducers/fields";
import highlightForSearch from "../../helpers/highlightForSearch";
import HtmlTooltip from "../material-components/Misc/HtmlTooltip";
import makeTooltipForField from "./makeTooltipForField";
import FieldFolderRename from "./FieldFolderRename";
import FieldFolderDragDrop from "./FieldFolderDragDrop";
import { FieldVisibilityTypes } from "../../helpers/constants";
import { getTreeSearch, getModalTreeSearch } from "../../reducers/search";
import { IAppState } from "../../types/stores/index";
import { IField } from "../../types/stores/fieldTypes";
import { IFieldsStore } from "../../types/stores/fields";

type Props = {
    // Passed In
    fieldKey: string | number;
    treeViewOnUpdate?: () => void; // Used for odd/even row coloring
    showAllDuringSearch?: boolean; // Used for folder search, searched folders are told to show all contents, even if they don't match
    TreeType: number;
    editable: boolean;
    showHiddenFields: boolean;
    ctrlShift: boolean;
    isModal: boolean;
    tabType: number;
    showTabs: boolean;
};

const FieldListFolder: React.FC<Props> = (props: Props) => {
    const {
        fieldKey,
        treeViewOnUpdate,
        showAllDuringSearch,
        TreeType,
        editable,
        showHiddenFields,
        ctrlShift,
        isModal,
        tabType,
        showTabs,
    } = props;

    const getFolderContainsSearchString = makeGetFolderContainsSearchString();
    const getFolderContainsLabelsSearch = makeGetFolderContainsLabelsSearch();
    const getFolderContainsLabelsSearch3 = makeGetFolderContainsLabelsSearch3();
    const getRenamingAndHasNoChildren = makeGetRenamingAndHasNoChildren();
    const getHasChildRenaming = makeGetHasRenamingChild();

    const fields = useSelector((state: IAppState) => {
        const fields =
            TreeType == FieldVisibilityTypes.EXPORT
                ? state.layoutfields
                : TreeType == FieldVisibilityTypes.REPORT
                ? state.reportFields
                : state.fields;
        return fields;
    });

    // fieldKey is actually a workid so need to get the field from fields.byFolder
    const field: IField = fields.byFolder[fieldKey];
    const fieldsByParent = useSelector((state: IAppState) => state.fields.byParent);
    const treeSearch: string = useSelector<IAppState, string>(state =>
        isModal ? getModalTreeSearch(state) : getTreeSearch(state)
    );
    const folderMatchesSearchString = treeSearch && field ? fieldMatchesSearchString(field, treeSearch) : false;
    const selectedFieldLabels = useSelector(
        (state: IAppState) =>
            state.fieldLabels.labels
                .filter(x => state.search.selectedFieldLabels.includes(x.FieldLabelId))
                .map(x => x.FieldLabelName) || []
    );
    const selectedFieldLabels3 = useSelector(
        (state: IAppState) =>
            state.fieldLabels3.labels
                .filter(x => x.FieldLabelId == state.search.selectedFieldLabels3)
                .map(x => x.FieldLabelName) || []
    );
    const folderContainsSearchString = useSelector((state: IAppState) => getFolderContainsSearchString(state, props));
    const folderContainsLabelsSearch = useSelector((state: IAppState) => getFolderContainsLabelsSearch(state, props));
    const folderContainsLabelsSearch3 = useSelector((state: IAppState) => getFolderContainsLabelsSearch3(state, props));
    const renamingAndHasNoChildren = useSelector((state: IAppState) => getRenamingAndHasNoChildren(state, props));
    const hasRenamingChild = useSelector((state: IAppState) => getHasChildRenaming(state, props));
    const isFolderRenaming = useSelector((state: IAppState) => state.vars.renamingFieldFolderId == field.workid);
    const pathname = useSelector(
        (state: IAppState) => state.router && state.router.location && state.router.location.pathname
    );

    const hasChildren =
        field && field.workid && fieldsByParent
            ? fieldsByParent[field.workid] && fieldsByParent[field.workid].length > 0
            : false;

    const renderFolderLabel = () => {
        let { text } = field;
        text = highlightForSearch(text, treeSearch);
        const links = [];

        if (editable) {
            // check for folder being renamed and display rename control
            if (isFolderRenaming) {
                return (
                    <div style={{ display: "inline-block", width: "calc(100% - 23px)", paddingRight: "0px" }}>
                        <FieldFolderRename
                            folderName={field.text}
                            folderId={field.workid}
                            key={field.workid}
                            isFieldFolder={true}
                        />
                    </div>
                );
            } else {
                let inlineBlock;

                if (pathname) {
                    if (pathname.includes("/admin/fields/edit")) {
                        inlineBlock = { margin: "-26px 30px 0px" };
                    } else {
                        inlineBlock = { display: "inline-block", width: "calc(100% - 23px)", paddingRight: "0px" };
                    }
                } else {
                    inlineBlock = { display: "inline-block", width: "calc(100% - 23px)", paddingRight: "0px" };
                }

                return (
                    <div style={inlineBlock}>
                        {text}
                        <div className="pull-right tree-links">{links}</div>
                    </div>
                );
            }
        } else {
            return <span>{text}</span>;
        }
    };

    const tt = makeTooltipForField(field, treeSearch);
    let headerMap: ((x: JSX.Element) => JSX.Element) | null;
    if (editable) {
        headerMap = x => (
            <FieldFolderDragDrop field={field} toolTip={tt}>
                {x}
            </FieldFolderDragDrop>
        );
    } else {
        headerMap = x => (
            <div>
                <ContextMenuTrigger
                    id="edit-context"
                    collect={props => props}
                    key={field.workid}
                    holdToDisplay={-1}
                    // @ts-ignore
                    item={field}
                    //@ts-ignore
                    hasChildren={hasChildren}
                >
                    <HtmlTooltip placement="right" title={tt} maxwidth={500} enterDelay={250}>
                        <div>{x}</div>
                    </HtmlTooltip>
                </ContextMenuTrigger>
            </div>
        );
    }

    let showAllInSearch = showAllDuringSearch || false;
    let itemClassName = "";
    let defaultCollapsed = true;
    let collapsed: boolean | undefined = undefined;

    if (isFolderRenaming) {
        itemClassName += " renaming";
        headerMap = null; // Don't allow drag and drop
        collapsed = renamingAndHasNoChildren; // Force folder open if renaming something with children
    }

    if (hasRenamingChild) {
        collapsed = false;
    }

    if (treeSearch || selectedFieldLabels.length > 0 || selectedFieldLabels3.length > 0) {
        if (treeSearch && folderMatchesSearchString) {
            showAllInSearch = true;
        }

        const notInTreeSearch = treeSearch && !folderMatchesSearchString && !folderContainsSearchString;
        const notInLabelsSearch = selectedFieldLabels.length > 0 && !folderContainsLabelsSearch;
        const notInLabelsSearch3 = selectedFieldLabels3.length > 0 && !folderContainsLabelsSearch3;
        if (notInTreeSearch || notInLabelsSearch || notInLabelsSearch3) {
            // Don't render folder if not in search results
            return null;
        }
        //If it's in the search results, we open the folder and can be closed by click
        defaultCollapsed = false;
        collapsed = false;
    }

    return (
        <TreeView
            nodeLabel={renderFolderLabel()}
            headerMap={headerMap}
            key={fieldKey}
            defaultCollapsed={defaultCollapsed}
            collapsed={collapsed}
            onUpdate={treeViewOnUpdate}
            itemClassName={itemClassName}
        >
            <FieldList
                key={fieldKey}
                fieldKey={fieldKey}
                showAllDuringSearch={showAllInSearch}
                treeViewOnUpdate={treeViewOnUpdate!}
                TreeType={TreeType}
                showHiddenFields={showHiddenFields}
                editable={editable}
                isPIIOnly={false}
                ctrlShift={ctrlShift}
                isModal={isModal}
                showTabs={showTabs}
                tabType={tabType}
            />
        </TreeView>
    );
};

const makeGetHasRenamingChild = () =>
    //@ts-ignore
    createSelector(
        (state: IAppState, props: Props) => {
            let fields: IFieldsStore;
            if (props.TreeType == FieldVisibilityTypes.EXPORT) {
                fields = state.layoutfields;
            } else if (props.TreeType == FieldVisibilityTypes.REPORT) {
                fields = state.reportFields;
            } else {
                fields = state.fields;
            }
            return fields.byFolder;
        },
        (state: IAppState, props: Props) => {
            let fields: IFieldsStore;
            if (props.TreeType == FieldVisibilityTypes.EXPORT) {
                fields = state.layoutfields;
            } else if (props.TreeType == FieldVisibilityTypes.REPORT) {
                fields = state.reportFields;
            } else {
                fields = state.fields;
            }

            if (!fields || !fields.byParent || !Object.prototype.hasOwnProperty.call(fields.byParent, props.fieldKey)) {
                return null;
            }
            return fields.byParent[props.fieldKey];
        },
        state => state.vars.renamingFieldFolderId,
        (fieldsById: { [workId: number]: IField }, childIds: Array<string | number>, renamingFieldFolderId: number) => {
            if (childIds == null || renamingFieldFolderId == null) {
                return false;
            }

            childIds = sortChildIds(childIds, fieldsById);

            return Boolean(childIds.find(x => fieldsById[x].workid == renamingFieldFolderId));
        }
    );

const makeGetRenamingAndHasNoChildren = () =>
    createSelector(
        (state: IAppState, props: Props) =>
            props.TreeType == FieldVisibilityTypes.EXPORT
                ? state.layoutfields
                : props.TreeType == FieldVisibilityTypes.REPORT
                ? state.reportFields
                : state.fields,
        (_, props) => props.fieldKey,
        state => state.vars.renamingFieldFolderId,
        (fields, fieldKey, renamingFieldFolderId): boolean => {
            const folder = fields.byFolder[fieldKey];
            // Don't bother computing if not renaming - optimization to avoid recursive search on lots of updates
            if (renamingFieldFolderId != folder.workid) {
                return false;
            }
            return Boolean(
                folder.workid && fields.byParent[folder.workid] && fields.byParent[folder.workid].length > 0
            );
        }
    );

const makeGetFolderContainsSearchString = () =>
    createSelector(
        (state: IAppState, props: Props) =>
            props.TreeType == FieldVisibilityTypes.EXPORT
                ? state.layoutfields
                : props.TreeType == FieldVisibilityTypes.REPORT
                ? state.reportFields
                : state.fields,
        (_, props: Props) => props.fieldKey,
        (state: IAppState, props: Props): string | Array<string> =>
            props.isModal ? getModalTreeSearch(state) : getTreeSearch(state),
        (fields, fieldKey, treeSearch) => fieldContainsSearchString(fields, fieldKey, treeSearch)
    );

const makeGetFolderContainsLabelsSearch = () =>
    createSelector(
        (state: IAppState, props: Props) =>
            props.TreeType == FieldVisibilityTypes.EXPORT
                ? state.layoutfields
                : props.TreeType == FieldVisibilityTypes.REPORT
                ? state.reportFields
                : state.fields,
        (_, props: Props) => props.fieldKey,
        (state: IAppState): Array<number> => state.search.selectedFieldLabels,
        (state: IAppState) => state.fieldLabels.labels,
        (fields, fieldKey, fieldLabelIds, fieldLabels) => {
            const selectedFieldLabels = fieldLabels
                .filter(x => fieldLabelIds.includes(x.FieldLabelId))
                .map(x => x.FieldLabelName);
            return fieldContainsSearchLabels(fields, fieldKey, selectedFieldLabels);
        }
    );

const makeGetFolderContainsLabelsSearch3 = () =>
    createSelector(
        (state: IAppState, props: Props) =>
            props.TreeType == FieldVisibilityTypes.EXPORT
                ? state.layoutfields
                : props.TreeType == FieldVisibilityTypes.REPORT
                ? state.reportFields
                : state.fields,
        (_, props: Props) => props.fieldKey,
        (state: IAppState): number => state.search.selectedFieldLabels3,
        (state: IAppState) => state.fieldLabels3.labels,
        (fields, fieldKey, fieldLabelIds3, fieldLabels3) => {
            const selectedFieldLabels3 = fieldLabels3
                .filter(x => x.FieldLabelId == fieldLabelIds3)
                .map(x => x.FieldLabelName);
            return fieldContainsSearchLabels3(fields, fieldKey, selectedFieldLabels3);
        }
    );

export default FieldListFolder;
