import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as actionCreators from "../../actions/actionCreators";
import * as adminCompaniesActions from "../../actions/adminCompaniesActions";

import AeLocalNavRight from "./AeLocalNavRight";
import LinearLoadingBar from "../loading/LinearLoadingBar";
import { goToUrl } from "../../actions/actionCreators";

class AeLocalNav extends React.Component {
    constructor() {
        super();

        this.state = {
            context: "",
            breadcrumbLink: "",
            notFoundPage: true,
        };
    }

    componentDidMount() {
        this.updateHeader();
    }

    componentDidUpdate(prevProps) {
        if (
            location.pathname.includes("/admin/companies/edit") &&
            prevProps.userCompanies != this.props.userCompanies
        ) {
            const path = location.pathname;
            this.setState({
                context: "Edit " + this.getCompanyName(path.substring(path.lastIndexOf("/") + 1)),
            });
        }
        if (prevProps.location && prevProps.location.pathname != this.props.location.pathname) {
            this.updateHeader();
        }
        // Marco: This was a way I found to refresh in the correct way the title of the page in not found
        if (!this.props.notFound && this.state.notFoundPage) {
            this.setState({ notFoundPage: false });
            this.updateHeader();
        }
        if (this.props.notFound && !this.state.notFoundPage) {
            this.setState({ notFoundPage: true });
            this.updateHeader();
        }
    }

    updateHeader = () => {
        const { location, notFound } = this.props;
        let path = window.location.pathname;
        if (location && location.pathname) {
            path = location.pathname;
        }

        // todo, this is disgusting, clean it - vu to vu
        // note from rachel - make this match the icon in the left nav this link came from
        // note from Marco - did not want to remove this comments while working on #4678
        if (path) {
            if (path.includes("/flows/")) {
                this.setState({ context: "Flows", breadcrumbLink: "/ae/flows/design" });
            } else if (path.includes("/connect")) {
                this.setState({ context: "Data Connect" });
            } else if (path.includes("/admin/") || path.includes("SingleViewControlCenter")) {
                this.setState({ context: "Admin" });
            } else if (path.includes("/reports/") || path.includes("Tableau") || path.includes("LayoutsMain/History")) {
                this.setState({ context: "Reports" });
            } else if (path.includes("/dashboard")) {
                this.setState({ context: "Dashboards" });
            } else if (path.includes("/make-a-wish") || path.includes("/MakeAWishReport")) {
                this.setState({ context: "Make-A-Wish" });
            } else if (path.includes("/tools")) {
                this.setState({ context: "Tools" });
            } else if (["/help", "/partners", "/dataproviders", "/discover"].find(p => path.includes(p))) {
                this.setState({ context: "Resource Center" });
            } else if (path.includes("/account")) {
                this.setState({ context: "My Account" });
            } else if (path == "/") {
                this.setState({ context: "Home" });
            } else {
                this.setState({ context: "", breadcrumbLink: "" });
            }
            if (notFound) {
                this.setState({ context: "Audience Engine" });
            }
        }
    };

    getCompanyName = companyId => {
        const company = this.props.userCompanies.filter(x => x.CompanyID == companyId)[0];
        if (!company) {
            return "Company";
        }
        return company.CompanyName;
    };

    static propTypes = {
        // From Router
        location: PropTypes.object,
        // Redux
        enabledFeatures: PropTypes.array,
        userCompanies: PropTypes.array,
        pwExpired: PropTypes.bool,
        notFound: PropTypes.bool,
        // Injected by Redux (action creator)
        loadingStates: PropTypes.any,
    };

    render() {
        const { loadingStates, pwExpired } = this.props;
        const { context, breadcrumbLink } = this.state;

        const linearLoadingBar = loadingStates ? (
            <LinearLoadingBar progress={loadingStates.globalLoadingProgress} show={loadingStates.showGlobalLoading} />
        ) : null;

        return (
            <div>
                {linearLoadingBar}
                <nav className={`navbar navbar-local-container navbar-default navbar-local-experian-exp`}>
                    <div className="container-fluid">
                        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                            <span className="nav-context">
                                {breadcrumbLink != "" ? (
                                    <a className="breadcrumb" href={breadcrumbLink}>
                                        {context}
                                    </a>
                                ) : context ? (
                                    context == "Make-A-Wish" ? (
                                        <span
                                            className="make-a-wish"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => top.store.dispatch(goToUrl("/make-a-wish/"))}
                                        >
                                            {context}
                                        </span>
                                    ) : (
                                        context
                                    )
                                ) : (
                                    <span />
                                )}
                            </span>
                            {!pwExpired && (
                                <ul className="nav navbar-nav navbar-right">
                                    <AeLocalNavRight />
                                </ul>
                            )}
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    enabledFeatures: state.session.enabledFeatures || [],
    loadingStates: state.loadingStates,
    userCompanies: state.adminCompanies.userCompanies || [],
    pwExpired: state.session.pwExpired,
    notFound: state.vars.notFound,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(Object.assign({}, actionCreators, adminCompaniesActions), dispatch);
//bindActionCreators(Object.assign({}, actionCreators, fieldTreeActions, audienceFolderActions), dispatch)
const AeLocalNavC = connect(mapStateToProps, mapDispatchToProps)(AeLocalNav);

export default AeLocalNavC;
