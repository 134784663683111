function flowScriptKeywords(state = null, action) {
    switch (action.type) {
        case "LOAD_FLOW_SCRIPT_KEYWORDS": {
            if (!action.keywords) {
                return [];
            }
            const newState = action.keywords;
            return newState;
        }
        default:
            return state;
    }
}

export default flowScriptKeywords;
