import { INasStore, NasActions } from "../types/actions/nas";
export const SET_NAS_FILES_LIST_LOADING = "SET_NAS_FILES_LIST_LOADING";
export const SET_NAS_FILES_LIST_ERROR = "SET_NAS_FILES_LIST_ERROR";
export const SET_NAS_FILES_LIST = "SET_NAS_FILES_LIST";
export const ADD_NAS_FILES_DOWNLOAD_QUEUE = "ADD_NAS_FILES_DOWNLOAD_QUEUE";
export const REMOVE_NAS_FILES_DOWNLOAD_QUEUE = "REMOVE_NAS_FILES_DOWNLOAD_QUEUE";
export const RESET_NAS = "RESET_NAS";
export const SET_NAS_FILES_INFO = "SET_NAS_FILES_INFO";

function nas(
    state = {
        files: new Array<string>(),
        error: null,
        isLoading: false,
        downloadQueue: new Array<string>(),
        filesInfo: [],
    },
    action: NasActions
): INasStore {
    switch (action.type) {
        case SET_NAS_FILES_LIST:
            return Object.assign({}, state, {
                files: action.files,
                error: null,
            });
        case SET_NAS_FILES_LIST_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case SET_NAS_FILES_LIST_ERROR:
            return Object.assign({}, state, {
                error: action.error,
            });
        case ADD_NAS_FILES_DOWNLOAD_QUEUE: {
            const newDownloadQueue = [...state.downloadQueue];
            newDownloadQueue.push(action.file);

            return Object.assign({}, state, {
                downloadQueue: newDownloadQueue,
            });
        }
        case REMOVE_NAS_FILES_DOWNLOAD_QUEUE: {
            const fileIndex = state.downloadQueue.indexOf(action.file);

            if (fileIndex >= 0) {
                const newDownloadQueue = [...state.downloadQueue];

                newDownloadQueue.splice(fileIndex, 1);

                return Object.assign({}, state, {
                    downloadQueue: newDownloadQueue,
                });
            } else {
                return state;
            }
        }
        case RESET_NAS:
            return Object.assign({}, state, {
                files: new Array<string>(),
                error: null,
                isLoading: false,
                downloadQueue: new Array<string>(),
                filesInfo: [],
            });
        case SET_NAS_FILES_INFO:
            return Object.assign({}, state, {
                filesInfo: action.filesInfo,
            });

        default:
            return state;
    }
}

export default nas;
