export const labelCompare = (a, b) => {
    //used to ignore casing
    const x = a.label.toUpperCase();
    const y = b.label.toUpperCase();

    return x > y ? 1 : -1;
};

export const sortSelectOptions = options =>
    options.sort((a, b) => {
        if (a.label == null) {
            a.label = "Unnamed";
        }
        if (b.label == null) {
            b.label == "Unnamed";
        }
        const comparison = a.label.toLowerCase().localeCompare(b.label.toLowerCase());
        if (comparison === 0) {
            return a.label.localeCompare(b.label);
        }
        return comparison;
    });
