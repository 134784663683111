import { Dispatch } from "redux";
import { FlowProcessingTypes } from "../helpers/constants";
import {
    ISetToggleFlowListing,
    ISetFlowClosed,
    ISetPreviousFlowFolder,
    ISetToggleListingPinnable,
    ISetFlowProcessType,
} from "../types/stores/flowDesigner";
import {
    TOGGLE_FLOW_LISTING,
    TOGGLE_FLOW_LISTING_PINNABLE,
    SET_FLOW_PROCESS_TYPE,
    SET_FLOW_CLOSED,
    SET_PREVIOUS_FLOW_FOLDER,
} from "../reducers/flowDesigner";

export const ToggleFlowListing = (currentValue: boolean): ISetToggleFlowListing => ({
    type: TOGGLE_FLOW_LISTING,
    isOpen: !currentValue,
});

export const ToggleFlowListingPinnable = (currentValue: boolean): ISetToggleListingPinnable => ({
    type: TOGGLE_FLOW_LISTING_PINNABLE,
    isPinned: !currentValue,
});

export const SetFlowProcessingType =
    (processTypeString: string) =>
    (dispatch: Dispatch<ISetFlowProcessType>): void => {
        const processType = FlowProcessingTypes[processTypeString] ? FlowProcessingTypes[processTypeString] : 1;

        dispatch({
            type: SET_FLOW_PROCESS_TYPE,
            processType,
        });
    };

export const SetPreviouslyClosedFlow = (hasClosed: boolean): ISetFlowClosed => ({
    type: SET_FLOW_CLOSED,
    hasClosed,
});

export const SetPreviouslySelectedFolder = (folderId: number): ISetPreviousFlowFolder => ({
    type: SET_PREVIOUS_FLOW_FOLDER,
    folderId,
});
