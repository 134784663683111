import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../actions/actionCreators";
import VariableLayoutListEntry from "./VariableLayoutListEntry";
import { variableMatchesSearchString } from "../../reducers/exportlayouts";
import withUpdateStripes from "../misc/withUpdateStripes";

type Props = {
    scope: boolean,
};

class VariableLayoutList extends React.Component<Props> {
    componentDidMount() {
        this.props.updateStripes();
    }

    componentDidUpdate() {
        this.props.updateStripes();
    }

    renderVariable = variableId => {
        const { variables, thisVariable, scope } = this.props;
        const variable = variables[variableId];

        return (
            <VariableLayoutListEntry
                variableId={variableId}
                variable={variable}
                key={variableId}
                selectedVariable={thisVariable}
                scope={scope}
            />
        );
    };

    render() {
        const { variables, treeSearch, scope } = this.props;
        if (variables != null) {
            let variableIds = Object.values(variables)
                .filter(x => x.VariableScope == scope)
                .map(x => x.Id);

            if (treeSearch) {
                variableIds = variableIds.filter(x => variableMatchesSearchString(variables[x], treeSearch));
            }

            const elements = variableIds.map(this.renderVariable);

            return <div>{elements}</div>;
        } else {
            return <div />;
        }
    }
}

VariableLayoutList.propTypes = {
    //Inject by redux
    variables: PropTypes.any,
    treeSearch: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    thisLayout: PropTypes.any,
    thisVariable: PropTypes.any,
    // FROM HOC
    updateStripes: PropTypes.func.isRequired,
};

const VariableLayoutListS = withUpdateStripes(VariableLayoutList);

import { getTreeSearch } from "../../reducers/search";
const mapStateToProps = state => ({
    treeSearch: getTreeSearch(state),
    thisVariable: state.selected.globalVariable,
});

const mapDispatchToProps = dispatch => bindActionCreators(actionCreators, dispatch);
const VariableLayoutListSConnected = connect(mapStateToProps, mapDispatchToProps)(VariableLayoutListS);

export default VariableLayoutListSConnected;
