import isEqual from "lodash/isEqual";
//import mergeDeep from '../helpers/mergeDeep';
//import clone from '../helpers/clone';
//import update from 'immutability-helper';

function filters(state = [], action) {
    switch (action.type) {
        case "LOAD_FILTERS": {
            if (!action.filters) {
                return state;
            }

            const newState = action.filters;

            // This transforms an array of objects into an associative array keyed off the filterID:
            //let newState = action.filters.reduce((obj, filterObj) => { return { ...obj, [filterObj.id]: filterObj }; }, {});
            if (state && isEqual(state, newState)) {
                return state;
            }
            return newState;
        }

        case "LOAD_REQUESTED_FILTERS": {
            if (!action.filters) {
                return state;
            }
            //copy the state
            const newState = [...state];

            for (const filter of action.filters) {
                const index = newState.findIndex(x => x.id == filter.id);

                if (index >= 0) {
                    newState[index] = filter;
                } else {
                    newState.push(filter);
                }
            }
            return newState;
        }

        case "REMOVE_REQUESTED_FILTER": {
            if (!action.filterId) {
                return state;
            }
            //copy the state
            const newState = [...state];
            const stateWithRemovedFilter = newState.filter(x => x.id !== action.filterId);

            return stateWithRemovedFilter;
        }

        default:
            return state;
    }
}

/*
Objectfield: "GENERAL_CONTRIBTRCAT"id: "10251"input: "select"label: "General Donor (136) (Experian > Lifestyles > Self - Charities and Volunteering)"multiple: "TRUE"type: "string"values: Array[2]0: Object1: "Responder (1)"__proto__: Object1: Object0: "Unknown (0)"__proto__: Objectlength: 2__proto__: Array[0]__proto__: Object
let filterObj = store.getState().filters[10251];
*/

export default filters;
