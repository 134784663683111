import React, { useState } from "react";
import update from "immutability-helper";
import { Grid } from "@material-ui/core";
import ScheduleCronStringEditor from "../../cron/ScheduleCronStringEditor";
import Moment from "moment";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { ScheduleState } from "../ScheduleAddEdit";

type Props = {
    schedule: ScheduleState;
    setSchedule: React.Dispatch<React.SetStateAction<ScheduleState>>;
    updateUnsavedChanges: () => void;
    isScheduleTemplate?: boolean;
    disabled?: boolean;
};

const EndDate: React.FC<Props> = ({
    schedule,
    setSchedule,
    updateUnsavedChanges,
    isScheduleTemplate,
    disabled,
}: Props) => {
    const [inputValue, setInputValue] = useState(Moment().format("MM/DD/YYYY"));

    const handleEndDateChange = val => {
        setSchedule(update(schedule, { endDate: { $set: val } }));
        setInputValue(Moment(val).format("MM/DD/YYYY"));
        updateUnsavedChanges();
    };

    return (
        <>
            <ScheduleCronStringEditor
                schedule={schedule}
                setSchedule={setSchedule}
                updateUnsavedChanges={updateUnsavedChanges}
                isScheduleTemplate={isScheduleTemplate}
                disabled={disabled}
            />
            <div style={{ display: "flex", alignItems: "flex-end", width: "500px" }}>
                <Grid item xs={2} style={{ padding: 0 }}>
                    <span>End date</span>
                </Grid>

                <Grid item xs={6}>
                    <MuiPickersUtilsProvider libInstance={Moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/DD/YYYY"
                            id="endDate"
                            label="End Date"
                            value={Moment(schedule.endDate)}
                            inputValue={
                                schedule.endDate == null ? inputValue : Moment(schedule.endDate).format("MM/DD/YYYY")
                            }
                            onChange={handleEndDateChange}
                            className="material-form-control"
                            KeyboardButtonProps={{
                                "aria-label": "endDate",
                            }}
                            disabled={disabled}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </div>
        </>
    );
};

export default EndDate;
