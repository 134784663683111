import { withStyles, Tooltip, TooltipProps } from "@material-ui/core";

interface CustomTooltipProps extends TooltipProps {
    maxwidth?: number;
}

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: (props: CustomTooltipProps) => (props.maxwidth ? props.maxwidth : 220),
        fontSize: theme.typography.pxToRem(14),
        border: "1px solid #dadde9",
    },
}))(Tooltip);

export default HtmlTooltip;
