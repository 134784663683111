import React from "react";
import { useSelector } from "react-redux";
import update from "immutability-helper";
import { MenuItem, TextField } from "@material-ui/core";
import { IAppState } from "../../../../types/stores/index";
import { ScheduleState } from "../ScheduleAddEdit";

type Props = {
    schedule: ScheduleState;
    setSchedule: React.Dispatch<React.SetStateAction<ScheduleState>>;
    updateUnsavedChanges: () => void;
    disabled?: boolean;
};

const EndDate: React.FC<Props> = ({ schedule, setSchedule, updateUnsavedChanges, disabled }: Props) => {
    const automationItems = useSelector((state: IAppState) => state.automation.userAutomationItems);

    const handleSourceIdChange = val => {
        const id = val.target.value;
        if (id != schedule.sourceId) {
            setSchedule(update(schedule, { sourceId: { $set: id } }));
            updateUnsavedChanges();
        }
    };

    return (
        <div style={{ display: "flex", alignItems: "flex-end", width: "500px" }}>
            <TextField
                id="automation"
                select
                label="Automation Item"
                value={typeof schedule.sourceId == "undefined" ? "" : schedule.sourceId}
                onChange={handleSourceIdChange}
                className="material-form-control"
                disabled={disabled}
            >
                {automationItems.map(item => (
                    <MenuItem key={item.Id} value={item.Id}>
                        {item.JobName}
                    </MenuItem>
                ))}
            </TextField>
        </div>
    );
};

export default EndDate;
