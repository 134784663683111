import debounce from "debounce";
import State from "./state";

const notify = () => {
    if (State.notify != null) {
        State.notify();
    }
};
const debouncedNotify = debounce(notify, 8);

export default function debounceUpdateBatcher() {
    debouncedNotify();
}
