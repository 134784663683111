import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
// import tryParseJSON from "../helpers/tryParseJSON";
import type { FlowMerge, FlowItem, FlowErrorsByItemId, FlowRelation } from "../types/flowTypes";

const myGenericReducer = makeReducerFor("FLOW_MERGE", "FlowMergeId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowMergesArray = createSelector(
    state => state.flowMerges.byId,
    (flowMergesById: {| [number]: FlowMerge |}): Array<FlowMerge> => {
        const r: Array<FlowMerge> = Object.values(flowMergesById);
        return r;
    }
);

export type FlowMergesByItemId = {
    [number]: Array<FlowMerge>,
};

export const getFlowMergesByFlowItemId = createSelector(
    state => getFlowMergesArray(state),
    (flowMerges: Array<FlowMerge>): FlowMergesByItemId =>
        flowMerges.reduce((acc, row) => {
            if (acc[row.FlowItemId] == null) {
                acc[row.FlowItemId] = [];
            }
            acc[row.FlowItemId].push(row);
            return acc;
        }, {})
);

export const getFlowMergesForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowMergesByFlowItemId(state),
    (selectedFlow: number, flowItems: Array<FlowItem>, flowMergesByItemId: FlowMergesByItemId): Array<FlowMerge> => {
        let result = [];
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        for (const itemId of itemIds) {
            const merges = flowMergesByItemId[itemId];
            if (merges != null) {
                result = result.concat(merges);
            }
        }
        return result;
    }
);

import { getFlowRelationsForSelectedFlow, getFlowRelationsForAllFlows } from "./flowRelations";

const mergesToErrorsById = (
    flowMerges: Array<FlowMerge>,
    flowItems: Array<FlowItem>,
    flowRelations: Array<FlowRelation>
): FlowErrorsByItemId => {
    const errors = {};
    const itemIds = flowItems.filter(fi => fi.FlowItemType.toLowerCase() == "merge").map(fi => fi.FlowItemId);
    for (const itemId of itemIds) {
        const theseMerges = flowMerges.filter(x => x.FlowItemId == itemId);
        errors[itemId] = validateFlowMerges(theseMerges, flowRelations);
    }
    return errors;
};

export const getMergeErrorsForSelectedFlow = createSelector(
    state => getFlowMergesForSelectedFlow(state),
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowRelationsForSelectedFlow(state),
    mergesToErrorsById
);

export const getMergeErrorsForAllFlows = createSelector(
    state => getFlowMergesArray(state),
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowRelationsForAllFlows(state),
    mergesToErrorsById
);

//////////////////// HELPERS /////////////////////////////

// ***** Must pass an array of all FlowMerge rows belonging to a particular itemId. *****
export const validateFlowMerges = (flowMerges: Array<FlowMerge>, flowRelations: Array<FlowRelation>): Array<string> => {
    const errors = [];

    const parentFlowItemIds = flowRelations.map(x => x.ParentFlowItemId);
    const flowMergesWithNoParent = flowMerges.filter(x => !parentFlowItemIds.includes(x.ParentFlowItemId));
    const nonSuppressionFlowMerges = flowMerges.filter(x => !x.FlowMergeIsSuppresion);

    if (flowMerges.length == 0) {
        errors.push("Must have at least one merge assigned.");
    }
    if (flowMergesWithNoParent.length > 0) {
        errors.push("Merge items must have a parent item assigned.");
    }
    if (nonSuppressionFlowMerges.length == 0) {
        errors.push("Merge items must have at least one item that isn't a suppression.");
    }

    return errors;
};
