import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { parseDate } from "../../../helpers/parseDate";
import { ScheduleType } from "../../../enums/schedules";

import wrapButtonWithConfirmModal from "../../../helpers/wrapButtonWithConfirmModal";
import prettyCron from "prettycron";
import Moment from "moment";

import {
    setScheduleModeAndEditId,
    requestEditSchedule,
    scheduleSetUnsavedChanges,
} from "../../../actions/scheduleActions";

import ScheduleHeader from "../misc/Header";
import ScheduleBreadCrumbs from "../misc/ScheduleBreadCrumbs";
import TimingSelector from "./timing/TimingSelector";
import ScheduleCronStringEditor from "../cron/ScheduleCronStringEditor";
import OneTime from "./timing/OneTime";
import EndAfterNOccurances from "./timing/EndAfterNOccurances";
import EndDate from "./timing/EndDate";
import TableRefresh from "./timing/TableRefresh";

import { Checkbox, Grid, TextField, FormControlLabel } from "@material-ui/core";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import { ColorButton } from "../../material-components/Misc/Button";
import { IAppState } from "../../../types/stores/index";
import { ISchedule } from "../../../types/stores/schedules";
import Paper from "@material-ui/core/Paper";
import TimeFrequency from "./timing/TimeFrequency";

type Props = {
    scheduleId: string;
    isScheduleTemplate?: boolean;
    companyId?: number;
    isExportModal?: boolean;
    onSaveCallback?: (id: string) => void;
};

type ScheduleError = {
    name?: string;
    cronString?: string;
    timeType?: string;
    runDate?: string;
};

export type ScheduleState = {
    id: string | null;
    name: string;
    triggerCount: number;
    isActive: boolean;
    isConsecutive: boolean;
    continueOnError: boolean;
    cronString: string;
    timezone: string | null;
    errors: ScheduleError;
    isCustomName: boolean;
    scheduleType: number;
    nOccurrences?: number | null;
    runDate: Moment.Moment | Date | string;
    endDate: Moment.Moment | Date | string | null;
    hour: number;
    minute: number;
    sourceId?: number | null;
    isScheduleTemplate: boolean;
    defaultTriggerType?: string | null;
    emails: string | null | undefined;
    RunCount: number;
    TimeType: number | null;
    TimeCount: number | null;
};

function GetCurrentState(schedule: ISchedule) {
    let runDate: Date | string | null = null;
    let endDate: Date | string | null = null;
    let hour = 0;
    let minute = 0;
    if (schedule.ScheduledRunTime != null) {
        runDate = parseDate(schedule.ScheduledRunTime);
        hour = runDate.getHours();
        minute = runDate.getMinutes();
    }
    if (schedule.EndDate != null) {
        endDate = parseDate(schedule.EndDate);
    }
    const currentState: ScheduleState = {
        id: schedule.Id,
        name: schedule.Name,
        triggerCount: schedule.TriggerCount,
        isActive: schedule.IsActive,
        isConsecutive: schedule.IsConsecutively,
        continueOnError: schedule.Continue,
        cronString: schedule.CronString,
        timezone: schedule.Timezone,
        errors: {},
        isCustomName: prettyCron.toString(schedule.CronString) != schedule.Name,
        scheduleType: schedule.ScheduleType,
        nOccurrences: schedule.MaxRuns,
        endDate: endDate == null ? Moment().format("MM/DD/YYYY") : endDate,
        hour,
        minute,
        runDate: runDate == null ? Moment().format("MM/DD/YYYY") : runDate,
        sourceId: schedule.SourceId,
        isScheduleTemplate: schedule.IsScheduleTemplate,
        defaultTriggerType: schedule.DefaultTriggerType,
        emails: schedule.Emails,
        RunCount: schedule.RunCount,
        TimeType: schedule.TimeType,
        TimeCount: schedule.TimeCount,
    };

    return currentState;
}

const ScheduleAddEdit: React.FC<Props> = ({
    scheduleId,
    companyId,
    isScheduleTemplate = false,
    isExportModal = false,
    onSaveCallback,
}: Props) => {
    const dispatch = useDispatch();

    const id = useSelector((state: IAppState) => scheduleId || state.schedules.editId);
    const isSaving = useSelector((state: IAppState) => state.schedules.isSaving);
    const schedules = useSelector((state: IAppState) => state.schedules.schedules || []);
    const activationDestinationSchedules = useSelector(
        (state: IAppState) => state.vars.activationDestinationSchedules || []
    );
    const usersTimezoneGuess = useSelector((state: IAppState) => state.vars.usersTimezoneGuess);
    const userTimeZonePref = useSelector((state: IAppState) => state.session.userSettings["time-zone"]);
    const userId = useSelector((state: IAppState) => state.session.userId);
    const enabledFeatures = useSelector((state: IAppState) => state.session.enabledFeatures);
    const flowId = useSelector((state: IAppState) => state.selected.flow);
    const isTemplateOrModal = isScheduleTemplate || isExportModal;

    const scheduleState = isExportModal
        ? activationDestinationSchedules.find(s => s.Id == id)
        : schedules.find(s => s.Id == id);

    const [canEdit, setCanEdit] = useState(true);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const [hasEmailErrors, setHasEmailErrors] = useState(false);
    const [saving, setSaving] = useState(false);
    const [schedule, setSchedule] = useState<ScheduleState>(
        scheduleState
            ? GetCurrentState(scheduleState)
            : {
                  id: "",
                  name: prettyCron.toString("0 3 * * 1"),
                  triggerCount: 0,
                  isActive: true,
                  isConsecutive: false,
                  continueOnError: false,
                  cronString: "0 3 * * 1",
                  timezone: usersTimezoneGuess,
                  errors: {},
                  isCustomName: false,
                  scheduleType: 0,
                  nOccurrences: null,
                  runDate: Moment().format("MM/DD/YYYY"),
                  endDate: Moment().add(1, "y").format("MM/DD/YYYY"),
                  hour: 0,
                  minute: 0,
                  sourceId: 0,
                  isScheduleTemplate,
                  defaultTriggerType: null,
                  emails: null,
                  RunCount: 0,
                  TimeType: null,
                  TimeCount: null,
              }
    );

    const canSave =
        !isSaving &&
        canEdit &&
        !hasEmailErrors &&
        (!isTemplateOrModal || (isTemplateOrModal && schedule.defaultTriggerType));

    useEffect(() => {
        if (scheduleState) {
            // update state for edit schedule
            let state = GetCurrentState(scheduleState);
            dispatch(setScheduleModeAndEditId("addedit", scheduleState.Id, isTemplateOrModal));
            setSchedule(state);

            const canEdit =
                scheduleState.UserID == userId ||
                enabledFeatures.includes("schedules-admin") ||
                scheduleState.IsScheduleTemplate;
            setCanEdit(canEdit);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, scheduleState, isTemplateOrModal, enabledFeatures]);

    const onConfirm = (mode, id) => {
        dispatch(setScheduleModeAndEditId(mode, id, isTemplateOrModal));
        if (hasUnsavedChanges) {
            dispatch(scheduleSetUnsavedChanges(!hasUnsavedChanges));
        }
    };

    const validate = () => {
        const errors: ScheduleError = {};

        if (!schedule.name || schedule.name.trim() == "") {
            errors.name = "Please enter a name.";
        }

        if ((isScheduleTemplate || isExportModal) && !schedule.defaultTriggerType) {
            errors.name = "Please select a trigger type.";
        }

        if (schedule.scheduleType == ScheduleType.TimeFrequency) {
            if (!schedule.TimeType || !schedule.TimeCount || schedule.TimeCount < 1) {
                errors.timeType = "Please enter a valid time frequency.";
            }

            if (schedule.runDate == null) {
                errors.runDate = "Please enter a valid date.";
            }
        }

        setSchedule(schedule => ({ ...schedule, errors }));

        const noErrors = Object.keys(errors).length == 0 && !hasEmailErrors;
        setHasErrors(!noErrors);

        updateUnsavedChanges();

        return noErrors;
    };

    const save = () => {
        setSaving(true);
        if (!validate()) {
            return;
        }
        const {
            id,
            name,
            cronString,
            isConsecutive,
            continueOnError,
            isActive,
            scheduleType,
            runDate,
            endDate,
            nOccurrences,
            hour,
            minute,
            sourceId,
            isScheduleTemplate,
            defaultTriggerType,
            emails,
            TimeType,
            TimeCount,
        } = schedule;
        //Getting the user preference time zone
        let runDt: Moment.Moment | null = null;
        if (runDate != null) {
            runDt = Moment(Moment(runDate).format("MM/DD/YYYY") + ` ` + hour + `:` + minute);
        }

        dispatch(
            requestEditSchedule(
                id,
                name,
                cronString,
                userTimeZonePref,
                scheduleType,
                runDt,
                endDate,
                nOccurrences,
                sourceId,
                isConsecutive,
                continueOnError,
                isActive,
                isScheduleTemplate,
                companyId,
                defaultTriggerType,
                emails,
                flowId,
                TimeType,
                TimeCount,
                onSaveCallback
            )
        );
    };

    const handleCheckbox = event => {
        let updateSchedule = { ...schedule };
        updateSchedule[event.target.value] = !updateSchedule[event.target.value];
        setSchedule(updateSchedule);
        updateUnsavedChanges();
    };

    const handleNameChange = e => {
        let name = e.target.value;

        if (name == "" && schedule.cronString) {
            name = prettyCron.toString(schedule.cronString);
        }

        const isCustomName = prettyCron.toString(schedule.cronString) != name.trim() && name.trim() != "";
        setSchedule(schedule => ({
            ...schedule,
            name,
            isCustomName,
        }));
        updateUnsavedChanges();
    };

    const handleEmailChange = e => {
        const emails = e.target.value;
        setSchedule(schedule => ({ ...schedule, emails }));
        validateEmails(emails);
        updateUnsavedChanges();
    };

    const updateUnsavedChanges = () => {
        const hasUnsavedChanges = !saving;
        setHasUnsavedChanges(!saving);
        dispatch(scheduleSetUnsavedChanges(hasUnsavedChanges));
    };

    const validateEmails = string => {
        if (!string) {
            return setHasEmailErrors(false);
        }

        const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        const result = string.replace(/\s/g, "").split(/,|;/);
        for (let i = 0; i < result.length; i++) {
            if (!regex.test(result[i])) {
                return setHasEmailErrors(true);
            }
        }

        return setHasEmailErrors(false);
    };

    const handleDefaultTriggerTypeChange = (value: string) => {
        const newSchedule = {
            ...schedule,
            defaultTriggerType: value,
        };
        setSchedule(newSchedule);
    };

    const renderTimingOptions = () => {
        const components = {
            [ScheduleType.Recurring.toString()]: ScheduleCronStringEditor,
            [ScheduleType.OneTime.toString()]: OneTime,
            [ScheduleType.NumberOfOccurrences.toString()]: EndAfterNOccurances,
            [ScheduleType.EndDate.toString()]: EndDate,
            [ScheduleType.TableRefresh.toString()]: TableRefresh,
            [ScheduleType.TimeFrequency.toString()]: TimeFrequency,
        };

        const Component = components[schedule.scheduleType.toString()];

        if (!Component) {
            return null;
        }

        return (
            <Component
                schedule={schedule}
                setSchedule={setSchedule}
                updateUnsavedChanges={updateUnsavedChanges}
                isScheduleTemplate={isScheduleTemplate}
                disabled={!canEdit}
            />
        );
    };

    const renderActionOptions = () => {
        const actionOptions: Array<{ value: string; label: string }> = [];
        if (isScheduleTemplate || isExportModal) {
            if (enabledFeatures.includes("flow")) {
                !isScheduleTemplate && actionOptions.push({ value: "FLOW_EXECUTE", label: "Execute a flow" });
                actionOptions.push({ value: "FLOW_DEPLOY", label: "Execute and deploy a flow" });
                enabledFeatures.includes("clone-flow-execute-deploy-schedule") &&
                    actionOptions.push({ value: "FLOW_CLONE_DEPLOY", label: "Clone, Execute and deploy a flow" });
            }
            if (enabledFeatures.includes("flow-tool-dataload") && !isScheduleTemplate) {
                actionOptions.push({ value: "FLOW_DATALOAD", label: "Execute flow and load custom scripts." });
            }
        }

        return actionOptions.map((action, i) => (
            <MenuItem key={i} value={action.value}>
                {action.label}
            </MenuItem>
        ));
    };

    return (
        <Paper style={{ margin: "30px 0px", padding: "15px" }}>
            {!isExportModal && (
                <ScheduleBreadCrumbs editId={schedule.id} mode="addedit" isScheduleTemplate={isScheduleTemplate} />
            )}
            <ScheduleHeader id={schedule.id} hasUnsavedChanges={hasUnsavedChanges} />
            <div className="row">
                <div className="col-sm-6">
                    <div className={"form-group " + (hasErrors && schedule.errors.name && "has-error")}>
                        <TextField
                            type="text"
                            onChange={handleNameChange}
                            error={hasErrors}
                            id="schedulename"
                            label="Schedule Name"
                            className="material-form-control"
                            value={typeof schedule.name == "undefined" ? "" : schedule.name}
                            helperText={(hasErrors && schedule.errors.name) || ""}
                            disabled={!canEdit}
                        />
                    </div>
                    <div className="form-group">
                        <TextField
                            type="text"
                            onChange={handleEmailChange}
                            error={hasEmailErrors}
                            id="scheduleEmails"
                            label="Emails"
                            className="material-form-control"
                            value={schedule.emails || ""}
                            helperText={`${
                                hasEmailErrors ? "Emails are invalid." : ""
                            } Enter emails separated by semicolons that should receive information about this schedule.
                            This is optional - emails added here will be included in the email CC. Leave it blank if you do not want additional emails in CC.`}
                            disabled={!canEdit}
                        />
                    </div>
                    <div className={"form-group " + (hasErrors && schedule.errors.cronString && "has-error")}>
                        <TimingSelector
                            schedule={schedule}
                            setSchedule={setSchedule}
                            updateUnsavedChanges={updateUnsavedChanges}
                            hasErrors={hasErrors}
                            disabled={!canEdit}
                        />
                        <div style={{ margin: "10px" }}>
                            <Grid container spacing={3}>
                                {renderTimingOptions()}
                                {hasErrors && schedule.errors.cronString && (
                                    <span className="help-block">{schedule.errors.cronString}</span>
                                )}
                            </Grid>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="row">
                        <div className="col-sm-6">
                            {schedule.id && !schedule.isScheduleTemplate && !isExportModal ? (
                                <div className="form-group">
                                    <label className="control-label" htmlFor="">
                                        Triggers
                                    </label>
                                    <p>
                                        {wrapButtonWithConfirmModal(
                                            `${schedule.triggerCount}` + " trigger(s)",
                                            hasUnsavedChanges,
                                            () => onConfirm("addedit_triggers", schedule.id),
                                            "fake-link"
                                        )}
                                    </p>
                                </div>
                            ) : (
                                <div className={"form-group " + (hasErrors && schedule.errors.name && "has-error")}>
                                    {isTemplateOrModal ? (
                                        <>
                                            <label className="control-label" htmlFor="">
                                                Trigger Type
                                            </label>
                                            <div>
                                                <FormControl style={{ width: "200px" }}>
                                                    <Select
                                                        labelId="lbl-trigger"
                                                        id="trigger-select"
                                                        onChange={e =>
                                                            handleDefaultTriggerTypeChange(e.target.value as string)
                                                        }
                                                        value={schedule.defaultTriggerType}
                                                        disabled={!canEdit}
                                                    >
                                                        {renderActionOptions()}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <label className="control-label" htmlFor="">
                                                Triggers
                                            </label>
                                            <p style={{ color: "#999", fontStyle: "italic" }}>
                                                No triggers. Add and setup triggers after saving.
                                            </p>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                        {schedule.id && !isTemplateOrModal && (
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <div>
                                        <label className="control-label" htmlFor="">
                                            Options
                                        </label>
                                    </div>
                                    <FormControlLabel
                                        value="isActive"
                                        checked={schedule.isActive}
                                        onClick={handleCheckbox}
                                        control={<Checkbox color="primary" disabled={!canEdit} />}
                                        label={<span style={{ fontSize: "18px" }}>Active</span>}
                                        disabled={!canEdit}
                                    />
                                    <FormControlLabel
                                        value="isConsecutive"
                                        checked={schedule.isConsecutive}
                                        onClick={handleCheckbox}
                                        control={<Checkbox color="primary" disabled={!canEdit} />}
                                        label={<span style={{ fontSize: "18px" }}>Consecutive</span>}
                                        disabled={!canEdit}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {schedule.id && !isScheduleTemplate && !isExportModal && (
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label className="control-label" htmlFor="">
                                        Audit
                                    </label>
                                    <p>
                                        {wrapButtonWithConfirmModal(
                                            "See audit log",
                                            hasUnsavedChanges,
                                            () => onConfirm("audit", schedule.id),
                                            "fake-link"
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label className="control-label" htmlFor="">
                                        Actions
                                    </label>
                                    <p>
                                        {wrapButtonWithConfirmModal(
                                            "See action log",
                                            hasUnsavedChanges,
                                            () => onConfirm("log", schedule.id),
                                            "fake-link"
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 text-right">
                    <ColorButton
                        style={{ margin: "0px 10px", fontSize: "12px" }}
                        backgroundcolor="#0075bc"
                        variant="contained"
                        onClick={save}
                        disabled={!canSave}
                    >
                        Save
                    </ColorButton>
                </div>
            </div>
        </Paper>
    );
};

export default ScheduleAddEdit;
