export const SET_XANDR_RATE_CATEGORIES_LOADING = "SET_XANDR_RATE_CATEGORIES_LOADING";
export const SET_XANDR_RATE_CATEGORIES = "SET_XANDR_RATE_CATEGORIES";

export type XandrRateCategory = {
    data_category_id: number;
    category_price: number;
    category_name: string;
    publisher_name: string;
};

export type SetXandrRateCategoriesLoadingAction = {
    type: typeof SET_XANDR_RATE_CATEGORIES_LOADING;
    isLoading: boolean;
};

export type SetXandrRateCategoriesAction = {
    type: typeof SET_XANDR_RATE_CATEGORIES;
    categories: Array<XandrRateCategory>;
};

export type XandrRateCategoryActions = SetXandrRateCategoriesLoadingAction | SetXandrRateCategoriesAction;

export type IXandrRateCategoryStore = {
    categories: Array<XandrRateCategory>;
    isLoading: boolean;
};
