import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import FeatureGate from "../FeatureGate";

///////////////////////////////////////////////////////////////////////////
///////// !!! PROPS INTERFACE MUST BE SAME AS LOCALNAVSOFTLINK !!! ////////
///////////////////////////////////////////////////////////////////////////
// Fix me?
// This is because we're transitioning between SPA and non-SPA
// and SoftLink needs to call HardLink if we're not on the SPA

type Props = {
    id: string;
    destination: string;
    isActive?: (match, location) => boolean;
    isSubHeader?: boolean;
    openTab?: boolean;
    isCollapsed?: boolean;
    iconClass?: string;
    text: string;
    collapsedIcon?: string;
    feature?: string | Array<string>;
    featureArrayMode?: "AND" | "OR";
    removeLi?: boolean;
};

const LocalNavHardLink: React.FC<Props> = ({
    destination,
    text,
    feature,
    featureArrayMode,
    iconClass,
    isCollapsed,
    collapsedIcon,
    removeLi,
    id,
}: Props) => {
    let activeClass = "";
    /*  state.vars.page doesn't contain the full path and I don't want to
        step on it and possibly break existing code that uses it */
    const pagePath = window.location.pathname.toLowerCase();
    if (pagePath == destination.toLowerCase()) {
        activeClass = "active";
    }

    const link = !isCollapsed ? (
        <a
            href={destination}
            className={`${activeClass} ${id}`}
            id={id}
            style={{ color: "#575755", textDecoration: "none" }}
        >
            {iconClass ? <i className={iconClass} /> : null}
            {text}
        </a>
    ) : (
        <Tooltip title={text} style={{ fontSize: 12 }} placement="right">
            <a href={destination} className={`${activeClass} ${id}`} id={id}>
                <img className={"collapsed-icon"} src={collapsedIcon} />
            </a>
        </Tooltip>
    );

    return (
        <FeatureGate feature={feature} featureArrayMode={featureArrayMode}>
            {removeLi ? link : <li className={activeClass}>{link}</li>}
        </FeatureGate>
    );
};

export default LocalNavHardLink;
