import React from "react";
import LayoutListEntry from "./LayoutListEntry";
import TreeItem from "../tree/TreeItem";
import LayoutListFolder from "./LayoutListFolder";

import naturalCompare from "natural-compare";
import { connect } from "react-redux";
import { createDeepEqualSelector } from "../../helpers/typedHelpers";
import { layoutMatchesSearchString } from "../../reducers/exportlayouts";
import { getTreeSearch } from "../../reducers/search";

import type { Folder } from "../../types/types";

type Props = {
    // Passed In
    folderType: string,
    folder: Folder, // The current folder to draw
    treeViewOnUpdate: () => void, // Used for odd/even row coloring
    showAllDuringSearch?: boolean, // Used for folder search, searched folders are told to show all contents, even if they don't match
    // Redux
    myLayoutIDs: Array<number>,
    enabledFeatures: Array<string>,
};

class FolderLayoutList extends React.Component<Props> {
    renderLayout = (layoutId: number) => <LayoutListEntry layoutId={layoutId} key={layoutId} />;

    renderFolderElements() {
        const { folder, folderType, treeViewOnUpdate } = this.props;
        const showAllDuringSearch = this.props.showAllDuringSearch || false;
        const filteredFolders = folder.children;

        return filteredFolders.map((node: Folder) => (
            <LayoutListFolder
                key={node.id}
                folder={node}
                folderType={folderType}
                treeViewOnUpdate={treeViewOnUpdate}
                showAllDuringSearch={showAllDuringSearch}
            />
        ));
    }
    render() {
        const { myLayoutIDs } = this.props;
        const layoutElements = myLayoutIDs.map(this.renderLayout);
        const folderElements = this.renderFolderElements();

        let emptyElement = null;
        if (!folderElements.length && !layoutElements.length) {
            emptyElement = <TreeItem>empty</TreeItem>;
        }

        return (
            <div>
                {folderElements}
                {layoutElements}
                {emptyElement}
            </div>
        );
    }
}

const makeGetMyLayoutIDs = () =>
    createDeepEqualSelector(
        state => state.layouts,
        state => getTreeSearch(state),
        (state, props) => props.showAllDuringSearch,
        (state, props) => props.folder.id,
        (layouts, treeSearch, showAllDuringSearch, folderId) => {
            const layoutIds = Object.keys(layouts);
            let myLayoutIDs = layoutIds.filter(layoutId => layouts[layoutId].LayoutFolderId == folderId);
            if (treeSearch && !showAllDuringSearch) {
                myLayoutIDs = myLayoutIDs.filter(layoutId => layoutMatchesSearchString(layouts[layoutId], treeSearch));
            }

            myLayoutIDs.sort((a, b) => {
                const nameA = layouts[a].LayoutName.toUpperCase();
                const nameB = layouts[b].LayoutName.toUpperCase();
                return naturalCompare(nameA, nameB);
            });

            return myLayoutIDs;
        }
    );

import type { MapStateToProps } from "react-redux";
const mapStateToProps: MapStateToProps<*, *, *> = () => {
    const getMyLayoutIDs = makeGetMyLayoutIDs();
    return (state, ownProps) => ({
        myLayoutIDs: getMyLayoutIDs(state, ownProps),
        enabledFeatures: state.session.enabledFeatures || [],
    });
};
const mapDispatchToProps = null;
const FolderLayoutListC = connect(mapStateToProps, mapDispatchToProps)(FolderLayoutList);

export default FolderLayoutListC;
