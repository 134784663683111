import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { getModalTreeSearch, getTreeSearch, isConceptualSearchTurnedOn as getCSTurnOn } from "../../reducers/search";
import {
    toggleConceptual,
    toggleModalConceptual,
    treeSearchUserTyped,
    clearTreeSearch,
    clearModalTreeSearch,
} from "../../actions/searchActions";
import { searchColumns, requestScriptObjects } from "../../actions/fieldTreeActions";

import CircularLoading from "../loading/CircularLoading";
import Tooltip from "@material-ui/core/Tooltip";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

const TreeSearch = ({ isActivityReports, isModal, isFieldExplorer }) => {
    const dispatch = useDispatch();

    const [searchBoxValue, setSearchBoxValue] = useState("");
    const [typingTimer, setTypingTimer] = useState(null);

    const initialTreeSearch = useSelector(state => (isModal ? state.search.modalTreeSearch : state.search.treeSearch)); // Just using this for when rehydrating / first load
    const isConceptualSearchTurnedOn = useSelector(state => getCSTurnOn(state, isModal));
    const conceptualPreference = useSelector(state => state.session.userSettings["conceptual-search-default"]);
    const treeSearch = useSelector(state => (isModal ? getModalTreeSearch(state) : getTreeSearch(state)));
    const willClearTree = useSelector(state =>
        isModal ? state.search.modalWillClearTree : state.search.willClearTree
    );
    const wordVecIsSearching = useSelector(state =>
        isModal ? state.search.modalWordVecIsSearching : state.search.wordVecIsSearching
    );
    const wordVecHasError = useSelector(state => state.search.wordVecHasError);
    const pathname = useSelector(state => state.router && state.router.location && state.router.location.pathname);
    useEffect(() => {
        initialTreeSearch && setSearchBoxValue(initialTreeSearch);
    }, [initialTreeSearch]);

    useEffect(() => {
        if (conceptualPreference) {
            const preferenceBool = conceptualPreference == "Y";
            if (preferenceBool != isConceptualSearchTurnedOn) {
                dispatch(isModal ? toggleModalConceptual() : toggleConceptual());
            }
        }
    }, [dispatch, conceptualPreference]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //To remove the value from the tree search and avoid getting wrong highlithed results #6529
        if (isModal) {
            dispatch(treeSearchUserTyped("", false));
            dispatch(clearTreeSearch());
        }
    }, [isModal]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (willClearTree && treeSearch) {
            cancel();
        }
    }, [willClearTree]); // eslint-disable-line react-hooks/exhaustive-deps

    const onChange = e => {
        const value = e.target.value;
        setSearchBoxValue(value);
        if (isFieldExplorer) {
            clearTimeout(typingTimer);
            setTypingTimer(
                setTimeout(() => {
                    if (value.length > 2) {
                        dispatch(searchColumns(value)).then(() => {
                            dispatch(treeSearchUserTyped(value, isModal));
                        });
                    } else if (value.length == 0) {
                        dispatch(requestScriptObjects());
                    }
                }, 1000)
            );
        } else {
            dispatch(treeSearchUserTyped(value, isModal));
        }
    };

    const cancel = () => {
        setSearchBoxValue("");
        dispatch(treeSearchUserTyped("", isModal));

        if (isFieldExplorer) {
            dispatch(requestScriptObjects()).then(() => {
                dispatch(isModal ? clearModalTreeSearch() : clearTreeSearch());
            });
        } else {
            dispatch(isModal ? clearModalTreeSearch() : clearTreeSearch());
        }
    };

    const clickConceptualIcon = () => dispatch(isModal ? toggleModalConceptual() : toggleConceptual());

    const getConceptualToolTip = () => {
        // Not perfect, especially when we add double word support
        const conceptualFoundMatches = Array.isArray(treeSearch) && treeSearch.length > 1;
        let conceptualSearchTooltip;
        if (isConceptualSearchTurnedOn && !searchBoxValue) {
            conceptualSearchTooltip = "Conceptual search active.";
        } else if (isConceptualSearchTurnedOn && wordVecIsSearching) {
            conceptualSearchTooltip = "Conceptual search active. Searching...";
        } else if (isConceptualSearchTurnedOn && wordVecHasError) {
            conceptualSearchTooltip = "Could not contact conceptual search service.";
        } else if (isConceptualSearchTurnedOn && !conceptualFoundMatches) {
            conceptualSearchTooltip = `Conceptual search active. No conceptual match terms found for "${searchBoxValue}"`;
        } else if (isConceptualSearchTurnedOn && conceptualFoundMatches) {
            conceptualSearchTooltip = `Conceptual search active. (${
                treeSearch.length - 1
            }) conceptual match terms found for "${searchBoxValue}".`;
            /*
            // will display the various search matches
            searchValues = treeSearch.map((word, i) => (
                <span key={word + "outer"}>
                    <span key={word} className="label label-default" style={{ fontWeight: 400 }}>
                        {word.replace(/"$/, "").replace(/^"/, "")}
                    </span>{" "}
                    {i % 5 == 0 ? <br /> : null}
                </span>
            ));

            conceptualSearchTooltip = (
                <div>
                    <span>
                        Conceptual search active. Searched for words related to:
                        <br />
                        {searchValues}
                    </span>
                </div>
            );
            */
        } else {
            conceptualSearchTooltip = "Conceptual search disabled.";
        }

        return conceptualSearchTooltip;
    };

    const isInFlows = pathname && pathname.includes("flows");
    const isFlowTree = pathname && pathname.includes("design");
    const isInSchedules = pathname && pathname.includes("schedules");

    return (
        <div>
            <InputBase
                key="tree-search"
                style={{ padding: 0 }}
                className={
                    isInFlows ? "form-control tree-search-input material-ui-input" : "form-control tree-search-input"
                }
                placeholder={
                    isFlowTree ? "Search..." : isInSchedules ? "Search by Keyword or FlowId" : "Search by Keyword"
                }
                fullWidth
                onChange={e => onChange(e)}
                margin="none"
                value={searchBoxValue}
                startAdornment={
                    !isActivityReports ? (
                        <InputAdornment position="end">
                            <Tooltip title={getConceptualToolTip()}>
                                <IconButton
                                    onClick={() => clickConceptualIcon()}
                                    className={
                                        isConceptualSearchTurnedOn
                                            ? "conceptual-icon-button active"
                                            : "conceptual-icon-button"
                                    }
                                >
                                    <i className="conceptual-icon" />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    ) : (
                        <div style={{ width: "5px" }} /> //padding in flow tree and activity reports
                    )
                }
                endAdornment={
                    wordVecIsSearching ? (
                        <CircularLoading size="x-small" color="#575755" />
                    ) : searchBoxValue ? (
                        <InputAdornment position="end">
                            <IconButton size="small" onClick={() => cancel()}>
                                <i className="material-icons">clear</i>
                            </IconButton>
                        </InputAdornment>
                    ) : null
                }
            />
        </div>
    );
};

TreeSearch.propTypes = {
    // all injected by redux
    initialTreeSearch: PropTypes.string,
    clearTreeSearch: PropTypes.func,
    clearModalTreeSearch: PropTypes.func,
    treeSearchUserTyped: PropTypes.func,
    toggleConceptual: PropTypes.func,
    toggleModalConceptual: PropTypes.func,
    treeSearch: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    willClearTree: PropTypes.bool,
    isConceptualSearchTurnedOn: PropTypes.bool,
    wordVecIsSearching: PropTypes.bool,
    wordVecHasError: PropTypes.bool,
    conceptualPreference: PropTypes.string,
    pathname: PropTypes.string,
    //passed in
    isActivityReports: PropTypes.bool,
    isModal: PropTypes.bool,
    isFieldExplorer: PropTypes.bool,
};

export default TreeSearch;
