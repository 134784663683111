import React, { useEffect } from "react";
import FieldList from "./FieldList";
import FieldContextMenu from "./FieldContextMenu";
import FieldEditContextMenu from "./FieldEditContextMenu";
import withUpdateStripes from "../misc/withUpdateStripes";

type Props = {
    TreeType: number;
    showHiddenFields?: boolean;
    isPIIOnly?: boolean;
    editable?: boolean;
    ctrlShift: boolean;
    isModal?: boolean;
    showTabs?: boolean;
    // Injected by HOC
    updateStripes?: () => void;
};

const FieldTree: React.FC<Props> = ({
    TreeType,
    showHiddenFields,
    isPIIOnly,
    editable,
    updateStripes,
    ctrlShift,
    showTabs,
    isModal,
}: Props) => {
    useEffect(() => {
        updateStripes && updateStripes();
    }, [updateStripes]);

    const contextMenu = editable ? <FieldEditContextMenu /> : <FieldContextMenu />;

    return (
        <div className="field-tree">
            <FieldList
                key="#"
                fieldKey="#"
                treeViewOnUpdate={updateStripes!}
                TreeType={TreeType}
                isPIIOnly={isPIIOnly || false}
                editable={Boolean(editable)}
                showHiddenFields={Boolean(showHiddenFields)}
                ctrlShift={ctrlShift}
                showAllDuringSearch={false}
                isModal={Boolean(isModal)}
                showTabs={showTabs || false}
                tabType={0}
            />
            {contextMenu}
        </div>
    );
};

export default withUpdateStripes(FieldTree);
