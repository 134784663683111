import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

class GlobalAjaxLoadingIndicator extends React.Component {
    static propTypes = {
        // Redux
        ajaxOutstandingRequestCount: PropTypes.number,
    };

    state = {
        showing: false,
    };

    componentDidUpdate(prevProps /*, prevState */) {
        const thisProps = this.props;

        // Outstanding requests went to 0, aka, turned off.
        if (prevProps["ajaxOutstandingRequestCount"] && !thisProps["ajaxOutstandingRequestCount"]) {
            if (typeof this.showTimer !== "undefined") {
                clearTimeout(this.showTimer);
            }
            this.setState({ showing: false });
        }

        // Outstanding requests went to 1 or above, aka, turned on.
        if (!prevProps["ajaxOutstandingRequestCount"] && thisProps["ajaxOutstandingRequestCount"]) {
            if (typeof this.showTimer !== "undefined") {
                clearTimeout(this.showTimer);
            }

            this.showTimer = setTimeout(() => {
                this.setState({ showing: true });
            }, 650);
        }

        // All of the above makes it so requests faster than 650ms don't show an animation.
    }

    render() {
        if (!this.state.showing) {
            return null;
        }
        return (
            <div style={{ position: "fixed", bottom: 10, right: 10, zIndex: 5000 }}>
                <div className="loading-spin" />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ajaxOutstandingRequestCount: state.vars.ajaxOutstandingRequestCount,
});
const mapDispatchToProps = null;
const GlobalAjaxLoadingIndicatorC = connect(mapStateToProps, mapDispatchToProps)(GlobalAjaxLoadingIndicator);

export default GlobalAjaxLoadingIndicatorC;
