import React from "react";
import { Provider } from "react-redux";
import store from "../../store";
import FieldTooltipContents from "./FieldTooltipContents";
import { IField } from "../../types/stores/fieldTypes";

const makeTooltipForField = (field: IField, treeSearch: string | Array<string> = ""): JSX.Element => (
    <>
        <Provider store={store}>
            <FieldTooltipContents field={field} treeSearch={treeSearch} />
        </Provider>
    </>
);

export default makeTooltipForField;
