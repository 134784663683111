import React from "react";

import PropTypes from "prop-types";

import * as audienceFolderActions from "../../actions/audienceFolderActions";
import * as fieldTreeActions from "../../actions/fieldTreeActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { FormControl, FilledInput, InputAdornment, IconButton } from "@material-ui/core";

class FieldFolderRename extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            folderName: this.props.folderName,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        this.nameElement.focus();
        this.nameElement.select();
    }

    onKeyDown(e) {
        if (e.nativeEvent.code == "Escape") {
            this.cancel();
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.save();
    }

    save() {
        const { requestRenameFolder, folderId, renamingAudienceFolderType, isFieldFolder, requestRenameFieldFolder } =
            this.props;
        if (isFieldFolder) {
            requestRenameFieldFolder(folderId, this.state.folderName);
        } else {
            let loadFlowFolders = false;
            if (renamingAudienceFolderType == "W") {
                loadFlowFolders = true;
            }
            requestRenameFolder(renamingAudienceFolderType, folderId, this.state.folderName, loadFlowFolders);
        }
    }

    cancel() {
        const { stopRenamingFolder, renamingAudienceFolderType, folderId, isFieldFolder, stopRenamingFieldFolder } =
            this.props;
        if (isFieldFolder) {
            stopRenamingFieldFolder(folderId);
        } else {
            stopRenamingFolder(renamingAudienceFolderType);
        }
    }

    render() {
        const { folderName } = this.state;
        const { folderId } = this.props;
        const cancelButton = (
            <IconButton style={{ padding: "5px" }} aria-label="Cancel" onClick={this.cancel}>
                <i className="material-icons" style={{ height: "15px", width: "15px", fontSize: "15px" }}>
                    cancel
                </i>
            </IconButton>
        );
        const saveButton = (
            <IconButton style={{ padding: "5px" }} color="primary" aria-label="Save" onClick={this.save}>
                <i className="material-icons" style={{ height: "15px", width: "15px", fontSize: "15px" }}>
                    save
                </i>
            </IconButton>
        );
        return (
            <form onSubmit={this.handleSubmit}>
                <FormControl style={{ width: "100%" }} variant="filled">
                    <FilledInput
                        id={"folder-rename-" + folderId}
                        value={folderName}
                        onChange={e => this.setState({ folderName: e.target.value })}
                        onKeyDown={e => this.onKeyDown(e)}
                        inputRef={input => (this.nameElement = input)}
                        endAdornment={
                            <InputAdornment position="end">
                                {cancelButton}
                                {saveButton}
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </form>
        );
    }
}

FieldFolderRename.propTypes = {
    // Passed in
    folderId: PropTypes.number.isRequired,
    folderName: PropTypes.string.isRequired,
    folderType: PropTypes.string,
    isFieldFolder: PropTypes.bool,
    // Redux
    renamingAudienceFolderType: PropTypes.string,
    // Injected by Redux (Action creators)
    stopRenamingFolder: PropTypes.func.isRequired,
    requestRenameFolder: PropTypes.func.isRequired,
    requestRenameFieldFolder: PropTypes.func.isRequired,
    stopRenamingFieldFolder: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    renamingAudienceFolderType: state.vars.renamingAudienceFolderType,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(Object.assign({}, audienceFolderActions, fieldTreeActions), dispatch);

const FieldFolderRenameConnected = connect(mapStateToProps, mapDispatchToProps)(FieldFolderRename);
export default FieldFolderRenameConnected;
