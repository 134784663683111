import React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as exportLayoutActions2 from "../../actions/exportLayoutActions2";
import * as audienceFolderActions from "../../actions/audienceFolderActions";

import FolderLayoutList from "./FolderLayoutList";
import LayoutContextMenu from "./LayoutContextMenu";
import LayoutFolderContextMenu from "./LayoutFolderContextMenu";
import type { Folder } from "../../types/types";
import withUpdateStripes from "../misc/withUpdateStripes";
import { sortTopLevelFolders } from "../../helpers/folder";

type Props = {
    // Passed In
    folderType: string,
    // Redux
    layoutFolders: Array<Folder>,
    userId: number,
    companyId: number,
    userNameForId: Object,
    // Injected by Redux (action creator)
    requestLoadFolders: string => void,
    // From withUpdateStripes HOC
    updateStripes: () => void,
};

class LayoutTreeFolder extends React.Component<Props> {
    static defaultProps = {
        folderType: "L",
    };

    componentDidUpdate() {
        this.props.updateStripes();
    }

    componentDidMount() {
        const { folderType } = this.props;
        this.props.updateStripes();
        this.props.requestLoadFolders(folderType);
    }

    render() {
        const { folderType, layoutFolders, updateStripes, userNameForId, userId, companyId } = this.props;
        const folders = sortTopLevelFolders(layoutFolders, userNameForId);
        const topLevelFolder: Folder = {
            folderType,
            children: folders,
            name: "",
            id: 0,
            order: 1,
            userId,
            companyId,
            isCompanyShare: false,
            level: -1,
            isInternalShare: false,
            isHistorical: false,
        };

        return (
            <div className="react_layouts">
                <FolderLayoutList
                    folderType={folderType}
                    folder={topLevelFolder}
                    treeViewOnUpdate={updateStripes}
                    showAllDuringSearch={false}
                    schedulesMode={false}
                />
                <LayoutContextMenu />
                <LayoutFolderContextMenu />
            </div>
        );
    }
}

const LayoutTreeFolderWithUpdateStripes = withUpdateStripes(LayoutTreeFolder);

import { makeFilterUsersWithNoLayoutFolders } from "../../reducers/_folderGeneral";

const mapStateToProps = () => {
    const filterUsersWithNoFolder = makeFilterUsersWithNoLayoutFolders();
    return (state, ownProps) => ({
        userId: state.session.userId,
        companyId: state.session.companyId,
        userNameForId: state.varsPersist.userNameForId,
        layoutFolders: filterUsersWithNoFolder(state, ownProps),
    });
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(Object.assign({}, exportLayoutActions2, audienceFolderActions), dispatch);
const LayoutTreeFolderC = connect(mapStateToProps, mapDispatchToProps)(LayoutTreeFolderWithUpdateStripes);

LayoutTreeFolderC.defaultProps = {
    folderType: "L",
};

export default LayoutTreeFolderC;
