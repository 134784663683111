import React from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/styles";
import MaterialTooltip from "@material-ui/core/Tooltip";
import CircularLoading from "../loading/CircularLoading";
import { FlowBaseTypes } from "../../helpers/constants";
import { IAppState } from "../../types/stores";

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        color: "#FFF",
        fontSize: 14,
    },
}))(MaterialTooltip);

type Props = {
    id: number;
    className: string;
    showMultiSelectIcon?: boolean;
};

const FlowIcon: React.FC<Props> = ({ id, className, showMultiSelectIcon }: Props) => {
    const flow = useSelector((state: IAppState) => state.flows.byId[id]);
    const selectedFlowIds = useSelector((state: IAppState) => state.flowMultiSelection.selectedFlowIds);

    const isUnlocked = !flow || !flow.IsLocked;

    const renderLock = () => {
        if (isUnlocked) {
            return null;
        }

        return (
            <HtmlTooltip
                title={
                    <span>
                        Flow is locked and may not be modified.
                        <br />
                        Use <strong>Save As</strong> to make a new copy of this flow.
                    </span>
                }
            >
                <i className="material-icons flow-header-icon">lock</i>
            </HtmlTooltip>
        );
    };

    const renderFlowType = () => {
        let flowTypeText = "";

        if (!flow) {
            return null;
        }

        switch (flow.FlowBaseType) {
            case FlowBaseTypes.Undecided:
                flowTypeText = "Simple Flow";
                break;
            case FlowBaseTypes.Model:
                flowTypeText = "Simple Flow - Model";
                break;
            case FlowBaseTypes.Campaign:
                flowTypeText = "Simple Flow - Campaign";
                break;
        }

        if ([FlowBaseTypes.Undecided, FlowBaseTypes.Model, FlowBaseTypes.Campaign].includes(flow.FlowBaseType)) {
            return (
                <HtmlTooltip title={flowTypeText}>
                    <i
                        className="flow-header-icon simple-flow-icon"
                        style={!isUnlocked ? { top: "4px", marginLeft: "5px" } : undefined}
                    ></i>
                </HtmlTooltip>
            );
        }
    };

    const renderCalculating = () => (
        <span className={className}>
            <CircularLoading
                size={className == "tree-icon" ? "x-small" : ""}
                saved={false}
                color={className == "tree-icon" ? "#575755" : "#FFF"}
            />
        </span>
    );

    const renderMultiSelection = () => {
        if (!showMultiSelectIcon || !flow || selectedFlowIds.length <= 0) {
            return null;
        }
        const iconClass = selectedFlowIds.includes(flow.FlowId)
            ? "glyphicon glyphicon-check"
            : "glyphicon glyphicon-unchecked";

        return <i className={"glyphicon m-r-5 " + iconClass} />;
    };

    return flow ? (
        <>
            {renderLock()}
            {flow.flowItemsRunning > 0 && renderCalculating()}
            {renderMultiSelection()}
            {renderFlowType()}
        </>
    ) : null;
};

export default FlowIcon;
