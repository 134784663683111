import React from "react";
import { SortDirection } from "../../types/stores/fieldTypes";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { InputLabel } from "@material-ui/core";

type Props = {
    value: SortDirection;
    className?: string;
    onChange: (value: SortDirection) => void;
    disabled?: boolean;
    label?: string;
};

const DirectionPicker: React.FC<Props> = ({ value, onChange, disabled, label }: Props) => (
    <FormControl>
        <InputLabel>{label || "Sort Direction"}</InputLabel>
        <Select
            style={{
                fontSize: 16,
                marginLeft: "10px",
                paddingTop: "10px",
                width: "300px",
            }}
            onChange={e => onChange(e.target.value as SortDirection)}
            value={value}
            disabled={disabled}
        >
            <MenuItem value="asc">Ascending</MenuItem>
            <MenuItem value="desc">Descending</MenuItem>
        </Select>
    </FormControl>
);

export default DirectionPicker;
