import React from "react";
import ThingListFolder from "../tree-general/ThingListFolder";

import { connect } from "react-redux";
import { createSelector } from "reselect";

import { getTreeSearch } from "../../reducers/search";
import { flowFolderIdContainsSearch } from "../../reducers/flows";
import { folderReducerKeyForType } from "../../helpers/folder";
import { allowedToDeleteFolder } from "../../reducers/_folderGeneral";

//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!1
// This needs reselectors for folderContainsSearch and
// renaming and has no children.  The imports above, or flow versions of them,
// will be needed.
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!1

import type { Folder } from "../../types/types";

type Props = {
    // Flow
    folderType: string,
    folder: Folder, // The current folder to draw
    treeViewOnUpdate: () => void, // Used for odd/even row coloring
    showAllDuringSearch?: boolean, // Used for folder search, searched folders are told to show all contents, even if they don't match
    defaultCollapsed: boolean,
    schedulesMode: Boolean,
    // Redux
    folderContainsSearch: boolean, // Do any children of this folder match the current treeSearch?
};

const FlowListFolder = (props: Props) => (
    <ThingListFolder listComponent="FlowList" renamingAndHasNoChildren={false} {...props} />
);

const makeGetFolderContainsSearch = () =>
    createSelector(
        state => state.flows,
        (state, props) => {
            const folderReducerKey = folderReducerKeyForType(props.folderType);
            return state.folders[folderReducerKey];
        },
        (state, props) => props.folder.id,
        state => getTreeSearch(state),
        (flow2state, folders, folderId, treeSearch) => {
            if (treeSearch == null || treeSearch == "") {
                return false;
            }
            return flowFolderIdContainsSearch(flow2state, folders, folderId, treeSearch);
        }
    );

const makeGetRenamingAndHasNoChildren = () =>
    createSelector(
        state => state.flows,
        (state, props) => state.folders[folderReducerKeyForType(props.folderType)],
        (_, props) => props.folder.id,
        state => state.vars.renamingAudienceFolderId,
        state => state.session.userId,
        (
            flows: Object,
            allFolders: Array<Folder>,
            folderId: number,
            renamingAudienceFolderId: number,
            userId: number
        ): boolean => {
            // Don't bother computing if not renaming - optimization to avoid recursive search on lots of updates
            if (renamingAudienceFolderId != folderId) {
                return false;
            }
            return allowedToDeleteFolder({ flows, audienceFolders: allFolders }, folderId, userId);
        }
    );

import type { MapStateToProps } from "react-redux";
const mapStateToProps: MapStateToProps<*, *, *> = () => {
    const getFolderContainsSearch = makeGetFolderContainsSearch();
    const getRenamingAndHasNoChildren = makeGetRenamingAndHasNoChildren();
    return (state, ownProps) => ({
        folderContainsSearch: getFolderContainsSearch(state, ownProps),
        renamingAndHasNoChildren: getRenamingAndHasNoChildren(state, ownProps),
    });
};
const mapDispatchToProps = null;
const FlowListFolderC = connect(mapStateToProps, mapDispatchToProps)(FlowListFolder);

export default FlowListFolderC;
