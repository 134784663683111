import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import type { FlowItemClientVariableD, FlowErrorsByItemId } from "../types/flowTypes";
const myGenericReducer = makeReducerFor("FLOW_ITEM_CLIENT_VARIABLE", "FlowItemClientVariableId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowItemClientVariablesArray = createSelector(
    state => state.flowItemClientVariables.byId,
    (flowItemClientVariablesById: {| [number]: FlowItemClientVariableD |}): Array<FlowItemClientVariableD> => {
        const r: Array<FlowItemClientVariableD> = Object.values(flowItemClientVariablesById);
        return r;
    }
);

export type FlowItemClientVariablesByItemId = {
    [number]: FlowItemClientVariableD,
};

export const getFlowItemClientVariablesByFlowItemId = createSelector(
    state => getFlowItemClientVariablesArray(state),
    (flowItemClientVariables: Array<FlowItemClientVariableD>): FlowItemClientVariablesByItemId =>
        flowItemClientVariables.reduce((acc, row) => {
            acc[row.FlowItemId] = row;
            return acc;
        }, {})
);

export const getFlowItemClientVariablesForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemClientVariablesArray(state),
    (selectedFlow: number, flowItemClientVariables: Array<FlowItemClientVariableD>): Array<FlowItemClientVariableD> =>
        flowItemClientVariables.filter(x => x.FlowId == selectedFlow)
);

import { getFlowRelationsForSelectedFlow, getFlowRelationsForAllFlows } from "./flowRelations";

const itemClientVariablesToErrorsById = (
    flowItemClientVariables: Array<FlowItemClientVariableD>
): FlowErrorsByItemId => {
    const errorsById = {};
    for (const flowItemClientVariable of flowItemClientVariables) {
        errorsById[flowItemClientVariable.FlowItemId] = validateFlowItemClientVariable(flowItemClientVariable);
    }
    return errorsById;
};

export const getItemClientVariableErrorsForSelectedFlow = createSelector(
    state => getFlowItemClientVariablesForSelectedFlow(state),
    state => getFlowRelationsForSelectedFlow(state),
    itemClientVariablesToErrorsById
);

export const getItemClientVariableErrorsForAllFlows = createSelector(
    state => getFlowItemClientVariablesArray(state),
    state => getFlowRelationsForAllFlows(state),
    itemClientVariablesToErrorsById
);

//import { measureFieldsStringToArray } from "../helpers/crosstabHelper";
//////////////////// HELPERS //////////////////////////////
export const validateFlowItemClientVariable = (flowItemClientVariable: FlowItemClientVariableD): Array<string> => {
    const errors = [];

    if (flowItemClientVariable.FlowId == null || flowItemClientVariable.FlowId == 0) {
        errors.push("Offer merge value must have a linked flow.");
    } else if (flowItemClientVariable.FlowItemId == null || flowItemClientVariable.FlowItemId == 0) {
        errors.push("Offer merge value must have a linked flow item.");
    } else if (flowItemClientVariable.VariableId == null || flowItemClientVariable.VariableId == 0) {
        errors.push("Offer merge value must have a linked offer code.");
    }

    return errors;
};
