import { createSelector } from "reselect";
import makeReducerFor from "./_genericDbReducer";
import subItemReducer from "./_genericFlowSubItemReducer";
import { getFlowItemsForSelectedFlow, getFlowItemsArray } from "./flowItems";
import type { FlowMultiExport, FlowItem } from "../types/flowTypes";
import { IFlowMultiExportStore } from "../types/stores/flowMultiExports";
import { IAppState } from "../types/stores";
import { FlowErrorsByItemId, FlowExport, FlowRelation } from "../types/stores/flowTypes";
import {
    getFlowExportsForSelectedFlow,
    getFlowExportsArray,
    getExportErrorsForSelectedFlow,
    getExportErrorsForAllFlows,
} from "./flowExports";
import { getFlowRelationsForSelectedFlow, getFlowRelationsForAllFlows } from "./flowRelations";

const myGenericReducer = makeReducerFor("FLOW_MULTI_EXPORT", "FlowMultiExportId");

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const myReducer = (state = {}, action: any): IFlowMultiExportStore =>
    subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export type FlowMultiExportByItemId = {
    [key: number]: FlowMultiExport;
};

export const getFlowMultiExportArray = createSelector(
    (state: IAppState) => state.flowMultiExports.byId,
    (flowMultiExportById: FlowMultiExportByItemId): Array<FlowMultiExport> => {
        const r: Array<FlowMultiExport> = Object.values(flowMultiExportById);
        return r;
    }
);

export const getFlowMultiExportsByFlowItemId = createSelector(
    (state: IAppState) => getFlowMultiExportArray(state),
    (flowMultiExports: Array<FlowMultiExport>): FlowMultiExportByItemId =>
        flowMultiExports.reduce((acc, row) => {
            acc[row.FlowItemId] = row;
            return acc;
        }, {})
);

export const getFlowMultiExportForSelectedFlow = createSelector(
    (state: IAppState) => getFlowItemsForSelectedFlow(state),
    (state: IAppState) => getFlowMultiExportsByFlowItemId(state),
    (flowItems: Array<FlowItem>, flowMultiExportsByItemId: FlowMultiExportByItemId): Array<FlowMultiExport> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        const result: Array<FlowMultiExport> = [];
        for (const itemId of itemIds) {
            const multiExport = flowMultiExportsByItemId[itemId];
            if (multiExport != null) {
                result.push(multiExport);
            }
        }
        return result;
    }
);

const reportsToErrorsById = (
    flowMultiExports: Array<FlowMultiExport>,
    flowRelations: Array<FlowRelation>,
    flowExports: Array<FlowExport>,
    exportErrorsById: FlowErrorsByItemId,
    flowItems: Array<FlowItem>
): FlowErrorsByItemId => {
    const errorsById = {};

    for (const multiExport of flowMultiExports) {
        errorsById[multiExport.FlowItemId] = validateMultiExport(
            multiExport,
            flowRelations,
            flowExports,
            exportErrorsById,
            flowItems
        );
    }

    return errorsById;
};

export const getMultiExportErrorsForSelectedFlow = createSelector(
    (state: IAppState) => getFlowMultiExportForSelectedFlow(state),
    (state: IAppState) => getFlowRelationsForSelectedFlow(state),
    (state: IAppState) => getFlowExportsForSelectedFlow(state),
    (state: IAppState) => getExportErrorsForSelectedFlow(state),
    (state: IAppState) => getFlowItemsForSelectedFlow(state),
    reportsToErrorsById
);

export const getMultiExportErrorsForAllFlows = createSelector(
    (state: IAppState) => getFlowMultiExportArray(state),
    (state: IAppState) => getFlowRelationsForAllFlows(state),
    (state: IAppState) => getFlowExportsArray(state),
    (state: IAppState) => getExportErrorsForAllFlows(state),
    (state: IAppState) => getFlowItemsArray(state),
    reportsToErrorsById
);

const validateMultiExport = (
    flowMultiExports: FlowMultiExport,
    flowRelations: Array<FlowRelation>,
    flowExports: Array<FlowExport>,
    exportErrorsById: FlowErrorsByItemId,
    flowItems: Array<FlowItem>
) => {
    const errors: Array<string> = [];

    const childRelations = flowRelations.filter(
        x => x.ParentFlowItemId == flowMultiExports.FlowItemId && x.ChildFlowItemId != 0
    );

    if (!childRelations || childRelations.length <= 0) {
        errors.push("Please select at least one destination.");
    }

    for (const relation of childRelations) {
        if (!flowExports.find(x => x.FlowItemId == relation.ChildFlowItemId)) {
            errors.push("There is an invalid child node added to the multi export.");
            break;
        }

        if (exportErrorsById[relation.ChildFlowItemId] && exportErrorsById[relation.ChildFlowItemId].length > 0) {
            errors.push("Destinations have required fields missing.");
            break;
        }

        // Executed Destination has Zero quantity
        const destItem = flowItems.find(x => x.FlowItemId == relation.ChildFlowItemId);
        if (destItem) {
            const flowExport = flowExports.find(x => x.FlowItemId == destItem.FlowItemId);

            if (
                flowExport &&
                flowExport.ShouldDeploy &&
                destItem.FlowItemQty != null &&
                destItem.FlowItemQty == 0 &&
                destItem.HasResultTable
            ) {
                errors.push(
                    "One of your selected destinations does not have any matched audience quantities. Please review and either remove or uncheck deploy " +
                        "for any destinations showing volumes of '0', alternatively you can also update your audience selection to improve counts."
                );
                break;
            }
        }
    }

    return errors;
};
