import React from "react";
import { useSelector } from "react-redux";
import { getTreeSearch } from "../../reducers/search";
import FieldIcon from "./FieldIcon";
import { FieldVisibilityTypes } from "../../helpers/constants";
import highlightForSearch from "../../helpers/highlightForSearch";
import { IAppState } from "../../types/stores/index";
import FieldRateDisplay from "../admin/field-rates/FieldRateDisplay";
import DistributionCountsDisplay from "../admin/field-rates/DistributionCountsDisplay";
import FeatureGate from "../FeatureGate";

type Props = {
    fieldKey: string | number;
    TreeType?: number;
};

const FieldListEntryText: React.FC<Props> = ({ fieldKey, TreeType }: Props) => {
    const field = useSelector((state: IAppState) => {
        const fields =
            TreeType == FieldVisibilityTypes.EXPORT
                ? state.layoutfields
                : TreeType == FieldVisibilityTypes.REPORT
                ? state.reportFields
                : state.fields;
        return fields.byId != null ? fields.byId[fieldKey] : "";
    });
    const treeSearch = useSelector((state: IAppState) => getTreeSearch(state));

    if (!field) {
        return <span>(unknown field)</span>;
    }
    const icon = <FieldIcon field={field} />;
    const externalIcon = field.IsPII ? <i className="material-icons-cloud">cloud</i> : null;
    const displayText = highlightForSearch(field.text, treeSearch);

    return (
        <span className="field-list-entry-text" style={{ marginLeft: "-5px" }}>
            {icon} {externalIcon} {displayText}{" "}
            <span className="field-list-entry-text" style={{ fontSize: 12 + "px" }}>
                <FeatureGate feature="display-distribution-counts">
                    {" "}
                    <DistributionCountsDisplay field={field}></DistributionCountsDisplay>
                </FeatureGate>
                <FeatureGate feature="display-field-rates">
                    {" "}
                    <FieldRateDisplay field={field}></FieldRateDisplay>
                </FeatureGate>
            </span>
        </span>
    );
};

export default FieldListEntryText;
