import update from "immutability-helper";

const defaultState = {
    tableReportTabKey: 1, // The active tab in TableReportApp.
    aggregationOperators: [],
    windowOperators: [],
};

function crosstab(state = defaultState, action) {
    switch (action.type) {
        case "TABLEREPORT_SET_TAB_KEY":
            return update(state, {
                tableReportTabKey: {
                    $set: action.tabKey,
                },
            });
        case "CROSSTAB_SET_AGGREGATION_OPERATORS":
            return update(state, {
                aggregationOperators: {
                    $set: action.aggregationOperators,
                },
            });
        case "CROSSTAB_SET_WINDOW_OPERATORS":
            return update(state, {
                windowOperators: {
                    $set: action.windowOperators,
                },
            });
        default:
            return state;
    }
}
export default crosstab;
