import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
// import tryParseJSON from "../helpers/tryParseJSON";
import type {
    FlowExportReport,
    FlowItem,
    FlowErrorsByItemId,
    FlowRelation,
    FlowFilter,
    FlowCase,
} from "../types/flowTypes";
import { getFlowRelationsForSelectedFlow, getFlowRelationsForAllFlows } from "./flowRelations";
import { getFlowCasesForSelectedFlow, getFlowCasesArray } from "./flowCases";
import { getFlowFiltersForSelectedFlow, getFlowFiltersArray } from "./flowFilters";
import { getAncestorFieldIdsForFlowItemId } from "../helpers/flowItems";
import { fieldsHaveNeedsApprovalLabels } from "../helpers/needsApproval";
import { getNeedsApprovalLabelIds } from "./fieldLabels";

const myGenericReducer = makeReducerFor("FLOW_EXPORT_REPORT", "FlowExportReportId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowExportReportsArray = createSelector(
    state => state.flowExportReports.byId,
    (flowExportReportsById: {| [number]: FlowExportReport |}): Array<FlowExportReport> => {
        const r: Array<FlowExportReport> = Object.values(flowExportReportsById);
        return r;
    }
);

export type FlowExportReportsByItemId = {
    [number]: FlowExportReport,
};

export const getFlowExportReportsByFlowItemId = createSelector(
    state => getFlowExportReportsArray(state),
    (flowExportReports: Array<FlowExportReport>): FlowExportReportsByItemId =>
        flowExportReports.reduce((acc, row) => {
            acc[row.FlowItemId] = row;
            return acc;
        }, {})
);

export const getFlowExportReportsForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowExportReportsByFlowItemId(state),
    (flowItems: Array<FlowItem>, flowExportReportsByItemId: FlowExportReportsByItemId): Array<FlowExportReport> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        const result = [];
        for (const itemId of itemIds) {
            const report = flowExportReportsByItemId[itemId];
            if (report != null) {
                result.push(report);
            }
        }
        return result;
    }
);

import { getLayoutObjects } from "./exportlayoutobjects";
const exportReportsToErrorsById = (
    flowExportReports: Array<FlowExportReport>,
    relations: Array<FlowRelation>,
    objects: any,
    needsApprovalLabelIds: Array<number>,
    flowFilters: Array<FlowFilter>,
    flowCases: Array<FlowCase>,
    enabledFieldLabels: any,
    isInternal: boolean
): FlowErrorsByItemId => {
    const errorsById = {};
    for (const flowExportReport of flowExportReports) {
        errorsById[flowExportReport.FlowItemId] = validateFlowExportReport(
            flowExportReport,
            objects,
            relations,
            needsApprovalLabelIds,
            flowFilters,
            flowCases,
            enabledFieldLabels,
            isInternal
        );
    }
    return errorsById;
};

export const getExportReportErrorsForSelectedFlow = createSelector(
    state => getFlowExportReportsForSelectedFlow(state),
    state => getFlowRelationsForSelectedFlow(state),
    state => getLayoutObjects(state),
    state => getNeedsApprovalLabelIds(state),
    state => getFlowFiltersForSelectedFlow(state),
    state => getFlowCasesForSelectedFlow(state),
    state => state.fieldsByCompany.enabledFieldLabels,
    state => state.session.isInternal,
    exportReportsToErrorsById
);

export const getExportReportErrorsForAllFlows = createSelector(
    state => getFlowExportReportsArray(state),
    state => getFlowRelationsForAllFlows(state),
    state => getLayoutObjects(state),
    state => getNeedsApprovalLabelIds(state),
    state => getFlowFiltersArray(state),
    state => getFlowCasesArray(state),
    state => state.fieldsByCompany.enabledFieldLabels,
    state => state.session.isInternal,
    exportReportsToErrorsById
);

import {
    measureFieldsStringToArray,
    crosstabFieldKeysStringToArray,
    isAnyMeasureFieldInvalid,
} from "../helpers/crosstabHelper";

//////////////////// HELPERS //////////////////////////////
export const validateFlowExportReport = (
    flowExportReport: FlowExportReport,
    layouts: Array<any>,
    relations: Array<any>,
    needsApprovalLabelIds: Array<number>,
    flowFilters: Array<FlowFilter>,
    flowCases: Array<FlowCase>,
    enabledFieldLabels: any,
    isInternal: boolean
): Array<string> => {
    const errors = [];
    if (flowExportReport.LayoutId == null || flowExportReport.LayoutId == 0) {
        errors.push("Report item must have a layout selected.");
    }
    // if (flowExportReport.DimensionFieldKeys != null) {
    if (!layouts || layouts.length <= 0) {
        return errors;
    }

    let thisLayout = layouts.filter(x => x.Layout.LayoutID == flowExportReport.LayoutId);
    if (thisLayout.length == 0) {
        errors.push("Layout no longer exists");
        return errors;
    }

    let layoutObjects = thisLayout[0].LayoutObjects;
    if (layoutObjects == null) {
        //errors.push("Unable to find layout objects."); // I don't know if this is an actual error or just data waiting to load.  -Matt 9/6/18
        return errors;
    }

    const ancestorFieldIds = getAncestorFieldIdsForFlowItemId(
        flowExportReport.FlowItemId,
        relations,
        flowFilters,
        flowCases
    );
    const ancestorHasNeedsApprovalFields = fieldsHaveNeedsApprovalLabels(
        ancestorFieldIds,
        needsApprovalLabelIds,
        enabledFieldLabels,
        isInternal
    );
    if (ancestorHasNeedsApprovalFields) {
        errors.push("Report parents contain fields that require approval.");
    }

    let hasUnknownObjects = false;
    let hasBadDimensionBands = false;
    if (flowExportReport.DimensionFieldKeys != null) {
        const dimensionObjects = crosstabFieldKeysStringToArray(flowExportReport.DimensionFieldKeys);
        const dimensionMeasureObjects = measureFieldsStringToArray(flowExportReport.DimensionFieldKeys);

        dimensionObjects.forEach(x => {
            if (!isNaN(x)) {
                let thisObjs = layoutObjects.filter(z => z.Id == x);
                let thisObj = thisObjs.length > 0 ? thisObjs[0] : null;

                if (thisObj == null && !hasUnknownObjects) {
                    hasUnknownObjects = true;
                }
            }
        });
        if (dimensionMeasureObjects.constructor === Array) {
            dimensionMeasureObjects.forEach(x => {
                let thisObjs = layoutObjects.filter(z => z.Id == x.fieldKey);
                let thisObj = thisObjs.length > 0 ? thisObjs[0] : null;

                if (thisObj == null && !hasUnknownObjects) {
                    hasUnknownObjects = true;
                }

                if (
                    x.windowOperator == "banding" &&
                    (x.windowOperatorOptions == null ||
                        x.windowOperatorOptions.intervals == null ||
                        x.windowOperatorOptions.intervals.length == 0)
                ) {
                    hasBadDimensionBands = true;
                }
            });
        }
    }

    if (
        flowExportReport.MeasureFieldKeys == null ||
        flowExportReport.MeasureFieldKeys == "" ||
        flowExportReport.MeasureFieldKeys == "[]"
    ) {
        errors.push("Report items must have at least one measure field set.");
    } else {
        const measureFields = measureFieldsStringToArray(flowExportReport.MeasureFieldKeys);
        const anyInvalidMeasureFields = isAnyMeasureFieldInvalid(measureFields);
        if (anyInvalidMeasureFields) {
            errors.push("One or more measure fields are currently invalid.");
        }
        measureFields.forEach(x => {
            const isDuplicated =
                measureFields.filter(y => y.fieldKey == x.fieldKey && x.aggregationOperator == y.aggregationOperator)
                    .length > 1;
            const errorMessage = "One or more measure fields are duplicated (field and aggregation).";
            if (isDuplicated && !errors.includes(errorMessage)) {
                errors.push(errorMessage);
                return errors;
            }

            let thisObjs = layoutObjects.filter(z => z.Id == x.fieldKey);
            let thisObj = thisObjs.length > 0 ? thisObjs[0] : null;

            if (thisObj == null && !hasUnknownObjects) {
                hasUnknownObjects = true;
            }

            if (
                x.windowOperator == "banding" &&
                (x.windowOperatorOptions == null ||
                    x.windowOperatorOptions.intervals == null ||
                    x.windowOperatorOptions.intervals.length == 0)
            ) {
                hasBadDimensionBands = true;
            }
        });
    }
    if (hasUnknownObjects) {
        errors.push("One or more report items are currently unknown.");
    }
    if (hasBadDimensionBands) {
        errors.push("One or more report items' bands have not been set.");
    }

    //check that it has a parent, if not, set as invalid (should not happen anymore) - #2616
    const parentRelations = relations.filter(
        x => x.ChildFlowItemId == flowExportReport.FlowItemId && x.ParentFlowItemId != 0
    );
    if (!parentRelations || parentRelations.length == 0) {
        errors.push("Export report needs to have an export parent.");
    }

    return errors;
};
