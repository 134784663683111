import { DropTarget } from "react-dnd";
import { DragDropTypes } from "../helpers/constants";

// When calling the withGenericFieldDropTarget helper, pass a Component to wrap.
// We expect these props to be passed in:
//
// onFieldDropped - callback of fieldKey dropped.

const withGenericFieldDropTarget = PassedComponent => {
    const listenTypes = [DragDropTypes.FIELD];
    const fieldTarget = makeFieldTarget();
    return DropTarget(listenTypes, fieldTarget, collectTarget)(PassedComponent);
};

export default withGenericFieldDropTarget;

// Drag and Drop Target (Field Targets accept types 'field')

const makeFieldTarget = () => ({
    canDrop(_, monitor) {
        const itemType = monitor.getItemType();
        return itemType == DragDropTypes.FIELD;
    },
    drop(props, monitor) {
        const itemType = monitor.getItemType();
        // Field dropped onto "Drag and drop here"
        if (itemType == DragDropTypes.FIELD) {
            const item = monitor.getItem();
            if (typeof props.onFieldDropped != "function") {
                console.warn("withGenericFieldDropTarget: You must pass me a props.onFieldDropped function."); // eslint-disable-line no-console
                return;
            }
            if (props.selectedFields.length > 0) {
                Array.prototype.forEach.call(props.selectedFields, field => {
                    props.onFieldDropped(parseInt(field));
                });
                props.clearSelectedFields();
            } else {
                props.onFieldDropped(parseInt(item.fieldKey));
            }
        }
    },
});

// React-dnd will inject these props into our component
const collectTarget = (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
});
