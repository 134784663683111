import React from "react";
import { ContextMenu, MenuItem, ContextMenuTriggerProps } from "react-contextmenu";
import { useDispatch, useSelector } from "react-redux";
import { genericQbAddField, copytoClip } from "../../actions/fieldTreeActions";
import { AddFieldToLayoutObjects } from "../../actions/exportLayoutActions2";
import connectMenu from "../../helpers/connectMenu";
import copy from "copy-to-clipboard";
import { IAppState } from "../../types/stores/index";

interface MenuItemProps {
    fieldKey: number;
    hasChildren: boolean;
}
type Props = { item?: ContextMenuTriggerProps & MenuItemProps };

const FieldContextMenu: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch();

    let { item } = props;

    const page = useSelector((state: IAppState) => state.vars.page);
    const selectedLayout = useSelector((state: IAppState) => state.selected.layout);
    const flowItem = useSelector((state: IAppState) => state.flowItems.byId[state.selected.flowItem]);
    const pathname = useSelector(
        (state: IAppState) => state.router && state.router.location && state.router.location.pathname
    );
    const companyWarehouseDB = useSelector((state: IAppState) => state.session.companyWarehouseDB);
    const fields = useSelector((state: IAppState) => state.fields.byId);

    const isOnPage = pageName => page && page.toLowerCase().includes(pageName);

    const copyFieldNameToClipboard = (fieldKey: number | null) => {
        if (fieldKey && fields[fieldKey]) {
            const field = fields[fieldKey];
            if (field) {
                copy(field.FieldName);
            }
        }
    };

    const copyTableNameToClipboard = (fieldKey: number | null) => {
        if (fieldKey && fields[fieldKey]) {
            const field = fields[fieldKey];
            if (field) {
                copy(companyWarehouseDB + ".dbo." + field.TableName); //todoschema
            }
        }
    };

    const identifier = "field-context";

    // TODO: Remove all isOnPage calls.  See refactors made to AudienceShell in 2018.
    // This makes the page too liable to change if the URLs change, but the behavior should
    // really be independent of the URL.
    const onRestrictedFieldsPage = pathname && pathname.toLowerCase().includes("restricted-fields");

    const showLayoutInclude = isOnPage("layout");
    const showInclude = !isOnPage("layout");
    const showAddField =
        (!flowItem || (flowItem.FlowItemType != "case" && flowItem.FlowItemType != "report")) &&
        !onRestrictedFieldsPage;

    const isScriptNode = flowItem && flowItem.FlowItemType == "script";

    const CopyToClipText = "Copy to Clipboard";
    const includeText = "Include in filters";

    let addField = (
        <MenuItem onClick={() => dispatch(genericQbAddField(item ? item.fieldKey : null))}>{includeText}</MenuItem>
    );

    return (
        <ContextMenu id={identifier} className={`${identifier} exp`}>
            {showInclude && (
                <div>
                    {showAddField && addField}
                    <MenuItem onClick={() => dispatch(copytoClip(item ? item.fieldKey : null))}>
                        {CopyToClipText}
                    </MenuItem>
                </div>
            )}
            {item && item.fieldKey && isScriptNode && (
                <div>
                    <MenuItem onClick={() => copyFieldNameToClipboard(item ? item.fieldKey : null)}>
                        Copy Field Name To Clipboard
                    </MenuItem>
                    <MenuItem onClick={() => copyTableNameToClipboard(item ? item.fieldKey : null)}>
                        Copy Table Name To Clipboard
                    </MenuItem>
                </div>
            )}
            {showLayoutInclude && (
                <div>
                    <MenuItem
                        onClick={() => dispatch(AddFieldToLayoutObjects(item ? item.fieldKey : null, selectedLayout))}
                    >
                        {includeText}
                    </MenuItem>
                </div>
            )}
        </ContextMenu>
    );
};

export default connectMenu("field-context")(FieldContextMenu);
