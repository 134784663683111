import { FlowExportFreewheelDriverFileFields } from "../types/stores/freewheel";
import { Destination } from "../types/types";

export const FREEWHEEL_DRIVER_FILE_FIELDS = "FREEWHEEL_DRIVER_FILE_FIELDS";

import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import { getFlowExportsForSelectedFlow, getFlowExportsArray } from "./flowExports";
import type { FlowItem, FlowErrorsByItemId, FlowExport } from "../types/stores/flowTypes";

const myGenericReducer = makeReducerFor(FREEWHEEL_DRIVER_FILE_FIELDS, "FlowExportFreewheelDriverFileFieldId");
import subItemReducer from "./_genericFlowSubItemReducer";
import { IAppState } from "../types/stores/index";
import { DeploySettings } from "../types/stores/companyTable";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowExportFreewheelDriverFileFieldsArray = createSelector(
    (state: IAppState) => state.flowExportFreewheelDriverFileFields.byId,
    (flowExportFreewheelDriverFileFieldsById: {
        [number: number]: FlowExportFreewheelDriverFileFields;
    }): Array<FlowExportFreewheelDriverFileFields> => {
        const r: Array<FlowExportFreewheelDriverFileFields> = Object.values(flowExportFreewheelDriverFileFieldsById);
        return r;
    }
);

export type FlowExportFreewheelDriverFileFieldsByItemId = {
    [number: number]: Array<FlowExportFreewheelDriverFileFields>;
};

export const getFlowExportFreewheelDriverFileFieldsByFlowItemId = createSelector(
    (state: IAppState) => getFlowExportFreewheelDriverFileFieldsArray(state),
    (
        flowExportFreewheelDriverFileFields: Array<FlowExportFreewheelDriverFileFields>
    ): FlowExportFreewheelDriverFileFieldsByItemId =>
        flowExportFreewheelDriverFileFields.reduce((acc, row) => {
            if (acc[row.FlowItemId] == null) {
                acc[row.FlowItemId] = [];
            }
            acc[row.FlowItemId].push(row);
            return acc;
        }, {})
);

export const getFlowExportFreewheelDriverFileFieldsForSelectedFlow = createSelector(
    (state: IAppState) => getFlowItemsForSelectedFlow(state),
    (state: IAppState) => getFlowExportFreewheelDriverFileFieldsByFlowItemId(state),
    (
        flowItems: Array<FlowItem>,
        flowExportFreewheelDriverFileFieldsByItemId: FlowExportFreewheelDriverFileFieldsByItemId
    ): Array<FlowExportFreewheelDriverFileFields> => {
        let result: Array<FlowExportFreewheelDriverFileFields> = [];
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        for (const itemId of itemIds) {
            const driverFileFields = flowExportFreewheelDriverFileFieldsByItemId[itemId];
            if (driverFileFields != null) {
                result = result.concat(driverFileFields);
            }
        }
        return result;
    }
);

const driverFileFieldsToErrorsById = (
    flowExports: Array<FlowExport>,
    flowExportFreewheelDriverFileFields: Array<FlowExportFreewheelDriverFileFields>,
    destinations: Array<any>
): FlowErrorsByItemId => {
    const errorsById = {};
    for (const flowExport of flowExports) {
        errorsById[flowExport.FlowItemId] = validateExportFreewheelDriverFileFields(
            flowExport,
            flowExportFreewheelDriverFileFields,
            destinations
        );
    }
    return errorsById;
};

export const getExportFreewheelDriverFileFieldErrorsForSelectedFlow = createSelector(
    (state: IAppState) => getFlowExportsForSelectedFlow(state),
    (state: IAppState) => getFlowExportFreewheelDriverFileFieldsForSelectedFlow(state),
    (state: IAppState) => state.vars.destinations,
    driverFileFieldsToErrorsById
);

export const getExportFreewheelDriverFileFieldErrorsForAllFlows = createSelector(
    (state: IAppState) => getFlowExportsArray(state),
    (state: IAppState) => getFlowExportFreewheelDriverFileFieldsArray(state),
    (state: IAppState) => state.vars.destinations,
    driverFileFieldsToErrorsById
);

export const validateExportFreewheelDriverFileFields = (
    flowExport: FlowExport,
    flowExportFreewheelDriverFileFields: Array<FlowExportFreewheelDriverFileFields>,
    destinations: Array<Destination>
): Array<string> => {
    const errors: Array<string> = [];

    if (!destinations || (destinations && destinations.length == 0)) {
        return errors;
    }

    const freewheelFields = flowExportFreewheelDriverFileFields.filter(x => x.FlowItemId == flowExport.FlowItemId);

    // No destination selected
    const destination = destinations.find(x => x.PartnerAccessId == flowExport.DestinationId);

    if (
        !destination ||
        (destination.DeploySetting != DeploySettings.DeployFreewheelDriverFile &&
            destination.DeploySetting != DeploySettings.DeployMagniteDriverFile)
    ) {
        return errors;
    }

    // Missing data
    if (
        freewheelFields.length == 0 &&
        (destination.DeploySetting == DeploySettings.DeployFreewheelDriverFile ||
            destination.DeploySetting == DeploySettings.DeployMagniteDriverFile)
    ) {
        errors.push("Driver File Fields missing.");
        return errors;
    }

    // Validations
    const noIdType = freewheelFields.filter(x => !x.IdTypes);

    if (noIdType.length > 0) {
        errors.push("Driver File must have an Id Type.");
    }

    const noFriendlyNames = freewheelFields.filter(x => !x.SegmentName);
    if (noFriendlyNames.length > 0) {
        errors.push("All Driver File Fields must have a Segment Name.");
    }

    // Freewheel validations
    if (destination.DeploySetting == DeploySettings.DeployFreewheelDriverFile) {
        const noNetworkId = freewheelFields.filter(x => !x.NetworkId);

        if (noNetworkId.length > 0) {
            errors.push("Driver File must have a Network Id.");
        }
    }

    // Magnite validations
    if (destination.DeploySetting == DeploySettings.DeployMagniteDriverFile) {
        const noSubGroupDescription = freewheelFields.filter(x => !x.SubGroupDescription);
        const noSegmentDescription = freewheelFields.filter(x => !x.SegmentDescription);
        const companyName = freewheelFields[0]?.CompanyName;
        const regex = /^[a-zA-Z0-9_>, \/'\.$()\-%]+$/;

        if (!companyName) {
            errors.push("Company name cannot be empty");
        }
        else if (!regex.test(companyName)) {
            errors.push("Please remove invalid characters from the Company Name");
        }

        if (noSubGroupDescription.length > 0) {
            errors.push("All Driver File Fields must have a SubGroup Description.");
        }

        if (noSegmentDescription.length > 0) {
            errors.push("All Driver File Fields must have a Segment Description.");
        }
    }

    const noPrice = freewheelFields.filter(x => x.Price == null);
    if (noPrice.length > 0) {
        errors.push("All Driver File Fields must have a Price.");
    }

    const companyLenght = freewheelFields.filter(x => x.CompanyName);
    if (companyLenght.length > 500) {
        errors.push("CompanyName is maximum of 500 varchar.");
    }

    return errors;
};
