import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    updateAttribute,
    newFlowSegmentSplitAndOffers,
    newFlowSegmentSplit,
    invalidateItemAndChildren,
    deleteFlowSegmentSplit,
} from "../../../../actions/flowActions";
import { getFlowSegmentSplitsByFlowItemId } from "../../../../reducers/flowSegmentSplits";
import { getFlowSegmentSplitOffersByFlowItemId } from "../../../../reducers/flowSegmentSplitOffers";
import { addCommas } from "../../../../helpers/typedHelpers";

import { IAppState } from "../../../../types/stores";
import { FlowSegmentSplit, FlowItem, FlowSegmentSplitOffer } from "../../../../types/stores/flowTypes";

import { getFlowExportsByFlowItemId } from "../../../../reducers/flowExports";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import RadioButton from "../../../material-components/Misc/RadioButton";
import { Button, Divider, TextField, InputAdornment, Checkbox, Paper } from "@material-ui/core";
import GenericConfirmModal from "../../../modals/GenericConfirmModal";
import { IClientVariable } from "../../../../types/stores/vars";
import NumericalTextField from "../../../material-components/Misc/NumericalTextField";
import { addThing } from "../../../../actions/flowActions";

interface IProps {
    flowItemId: number;
    parentFlowItemId: number;
    disabled: boolean;
    destinationOffers: Array<IClientVariable>;
}

const FlowExportSplit: React.FC<IProps> = ({ flowItemId, parentFlowItemId, disabled, destinationOffers }: IProps) => {
    const dispatch = useDispatch();

    const [splitMethod, setSplitMethod] = useState("relative");

    const flowSegmentSplits = useSelector<IAppState, Array<FlowSegmentSplit>>(state => {
        let segments = getFlowSegmentSplitsByFlowItemId(state);
        return segments[flowItemId] || [];
    });

    const flowSegmentSplitOffers = useSelector<IAppState, Array<FlowSegmentSplitOffer>>(state => {
        let segments = getFlowSegmentSplitOffersByFlowItemId(state);
        return segments[flowItemId] || [];
    });
    const flowExportsByFlowItemId = useSelector(state => getFlowExportsByFlowItemId(state));
    const existingExport = flowExportsByFlowItemId[flowItemId];
    const parentSegmentSplits = flowSegmentSplits.filter(
        x => x.ParentFlowItemId == parentFlowItemId && x.FlowItemId == flowItemId
    );
    const parentFlowItem = useSelector<IAppState, FlowItem>(state => state.flowItems.byId[parentFlowItemId]);
    const enabledFeatures = useSelector((state: IAppState) => state.session.enabledFeatures);

    useEffect(() => {
        if (parentSegmentSplits.length > 0) {
            const segmentSplit = parentSegmentSplits[0];
            if (segmentSplit.AbsoluteOrRelative && segmentSplit.AbsoluteOrRelative != splitMethod) {
                setSplitMethod(segmentSplit.AbsoluteOrRelative);
            }
        }
    }, [
        parentSegmentSplits,
        splitMethod,
        enabledFeatures,
        destinationOffers,
        flowSegmentSplitOffers.length,
        flowSegmentSplits,
        dispatch,
    ]);

    const handleRelOrAbsRadio = e => {
        for (const split of parentSegmentSplits) {
            dispatch(updateAttribute("flowSegmentSplits", split.SegmentSplitId, "AbsoluteOrRelative", e.target.value));
        }

        dispatch(invalidateItemAndChildren(flowItemId));
    };

    const addRowClick = () => {
        if (enabledFeatures.includes("flow-destination-level-splits-all-offers")) {
            dispatch(newFlowSegmentSplitAndOffers(flowItemId, parentFlowItemId, splitMethod, destinationOffers));
        } else {
            dispatch(newFlowSegmentSplit(flowItemId, parentFlowItemId, splitMethod));
        }
        dispatch(invalidateItemAndChildren(flowItemId));
    };

    const renderAbsoluteOrRelativeCell = (split: FlowSegmentSplit) => {
        if (split.AbsoluteOrRelative == "absolute") {
            return renderAbsoluteNumRows(split);
        } else {
            return renderRelativePercentRows(split);
        }
    };

    const updateSplitSegmentAllOffers = () => {
        // do a for each with each segment split
        // if it is missing the segemnt, then add it.
        flowSegmentSplits.forEach(x => {
            destinationOffers.forEach(z => {
                let thisSegmentSplitOffer = flowSegmentSplitOffers.filter(
                    y => y.SegmentSplitId == x.SegmentSplitId && y.DestinationOfferId == z.Id
                )[0];
                if (thisSegmentSplitOffer == null) {
                    const offer: FlowSegmentSplitOffer = {
                        SegmentSplitOfferId: 0,
                        SegmentSplitId: x.SegmentSplitId,
                        DestinationOfferId: z.Id,
                        Value: "",
                        FlowItemId: flowItemId,
                    };
                    dispatch(addThing("flowSegmentSplitOffers", offer));
                }
            });
        });
    };
    const renderAbsoluteNumRows = (split: FlowSegmentSplit) => (
        <NumericalTextField
            disabled={disabled}
            className="material-form-control"
            value={split.AbsoluteNumRows || ""}
            onBlur={e => {
                let value = parseInt(e.target.value);
                if (value < 0) {
                    value = 0;
                }
                dispatch(updateAttribute("flowSegmentSplits", split.SegmentSplitId, "AbsoluteNumRows", value, true));
                dispatch(invalidateItemAndChildren(flowItemId));
            }}
            InputProps={{
                endAdornment: <InputAdornment position="end">rows</InputAdornment>,
            }}
        />
    );

    const renderRelativePercentRows = (split: FlowSegmentSplit) => {
        const sumOtherRows: number = parentSegmentSplits.reduce((acc: number, row: FlowSegmentSplit): number => {
            const rowCount = row.RelativePercentRows != null ? row.RelativePercentRows : 0;
            return split.SegmentSplitId == row.SegmentSplitId ? acc : acc + rowCount;
        }, 0);

        return (
            <NumericalTextField
                disabled={disabled}
                className="material-form-control transparent"
                value={split.RelativePercentRows || ""}
                onBlur={e => {
                    let value = parseFloat(e.target.value);

                    if (value < 0) {
                        value = 0;
                    } else if (value > 100) {
                        value = 100;
                    }

                    const newVal = sumOtherRows + value > 100 ? 100 - sumOtherRows : value;
                    dispatch(updateAttribute("flowSegmentSplits", split.SegmentSplitId, "RelativePercentRows", newVal));
                    dispatch(invalidateItemAndChildren(flowItemId));
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
            />
        );
    };

    const renderDeleteSplitCell = (split: FlowSegmentSplit) => {
        const confirmDeleteBody = <div>{`Are you sure you want to delete this row?`}</div>;
        const confirmDeleteText = "Delete Row";

        return (
            <div className="row-actions">
                <GenericConfirmModal
                    onConfirm={() => {
                        dispatch(deleteFlowSegmentSplit(split.SegmentSplitId));
                        dispatch(invalidateItemAndChildren(flowItemId));
                    }}
                    body={confirmDeleteBody}
                    confirmText={confirmDeleteText}
                    title="Confirm Delete Split"
                    buttonText={
                        <i className={`material-icons material-icon-hover-style ${disabled ? "" : "clickable"}`}>
                            close
                        </i>
                    }
                    buttonDisabled={disabled}
                    cnBtnStyle="normal-transparent-button"
                />
            </div>
        );
    };

    return (
        <React.Fragment>
            <Paper>
                <div className="flow-edit-header">
                    <p
                        style={{ marginLeft: "20px", fontSize: "14px !important" }}
                    >{`${parentFlowItem.FlowItemName} - Split Settings`}</p>
                    <div className="flow-edit-header-actions">
                        <div className="actions-left-container large-switch">
                            {parentSegmentSplits.length > 0 && (
                                <React.Fragment>
                                    <span style={{ fontWeight: 500, marginRight: "10px", fontSize: "12px" }}>
                                        Method
                                    </span>
                                    <RadioButton
                                        id="relative-or-absolute"
                                        name="RelOrAbs"
                                        disabled={disabled}
                                        options={[
                                            { label: "Relative", value: "relative" },
                                            { label: "Absolute", value: "absolute" },
                                        ]}
                                        onChange={handleRelOrAbsRadio}
                                        row={true}
                                        valChecked={splitMethod}
                                    />
                                </React.Fragment>
                            )}
                            <Button
                                variant="contained"
                                color="secondary"
                                className={"edit-button"}
                                startIcon={<i className="material-icons">add</i>}
                                disabled={disabled}
                                onClick={addRowClick}
                            >
                                Add Row
                            </Button>
                        </div>
                    </div>
                </div>
                <Divider style={{ margin: "20px auto" }} />
                <div className="flow-edit-body">
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {((enabledFeatures.includes("flow-destination-level-splits-all-offers") &&
                                        existingExport.IsSeperateFiles) ||
                                        (enabledFeatures.includes("flow-destination-level-splits") &&
                                            !enabledFeatures.includes("flow-destination-level-splits-all-offers"))) && (
                                        <TableCell style={{ fontSize: 12 }} align="left">
                                            Audience Name
                                        </TableCell>
                                    )}
                                    {enabledFeatures.includes("flow-destination-level-splits-all-offers") &&
                                        destinationOffers.map(x => (
                                            <TableCell
                                                key={"segmentoffer" + x.Id}
                                                style={{ fontSize: 12 }}
                                                align="center"
                                            >
                                                {x.VariableName}
                                            </TableCell>
                                        ))}
                                    <TableCell style={{ fontSize: 12 }} align="center">
                                        Amount
                                    </TableCell>
                                    <TableCell style={{ fontSize: 12 }} align="right">
                                        Count
                                    </TableCell>
                                    <TableCell style={{ fontSize: 12 }} align="center">
                                        Deploy
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {parentSegmentSplits.map(x => (
                                    <TableRow key={"segment-split-row" + x.SegmentSplitId}>
                                        {((enabledFeatures.includes("flow-destination-level-splits-all-offers") &&
                                            existingExport.IsSeperateFiles) ||
                                            (enabledFeatures.includes("flow-destination-level-splits") &&
                                                !enabledFeatures.includes(
                                                    "flow-destination-level-splits-all-offers"
                                                ))) && (
                                            <TableCell>
                                                <TextField
                                                    style={{
                                                        fontSize: 16,
                                                        width: "95%",
                                                        marginLeft: "15px",
                                                        top: "-5px",
                                                        position: "relative",
                                                        margin: "6px",
                                                    }}
                                                    key={"segment-split-label-" + x.SegmentSplitId}
                                                    type="text"
                                                    value={x.SegmentName}
                                                    disabled={disabled}
                                                    inputProps={{
                                                        style: { fontSize: "16px" },
                                                    }}
                                                    onChange={e => {
                                                        let regex = /[^a-zA-Z0-9%_ ]/g;
                                                        if (
                                                            enabledFeatures.includes(
                                                                "export-destination-offer-variables"
                                                            )
                                                        ) {
                                                            regex = /[^a-zA-Z0-9%_-{}: ]/g;
                                                        }
                                                        const newValue = e.target.value.replace(regex, "");
                                                        dispatch(
                                                            updateAttribute(
                                                                "flowSegmentSplits",
                                                                x.SegmentSplitId,
                                                                "SegmentName",
                                                                newValue,
                                                                true
                                                            )
                                                        );
                                                        dispatch(invalidateItemAndChildren(flowItemId));
                                                    }}
                                                />
                                            </TableCell>
                                        )}
                                        {enabledFeatures.includes("flow-destination-level-splits-all-offers") &&
                                            destinationOffers.map(z => (
                                                <TableCell
                                                    key={"segmentofferval" + z.Id}
                                                    style={{ fontSize: 12 }}
                                                    align="center"
                                                >
                                                    <TextField
                                                        style={{
                                                            fontSize: 16,
                                                            width: "95%",
                                                            marginLeft: "15px",
                                                            top: "-5px",
                                                            position: "relative",
                                                            margin: "6px",
                                                        }}
                                                        key={"segment-split-label-" + x.SegmentSplitId}
                                                        type="text"
                                                        onFocus={() => {
                                                            updateSplitSegmentAllOffers();
                                                        }}
                                                        value={
                                                            flowSegmentSplitOffers.filter(
                                                                y =>
                                                                    y.SegmentSplitId == x.SegmentSplitId &&
                                                                    y.DestinationOfferId == z.Id
                                                            )[0] != null
                                                                ? flowSegmentSplitOffers.filter(
                                                                      y =>
                                                                          y.SegmentSplitId == x.SegmentSplitId &&
                                                                          y.DestinationOfferId == z.Id
                                                                  )[0].Value
                                                                : ""
                                                        }
                                                        disabled={disabled}
                                                        inputProps={{
                                                            style: { fontSize: "16px" },
                                                        }}
                                                        onChange={e => {
                                                            let regex = /[^a-zA-Z0-9%_ ]/g;
                                                            if (
                                                                enabledFeatures.includes(
                                                                    "export-destination-offer-variables"
                                                                )
                                                            ) {
                                                                regex = /[^a-zA-Z0-9%_-{}: ]/g;
                                                            }
                                                            const newValue = e.target.value.replace(regex, "");

                                                            dispatch(
                                                                updateAttribute(
                                                                    "flowSegmentSplitOffers",
                                                                    flowSegmentSplitOffers.filter(
                                                                        y =>
                                                                            y.SegmentSplitId == x.SegmentSplitId &&
                                                                            y.DestinationOfferId == z.Id
                                                                    )[0] != null
                                                                        ? flowSegmentSplitOffers.filter(
                                                                              y =>
                                                                                  y.SegmentSplitId ==
                                                                                      x.SegmentSplitId &&
                                                                                  y.DestinationOfferId == z.Id
                                                                          )[0].SegmentSplitOfferId
                                                                        : null,
                                                                    "Value",
                                                                    newValue,
                                                                    true
                                                                )
                                                            );
                                                            dispatch(invalidateItemAndChildren(flowItemId));
                                                        }}
                                                    />
                                                </TableCell>
                                            ))}
                                        <TableCell>{renderAbsoluteOrRelativeCell(x)}</TableCell>
                                        <TableCell style={{ fontSize: 12 }} align="right">
                                            {addCommas(x.SplitQuantity)}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Checkbox
                                                checked={x.IsIncludeInDeploy}
                                                onClick={() => {
                                                    dispatch(
                                                        updateAttribute(
                                                            "flowSegmentSplits",
                                                            x.SegmentSplitId,
                                                            "IsIncludeInDeploy",
                                                            !x.IsIncludeInDeploy,
                                                            true
                                                        )
                                                    );
                                                    dispatch(invalidateItemAndChildren(flowItemId));
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>{renderDeleteSplitCell(x)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Paper>
        </React.Fragment>
    );
};
export default FlowExportSplit;
