import React, { useState, useEffect } from "react";
import Loading from "../../Loading";
import loadjs from "loadjs";

import { loadjQueryCron } from "../../../helpers/loadResources";

type Props = {
    children: React.ReactNode;
};

const LoadJQueryCron: React.FC<Props> = (props: Props) => {
    const [jQueryCronLoaded, setJQueryCronLoaded] = useState<boolean>(loadjs.isDefined("jQueryCron"));

    useEffect(() => {
        if (!jQueryCronLoaded) {
            loadjQueryCron(() => setJQueryCronLoaded(true));
        }
    }, [jQueryCronLoaded]);

    return !jQueryCronLoaded ? <Loading /> : <div>{props.children}</div>;
};

export default LoadJQueryCron;
