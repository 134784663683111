import { Dispatch } from "redux";
import { request } from "../helpers/httpInterceptor";
import h from "../helpers";
import { SET_FREEWHEEL_LOADING, SET_FREEWHEEL_ID_TYPES } from "../reducers/flowExportObjects";
import { ISetFreewheelLoading, ISetFreewheelIdTypes, IIdType } from "../types/stores/freewheel";

export const setFreewheelLoading = (isLoading: boolean): ISetFreewheelLoading => ({
    type: SET_FREEWHEEL_LOADING,
    isLoading,
});

export const setFreewheelIdTypes = (idTypes: Array<IIdType>): ISetFreewheelIdTypes => ({
    type: SET_FREEWHEEL_ID_TYPES,
    idTypes,
});

export const getIdTypes =
    () =>
    (dispatch: Dispatch): void => {
        setFreewheelLoading(true);

        request("/FlowExportFreewheel/GetIdTypes", { credentials: "same-origin" }, dispatch)
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const result = data as unknown as { idTypes: Array<IIdType> };
                dispatch(setFreewheelIdTypes(result.idTypes));
                dispatch(setFreewheelLoading(false));
            })
            .catch(error => {
                dispatch(setFreewheelIdTypes([]));
                dispatch(setFreewheelLoading(false));
                h.error("Error getting Freewheel Id Types.", error);
            });
    };
