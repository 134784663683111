import React from "react";

import { connect } from "react-redux";
import highlightForSearch from "../../helpers/highlightForSearch";

import type { Flow, FlowItem } from "../../types/flowTypes";

type Props = {
    // Passed in
    flowId: number,
    // Redux
    flow: ?Flow,
    flowItems: Array<FlowItem>,
    treeSearch: string | Array<string>,
};

class FlowListEntryText extends React.Component<Props> {
    redDotRender() {
        return (
            <span
                style={{
                    height: "5px",
                    width: "5px",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    display: "inline-block",
                    marginBottom: "4px",
                }}
            ></span>
        );
    }

    render() {
        const { flow, treeSearch, flowItems } = this.props;
        if (flow == null) {
            return null;
        }
        const flowItemErrors =
            flowItems != null ? Object.values(flowItems).filter(x => x.FlowId == flow.FlowId && x.IsError) : null;
        const hasError = flowItemErrors.length > 0;
        const displayText = highlightForSearch(flow.FlowName, treeSearch);
        return (
            <span className="flow-list-entry-text">
                {displayText} {flow.hasError || hasError ? this.redDotRender() : ""}
            </span>
        );
    }
}

import { getTreeSearch } from "../../reducers/search";
import type { MapStateToProps } from "react-redux";

const mapStateToProps: MapStateToProps<*, *, *> = (state, ownProps) => ({
    flow: state.flows.byId[ownProps.flowId || 0],
    flowItems: state.flowItems.byId || [],
    treeSearch: getTreeSearch(state),
});
const mapDispatchToProps = null;
const FlowListEntryTextC = connect(mapStateToProps, mapDispatchToProps)(FlowListEntryText);

export default FlowListEntryTextC;
