import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../actions/exportLayoutActions2";
import TreeItem from "../tree/TreeItem";
import Tooltip from "@material-ui/core/Tooltip";

import { DragSource } from "react-dnd";
import { DragDropTypes } from "../../helpers/constants";

import { ContextMenuTrigger } from "react-contextmenu";

class ClientFieldItem extends React.Component {
    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
        this.handleDoubleClick = this.handleDoubleClick.bind(this);
    }

    handleClick() {}

    handleDoubleClick() {
        this.props.AddClientFieldToLayoutObjects(this.props.selectedLayout);
    }

    componentDidMount() {
        $(this.divNode).bstooltip();
    }

    componentWillUnmount() {
        $(this.divNode).bstooltip("destroy");
    }

    render() {
        const { connectDragSource, clientField } = this.props;

        if (clientField != null) {
            return connectDragSource(
                <div
                    style={{ position: "relative" }}
                    data-container="body"
                    data-toggle="tooltip"
                    data-placement="rightlocal"
                    ref={node => (this.divNode = node)}
                >
                    <Tooltip placement="right" title="Primary ID's export name will change depending on the context.">
                        <div>
                            <ContextMenuTrigger
                                id="variable-tree"
                                collect={props => props}
                                fieldKey={clientField.FieldUniqueKey}
                                holdToDisplay={-1}
                            >
                                <TreeItem
                                    handleClick={this.handleClick}
                                    handleDoubleClick={this.handleDoubleClick}
                                    active={false}
                                >
                                    {/* {clientField[0].FieldName} */}
                                    {"Primary Id"}
                                </TreeItem>
                            </ContextMenuTrigger>
                        </div>
                    </Tooltip>
                </div>
            );
        } else {
            return <div />;
        }
    }
}

// Prop Types /
const propTypes = {
    // Provided from outside
    AddClientFieldToLayoutObjects: PropTypes.func,
    clientField: PropTypes.object,
    selectedLayout: PropTypes.number,
    connectDragSource: PropTypes.func.isRequired,
};
ClientFieldItem.propTypes = propTypes;

// Drag and Drop here
function collect(connect, monitor) {
    // React-dnd will inject these props into our component
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    };
}

const fieldSource = {
    canDrag() {
        return true;
    },
    beginDrag(props) {
        return { variableId: props.variableId };
    },
};
const ClientFieldItemDND = DragSource(DragDropTypes.CLIENTFIELD, fieldSource, collect)(ClientFieldItem);

// Redux //
//import { getTreeSearch } from '../../reducers/search';
const mapStateToProps = state => ({
    //treeSearch: getTreeSearch(state),
    clientField: state.layoutObjects.clientFieldObject,
    userId: state.session.userId,
    selectedLayout: state.selected.layout,
});
const mapDispatchToProps = dispatch => bindActionCreators(actionCreators, dispatch);

// export { GlobalVariableListEntry };
const ClientFieldItemDNDC = connect(mapStateToProps, mapDispatchToProps)(ClientFieldItemDND);

export default ClientFieldItemDNDC;
