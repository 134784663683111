import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAttribute, invalidateItemAndChildren } from "../../../../actions/flowActions";
import { makeGetFlowExport } from "../FlowExportEdit";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import { FlowItem } from "../../../../types/stores/flowTypes";
import { IAppState } from "../../../../types/stores";

type Props = {
    // Passed In
    flowItemId: number;
    parameterName: string;
    toggleLabel: string;
    onTooltip: string;
    offTooltip: string;
    onText: string;
    offText: string;
    isInvalidateItemAndChildren: boolean;
    flowItemType: string;
    isDisabled: boolean;
};

const FlowItemToggle: React.FC<Props> = (props: Props) => {
    const {
        parameterName,
        flowItemType,
        isInvalidateItemAndChildren,
        toggleLabel,
        onTooltip,
        offTooltip,
        onText,
        offText,
        isDisabled,
        flowItemId,
    } = props;

    const dispatch = useDispatch();

    const getFlowExport = makeGetFlowExport();
    const flowItem = useSelector<IAppState, FlowItem>(state => state.flowItems.byId[props.flowItemId]);
    const flowExport = useSelector((state: IAppState) =>
        getFlowExport(state, {
            flowItemId,
            isModal: false,
            hideExtra: false,
        })
    );

    const handleSwitchChange = () => {
        if (flowItem == null) {
            return;
        }

        let switchValue = flowItem[parameterName];
        if (flowExport != null && flowItemType == "Export") {
            switchValue = flowExport[parameterName];
        }

        if (flowExport != null && flowItemType != null && flowItemType == "Export") {
            dispatch(updateAttribute("flowExports", flowExport.FlowExportId, parameterName, !switchValue));
            if (isInvalidateItemAndChildren) {
                dispatch(invalidateItemAndChildren(flowExport.FlowItemId));
            }
        } else {
            dispatch(updateAttribute("flowItems", flowItem.FlowItemId, parameterName, !switchValue));
            if (isInvalidateItemAndChildren) {
                dispatch(invalidateItemAndChildren(flowItem.FlowItemId));
            }
        }
    };

    if (flowItem == null) {
        return null;
    }

    let trueText = onText || "ON";
    let falseText = offText || "OFF";
    let switchValue = flowItem[parameterName];

    if (flowExport != null && flowItemType == "Export") {
        switchValue = flowExport[parameterName];
    }

    const label = (
        <Tooltip
            title={
                <>
                    <strong>{trueText}</strong>: {onTooltip}
                    <br />
                    {offTooltip && (
                        <span>
                            <strong>{falseText}</strong>: {offTooltip}
                        </span>
                    )}
                </>
            }
            id="inner_join_tip"
        >
            <div style={{ fontSize: "16px" }}>
                <strong>{toggleLabel}&nbsp;</strong>
                <Icon style={{ fontSize: "16px" }}>info</Icon>
            </div>
        </Tooltip>
    );

    return (
        <FormControlLabel
            labelPlacement="start"
            style={{
                minWidth: "175px",
                margin: "0 auto",
            }}
            control={
                <Checkbox
                    id={"flow-item-toggle-" + flowItem.FlowItemId}
                    color="primary"
                    onChange={handleSwitchChange}
                    checked={switchValue}
                    value={switchValue}
                    disabled={isDisabled}
                />
            }
            label={label}
        />
    );
};

export default FlowItemToggle;
