import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../types/stores";
import { IField } from "../../../types/stores/fieldTypes";

type Props = { field: IField };

const FieldRateDisplay: React.FC<Props> = ({ field }: Props) => {
    const [currentFieldRateCPM, setCurrentFieldRateCPM] = useState(0);
    const fieldRatesById = useSelector((state: IAppState) => state.fieldsByCompany.fieldRates);
    const taxmanFieldRatesById = useSelector((state: IAppState) => state.fieldsByCompany.taxmanFieldRates);
    const USDollar = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });
    useEffect(() => {
        let fieldRate = fieldRatesById[field.UmbrellaFieldId || 0];
        let taxmanFieldRate = taxmanFieldRatesById[field.id || 0];
        //let fieldRate = fieldRatesById[field.fieldkey];
        if (fieldRate && fieldRate.CPM != 0) {
            setCurrentFieldRateCPM(fieldRate.CPM);
        } else if (taxmanFieldRate && taxmanFieldRate.CPM != 0) {
            setCurrentFieldRateCPM(taxmanFieldRate.CPM);
        }
    }, [field, fieldRatesById, taxmanFieldRatesById]);

    return <> {currentFieldRateCPM != 0 ? "@" + USDollar.format(currentFieldRateCPM) : null}</>;
};

export default FieldRateDisplay;
