import { GET_NOTIFICATIONS } from "../actions/userNotificationActions";
import { IDashboardWidgetStore, DashboardActions } from "../types/stores/dashboardWidgets";

export const UPDATING_WIDGETS = "UPDATING_WIDGETS";
export const GET_USER_WIDGETS = "GET_USER_WIDGETS";
export const TOGGLE_WIDGET = "TOGGLE_WIDGET";
export const MOVE_WIDGET = "MOVE_WIDGET";
export const GET_USER_COMPANY_FLOWS_STATUS = "GET_USER_COMPANY_FLOWS_STATUS";
export const GET_USER_COMPANY_SCHEDULE_STATUS = "GET_USER_COMPANY_SCHEDULE_STATUS";
export const GET_RECENT_FLOWS = "GET_RECENT_FLOWS";
export const GET_FILE_MASTER_LOG = "GET_FILE_MASTER_LOG";
export const GET_MODELS = "GET_MODELS";
export const REMOVE_USER_COMPANY_FLOWS_STATUS = "REMOVE_USER_COMPANY_FLOWS_STATUS";
export const GET_USER_COMPANY_COMPLETED_FLOWS_STATUS = "GET_USER_COMPANY_COMPLETED_FLOWS_STATUS";
export const GET_USER_COMPANY_RUNNING_FLOWS_STATUS = "GET_USER_COMPANY_RUNNING_FLOWS_STATUS";

function dashboardWidgets(
    state: IDashboardWidgetStore = {
        widgets: null,
        loading: {
            dataDashboard: true,
            modelStatus: true,
            recentFlows: true,
            runningFlows: true, //Flow Status Tab 1
            completedFlows: true, //Flow Status Tab 2
            recentNotifications: true,
            scheduleStatus: true,
        },
        completedFlows: [],
        runningFlows: [],
        fileMasterLog: [],
        models: [],
        recentFlows: [],
        scheduleStatus: [],
    },
    action: DashboardActions
): IDashboardWidgetStore {
    switch (action.type) {
        case GET_USER_WIDGETS:
            return Object.assign({}, state, {
                widgets: action.widgets,
            });
        case GET_FILE_MASTER_LOG:
            return Object.assign({}, state, {
                fileMasterLog: action.fileMasterLog,
                loading: {
                    ...state.loading,
                    dataDashboard: action.loading,
                },
            });
        case GET_MODELS:
            return Object.assign({}, state, {
                models: action.models,
                statuses: action.statuses,
                loading: {
                    ...state.loading,
                    modelStatus: action.loading,
                },
            });
        case GET_RECENT_FLOWS: {
            return Object.assign({}, state, {
                recentFlows: action.recentFlows,
                loading: {
                    ...state.loading,
                    recentFlows: action.loading,
                },
            });
        }
        case GET_USER_COMPANY_FLOWS_STATUS: {
            return Object.assign({}, state, {
                runningFlows: action.runningFlows,
                completedFlows: action.completedFlows,
                loading: {
                    ...state.loading,
                    runningFlows: action.loading,
                    completedFlows: action.loading,
                },
            });
        }
        case GET_USER_COMPANY_COMPLETED_FLOWS_STATUS: {
            return Object.assign({}, state, {
                completedFlows: action.completedFlows,
                loading: {
                    ...state.loading,
                    completedFlows: action.loading,
                },
            });
        }
        case GET_USER_COMPANY_RUNNING_FLOWS_STATUS: {
            return Object.assign({}, state, {
                runningFlows: action.runningFlows,
                loading: {
                    ...state.loading,
                    runningFlows: action.loading,
                },
            });
        }
        case REMOVE_USER_COMPANY_FLOWS_STATUS:
            return Object.assign({}, state, {
                runningFlows: state.runningFlows.filter(item => item.FlowId !== action.flowIdToRemove),
            });
        case GET_NOTIFICATIONS: {
            return Object.assign({}, state, {
                loading: {
                    ...state.loading,
                    recentNotifications: action.loading,
                },
            });
        }
        case TOGGLE_WIDGET: {
            const widgetToToggle = state.widgets!.find(x => x.WidgetName == action.WidgetName);
            let newWidgets = state.widgets!;

            if (widgetToToggle) {
                newWidgets = state.widgets!.filter(x => x.WidgetName !== widgetToToggle.WidgetName);
                widgetToToggle.WidgetIsVisible = action.WidgetIsVisible;
                newWidgets.push(widgetToToggle);
            }

            return Object.assign({}, state, {
                widgets: newWidgets,
            });
        }
        case MOVE_WIDGET: {
            return Object.assign({}, state, {
                widgets: action.widgets,
            });
        }
        case GET_USER_COMPANY_SCHEDULE_STATUS: {
            return Object.assign({}, state, {
                scheduleStatus: action.scheduleStatus,
                loading: {
                    ...state.loading,
                    scheduleStatus: action.loading,
                },
            });
        }
        default:
            return state;
    }
}

export default dashboardWidgets;
