import React, { Component } from "react";
import PropTypes from "prop-types";

class FileBrowser extends Component {
    static propTypes = {
        fileName: PropTypes.string,
        uploading: PropTypes.bool,
        fileTypes: PropTypes.string,
        selectedFileChanged: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
    };

    onFileChanged = () => {
        const { selectedFileChanged } = this.props;
        const file = this.uploadBtn.files[0];
        selectedFileChanged(file, true);
        this.uploadBtn.value = null;
    };

    render() {
        const { fileName, uploading, fileTypes, disabled } = this.props;
        return (
            <form className="uploader" encType="multipart/form-data">
                <div className="input-group">
                    <label className="input-group-btn">
                        <span className="btn btn-primary" title="Select a file">
                            Browse&hellip;{" "}
                            <input
                                disabled={uploading || disabled}
                                ref={c => {
                                    this.uploadBtn = c;
                                }}
                                type="file"
                                name="File"
                                accept={fileTypes}
                                style={{ display: "none" }}
                                onChange={this.onFileChanged}
                            />
                        </span>
                    </label>
                    <input
                        id="uploadFile"
                        type="text"
                        className="form-control"
                        readOnly
                        value={fileName}
                        disabled={uploading || disabled}
                    />
                </div>
            </form>
        );
    }
}
export default FileBrowser;
