import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as fieldTreeActions from "../../actions/fieldTreeActions";
import Tooltip from "@material-ui/core/Tooltip";

import FieldListEntryText from "../tree-field/FieldListEntryText";
import makeTooltipForField from "../tree-field/makeTooltipForField";

class CrosstabDimensionField extends React.Component {
    static propTypes = {
        // Passed In
        fieldKey: PropTypes.number,
        isMeasure: PropTypes.bool,
        operator: PropTypes.string /* Not sure */,
        hideRemoveLink: PropTypes.bool,
        isValueField: PropTypes.bool,
        fieldIdChanged: PropTypes.func,
        onRemoveField: PropTypes.func,
        // Redux
        fields: PropTypes.any,
        enabledFeatures: PropTypes.array,
        // Injected by Redux (action creator)
        crosstabRemoveMeasureField: PropTypes.func.isRequired,
        removeFieldInCrosstab: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
    };

    render() {
        const { fieldKey, isMeasure, operator, isValueField, onRemoveField, fields, hideRemoveLink, disabled } =
            this.props;
        if (!fieldKey || !fields) {
            return null;
        }

        const tt = makeTooltipForField(fields[fieldKey], "") || "";

        let spanClass = "field-label field-label-default";
        if (isMeasure) {
            spanClass = "field-label field-label-measure";
        }

        let removeFunction;
        if (typeof onRemoveField == "function") {
            removeFunction = () => onRemoveField(fieldKey);
        } else if (isValueField) {
            removeFunction = () => this.props.fieldIdChanged(0);
        } else if (isMeasure) {
            removeFunction = () => this.props.crosstabRemoveMeasureField(fieldKey, operator);
        } else {
            removeFunction = () => this.props.removeFieldInCrosstab(fieldKey);
        }
        return (
            <span key={"wrapper" + fieldKey} style={{ marginRight: "10px" }}>
                <Tooltip placement="bottom" title={tt}>
                    <span style={{ marginRight: "10px" }}>
                        <span className={spanClass}>
                            <FieldListEntryText fieldKey={fieldKey} key={fieldKey} />
                            {!hideRemoveLink && (
                                <span
                                    onClick={disabled ? null : removeFunction}
                                    style={{ textDecoration: "underline" }}
                                >
                                    <i
                                        className={`glyphicon glyphicon-remove ${disabled ? "" : "clickable"}`}
                                        style={{ color: "#a92a2a", marginLeft: "10px", cursor: "pointer" }}
                                    />
                                </span>
                            )}
                        </span>
                    </span>
                </Tooltip>
            </span>
        );
    }
}

const mapStateToProps = state => ({
    fields: state.fields.byId,
    enabledFeatures: state.session.enabledFeatures || [],
});
const mapDispatchToProps = dispatch => bindActionCreators(fieldTreeActions, dispatch);
const CrosstabDimensionFieldC = connect(mapStateToProps, mapDispatchToProps)(CrosstabDimensionField);

export default CrosstabDimensionFieldC;
