import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
    addExportDistributionPlatform,
    requestFlowExportDestinationPartners,
    updateAttribute,
    deleteFlowExportDistributionPlatform,
} from "../../../../actions/flowActions";
import { getSelectedFlowAndItemPermissions } from "../../../../reducers/flowItems";
import { getFlowExportDistributionPlatformsArray } from "../../../../reducers/flowExportDistributionPlatforms";
import { Paper, Table, IconButton, Button, Divider, TextField, Tooltip, MenuItem, Select } from "@material-ui/core";
import TableHeader from "../../../material-components/Table/TableHeader";
import TableBody from "../../../material-components/Table/TableBody";
import TableFooter from "../../../material-components/Table/TableFooter";
import type { FlowExportDistributionPlatform } from "../../../../types/flowTypes";
import isValidEmailAddress from "../../../../helpers/emailValidation";
import { MoneyRegex } from "../../../../helpers/constants";
import { labelCompare } from "../../../../helpers/selectHelper";
import { IAppState } from "../../../../types/stores";

type Props = {
    destinationId: number;
    flowItemId: number;
};

const FlowExportDistributionPlatforms: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch();
    const { destinationId, flowItemId } = props;

    const destinationPartners = useSelector(
        (state: IAppState) => state.flowExportObjects.destinationPartners[destinationId] || null
    );
    const distributionPlatforms = useSelector((state: IAppState) => getDistributionPlatforms(state, props));
    const canEdit = useSelector((state: IAppState) => getSelectedFlowAndItemPermissions(state).item.canEdit);
    const flow = useSelector((state: IAppState) => state.flows.byId[state.selected.flow]);

    const [localPlatforms, setLocalPlatforms] = useState<Array<FlowExportDistributionPlatform>>(distributionPlatforms);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    useEffect(() => {
        if (!destinationPartners && destinationId) {
            dispatch(requestFlowExportDestinationPartners(destinationId));
        }
    }, [dispatch, destinationId, destinationPartners]);

    useEffect(() => {
        if (distributionPlatforms.length != localPlatforms.length) {
            setLocalPlatforms(distributionPlatforms);
        }
    }, [distributionPlatforms, localPlatforms]);

    const getValidationError = (attribute: string, value: any) => {
        let error;

        if (
            (attribute == "ContactName" ||
                attribute == "ContactEmail" ||
                attribute == "DestinationID" ||
                attribute == "CPM") &&
            value == ""
        ) {
            error = "Required";
        } else if (
            (attribute == "ContactEmail" && !isValidEmailAddress(value)) ||
            (attribute == "CPM" && !MoneyRegex.test(value))
        ) {
            error = "Invalid";
        } else if (attribute == "PartnerId" && value == 0) {
            error = "Required";
        }
        return error;
    };

    const addDistributionPlatform = () => dispatch(addExportDistributionPlatform(flowItemId));

    const updateDistributionPlatformAttribute = (id: number, attribute: string, value: any) =>
        dispatch(updateAttribute("flowExportDistributionPlatforms", id, attribute, value, true));

    const deletePlatform = (row: FlowExportDistributionPlatform) => {
        setLocalPlatforms(localPlatforms.filter(x => x.FlowDistribututionId !== row.FlowDistribututionId));
        dispatch(deleteFlowExportDistributionPlatform(row.FlowExportDistributionPlatformId));
    };

    const renderPartnerSelector = (row: FlowExportDistributionPlatform) => {
        const usedIds = distributionPlatforms.filter(x => x.PartnerId != 0).map(x => x.PartnerId);
        const selectOptions = destinationPartners
            .map(item => ({
                label: item.CompanyName,
                value: item.CompanyID,
                disabled: usedIds.includes(item.CompanyID),
                item,
            }))
            .sort(labelCompare);

        return (
            <Select
                displayEmpty
                value={row.PartnerId || ""}
                onChange={e => {
                    const newPartners = [...localPlatforms];
                    const partnerIndex = localPlatforms.findIndex(
                        x => x.FlowExportDistributionPlatformId == row.FlowExportDistributionPlatformId
                    );
                    newPartners[partnerIndex].PartnerId = e.target.value;
                    setLocalPlatforms(newPartners);
                    updateDistributionPlatformAttribute(
                        row.FlowExportDistributionPlatformId,
                        "PartnerId",
                        e.target.value
                    );
                }}
                style={{
                    fontSize: 16,
                    minWidth: 140,
                }}
                inputProps={{ style: { maxWidth: 110 } }}
                disabled={!canEdit || (flow && flow.IsLocked)}
                required
            >
                <MenuItem disabled value="">
                    <em>Select...</em>
                </MenuItem>
                {selectOptions.map(x => (
                    <MenuItem key={x.value} value={x.value}>
                        {x.label}
                    </MenuItem>
                ))}
            </Select>
        );
    };

    const handleChange = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { id, name, value } = e.target;
        const newPlatforms = [...localPlatforms];
        const index = localPlatforms.findIndex(x => x.FlowExportDistributionPlatformId == parseInt(id));
        newPlatforms[index] = {
            ...newPlatforms[index],
            [name]: value,
        };
        setLocalPlatforms(newPlatforms);
    };

    const renderEditableColumn = (row: FlowExportDistributionPlatform, columnName: string) => {
        if (destinationPartners == null) {
            return null;
        }

        let helperText;
        const value = row[columnName];
        const displayvalue = value != null ? value.toString() : "";
        const error = getValidationError(columnName, value != null ? value.toString() : "");

        if (columnName == "DestinationID") {
            if (row.PartnerId != 0) {
                const destinationPartner = destinationPartners.filter(x => x.CompanyID == row.PartnerId)[0];
                helperText = destinationPartner ? destinationPartner.DestinationIDHelperText : "";
            }
        }

        return (
            <TextField
                id={row.FlowExportDistributionPlatformId.toString()}
                disabled={!canEdit || (flow && flow.IsLocked)}
                defaultValue={displayvalue || ""}
                placeholder={helperText || ""}
                name={columnName}
                onBlur={e => {
                    handleChange(e);
                    updateDistributionPlatformAttribute(
                        row.FlowExportDistributionPlatformId,
                        columnName,
                        e.target.value
                    );
                }}
                required
                helperText={error ? error : null}
            />
        );
    };

    const renderDelete = (row: FlowExportDistributionPlatform) => (
        <Tooltip title={"Delete Distribution Platform Setting"}>
            <div>
                <IconButton
                    color="secondary"
                    onClick={() => deletePlatform(row)}
                    aria-label="delete platform"
                    disabled={!canEdit || !flow || (flow && flow.IsLocked)}
                >
                    <i className="material-icons">close</i>
                </IconButton>
            </div>
        </Tooltip>
    );

    let tableHeader = [
        ["PartnerId", "Partner Id", "smDown"],
        ["ContactName", "Contact Name", "smDown"],
        ["ContactEmail", "Contact Email", "smDown"],
        ["DestinationID", "Destination Id", "smDown"],
        ["CPM", "CPM", "smDown"],
        ["FlowExportDistributionPlatformId", "", "smDown"],
    ];

    const emptyMessage = ["EmptyMessage", "No Data to Display.", "smDown"];

    if (!destinationPartners || destinationPartners.length == 0) {
        return null;
    }

    const renderTable = () => {
        let data: Array<any> = [];
        localPlatforms.map(dataRow => {
            data.push([
                ["PartnerId", renderPartnerSelector(dataRow), "smDown"],
                ["ContactName", renderEditableColumn(dataRow, "ContactName"), "smDown"],
                ["ContactEmail", renderEditableColumn(dataRow, "ContactEmail"), "smDown"],
                ["DestinationID", renderEditableColumn(dataRow, "DestinationID"), "smDown"],
                ["CPM", renderEditableColumn(dataRow, "CPM"), "smDown"],
                ["FlowExportDistributionPlatformId", renderDelete(dataRow), "smDown"],
            ]);
        });

        return (
            <Paper>
                <Table size="medium">
                    <TableHeader headers={tableHeader} />
                    <TableBody data={data} page={page} rowsPerPage={rowsPerPage} emptyMessage={emptyMessage} />
                </Table>
                <TableFooter
                    displayPaging={true}
                    rowsPerPageOptions={[5, 10, 15]}
                    count={localPlatforms.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(_, newPage) => {
                        setPage(newPage);
                    }}
                    onRowsPerPageChange={e => {
                        setRowsPerPage(parseInt(e.target.value, 10));
                        setPage(0);
                    }}
                />
            </Paper>
        );
    };

    return (
        <>
            <div className="flow-edit-header">
                <div style={{ flexGrow: 1 }}>
                    <p>Distribution Platform Settings</p>
                    <span className="helper-text">
                        Input your platform specific information such as contact info and account numbers
                    </span>
                </div>
                <div className="flow-edit-header-actions">
                    <Button
                        variant="contained"
                        color="secondary"
                        className={"edit-button"}
                        startIcon={<i className="material-icons">add</i>}
                        onClick={addDistributionPlatform}
                        disabled={!canEdit || (flow && flow.IsLocked)}
                    >
                        Add Row
                    </Button>
                </div>
            </div>
            <Divider style={{ margin: "20px auto" }} />
            <div className="flow-edit-body">
                <div className="row">
                    <div className="col-sm-12">{renderTable()}</div>
                </div>
            </div>
        </>
    );
};

//@ts-ignore
const getDistributionPlatforms = createSelector(
    state => getFlowExportDistributionPlatformsArray(state),
    (_, props) => props.flowItemId,
    (
        allDistributionPlatforms: Array<FlowExportDistributionPlatform>,
        flowItemId: number
    ): Array<FlowExportDistributionPlatform> => allDistributionPlatforms.filter(x => x.FlowItemId == flowItemId)
);

export default FlowExportDistributionPlatforms;
