import { VariableValueType, IAdvertisersByCompany } from "./stores/vars";

export default class Foo {}

/// REDUX? ///
// This is mostly a hack to get a Dispatch() that accepts thunks and normal actions as well
// Not 100% this is best solution, see https://github.com/flowtype/flow-typed/issues/574,
// That's where I grabbed this
/* eslint-disable */
type State = any;
type Action = { type: $Subtype<string> };
type _ThunkAction<R> = (dispatch: Dispatch) => R;
type _ThunkActionGS<R> = (dispatch: Dispatch, getState: GetState) => R;
export type ThunkAction = _ThunkAction<any> | _ThunkActionGS<any>;
export type Dispatch = (action: Action | ThunkAction) => any;
export type GetState = () => State;
type ThunkActionCreator<Args, R, F: (...args: Args) => _ThunkAction<R>> = (...args: Args) => R;
export type BoundAction<F> = ThunkActionCreator<*, *, F>;
/* eslint-enable */
/// REDUX ///

export type MergeBehavior = "replace" | "merge-overwrite" | "merge";
export type SingleViewData = {
    barChartResults: any, // {Online Display: 11, Mobile Display: 4, Television: 0, ... }
    results: Array<SingleViewItem>,
    succes: boolean,
};
export type Folder = {
    name: string,
    id: number,
    userId: number,
    order: number,
    level: number,
    children: Array<Folder>,
    isCompanyShare: boolean,
    companyId: number,
    folderType: string,
    isInternalShare: boolean,
    isHistorical: boolean,
};

export type SortDirection = "asc" | "desc" | "";
export type SortByFields = {
    SortFieldId: ?number,
    SortDirection: ?SortDirection,
};
export type SortByFieldNoNull = {
    SortFieldId: number,
    SortDirection: SortDirection,
};

// Types for:
// Crosstab

// Needs to line up with CrosstabEngine.cs
export type MeasureField = {
    isSplit: boolean, // If the measure field is a split, we only use splitOptions and avoid the rest
    splitOptions: SplitOptions,
    fieldKey: number,
    aggregationOperator: string,
    windowOperator: string,
    windowOperatorOptions: WindowOperatorOptions,
    isHardcoded: boolean,
    hardcodedValue: string,
    measureType: number,
    dateDiffOptions: DateDiffOptions,
    houseExpandOptions: HouseExpandOptions,
};

export type HouseExpandOptions = {
    originalFlag: string,
    additionalFlag: string,
};

export type DateDiffOptions = {
    dateMeasureType: "day" | "month" | "year" | null,
    fieldDataType: "D" | "N" | null,
};

export type FieldCompareOptions = {
    operator: "equal" | "notequal" | "greater" | "greaterorequal" | "less" | "lessorequal" | null,
};

export type SplitOptions = {
    absoluteOrRelative: "absolute" | "relative" | null,
    absoluteNumRows: ?number,
    relativePercentRows: ?number,
};

// Needs to line up with CrosstabEngine.cs
export type WindowOperatorOptions = {
    num: ?number,
    direction: string,
    partitionField: ?number,
    intervals: Array<BandingInterval>,
};

// Needs to line up with CrosstabEngine.cs
export type BandingInterval = {
    lowerBound: BandingIntervalValue,
    upperBound: BandingIntervalValue,
    label: string,
};

// Needs to line up with CrosstabEngine.cs
export type BandingIntervalValue = {
    value: string, // "Infinity", "-Infinity", or number
    //value: number | "Infinity" | "-Infinity", // Try this?
    isInclusive: boolean,
};

// Needs to line up with CrosstabEngine.cs
export type OperatorInfo = {
    value: string,
    label: string,
    template: string,
    disallowedFieldDataTypes: Array<string>, // really Array<Char>
    disabled: boolean,
};
// End
// Crosstab / TableReport / CalculatedFields

export type DestinationVariable = {
    PartnerAccessId: number,
    VariableId: number,
    Id: number,
    Variablename: string,
};

export type DestinationVariableWithValue = DestinationVariable & {
    VariableValue: ?VariableValueType,
};

// SingleView
export type SingleViewItem = {
    AcceptFlag: "Y" | "N",
    AccessType: "F" | "N" | null,
    CPM: null, // Must be wrong
    CampaignId: number,
    CampaignSpend: null, // Must be wrong
    CompanyIcon: ?string, // "aol_80.png" or "NA" or null
    CompanyName: string,
    CompanySHortName: string,
    ContactEmail: string,
    ContactName: string,
    DateAccepted: ?string, // Microsoft date: "/Date(1494615984593)/"
    DeploySegmentCodeSetting: ?number,
    DistributionDate: ?string, // Microsoft date: "/Date(1494615984593)/"
    EndDate: ?string, // Microsoft date: "/Date(1494615984593)/"
    EndDistribution: ?string, // Microsoft date: "/Date(1494615984593)/"
    Expiration: null, // ?Wrong?
    Frequency: null, // ?Wrong?
    GroupCompanyIcon: string, // possibly empty
    GroupCompany_CompanyID: ?number,
    GroupHeader: ?string,
    NumberDistributions: number,
    OutputRank: number,
    PartnerId: number,
    Quantity: number,
    RequestFlag: null | "Y",
    SingleviewId: number,
    StartDate: ?string, // Microsoft date: "/Date(1494615984593)/"
    Status: number, // Always 0?
    SubHeader: ?string,
    Submitter: number,
    TableKey: number,
    VerticalId: number,
    VerticalName: string,
    Weight: ?number,
    isGrouped: boolean,
    isSelected: null | "Y" | "N",
    IsSingleviewCountRunning: boolean,
    IsSingleviewCountErrored: boolean,
    SegmentsBelowMinSingleViewCount: ?string,
};

export type SingleViewRequest = {
    CPM: string, // Null or ???
    CampaignSpend: ?number, // Null or float/double
    DistributionDate: string, // MM/dd/yyyy
    EndDistribution: string, // MM/dd/yyyy
    StartDate: string, // MM/dd/yyyy
    EndDate: string, // MM/dd/yyyy
    Status: number,
    PartnerId: number,
    CompanyName: string,
    ContactName: string,
    ContactEmail: string,
    DestinationId: string,
    FacebookId: string,
    CompanyIcon: string,
    IsGrouped: boolean,
    DestinationIDHelperText: string,
    SegmentDescription: string,
};

export const CrosstabMeasureType = {
    FIELD: 1,
    SPLIT: 2,
    FLAG: 3,
    FIELD_EQUALITY: 4,
    FIELD_DIFF: 5,
    FIELD_BANDING: 6,
    HOUSE_EXPAND: 7,
};
export type CrosstabMeasureTypeT = $Keys<typeof CrosstabMeasureType>;

export type Company = {
    CompanyID: number,
    CompanyName: string,
    CompanyActive: string, // y/n
    CompanyDateCreated: string, // 2014-01-21T11:01:40.783,
    CompanyCreatedBy: number,
    CompanyDateUpdated: string, // 2015-09-03T10:21:00,
    CompanyUpdatedBy: number,
    CompanyShortName: string,
    CompanyFolder: string,
    CompanyType: string, // u / U / ?
    CompanyIcon: string,
    CompanyDescription: string,
    CompanyLink: string,
    CompanyContactEmail: string,
    CompanySortOrder: null,
    DeploySegmentCodeSetting: null,
    Features: null, // dapper - always null
    ApplicationUsers: null, // dapper - always null
    CompanyExport: boolean,
    PrimaryKeyIsLUID: boolean,
    IsIndirectDestination: boolean,
};

export type NormalizedCompanyData = {
    // Output from normalizer
    entities: {
        companies: {
            [id: number]: Company,
        },
    },
    result: {
        companies: Array<number>,
    },
};

export type FeatureCompanies = {
    v_CompanyTable_CompanyID: number,
    Feature_Id: number,
};

export type Features = {
    Id: number,
    Name: string,
    Description: string,
    IsExternal: boolean,
    v_CompanyTables: null, // dapper - always null
    ApplicationUsers: null, // dapper - always null
};

export type IndexType = {
    [number]: Array<number>,
};
export type UniqueIndexType = {
    [number]: number,
};

// Notifications //
//type GenericGroupArgs = { [string]: any };
export type GroupArgsFlowItemStarted = {
    flowName: string,
    flowItemName: string,
    typeName: "GroupArgsFlowItemStarted",
};
export type GroupArgsFlowItemComplete = {
    flowName: string,
    flowItemName: string,
    qty: ?number,
    typeName: "GroupArgsFlowItemComplete",
};
export type NotificationGroupArgs = GroupArgsFlowItemComplete | GroupArgsFlowItemStarted; // Add other types here " | GroupArgsMyOtherType "
// Warning about these types in NotificationGroupArgs, your component that picks up the group args and reads them will need to refine the
export type NotificationObject = {
    id: number,
    text: string,
    componentName: string,
    width: string,
    className: string,
    delay: number,
    dismissed: boolean,
    groupName: string,
    groupArgsList: Array<NotificationGroupArgs>,
    variant: string,
};
export type NotificationOptions = {
    width: string,
    className: string,
    delay: number,
    componentName?: string,
    groupName?: string,
    groupArgs?: NotificationGroupArgs,
    variant: string,
};

export type User = {
    Id: number,
    OriginalKey: ?string,
    Email: ?string,
    EmailConfirmed: boolean,
    PhoneNumber: ?string,
    PhoneNumberConfirmed: boolean,
    TwoFactorEnabled: boolean,
    LockOutEndDateUtc: ?Date,
    LockOutEnabled: boolean,
    AccessFailedCount: number,
    UserName: string,
    FirstName: ?string,
    LastName: ?string,
    PasswordLastChanged: ?string,
    UserActive: ?boolean,
    LastLoginTime: ?string,
    RegistrationDate: ?string,
    CurrentCompanyID: ?number,
    IsInternal: boolean,
    IsSendWelcomeEmail: boolean,
    IsSSO: boolean,
    IsSSOB2B: boolean,
    ExpireDate: ?string,
    errors: ?any,
    LogonId: ?string,
};

export type NormalizedUserData = {
    // Output from normalizer
    entities: {
        users: {
            [id: number]: User,
        },
    },
    result: {
        users: Array<number>,
    },
};

export type UserCompany = {
    CompanyID: number,
    UserKey: number,
    UserSecurityLevelKey: ?number,
    ID: number,
    TableauAccess: ?string,
};

export type NormalizedUserCompanyData = {
    // Output from normalizer
    entities: {
        userCompanies: {
            [id: number]: UserCompany,
        },
    },
    result: {
        userCompanies: Array<number>,
    },
};

export type Role = {
    Id: number,
    Name: string,
    Description: ?string,
    InternalOnly: boolean,
};

export type NormalizedRoleData = {
    // Output from normalizer
    entities: {
        roles: {
            [id: number]: Role,
        },
    },
    result: {
        roles: Array<number>,
    },
};

export type Site = {
    Id: number,
    Name: ?string,
    Description: ?string,
    URI: ?string,
};

export type CompanySeat = {
    Id: number,
    CompanyId: number,
    AllowedMaximumSeats: number,
    UsedSeats: number,
    RequestedSeats: ?number,
    RequestedOn: ?Date,
    RequestedBy: ?number,
};

export type CheckStatusResponse = {
    hasAccess: Boolean,
};

export type SiteCustomization = {
    Id: number,
    Name: string,
    CustomizationType: string,
    Value: string,
};

export type DataloadDestination = {
    label: string,
    value: number,
    item: IAdvertisersByCompany,
};
