import React, { useState, useEffect, CSSProperties } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAttribute, invalidateItemAndChildren } from "../../../actions/flowActions";
import { requestClientField } from "../../../actions/exportLayoutActions2";
import { createSelector } from "reselect";
import { getFlowSVDedupesByFlowItemId } from "../../../reducers/flowSVDedupes";
import { makeGetPermissionsItemFromProps } from "../../../reducers/flowItems";
import SingleViewFieldPicker from "./Shared/SingleViewFieldPicker";
import SortByFieldsPicker from "../../misc/SortByFieldsPicker";
import { Divider } from "@material-ui/core";
import RadioButton from "../../material-components/Misc/RadioButton";
import { IAppState } from "../../../types/stores";
import { FlowSVDedupeD } from "../../../types/stores/flowTypes";
import { FlowSVDedupesByItemId } from "../../../types/stores/flowSVDedupes";
import InfoTooltip from "../../InfoTooltip";
import BouncingDots from "../../loading/BouncingDots";
import TreesWithSearch from "../../tree/TreesWithSearch";

type Props = {
    flowItemId: number;
    canEdit?: boolean;
    containerHeight?: CSSProperties;
};

type FlowSVDedupeSelector<S> = (state: IAppState, props: Props) => S;
export const makeGetFlowSVDedupe = (): FlowSVDedupeSelector<FlowSVDedupeD | undefined> =>
    // @ts-ignore
    createSelector(
        (state: IAppState) => getFlowSVDedupesByFlowItemId(state),
        (_, props: Props) => props.flowItemId,
        (flowSVDedupesByFlowItemId: FlowSVDedupesByItemId, flowItemId: number): FlowSVDedupeD | undefined =>
            flowSVDedupesByFlowItemId[flowItemId]
    );

const FlowSVDedupeEdit: React.FC<Props> = ({ flowItemId, canEdit, containerHeight }: Props) => {
    const dispatch = useDispatch();

    const getFlowSVDedupe = makeGetFlowSVDedupe();
    const getPermissionsItemFromProps = makeGetPermissionsItemFromProps();
    const isTreeLoading = useSelector((state: IAppState) => state.loadingStates.isTreeLoading);
    const flowSVDedupe = useSelector((state: IAppState) => getFlowSVDedupe(state, { flowItemId }));
    const clientFieldObject = useSelector((state: IAppState) => state.layoutObjects.clientFieldObject);
    const flow = useSelector((state: IAppState) => state.flows.byId[state.selected.flow]);
    const permissions = useSelector((state: IAppState) => getPermissionsItemFromProps(state, { flowItemId }));

    const [sortOption, setSortOption] = useState("random");

    useEffect(() => {
        const newSortOption = flowSVDedupe?.SortByFields && flowSVDedupe?.SortByFields.length > 0 ? "custom" : "random";
        setSortOption(newSortOption);
    }, [flowSVDedupe?.SortByFields]);

    useEffect(() => {
        dispatch(requestClientField());
    }, [dispatch]);

    useEffect(() => {
        // Default to first party key if not set
        if (flowSVDedupe && flowSVDedupe.SVField == null && clientFieldObject) {
            dispatch(
                updateAttribute("flowSVDedupes", flowSVDedupe.FlowSVDedupeId, "SVField", clientFieldObject.FieldName)
            );
        }
    }, [flowSVDedupe, clientFieldObject, dispatch]);

    const invalidate = () => dispatch(invalidateItemAndChildren(flowItemId));

    const fieldChanged = newVal => {
        if (flowSVDedupe != null) {
            dispatch(updateAttribute("flowSVDedupes", flowSVDedupe.FlowSVDedupeId, "SVField", newVal));
            invalidate();
        }
    };

    const sortByFieldsChanged = newVal => {
        if (flowSVDedupe != null) {
            dispatch(updateAttribute("flowSVDedupes", flowSVDedupe.FlowSVDedupeId, "SortByFields", newVal, true));
            invalidate();
        }
    };

    const handleOptionChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        if (newValue == "random") {
            sortByFieldsChanged([]);
        } else {
            setSortOption(newValue);
        }
    };

    if (flowSVDedupe == null) {
        return (
            <>
                <p>Error, table entry is missing.</p>
            </>
        );
    }

    const passedInCanEdit = canEdit != false ? true : false;

    return (
        <>
            <div className="flow-edit-header">
                <div style={{ flexGrow: 1, placeSelf: "flex-end" }}>
                    <p>Dedupe Settings</p>
                    <span className="helper-text">
                        Sort by random or fields by dragging and dropping below
                        <InfoTooltip id={flowItemId + "-sv-dedupe-sessings"}>
                            If you are not able to override dedupe settings, please reach out to a Super Administrator
                            to remove the override.
                        </InfoTooltip>
                    </span>
                </div>
                <div className="flow-edit-header-actions" style={{ marginLeft: "35px" }}>
                    <div className="row flow-dedupe-header">
                        <h6 className="m-t">Choose Dedupe Key</h6>
                        <SingleViewFieldPicker
                            value={flowSVDedupe.SVField}
                            onChange={fieldChanged}
                            disabled={
                                !passedInCanEdit ||
                                (flow && flow.IsLocked) ||
                                (permissions && permissions.item && permissions.item.canEdit == false)
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="flow-edit-body" style={containerHeight ? containerHeight : { height: "60vh" }}>
                <Divider style={{ margin: "20px auto" }} />
                <div className="sort-fields-header large-switch">
                    <span style={{ fontWeight: 500, textTransform: "none", marginRight: "10px" }}>Sorting</span>
                    <RadioButton
                        id="sort-option"
                        name="sortOption"
                        options={[
                            { label: "Random", value: "random" },
                            { label: "Sort By Fields", value: "custom" },
                        ]}
                        onChange={handleOptionChangeRadio}
                        disabled={
                            !passedInCanEdit ||
                            (flow && flow.IsLocked) ||
                            (permissions && permissions.item && permissions.item.canEdit == false)
                        }
                        row={true}
                        valChecked={sortOption}
                    />
                    <div className={sortOption == "random" ? "hidden" : ""}>
                        {passedInCanEdit && flow ? (
                            <div className="row" style={{ marginTop: "10px" }}>
                                <div className="col-xs-4">
                                    {isTreeLoading ? (
                                        <div
                                            style={{
                                                marginTop: "100px",
                                                width: "100%",
                                                textAlign: "center",
                                            }}
                                        >
                                            <BouncingDots />
                                            <p>Loading fields...</p>
                                        </div>
                                    ) : (
                                        <TreesWithSearch ctrlShift showFieldTree showExportTree outerHeight="550px" />
                                    )}
                                </div>
                                <div className="col-xs-8" style={{ marginTop: "-50px" }}>
                                    <SortByFieldsPicker
                                        onChange={sortByFieldsChanged}
                                        value={flowSVDedupe.SortByFields}
                                        disabled={
                                            !passedInCanEdit ||
                                            (flow && flow.IsLocked) ||
                                            (permissions && permissions.item && permissions.item.canEdit == false)
                                        }
                                    />
                                </div>
                            </div>
                        ) : (
                            <SortByFieldsPicker
                                onChange={sortByFieldsChanged}
                                value={flowSVDedupe.SortByFields}
                                disabled={
                                    !passedInCanEdit ||
                                    (flow && flow.IsLocked) ||
                                    (permissions && permissions.item && permissions.item.canEdit == false)
                                }
                            />
                        )}
                        {/* <SortByFieldsPicker
                            onChange={sortByFieldsChanged}
                            value={flowSVDedupe.SortByFields}
                            disabled={
                                !passedInCanEdit ||
                                (flow && flow.IsLocked) ||
                                (permissions && permissions.item && permissions.item.canEdit == false)
                            }
                        /> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default FlowSVDedupeEdit;
