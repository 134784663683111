import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setScheduleModeAndEditId } from "../../../actions/scheduleActions";
import wrapButtonWithConfirmModal from "../../../helpers/wrapButtonWithConfirmModal";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { IAppState } from "../../../types/stores";

type Props = {
    mode: string;
    editId: string | null;
    isScheduleTemplate?: boolean;
};

const ScheduleBreadCrumbs: React.FC<Props> = ({ mode, editId, isScheduleTemplate }: Props) => {
    const dispatch = useDispatch();

    const schedules = useSelector((state: IAppState) => state.schedules.schedules || []);
    const mySchedules = schedules.filter(x => editId && x.Id == editId);
    const schedule = mySchedules.length == 1 ? mySchedules[0] : null;
    const hasUnsavedChanges = useSelector(
        (state: IAppState) => state.schedules.isUnsavedChanges || state.schedules.triggersWithUnsavedChanges.length > 0
    );

    const schedulesLink = wrapButtonWithConfirmModal(
        "Schedules",
        hasUnsavedChanges,
        () => dispatch(setScheduleModeAndEditId("list", null, isScheduleTemplate)),
        "fake-link"
    );

    return (
        <>
            {mode == "addedit" && (
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit">{schedulesLink}</Link>
                    <Link
                        color="textPrimary"
                        aria-current="page"
                        onClick={() => dispatch(setScheduleModeAndEditId("addedit", editId, isScheduleTemplate))}
                    >
                        <span className="fake-link">{schedule ? schedule.Name : "(new schedule)"} </span>
                    </Link>
                </Breadcrumbs>
            )}
            {mode == "addedit_triggers" && (
                <Breadcrumbs aria-label="breadcrumb">
                    <Link>{schedulesLink}</Link>
                    <Link color="inherit">
                        {wrapButtonWithConfirmModal(
                            schedule ? schedule.Name : "",
                            hasUnsavedChanges,
                            () => dispatch(setScheduleModeAndEditId("addedit", editId, isScheduleTemplate)),
                            "fake-link"
                        )}
                    </Link>
                    <Typography color="textPrimary" aria-current="page" style={{ fontSize: 18 }}>
                        Triggers
                    </Typography>
                </Breadcrumbs>
            )}
            {mode == "audit" && (
                <Breadcrumbs aria-label="breadcrumb">
                    <Link>{schedulesLink}</Link>
                    <Link
                        color="inherit"
                        onClick={() => dispatch(setScheduleModeAndEditId("addedit", editId, isScheduleTemplate))}
                    >
                        <span className="fake-link">{schedule ? schedule.Name : ""}</span>
                    </Link>
                    <Typography color="textPrimary" aria-current="page" style={{ fontSize: 18 }}>
                        Audit
                    </Typography>
                </Breadcrumbs>
            )}
            {mode == "log" && (
                <Breadcrumbs aria-label="breadcrumb">
                    <Link>{schedulesLink}</Link>
                    <Link
                        color="inherit"
                        onClick={() => dispatch(setScheduleModeAndEditId("addedit", editId, isScheduleTemplate))}
                    >
                        <span className="fake-link"> {schedule ? schedule.Name : ""} </span>
                    </Link>
                    <Typography color="textPrimary" aria-current="page" style={{ fontSize: 18 }}>
                        Action Log
                    </Typography>
                </Breadcrumbs>
            )}
        </>
    );
};

export default ScheduleBreadCrumbs;
