import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import type { FlowItem, FlowEmpty } from "../types/flowTypes";

const myGenericReducer = makeReducerFor("FLOW_EMPTY", "FlowEmptyId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowEmptiesArray = createSelector(
    state => state.flowEmpties.byId,
    (flowEmptiesById: {| [number]: FlowEmpty |}): Array<FlowEmpty> => {
        const r: Array<FlowEmpty> = Object.values(flowEmptiesById);
        return r;
    }
);

export type FlowEmptiesByItemId = {
    [number]: FlowEmpty,
};

export const getFlowEmptiesByFlowItemId = createSelector(
    state => getFlowEmptiesArray(state),
    (flowEmpties: Array<FlowEmpty>): FlowEmptiesByItemId =>
        flowEmpties.reduce((acc, row) => {
            acc[row.FlowItemId] = row;
            return acc;
        }, {})
);

export const getFlowEmptiesForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowEmptiesByFlowItemId(state),
    (selectedFlow: number, flowItems: Array<FlowItem>, flowEmptiesByItemId: FlowEmptiesByItemId): Array<FlowEmpty> => {
        const result = [];
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        for (const itemId of itemIds) {
            const empty = flowEmptiesByItemId[itemId];
            if (empty != null) {
                result.push(empty);
            }
        }
        return result;
    }
);
