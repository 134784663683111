function joinEdit(
    state = {
        existingJoins: [],
        joins: [],
        MasterFields: [],
        ChildFields: [],
        joinId: -1,
        tables: [],
        uploading: false,
    },
    action
) {
    switch (action.type) {
        case "GET_AVAILABLE_JOINS":
            return Object.assign({}, state, {
                existingJoins: action.existingJoins,
            });
        case "GET_MASTER_CHILD_AND_FIELDS":
            return Object.assign({}, state, {
                joins: action.joins,
                MasterFields: action.MasterFields,
                ChildFields: action.ChildFields,
                joinId: action.joinId,
            });
        case "GET_AVAILABLE_TABLES":
            return Object.assign({}, state, {
                tables: action.tables,
            });
        case "SAVE_JOINS": {
            return Object.assign({}, state, {
                uploading: action.uploading,
            });
        }
        case "DELETE_JOIN": {
            return Object.assign({}, state, {
                uploading: action.uploading,
            });
        }
        default:
            return state;
    }
}

export default joinEdit;
