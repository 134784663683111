import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { hexToRgb, ColorLuminance } from "../../../styles/dashComponentStyles/myDashboard";

export const ColorButton = withStyles(theme => ({
    root: {
        color: props => theme.palette.getContrastText(props.backgroundcolor),
        backgroundColor: props => props.backgroundcolor,
        border: props =>
            props.outlined ? "2px solid " + theme.palette.getContrastText(props.backgroundcolor) + " !important" : null,
        transition:
            "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "&:hover": {
            color: props => theme.palette.getContrastText(props.backgroundcolor),
            backgroundColor: props => ColorLuminance(props.backgroundcolor, -0.1),
            boxShadow: props =>
                "0 4px 20px 0 rgba(" +
                hexToRgb("#FFF") +
                ",.14), 0 7px 10px -5px rgba(" +
                hexToRgb(props.backgroundcolor) +
                ",.4)",
        },
    },
}))(Button);
