import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../actions/actionCreators";
import * as flowActions from "../../actions/flowActions";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

class CompanySelect extends React.Component {
    constructor(props) {
        super(props);
        const { selectedCompany } = this.props;

        this.state = {
            selectedCompany,
        };
    }

    componentDidMount() {
        if (this.props.isAuthenticated && this.props.companies.length == 0) {
            this.props.requestMyCompanies();
        }
    }

    componentDidUpdate(prevProps) {
        const isLoggingIn = !prevProps.isAuthenticated && this.props.isAuthenticated;

        if (isLoggingIn) {
            this.props.requestMyCompanies();
        }
    }

    changeCompany = e => {
        const newCompanyId = e.target.value;
        const oldCompanyId = this.state.selectedCompany;

        if (this.props.isUpdateOnChange) {
            if (this.props.flow != null && this.props.flow.hasUnsavedChanges) {
                this.props.CAPageChangeCompany(newCompanyId, oldCompanyId);
            } else {
                this.props.deleteAllItemsInFlow(-1);
                this.props.CAPageChangeCompanyNoUnsavedCheck(newCompanyId);
                this.setState({ selectedCompany: newCompanyId });
            }
        }
        if ("handleFieldChange" in this.props) {
            this.props.handleFieldChange(newCompanyId);
            this.setState({ selectedCompany: newCompanyId });
        }
    };

    handleChange = e =>
        this.props.activeConnections > 0
            ? // #6195 - http://gitlab.solutiondesign.experian.local/sdt/oap/-/issues/6195
              this.props.showModal("FILE_BIG_SIZE_UPLOAD_WARNING", {
                  continue: () => this.changeCompany(e),
                  type: "Change Company",
              })
            : this.changeCompany(e);

    render() {
        const { companies, isAuthenticated, hasLabel, helperText } = this.props;
        const { selectedCompany } = this.state;
        if (!isAuthenticated) {
            return null;
        }
        const sortedCompanies = companies.sort((a, b) => {
            const nameA = a.CompanyName.toLowerCase();
            const nameB = b.CompanyName.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        const options = sortedCompanies.map(company => (
            <MenuItem key={company.CompanyID} className="company-select-option" value={company.CompanyID}>
                {company.CompanyName}
            </MenuItem>
        ));

        return (
            <FormControl style={{ minWidth: "200px" }}>
                {hasLabel && <InputLabel htmlFor={`CompanySelectionSelect`}>Company</InputLabel>}
                <Select
                    displayEmpty
                    value={selectedCompany}
                    onChange={this.handleChange}
                    inputProps={{
                        id: "CompanySelectionSelect",
                        style: { minWidth: "200px" },
                    }}
                >
                    {options}
                </Select>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        );
    }
}
CompanySelect.propTypes = {
    // Redux
    enabledFeatures: PropTypes.array,
    companies: PropTypes.array,
    selectedCompany: PropTypes.number,
    isAuthenticated: PropTypes.bool,
    setSelectedFlow: PropTypes.func,
    deleteAllItemsInFlow: PropTypes.func,
    flow: PropTypes.object,
    activeConnections: PropTypes.number,
    // Injected by Redux (action creator)
    setVar: PropTypes.func,
    requestMyCompanies: PropTypes.func,
    CAPageChangeCompany: PropTypes.func,
    CAPageChangeCompanyNoUnsavedCheck: PropTypes.func,
    showModal: PropTypes.func,
    // Props
    isUpdateOnChange: PropTypes.bool,
    handleFieldChange: PropTypes.func,
    hasLabel: PropTypes.bool,
    helperText: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => ({
    companies: state.session.companies || [],
    selectedCompany: state.session.companyId,
    isAuthenticated: state.session.isAuthenticated,
    isUpdateOnChange: ownProps.isUpdateOnChange || false,
    flow: state.flows.byId[state.selected.flow],
    enabledFeatures: state.session.enabledFeatures || [],
    activeConnections: state.fileImportQueue.activeConnections || 0,
});
const mapDispatchToProps = dispatch => bindActionCreators(Object.assign({}, flowActions, actionCreators), dispatch);
const CompanySelectC = connect(mapStateToProps, mapDispatchToProps)(CompanySelect);

export default CompanySelectC;
