import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../actions/actionCreators";
import { requestFieldTree, setSelectedField } from "../../actions/fieldTreeActions";
import withModal from "../modals/withModal";
import FieldListEntryText from "./FieldListEntryText";
import makeTooltipForField from "./makeTooltipForField";
import HtmlTooltip from "../material-components/Misc/HtmlTooltip";
import { IAppState } from "../../types/stores/index";

type Props = { value: string | number; onChange: (fieldId: string | number | null) => void };

const FieldPicker: React.FC<Props> = ({ value, onChange }: Props) => {
    const dispatch = useDispatch();

    const field = useSelector(
        (state: IAppState) => (state.fields && state.fields.byId && state.fields.byId[value]) || null
    );
    const isInNewFlows = useSelector((state: IAppState) =>
        Boolean(
            state.router &&
                state.router.location &&
                state.router.location.pathname &&
                state.router.location.pathname.includes("/flows")
        )
    );

    useEffect(() => {
        if (value != null) {
            dispatch(requestFieldTree(0, 5));
        }
    }, [value, dispatch]);

    const reset = () => onChange(null);

    const openModal = () => {
        // change selected field to VALUE
        dispatch(setSelectedField(value));
        dispatch(requestFieldTree(0, 5));
        dispatch(showModal("FIELD_PICKER_MODAL", { onChange }));
    };

    let fieldDisplay;
    let thisToolTip = <em>(No Field Selected)</em>;
    if (!value) {
        fieldDisplay = <em>(No Field Selected)</em>;
        thisToolTip = isInNewFlows ? (
            <em>(No Field Selected)</em>
        ) : (
            <HtmlTooltip id={"tooltip" + value.toString()} title="No Field Selected">
                <em>(No Field Selected)</em>
            </HtmlTooltip>
        );
    } else {
        if (field) {
            thisToolTip = makeTooltipForField(field, "");
        }
        fieldDisplay = <FieldListEntryText fieldKey={value} />;
    }

    return (
        <HtmlTooltip placement="right" title={thisToolTip} maxwidth={500} enterDelay={250}>
            <div className="field-picker">
                {fieldDisplay}
                <i className="material-icons clickable x-small-fab" onClick={openModal}>
                    edit
                </i>
                <i className="material-icons clickable x-small-fab" onClick={reset}>
                    clear
                </i>
            </div>
        </HtmlTooltip>
    );
};

export default withModal(FieldPicker, "FIELD_PICKER");
