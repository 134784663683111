import React from "react";

import { connect } from "react-redux";
import highlightForSearch from "../../helpers/highlightForSearch";

type Props = {
    // Passed in
    text: string,
    // Redux
    treeSearch: string | Array<string>,
};

class TextWithHighlightedSearchTerms extends React.Component<Props> {
    render() {
        const { text, treeSearch } = this.props;
        if (text == null) {
            return null;
        }

        const displayText = highlightForSearch(text, treeSearch);

        return <span>{displayText}</span>;
    }
}

import { getTreeSearch } from "../../reducers/search";
import type { MapStateToProps } from "react-redux";

const mapStateToProps: MapStateToProps<*, *, *> = state => ({
    treeSearch: getTreeSearch(state),
});
const mapDispatchToProps = null;
const TextWithHighlightedSearchTermsC = connect(mapStateToProps, mapDispatchToProps)(TextWithHighlightedSearchTerms);

export default TextWithHighlightedSearchTermsC;
