import React from "react";
import GenericConfirmModal from "../components/modals/GenericConfirmModal";

const wrapButtonWithConfirmModal = (buttonText, hasUnsavedChanges, onConfirm, cnBtnStyle) =>
    hasUnsavedChanges ? (
        <GenericConfirmModal
            onConfirm={onConfirm}
            body={`You have unsaved changes. Are you sure you wish to continue and discard your changes?`}
            confirmText="Discard Changes"
            title="Possible Loss of Work"
            buttonText={buttonText}
            cnBtnStyle={cnBtnStyle}
        />
    ) : (
        <span className={cnBtnStyle} onClick={onConfirm}>
            {buttonText}
        </span>
    );
export default wrapButtonWithConfirmModal;
