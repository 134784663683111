import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sessionActions from "../../actions/sessionActions";

const LoginLogout = ({ isAuthenticated, requestLogout }) =>
    isAuthenticated ? (
        <span onClick={() => requestLogout()} id="logoutanchor">
            Log Out
        </span>
    ) : (
        <a href="/Account/Login">Log In</a>
    );
LoginLogout.propTypes = {
    isAuthenticated: PropTypes.bool,
    requestLogout: PropTypes.func,
};

const mapStateToProps = state => ({
    isAuthenticated: state.session.isAuthenticated,
});
const mapDispatchToProps = dispatch => bindActionCreators(sessionActions, dispatch);
const LoginLogoutC = connect(mapStateToProps, mapDispatchToProps)(LoginLogout);

export default LoginLogoutC;
