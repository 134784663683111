import type { Site } from "../types/types";

function sites(state: Array<Site> = [], action: any) {
    switch (action.type) {
        case "SITE_REPLACE_LIST": {
            const newState = action.sites;
            return newState;
        }

        default:
            return state;
    }
}

export default sites;
