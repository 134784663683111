import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../types/stores";
import { IField } from "../../../types/stores/fieldTypes";

type Props = { field: IField };

const DistributionCountsDisplay: React.FC<Props> = ({ field }: Props) => {
    const [currentDistributionCount, setCurrentDistributionCount] = useState(0);
    const fieldDistributionCountsById = useSelector(
        (state: IAppState) => state.fieldsByCompany.fieldDistributionCounts
    );
    const DCtext = "Experian Household Count";
    const DCFprmat = new Intl.NumberFormat("en-US", {
        notation: "compact",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    useEffect(() => {
        let dc = fieldDistributionCountsById[field.fieldkey];
        if (dc && dc.Fieldcount != 0) {
            setCurrentDistributionCount(dc.Fieldcount);
        }
    }, [field, fieldDistributionCountsById]);

    return <> {currentDistributionCount != 0 ? DCFprmat.format(currentDistributionCount) + " " + DCtext : null}</>;
};

export default DistributionCountsDisplay;
