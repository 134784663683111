import { IAwsStore, AwsActions } from "../types/actions/aws";
export const SET_AWS_S3_FILE_LIST_LOADING = "SET_AWS_S3_FILE_LIST_LOADING";
export const SET_AWS_S3_FILE_LIST_ERROR = "SET_AWS_S3_FILE_LIST_ERROR";
export const SET_AWS_S3_FILE_LIST = "SET_AWS_S3_FILE_LIST";
export const SET_AWS_S3_FILE_DOWNLOADING = "SET_AWS_S3_FILE_DOWNLOADING";
export const SET_AWS_S3_FILE_INFO = "SET_AWS_S3_FILE_INFO";
export const RESET_AWS_S3 = "RESET_AWS_S3";
export const SET_AWS_FLOW_CONTROL_FILES_INFO = "SET_AWS_FLOW_CONTROL_FILES_INFO";

function aws(
    state = {
        files: [],
        error: false,
        isLoading: false,
        isDownloading: false,
        filePreview: "",
        fileQuantity: 0,
        fileName: "",
        flowControlFilesInfo: [],
    },
    action: AwsActions
): IAwsStore {
    switch (action.type) {
        case SET_AWS_S3_FILE_LIST:
            return Object.assign({}, state, {
                files: action.files,
                error: null,
            });
        case SET_AWS_S3_FILE_LIST_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case SET_AWS_S3_FILE_LIST_ERROR:
            return Object.assign({}, state, {
                error: action.error,
            });
        case SET_AWS_S3_FILE_DOWNLOADING:
            return Object.assign({}, state, {
                isDownloading: action.isDownloading,
            });
        case SET_AWS_S3_FILE_INFO:
            return Object.assign({}, state, {
                filePreview: action.filePreview,
                fileName: action.fileName,
                fileQuantity: action.fileQuantity,
            });
        case RESET_AWS_S3:
            return Object.assign({}, state, {
                files: [],
                error: false,
                isLoading: false,
                isDownloading: false,
                fileName: "",
                filePreview: "",
            });
        case SET_AWS_FLOW_CONTROL_FILES_INFO:
            return Object.assign({}, state, {
                flowControlFilesInfo: action.flowControlFilesInfo,
            });

        default:
            return state;
    }
}

export default aws;
