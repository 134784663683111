import React from "react";
import { Icon, Tooltip } from "@material-ui/core";

type Props = {
    placement?:
        | "bottom-end"
        | "bottom-start"
        | "bottom"
        | "left-end"
        | "left-start"
        | "left"
        | "right-end"
        | "right-start"
        | "right"
        | "top-end"
        | "top-start"
        | "top";
    id: string;
    children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal;
    style?: { [attribute: string]: string };
};

// Adds circle with i and specified tooltip
const InfoTooltip: React.FC<Props> = ({
    placement = "right",
    id = "tooltip",
    style = { marginLeft: "5px" },
    children,
}: Props) => (
    <Tooltip id={id} placement={placement} title={children}>
        <Icon style={style}>info</Icon>
    </Tooltip>
);

export default InfoTooltip;
