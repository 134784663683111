import React from "react";
import LoadJQueryCron from "./LoadJQueryCron";
import CronStringEditor from "./CronStringEditor";
import { ScheduleState } from "../schedules/ScheduleAddEdit";
import prettyCron from "prettycron";

type Props = {
    schedule: ScheduleState;
    setSchedule: React.Dispatch<React.SetStateAction<ScheduleState>>;
    updateUnsavedChanges: () => void;
    isScheduleTemplate?: boolean;
    disabled?: boolean;
};

const ScheduleCronStringEditor: React.FC<Props> = ({
    schedule,
    setSchedule,
    updateUnsavedChanges,
    isScheduleTemplate,
    disabled,
}: Props) => {
    const handleCronStringChange = cronString => {
        setSchedule(schedule => ({
            ...schedule,
            name: schedule.isCustomName ? schedule.name : prettyCron.toString(cronString),
            cronString,
        }));
        updateUnsavedChanges();
    };

    return schedule.cronString ? (
        <div className="m-b">
            <LoadJQueryCron>
                <CronStringEditor
                    key={"a" + schedule.id}
                    defaultValue={schedule.cronString}
                    onChange={handleCronStringChange}
                    isScheduleTemplate={isScheduleTemplate}
                    disabled={disabled}
                />
            </LoadJQueryCron>
        </div>
    ) : null;
};

export default ScheduleCronStringEditor;
